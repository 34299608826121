import React from 'react';

import './Inputs.css';


class InputArray extends React.Component{ 

	generateRowN(ii) {

		return(
			<tr className='input-table-row'>
			    <td className='input-table-row-label'>{this.props.labels[ii]}</td>

				<td className='input-table-data-cell'>
			    	<input 
			    		className='input-table-data-field'
			            name={`'item-${ii}' `}
			            value={this.props.data[ii]} 
			            onChange={this.props.handleChange} 
			            onBlur={this.props.onExit}
			            data-index-num={ii}
			            data-name={this.props.dataName}
			            required
			        />
			    </td>

			  </tr>
		)
	}

	generateRowAll() {
		let nRows = this.props.labels.length;
		let rowNums = [...Array(nRows).keys()]

		return(
			<React.Fragment>
				{rowNums.map((ii) => (
					this.generateRowN(ii)
			    ))}
			</React.Fragment>
		)
	}
	

  render(){ 
  	if(!this.props.hideComponent){
  		return( 
	    	<section className = 'input-table-container'>
				<h3 className='input-title'>{this.props.tableName}</h3>
		      	<form className='input-table-form'> 

			      	<table className='input-table'>
			      	  {this.generateRowAll()}
					</table>			      	
			    </form> 
		    </section>
	    )
  	} else {
  		return null;
  	}
     
  } 
} 


export default InputArray;