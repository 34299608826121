import React from 'react';
import { withRouter } from 'react-router-dom';



class NewSuccess extends React.Component{ 

  loginRedirect() {
    this.props.history.push({
        pathname: '/login',
      })
  }

  render(){ 
    return( 
      <div className="acct-msg-container">
        <h2 className="account-h2"> Account Successfully Created </h2>
        <p className="account-text"> 
          For security purposes, new accounts must be
          manually approved by Berkeley Lab staff at this time. 
          <br/><br/>
          While your account
          is pending, you will not be able to sign in to the application.
          <br/><br/>
          Please allow up to two business days for your new account to
          be approved. If you do not receive a response within this period,
          please contact our support team at <span className="account-highlight">dercam@lbl.gov</span>
          <br/><br/>
          We appreciate your patience during this process.
       </p>

       <div className='text-button activate-button' onClick={()=>{this.props.history.push({pathname: '/login'})}}>
          <h2>Account Login</h2>
        </div>

      </div>
    ) 
  } 
} 


export default withRouter(NewSuccess);