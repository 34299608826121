import React from 'react';

import Helper from '../../helper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

import ResultsTable from '../ResultsTable/ResultsTable';
import ChartMonthly from '../ChartMonthly/ChartMonthly'
import ToggleSection from '../ToggleSection/ToggleSection'
import ResultsSelect from './ResultsSelect'

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';

import { convertAnnDataForPlot, convertMonDataForPlot, processMonTable, processTsPlotData,
				 getMetricOptions, getMetricFromKey, transposeData,
				 filterFirst, filterMetricCat, processInvPlot, unpackInvData, unpackAnnData, unpackMonData
			 } from './resultsProcessing'


class SectionMon extends React.Component {

	constructor(props){
		super(props)

		this.renderSection = this.renderSection.bind(this);
	}

	renderSection() {

		// define annual metric title, if a valid metric has been selected
		var curMonMetric = getMetricFromKey(this.props.metricsMon, this.props.keyMonMetric)
		
		if(curMonMetric) {
			var monChartTitle = `${curMonMetric.label} [Unit:${curMonMetric.unit}]`;
		} else {
			var monChartTitle = 'Select Metric To Display';
		}

		// format monthly metric data for table view
		var curMonMetricData = null
		if(this.props.dataMonTable && this.props.keyMonMetric) {
			// console.log(this.props.dataMonTable)
			// console.log(this.props.keyMonMetric)
			curMonMetricData = this.props.dataMonTable[this.props.keyMonMetric]
			curMonMetricData = processMonTable(curMonMetricData)

			// console.log(curMonMetricData)
		}


		var curMonDataTable = processMonTable()

		return(
			<div className='results-section'>
				<h2 className='results-heading'>4. Monthly Metrics</h2>
				
				<div className='res-select-multi-container'>
					<ResultsSelect 
						name='keyMonCat'
						label='Category'
						onChange={this.props.updateMonSelect}
						optionList={this.props.metricCategories}
					/>
					<ResultsSelect 
						name='keyMonMetric'
						label='Metric'
						onChange={this.props.updateMonSelect}
						optionList={getMetricOptions(this.props.metricsMon, this.props.keyMonCat)}
					/>
				</div>


				<div className='results-chart-container'>
					<h3 className='chart-title'>{monChartTitle}</h3>
					< ChartMonthly 
						data={this.props.dataMonPlot}
						colors={this.props.monColorMap}
						scenarios={this.props.scenarios}
					/>
				</div>

				<ToggleSection
					label='Monthly Metric Tables'
				>

					<div className='results-subsection'>
						<h3 className='chart-title'>{`Existing Assets - ${monChartTitle}`}</h3>
						<ResultsTable
							data={curMonMetricData.ex}
							procColLabels={this.props.getOutageScenario}
						/>
					</div>

					<div className='results-subsection'>
						<h3 className='chart-title'>{`New Investments - ${monChartTitle}`}</h3>
						<ResultsTable
							data={curMonMetricData.new}
							procColLabels={this.props.getOutageScenario}
						/>
					</div>
					<div className='results-note-table'>
						<p>
							Note: data tables can be highlighted, copied, and pasted
							into Excel or other spreadsheet applications.
							<br/><br/>
							These tables show data for the metric selected above.
							They will update if a new metric is selected.
						</p>			
					</div>
				</ToggleSection>
			</div>
		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Monthly Metrics'
			/>
		)
	}

}

export default SectionMon;