import React from 'react';

import InputField from '../Inputs/InputField';
import InputTextArea from '../Inputs/InputTextArea';
import InputSelect from '../Inputs/InputSelect';
import InputCheckbox from '../Inputs/InputCheckbox';
import InputDataField from'../Inputs/InputDataField';

import Modal from '../Modal/Modal';

import HelpBox2 from '../HelpBox/HelpBox2'

import { ReactComponent as LoadIcon } from '../../icons/loading.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

import Helper from '../../helper';

const _ = require('lodash');


class ProjectPageLoads extends React.Component {

	constructor(props) {
		super(props)

		this.state = {

			displayToggle: true, // binary to display menu 1 vs 2

			displayIndex: 0, // index of which input menu to display

			isFormatted: false, // binary whether user-data has been formatted

			refData: null,

			dlPending: false,
			dlSuccess: false,
			dlFail: false,

			formatPending: false,
			formatSuccess: false,
			formatFail: false,

			dataErrorMsg: ''

		}

		this.returnToFormatter = this.returnToFormatter.bind(this);
		this.switchForm = this.switchForm.bind(this);
		this.switchFormIndex = this.switchFormIndex.bind(this);
		this.toggleFormatted = this.toggleFormatted.bind(this);

		this.downloadData = this.downloadData.bind(this);
		this.writeUserData = this.writeUserData.bind(this);

		this.renderRefMenu = this.renderRefMenu.bind(this);
		this.render8760Menu = this.render8760Menu.bind(this);
		this.renderDaytypeMenu = this.renderDaytypeMenu.bind(this);
		// this.renderMenuSelector = this.renderMenuSelector.bind(this);

	}

	switchForm() {
		this.setState({displayToggle:!this.state.displayToggle})
	}

	switchFormIndex(newIndex) {
		this.setState({
			displayIndex:newIndex,
			isFormatted: false // set formatted flag to false on any menu switch
		})
	}

	toggleFormatted() {

		// if isFormatted currently false, run process8760 func in props
		if (!this.state.isFormatted) {
			this.props.process8760()
		}

		// toggle current isFormatted state
		this.setState({isFormatted:!this.state.isFormatted})
	}

	returnToFormatter() {
		// drops all state flags, so that component renders the menu

		this.setState(
			{
				dlPending: false,
				dlSuccess: false,
				dlFail: false,
				formatPending: false,
				formatSuccess: false,
				formatError: false,
				dataErrorMsg: ''
			}
		)
	}

	calcScaling() {
		// calculates the scaling factors for elec and ng based on inputs selected by user

		const scalingFactors = {
			'elec': 1,
			'ng': 1,
		}

		// check if bldg has been selected. if not return defaults
		if(!this.props.selectedBldg){
			return scalingFactors
		}

		// extract ref size and consumption
		const refSize = this.props.selectedBldg.SIZE;
		const refElec = this.props.selectedBldg.ELEC/1000;
		const refNg = this.props.selectedBldg.NG/1000;

		// determine if user is scaled by size or energy
		if(this.props.bldg_scaleBin) {
			// if bldg_scaleBin, scale by size

			scalingFactors.elec = +(this.props.bldg_size/refSize).toFixed(3)
			scalingFactors.ng = +(this.props.bldg_size/refSize).toFixed(3)							

		} else {
			// otherwise, scale by energy values

			scalingFactors.elec = +(this.props.bldg_annElec/refElec).toFixed(3)
			scalingFactors.ng = +(this.props.bldg_annNg/refNg).toFixed(3)

		}

		return scalingFactors


	}

	async downloadData() {

		await this.setState({dlPending:true})

		// download data from server
		await Helper.sleep(2000)

		// check that bldg id has been selected
		if(!Number.isInteger(this.props.bldg_bldgId)){
			var dataErrorMsg = "No building selected or bldg ID not found"
			this.setState({dlPending:false, dlFail: true, dataErrorMsg: dataErrorMsg})
			return
		}

		// check that user has selected node to apply to
		if(!this.props.nodeList.includes(parseInt(this.props.bldg_node))){
			var dataErrorMsg = "Invalid node selected. Please select location where load data should be written"
			this.setState({dlPending:false, dlFail: true, dataErrorMsg: dataErrorMsg})
			return
		}


		// url to fetch data for building reference library
		let urlBldgData = `${process.env.REACT_APP_URL_BASE}/data/load/dl?bldgId=${this.props.bldg_bldgId}`;

		var refData = [];

		let response = await fetch(urlBldgData, {credentials: 'include'});

	    // if status GET request successful
	    if(response.status===200) {
	      	refData = await response.json();
	    } else {
	    	var dataErrorMsg = "Server failed to return requested building data"
			this.setState({dlPending:false, dlFail: true, dataErrorMsg: dataErrorMsg})
			return
	    }

		// calc scaling factor
		const scalingFactors = this.calcScaling()

		// create copy of load template
		var newLoadData = _.cloneDeep(this.props.loadTemplate)
		const refLoadData = _.cloneDeep(refData.data)

		// list of end-uses for elec or ng scaling
		const loadTypesElec = ['electricity-only', 'cooling', 'refrigeration', ];
		const loadTypesNg = ['space-heating', 'water-heating', 'naturalgas-only'];

		// get keys for refLoadData nest dict
		const eKeys = Object.keys(refLoadData) // end-use key list
		const dKeys = Object.keys(refLoadData[eKeys[0]]); // day-type key list
		const mKeys = Object.keys(refLoadData[eKeys[0]][dKeys[0]]); // month key list

		// loop through ref data keys
		for(let ee=0; ee<eKeys.length; ee++) {
			for(let dd=0; dd<dKeys.length; dd++) {
				for(let mm=0; mm<mKeys.length; mm++) {
					
					// get current keys
					let eKey = eKeys[ee];
					let dKey = dKeys[dd];
					let mKey = mKeys[mm];

					// get hourly data from ref data
					let hourlyData = refLoadData[eKey][dKey][mKey];

					// determine scaling 
					if(loadTypesElec.includes(eKey)) {
						var scalar = scalingFactors.elec
					} else {
						var scalar = scalingFactors.ng
					}

					// apply scalar to hourly data
					hourlyData = hourlyData.map((val) => {
						return +(val*scalar).toFixed(2);
					})

					// get indices of keys from template data
					// Note: order of indices is different between ref and template data
					let eInd = newLoadData.rowLabels1.indexOf(eKey);
					let mInd = newLoadData.rowLabels2.indexOf(Helper.capitalizeFirst(mKey)); // have to capitalize month due to format mismatch
					let dInd = newLoadData.rowLabels3.indexOf(dKey);

					// check that indices all successfully found
					if(eInd < 0 || mInd < 0 || dInd < 0) {
						console.log(`cannot find indices for ${eKey} ${dKey} ${mKey}`)
						continue
					} else {
						// console.log(`copying data for ${eKey} ${dKey} ${mKey}`)
						// console.log(hourlyData)
					}

					// if all indices found, apply data to template
					newLoadData.data[eInd][mInd][dInd] = hourlyData

				}	
			}
		}


		// determine destination table location
		const destTableName = `loadinput_n${this.props.bldg_node}_p`

		// update label data in newLoadData obj
		newLoadData.key = `loadinput_n${this.props.bldg_node}_p`;
        newLoadData.label = `Input Load - Node ${this.props.bldg_node}`;

		// write new data to table
		var writeStatus = this.props.replaceTable(newLoadData, destTableName)

		// if success, return to formatter
		if(writeStatus.success){
			await this.setState({dlPending:false, dlSuccess: true, refData: refData, newLoadData:newLoadData})
		} else {
			var dataErrorMsg = writeStatus.msg
			await this.setState({dlPending:false, dlFail: true, refData: refData, newLoadData:newLoadData, dataErrorMsg:dataErrorMsg})
		}

	}

	async writeUserData() {

		// method for processing user-provided 8760 data
		// and writing to table data

		await this.setState({formatPending:true})

		// download data from server
		await Helper.sleep(2000)

		// check that user has selected node to apply to
		if(!this.props.nodeList.includes(parseInt(this.props.bldg_node))){
			var dataErrorMsg = "Invalid node selected. Please select location where load data should be written"
			this.setState({formatPending:false, formatFail: true, dataErrorMsg: dataErrorMsg})
			return
		}

		// create copy of load template
		var newLoadData = _.cloneDeep(this.props.loadTemplate)
		

		// get the processed user-defined load data from props
		newLoadData.data = this.props.loadDataFormatted


		// determine destination table location
		const destTableName = `loadinput_n${this.props.bldg_node}_p`;

		// update label data in newLoadData obj
		newLoadData.key = `loadinput_n${this.props.bldg_node}_p`;
        newLoadData.label = `Input Load - Node ${this.props.bldg_node}`;

        console.log(newLoadData)

		// write new data to table
		var writeStatus = this.props.replaceTable(newLoadData, destTableName)


		// if success, return to formatter
		if(writeStatus.success){
			await this.setState({formatPending:false, formatSuccess: true, newLoadData:newLoadData})
		} else {
			var dataErrorMsg = writeStatus.msg
			await this.setState({formatPending:false, formatFail: true, newLoadData:newLoadData, dataErrorMsg:dataErrorMsg})
		}

	}

	renderMenuSelector() {
		// func displays buttons for switching input form

		const menuButtonData = [
			{
				label: 'Load Reference Data',
				index: 0,
				active: false
			},
			{
				label: 'Upload 8760 Data',
				index: 1,
				active: false
			},
			{
				label: 'Upload Daytype Data',
				index: 2,
				active: false
			}
		]

		menuButtonData[this.state.displayIndex].active = true

		const menuButtonHtml = menuButtonData.map((btnData)=>{

			// add active class if current btn is active
			var btnClass = 'text-button input-select-button'

			if (btnData.active) {
				btnClass += ' input-select-button-active'
			}

			return(
				<div className={btnClass} onClick={()=>{this.switchFormIndex(btnData.index)}}>
					<h2>{btnData.label}</h2>
				</div>
			)
		})

		return(
			<>
			<h2 className='npdc-subheader'> Select Input Method </h2>
			<br/>
			<div className='centered-row-container'>
			{menuButtonHtml}	
			</div>
			</>
		)

	}

	renderInput8760() {
		// method renders input fields for user 8760 data
		return(
			<>
				 

        		<tr>
					<td className='npdc-input-label npdc-load-form-label' colSpan='2'>
						Electricity-Only [kW]
					</td>
				</tr>
				<tr>
					<td colSpan='2'>
						<textarea 
				          name='bldg_tsElec'
				          className='npdc-load-form-area'
				          value={this.props.bldg_tsElec} 
				          onChange={this.props.handleChange} 
				          required
				        /> 
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label npdc-load-form-label' colSpan='2'>
						Cooling [kW]
					</td>
				</tr>
				<tr>
					<td colSpan='2'>
						<textarea 
				          name='bldg_tsCool'
				          className='npdc-load-form-area'
				          value={this.props.bldg_tsCool} 
				          onChange={this.props.handleChange} 
				          required
				        /> 
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label npdc-load-form-label' colSpan='2'>
						Heating [kW]
					</td>
				</tr>
				<tr>
					<td colSpan='2'>
						<textarea 
				          name='bldg_tsHeat'
				          className='npdc-load-form-area'
				          value={this.props.bldg_tsHeat} 
				          onChange={this.props.handleChange} 
				          required
				        /> 
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label npdc-load-form-label' colSpan='2'>
						Water Heating [kW]
					</td>
				</tr>
				<tr>
					<td colSpan='2'>
						<textarea 
				          name='bldg_tsWater'
				          className='npdc-load-form-area'
				          value={this.props.bldg_tsWater} 
				          onChange={this.props.handleChange} 
				          required
				        /> 
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label npdc-load-form-label' colSpan='2'>
						Refrigeration [kW]
					</td>
				</tr>
				<tr>
					<td colSpan='2'>
						<textarea 
				          name='bldg_tsRef'
				          className='npdc-load-form-area'
				          value={this.props.bldg_tsRef} 
				          onChange={this.props.handleChange} 
				          required
				        /> 
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label npdc-load-form-label' colSpan='2'>
						Natural-Gas Only [kW-equivalent]
					</td>
				</tr>
				<tr>
					<td colSpan='2'>
						<textarea 
				          name='bldg_tsNg'
				          className='npdc-load-form-area'
				          value={this.props.bldg_tsNg} 
				          onChange={this.props.handleChange} 
				          required
				        /> 
					</td>
				</tr>
			</>
		)

	}

	renderInputDaytype() {
		// method renders input fields for user 8760 data
		return(
			<>
				<tr>
					<td className='npdc-input-label npdc-load-form-label' colSpan='2'>
						Formatted Load Data (All):
					</td>
				</tr>
				<tr>
					<td colSpan='2'>
						<textarea 
				          name='bldg_tsDaytype'
				          className='npdc-load-form-area'
				          value={this.props.bldg_tsNg} 
				          onChange={this.props.handleChange} 
				          required
				        /> 
					</td>
				</tr>
			</>
		)

	}

	renderStatus8760() {
		// renders a div with status of formatted data if isFormatted in props is true	

		return(
			<>
				<tr>
					<td className='npdc-input-label'>
						Electricity-Only
					</td>
					<td className='npdc-static-data'>
						{`${this.props.bldg_lenElec} values found`}
					</td>
					<td className='npdc-unit-label'>
						
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label'>
						Cooling
					</td>
					<td className='npdc-static-data'>
						{`${this.props.bldg_lenCool} values found`}
					</td>
					<td className='npdc-unit-label'>
						
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label'>
						Heating
					</td>
					<td className='npdc-static-data'>
						{`${this.props.bldg_lenHeat} values found`}
					</td>
					<td className='npdc-unit-label'>
						
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label'>
						Water Heating
					</td>
					<td className='npdc-static-data'>
						{`${this.props.bldg_lenWater} values found`}
					</td>
					<td className='npdc-unit-label'>
						
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label'>
						Refrigeration
					</td>
					<td className='npdc-static-data'>
						{`${this.props.bldg_lenRef} values found`}
					</td>
					<td className='npdc-unit-label'>
						
					</td>
				</tr>

				<tr>
					<td className='npdc-input-label'>
						Natural-Gas Only
					</td>
					<td className='npdc-static-data'>
						{`${this.props.bldg_lenNg} values found`}
					</td>
					<td className='npdc-unit-label'>
						
					</td>
				</tr>
			</>
		)
	}

	renderStatusDaytype() {
		// renders a div with status of formatted data if isFormatted in props is true	

		return(
			<>
				<tr>
					<td className='npdc-input-label'>
						Daytype Formatted Load
					</td>
					<td className='npdc-static-data'>
						{`${this.props.bldg_tsDaytype.len} values found. 5184 expected`}
					</td>
					<td className='npdc-unit-label'>
						
					</td>
				</tr>

			</>
		)
	}

	renderRefMenu() {
		// method renders the menu section for importing reference data

		return(
			<div className='npdc-section'>

				<h2 className='npdc-subheader'> Reference Building Loads </h2>

				<HelpBox2
					label='More Info'
				>
					
					<p className='npdc-text'>
						Use this menu to import and scale load profiles for existing
						reference building types and locations. Once building properties,
						scaling, and node have been selected, press the button below to
						download and process the load data. New data will be written to
						corresponding table in the current model.
					</p>
					

				</HelpBox2>

				<table className='npdc-load-form-table'>
					<tr>
						<td className='npdc-input-label'>
							Building Type
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.bldgList}
								fieldName='bldg_type'
								fieldLabel={null}
								formValue={this.props.bldg_type}
								onChange={this.props.handleChangeBldg}
								inTable={true}
							/>
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							Vintage
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.vintageList}
								fieldName='bldg_vint'
								fieldLabel=''
								formValue={this.props.bldg_vint}
								onChange={this.props.handleChangeBldg}
							/>
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							Climate Zone
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.climateList}
								fieldName='bldg_climateZone'
								fieldLabel=''
								formValue={this.props.bldg_climateZone}
								onChange={this.props.handleChangeBldg}
							/>
						</td>
						<td>
							
						</td>
					</tr>


					<tr className={`${Helper.showHideHtml(true)}`}>
						<td><br/></td>
					</tr>
					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-grid-header' colSpan="3">
							Select Scaling Method
						</td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							Building Size
						</td>
						<td>
							<InputCheckbox
								fieldLabel=''
								fieldName='bldg_scaleBin'
								formValue={this.props.bldg_scaleBin}
								onChange={this.props.toggleScaling}
								type='bin'
							/>
						</td>
						<td>
							
						</td>
					</tr>


					<tr>
						<td className='npdc-input-label'>
							Annual Energy
						</td>
						<td>
							<InputCheckbox
								fieldLabel=''
								fieldName='bldg_scaleBin'
								formValue={!this.props.bldg_scaleBin}
								onChange={this.props.toggleScaling}
								type='bin'
							/>
						</td>
						<td>
							
						</td>
					</tr>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td><br/></td>
					</tr>
					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-grid-header' colSpan="3">
							Building Data
						</td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							Building Size
						</td>
						<td>
							<InputField
								fieldName='bldg_size'
								fieldLabel=''
								formValue={this.props.bldg_size}
								onChange={this.props.handleChange}
								onExit={this.handleExitNumeric}
								data-type='pos'
							/>
						</td>
						<td className='npdc-unit-label'>
							m<sup>2</sup>
						</td>
					</tr>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td><br/></td>
					</tr>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-grid-header' colSpan="3">
							Annual Energy Consumption
						</td>
					</tr>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-input-label'>
							Electricity
						</td>
						<td>
							<InputField
								fieldName='bldg_annElec'
								fieldLabel=''
								formValue={this.props.bldg_annElec}
								onChange={this.props.handleChange}
								onExit={this.handleExitNumeric}
								data-type='pos'
							/>
						</td>
						<td className='npdc-unit-label'>
							MWh
						</td>
					</tr>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-input-label'>
							Natural Gas
						</td>
						<td>
							<InputField
								fieldName='bldg_annNg'
								fieldLabel=''
								formValue={this.props.bldg_annNg}
								onChange={this.props.handleChange}
								onExit={this.handleExitNumeric}
								data-type='pos'
							/>
						</td>
						<td className='npdc-unit-label'>
							MWh
						</td>
					</tr>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-grid-header' colSpan="3">
							Current Scaling Factors
						</td>
					</tr>


					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-input-label'>
							Electricity 
						</td>
						<td className='npdc-static-data'>
							{this.calcScaling().elec}
						</td>
						<td className='npdc-unit-label'>
							
						</td>
					</tr>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-input-label'>
							Natural Gas
						</td>
						<td className='npdc-static-data'>
							{this.calcScaling().ng}
						</td>
						<td className='npdc-unit-label'>
							
						</td>
					</tr>

					<tr>
						<td><br/><br/></td>
					</tr>
					<tr>
						<td className='npdc-input-label'>
							Apply to Node
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.nodeList}
								fieldName='bldg_node'
								fieldLabel={null}
								formValue={this.props.bldg_node}
								onChange={this.props.handleChange}
								inTable={true}
								data-type='int'
							/>
						</td>
						<td>
							
						</td>
					</tr>

				</table>

				<br/>

				<div className='text-button npdc-button' onClick={this.downloadData}>
					<h2>Download & Apply</h2>
				</div>

			</div>
		)

	}

	render8760Menu() {
		// method renders the menu section for processing user data manually

		return(
			<div className='npdc-section'>

				<h2 className='npdc-subheader'> Custom Load Formatter</h2>

				<HelpBox2
					label='More Info'
				>
					<p className='npdc-text'>
						Use this menu to process custom load profile data. Please paste 
					    hourly (8760) load data into any applicable
						load end-uses. 
						<br/><br/>
						Values can be comma, space, tab, or line delimited. Any letter or special
						characters will be removed. Any end-use left blank will assume a load profile 
						of 0. If you enter fewer than 8760 values, missing values of 0 will be assumed,
						and may affect the resulting load profiles. Any values beyond 8760 provided will
						be ignored.
						<br/><br/>
						Provide initial day-of-week to allow the model to
						accurately identify weekday and weekends. Select node to apply the
						new profile.
						<br/><br/>
						Once you have pasted your annual data into the corresponding fields, press Check & Format Data 
						to confirm your data has been parsed corrected. Once formatted, press Apply Formatted Data to
						write your custom data to the selected node load input table.
					</p>
				</HelpBox2>

				<table className='npdc-load-form-table'>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-grid-header' colSpan='2'>
							Annual Hourly Load Profiles
						</td>
					</tr>

						{!this.state.isFormatted ? this.renderInput8760() : this.renderStatus8760()}

					<tr>
						<td><br/><br/></td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							Initial Day of Week
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.dowList}
								fieldName='bldg_initDow'
								fieldLabel={null}
								formValue={this.props.bldg_initDow}
								onChange={this.props.handleChangeBldg}
								inTable={true}
							/>
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							Apply to Node
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.nodeList}
								fieldName='bldg_node'
								fieldLabel={null}
								formValue={this.props.bldg_node}
								onChange={this.props.handleChange}
								inTable={true}
								data-type='int'
							/>
						</td>
						<td>
							
						</td>
					</tr>

				</table>

				<br/>

				<div className='text-button npdc-button' onClick={this.toggleFormatted}>
					<h2>{!this.state.isFormatted ? 'Check & Format Data' : 'Update Custom Data'} </h2>
				</div>
				<br/>

				{this.renderDataStatus}

				{this.state.isFormatted ? 
						<div className='text-button npdc-button' onClick={this.writeUserData}>
							<h2>Apply Formatted Data</h2>
						</div>
					:
						<div className='text-button npdc-button text-button-disabled'>
							<h2>Apply Formatted Data</h2>
						</div>
				}

			</div>
		)
		
	}

	renderDaytypeMenu() {
		// method renders the menu section for processing user data manually

		return(
			<div className='npdc-section'>

				<h2 className='npdc-subheader'> Import Table Load Data</h2>

				<HelpBox2
					label='More Info'
				>
					<p className='npdc-text'>
						Use this menu to process custom load profile data that has already been
						formatted into DER-CAM daytypes. Please paste 
					    hourly daytype load data into the menu input field. 
						<br/><br/>
						Values can be comma, space, tab, or line delimited. Any letter or special
						characters will be removed. The menu expects data to be in the order it is shown
						in the table input. Please ensure your data is in the same order for month, daytype,
						and end-use before pasting into the menu.
						<br/><br/>
						The menu expects 5184 individual data points (12 months × 3 daytypes × 6 end-uses × 24 hours).
						If fewer data points that these are provided, the omitted values are assumed to be 0. If too many
						data points are provided, extraneous data will be neglected. The menu will check the volume of data
						provided and alert you to any discrepency.
						<br/><br/>
						Once you have pasted your daytype data into the corresponding fields, press Check Input Data 
						to confirm your data has been parsed corrected. Once checked, press Apply Formatted Data to
						write your custom data to the selected node load input table.
					</p>
				</HelpBox2>

				<table className='npdc-load-form-table'>

					<tr className={`${Helper.showHideHtml(true)}`}>
						<td className='npdc-grid-header'>
							Daytype-Formatted Load Profiles
						</td>
					</tr>

						{!this.state.isFormatted ? this.renderInputDaytype() : this.renderStatusDaytype()}

					<tr>
						<td><br/><br/></td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							Apply to Node
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.nodeList}
								fieldName='bldg_node'
								fieldLabel={null}
								formValue={this.props.bldg_node}
								onChange={this.props.handleChange}
								inTable={true}
								data-type='int'
							/>
						</td>
						<td>
							
						</td>
					</tr>

				</table>

				<br/>

				<div className='text-button npdc-button' onClick={this.toggleFormatted}>
					<h2>{!this.state.isFormatted ? 'Check Input Data' : 'Update Custom Data'} </h2>
				</div>
				<br/>

				{this.renderDataStatus}

				{this.state.isFormatted ? 
						<div className='text-button npdc-button' onClick={this.writeUserData}>
							<h2>Apply Validated Data</h2>
						</div>
					:
						<div className='text-button npdc-button text-button-disabled'>
							<h2>Apply Validated Data</h2>
						</div>
				}

			</div>
		)
		
	}


	render() {

		// status modals for downloading reference data
		if(this.state.dlPending){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<LoadIcon />}
							label={<>Downloading & Scaling Reference Data</>}
							rotating={true}
			  			>

			  			</Modal>
		  			</div>
		  		</div>
			)
		}

		if(this.state.dlSuccess){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<SuccessIcon />}
							label={<>New Reference Data <br/>Successfully Written to Model</>}
							rotating={false}
			  			>
			  				<div className='modal-btn dark-btn' onClick={this.returnToFormatter}>
								<h2 className='modal-btn-text'> Return to Load Formatter </h2>
							</div>

		  				</Modal>
	  				</div>
		  		</div>
			)
		}

		if(this.state.dlFail){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<ErrorIcon />}
							label={<>Data Download Failed</>}
							rotating={false}
							modalText={
								'An error occurred when downloading or formatting the requested load data.'+
								' Please return to the formatter menu and retry after a few minutes.'
							}
			  			>
			  				<div className='modal-text'>
								<br/><p>{`Error Msg: ${this.state.dataErrorMsg}`}</p>
							</div>

			  				<div className='modal-btn dark-btn' onClick={this.returnToFormatter}>
								<h2 className='modal-btn-text'> Return to Load Formatter </h2>
							</div>

			  			</Modal>
		  			</div>
		  		</div>
			)
		}


		// status modals for formatting user data
		if(this.state.formatPending){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<LoadIcon />}
							label={<>Processing User Load Data</>}
							rotating={true}
			  			>

			  			</Modal>
		  			</div>
		  		</div>
			)
		}

		if(this.state.formatSuccess){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<SuccessIcon />}
							label={<>User Load Data <br/>Successfully Written to Model</>}
							rotating={false}
			  			>
			  				<div className='modal-btn dark-btn' onClick={this.returnToFormatter}>
								<h2 className='modal-btn-text'> Return to Load Formatter </h2>
							</div>

		  				</Modal>
	  				</div>
		  		</div>
			)
		}

		if(this.state.formatFail){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<ErrorIcon />}
							label={<>User Load Data Processing Failed</>}
							rotating={false}
							modalText={
								'An error occurred when processing or formatting user load data.'+
								' Please check the provided load data and try again.'
							}
			  			>
			  				<div className='modal-text'>
								<br/><p>{`Error Msg: ${this.state.dataErrorMsg}`}</p>
							</div>

			  				<div className='modal-btn dark-btn' onClick={this.returnToFormatter}>
								<h2 className='modal-btn-text'> Return to Load Formatter </h2>
							</div>

			  			</Modal>
		  			</div>
		  		</div>
			)
		}

		// define list of menus to display, selected bases on self.state.displayIndex
		const displayFuncList = [
			this.renderRefMenu,
			this.render8760Menu,
			this.renderDaytypeMenu
			
		];

		let displayFunc = displayFuncList[this.state.displayIndex]

		return(
			<div className='page-scroll-container-new-project project-main-container'>

				<div className='npdc-container'>
					<h1 className='npdc-header'> Building Load Menu </h1>
	    			
	    			<div className='npdc-panel'>

	    				<div className='npdc-section'>
							{this.renderMenuSelector()}
						</div>

	    				{displayFunc()}
					</div>	
				
				</div>

	    	</div>
		)

		
	}
}



export default ProjectPageLoads;