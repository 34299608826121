import React from 'react';

import './HomeApi.css';
import Navbar from '../Navbar/Navbar'
import doc from './ApiDoc.js';

import DlUserGuide from "../../resources/dercam_user_manual_v59.pdf"
import DlInputSingle from "../../resources/dercam_input_template_single_node_v59.xlsx"
import DlInputMulti from "../../resources/dercam_input_template_multi_node_v59.xlsx"


class HomeApi extends React.Component {

	renderUserSection() {
		// section renders user api info
		// don't render anything is user data is not passed in props
		if(!this.props.authUser) {
			return null;
		} 

		return (
			<section className = 'api-section'>
			<h1 className='api-section-header'>User Account Info</h1>
				<table className='api-table'>
				  <tr>
				    <td className='api-field'>email address:</td>
				    <td className='api-value'>{this.props.authUser.email}</td>
				  </tr>
				  <tr>
				    <td className='api-field'>API key:</td>
				    <td className='api-value'>{this.props.authUser.key}</td>
				  </tr>
				</table>

				<div className='api-user-text'>
					<p>Your API key allows you access to the DER-CAM API service. You can use
					your key and the example code provided below to interact directly with our
					DER-CAM optimization servers. Do not share your key with others. Misuse of
					your API account could result in temporary  or permanent suspesion of access.
					</p>
				</div>

		    </section>
		)
	}

	renderDownloadSection() {
		// section renders user api info
		// don't render anything is user data is not passed in props
		if(!this.props.authUser) {
			return null;
		} 

		return (
			<section className = 'api-section'>
				<h1 className='api-section-header'>Download API Resources</h1>

				<section className = 'api-dl-section'>
				
					<div className='dark-btn api-dl-btn'>
	    				<a
		                  download="dercam_user_manual_v59.pdf"
		                  href={DlUserGuide}
		                >
	    				<h2>User Guide</h2>
	    				</a>
			    	</div>

			    	<div className='dark-btn api-dl-btn'>
	    				<a
		                  download="dercam_input_template_single_node_v59.xlsx"
		                  href={DlInputSingle}
		                >
	    				<h2>Input Template (Single-Node)</h2>
	    				</a>
			    	</div>

			    	<div className='dark-btn api-dl-btn'>
	    				<a
		                  download="dercam_input_template_multi_node_v59.xlsx"
		                  href={DlInputMulti}
		                >
	    				<h2>Input Template (Multi-Node)</h2>
	    				</a>
			    	</div>

		    	</section>

		    </section>
		)
	}

	render() {



		return (
			<div className="page-container">
				
				<div className='page-scroll-container'>
					<div className='api-user-section'>
						<h2 className='sec-title-h1 api-header'>DER-CAM API</h2>
						
						{this.renderUserSection()}
						
						{this.renderDownloadSection()}
					</div>
					{doc}
				</div>
		    </div>
		)
	}
}

//<Navbar activateHome = {this.props.activateHome}/>

export default HomeApi;