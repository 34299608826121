const _ = require('lodash');

const Helper = {

	emailIsValid (email) {
		// returns boolean if email arg is in valid email format x@y.z
	  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	},

	noSpecial (str) {
		// returns boolean if no special char in arg str
	 return !/[~`!#$%\^&*+=\[\]\\;,/{}|\\":<>\?]/g.test(str);
	}, 

	hasNumber(str) {
		// returns boolean if arg str contains number
	  return /\d/.test(str);
	},

	nameIsValid(str) {
		// return boolean if arg str contains neither number or special char
		return (this.noSpecial(str) && !this.hasNumber(str) && str.length>1);
	},

	pwIsValid(pw, pw2) {
		// returns boolean in arg pw and pw2 match, and are at least 8 char length
		return (pw.length >= 8 && pw==pw2);

	},

	pwIsLongEnough(pw) {
		// returns boolean if pw contains at least 8 char
		return (pw.length >= 8);
	},

	pwIsValidComplex(pw) {
		// check if pw >= 14
		if(pw.length >= 14){
			return true
		}

		// else check if pw >= 8
		if(pw.length >= 8){

			//check if it has uppercase, lowercase, numeric, and special
			const hasLower = pw.toUpperCase() != pw;
			const hasUpper = pw.toLowerCase() != pw;
			const hasNumeric = this.hasNumber(pw);
			const hasSpecial = !this.noSpecial(pw);

			if(hasLower && hasUpper && hasNumeric && hasSpecial){
				return true
			} else {
				return false
			}

		} else {
			return false
		}
	},

	textIsValid(text) {
		// text is null
		if(!text){
			return false
		}
		// text is empty str
		if(text===''){
			return false
		}
		// text is whitespace only
		if(!text.replace(/\s/g, '').length){
			return false
		}

		return true
	},

	projectNameValid(projName) {
		// if projName missing, return false
		if(!projName) {
			return false
		}
		// if projName is just spaces, return false
		if (!projName.replace(/\s/g, '').length) {
		  return false
		}

		// otherwise return true
		return true
	},

	validateAssetName(assetName, assetLabel='asset', minLen=3) {

		console.log(assetName)

		// if projName missing, set to empty string
		if(!assetName) {
			assetName = ''
		}

		// remove any special characters
		let regex = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/g;
		assetName = assetName.replace(regex, '');

		console.log(assetName)

		// if projName is just spaces, generate generic asset name
		if ((!assetName.replace(/\s/g, '').length)||assetName.length<minLen) {
		  
			assetName = assetLabel + '_' + Helper.genString(4)

		}

		console.log(assetName)

		// otherwise return true
		return assetName

	},

	notWhiteSpace(str) {
		// method checks if string is empty of whitespace only

		if (!str.replace(/\s/g, '').length) {
  			return false
		} else {
			return true
		}
	},

	removeWhiteSpace(str) {
		// method removes all whitespace from str

		return str.replace(/\s/g, "");
	},

	filterObjList(arr, attrName, attrValue) {
		// returns filter array where attrName equals attrValue
		if(!arr){
			return null
		}

		var newArray = arr.filter(function (el) {
		  return el[attrName] === attrValue;
		});

		return newArray
	},

	filterObjListWithList(arr, attrName, attrList) {
		// returns filter array where value stored under attrName is in list attrList
		if(!arr){
			return null
		}

		var newArray = arr.filter(function (el) {
		  return attrList.indexOf(el[attrName]) >=0 ;
		});

		return newArray
	},

	filterExcludeObjList(arr, attrName, attrValue) {
		// returns filter array where attrName not equal attrValue
		var newArray = arr.filter(function (el) {
		  return el[attrName] !== attrValue;
		});

		return newArray
	},

	capitalizeFirst(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	},


	assignNestedValue(obj, keyPath, value) {
		// assignes a value in a nested object, given list of nested keys
		// if nested location doesn't exist, creates empty objects to allow for assignment
	   var lastKeyIndex = keyPath.length-1;
	   for (var i = 0; i < lastKeyIndex; ++ i) {
	     let key = keyPath[i];
	     if (!(key in obj)){
	       obj[key] = {}
	     }
	     obj = obj[key];
	   }
	   obj[keyPath[lastKeyIndex]] = value;
	},

	showHideHtml(condition) {
		if(!condition) {
			return 'display-none'
		} else {
			return null
		}
	},

	randBetween(min, max) {  
	  return Math.floor(
	    Math.random() * (max - min) + min
	  )
	},

	intRange(start, end) {
	  return Array(end - start + 1).fill().map((_, idx) => start + idx)
	},

	boolToInt(x){
		// method to convert bool to 1 or 0 int
		return x ? 1 : 0;
	},

	genString(length) {
	    var result           = '';
	    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	    var charactersLength = characters.length;
	    for ( var i = 0; i < length; i++ ) {
	      result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		return result;
	},

	genCapString(length) {
		//method to generate strings of capitalized letters only
	    var result           = '';
	    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	    var charactersLength = characters.length;
	    for ( var i = 0; i < length; i++ ) {
	      result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		return result;
	},

	sleep(ms) {
	  return new Promise(resolve => setTimeout(resolve, ms));
	},

	transposeObj(obj) {
		// method transposes a list of identical nest object
		// {a:{x: y:}, b:{x:, y:}} -> {x:{a:, b:}, y:{a:, b:}}
		// does not check that obj structure is appropriate
		// will do unexpected changes to inappropriate str objects

		let outerKeys = Object.keys(obj)
		let innerKeys = Object.keys(obj[outerKeys[0]])

		let newObj = {}

		// loop through inner keys of initial obj
		for (let ii=0; ii<innerKeys.length; ii++) {
			// get current inner key
			let iKey = innerKeys[ii];
			// init inner key as outer key in new object
			newObj[iKey] = {}

			// loop through outer keys of init obj
			for (let oo=0; oo<outerKeys.length; oo++) {
				// get current outer key
				let oKey = outerKeys[oo];

				// assign val from init obj to new location
				newObj[iKey][oKey] = obj[oKey][iKey]
			
			}
		}

		return newObj

	},

	updateTableObj(table, label, value) {
		// method updates a 1d projData table value in the location where 'label' is located
		// only works for projData tables for dercam projects

		// find index of label in table.label
		const index = table.labels.indexOf(label);

		// if index found, update with value
		if (index >= 0) {
			table.data[index] = value
		} else {
			console.log(`label value ${label} not found in table ${table.key}`)
		}

		return table
	},

	getTableObj(table, label) {
		// method extracts a 1d projData table value in the location where 'label' is located
		// only works for projData tables for dercam projects

		// find index of label in table.label
		const index = table.labels.indexOf(label);

		// if index found, return it
		if (index >= 0) {
			return table.data[index]
		} else {
			// if not found, return null
			return null
		}

		return table
	},

	numericalArray(arr) {

		// if arr is list, convert to csv string
		arr = arr.toString()

		// delete all letters, special chars except . and ,
		arr = arr.replace( /[$&+:;=?@#|'<>-^*()%!-]|[A-Z]|[a-z]/g, "" )

		// convert whitespace, tabs, newlines and commas to single place
		// split by single spaces
		arr = arr.replace( /\s+|\s|\t|\n|,/g, " " ).replace(/\s\s+/g, " ").split(' ')

		// filter any empty or non-number entries
		arr = arr.filter(Number) 

		return arr

	},

	splitArray(arr, len) {
		// divides a single array into arrays of length (len)
		var segment = [], i = 0, n = arr.length;
		while (i < n) {
		segment.push(arr.slice(i, i += len));
		}
		return segment;
	},

	// splitByMonth(arr) {
	// 	// method divides array into 12 arrays, based on number of days per month

	// 	var monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

	// 	let monArray = []
	// 	let curDay = 0

	// 	for(let mm=0; mm<monthDays.length; mm++) {
	// 		monLen = monthDays[mm];

	// 		let curMonData = arr.slice(curDay, curDay+monLen)

	// 		curDay += monLen

	// 		monArray.push(cureMonData)
	// 	}

	// 	return monArray
	// },

	quantile(arr, q) {
		// method to get quantile of numeric array
		// e.g. median q = 0.5

		const asc = arr => arr.sort((a, b) => a - b);

	    const sorted = asc(arr);
	    const pos = (sorted.length - 1) * q;
	    const base = Math.floor(pos);
	    const rest = pos - base;
	    if (sorted[base + 1] !== undefined) {
	        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
	    } else {
	        return sorted[base];
	    }
	},

	generateMonIndex() {
		// creates an array of values, indcating what month a day is in

		var monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

		var monIndex = []

		for(let mm=0; mm<monthDays.length; mm++) {
			let monLen = monthDays[mm];

			let curMonIndex = Array(monLen).fill(mm+1)

			monIndex = monIndex.concat(curMonIndex)
		}

		return monIndex
	},

	generateDayOfWeekIndex(startDay=0) {
		// method generates a list Day-of-week indices
		// and index of whether day of week is weekday or weekend
		// take startDay num with Sunday = day 0

		var nDays = 365
		var dayOfWeekIndex = [];
		var isWeekdayIndex = [];

		for(let dd=0; dd<nDays; dd++) {

			// get current day of week from day index and start day
			let curDayIndex = (startDay + dd)%7

			// get weekday bool if curDayIndex != 0 or 6
			let isWeekDay = (curDayIndex == 0 || curDayIndex == 6) ? 0 : 1;

			dayOfWeekIndex.push(curDayIndex);
			isWeekdayIndex.push(isWeekDay);

		}

		return [dayOfWeekIndex, isWeekdayIndex]

	},

	createMatrix(rows, cols, defaultValue=0) {
		// method creates 2-d (nest arrays) object
		// with specified default value

		  var arr = [];

		  // Creates all lines:
		  for(var i=0; i < rows; i++){

		      // Creates an empty line
		      arr.push([]);

		      // Adds cols to the empty line:
		      arr[i].push( new Array(cols));

		      for(var j=0; j < cols; j++){
		        // Initializes:
		        arr[i][j] = defaultValue;
		      }
		  }

		return arr;
	},

	// cell-type checker funcs
	checkAsNum (val) {
		// checks value and attempts to convert to numeric

		// attempt to convert to num
		let numericVal = parseFloat(val);
	    
	    // if conversion fails, replace value with 0
	    if(isNaN(numericVal)) {
	    	numericVal = 0
	    }

	    return numericVal;
	}, 

	checkAsInt(val) {
		// checks value and attempts to convert to integer

		// convert to numeric
		val = Helper.checkAsNum(val)

		// convert numeric to int
		val = parseInt(val)

		return val
	},

	checkAsPos(val) {
		// checks value and attempts to convert to positive number

		// convert to numeric
		val = Helper.checkAsNum(val)

		// if negative, convert to pos
		if(val<0) {
			val = val * -1
		}

		return val
	},

	checkAsPosInt(val) {
		// checks value and attempts to convert to integer

		// convert to numeric
		val = Helper.checkAsNum(val)

		// convert numeric to int
		val = parseInt(val)

		// convert to positive
		val = Helper.checkAsInt(val)

		return val
	},

	checkAsBin(val) {
		// checks value and attempts to convert to binary value
		
		// convert to numeric
		val = Helper.checkAsNum(val);

		// if val nonzero, convert to 1
		if(val){
			val = 1
		} else {
			val = 0
		}

		return val
	},

	checkAsFrac(val) {
		// checks value and attempts to convert to decimal between 0 and 1

		// convert to numeric
		val = Helper.checkAsNum(val)

		// if val greater than 1, set to 1
		if(val > 1) {
			val = 1
		} 

		// if val less than 0, set to 0
		if(val < 0) {
			val = 0
		} 

		return val
	},

	checkAsNegFrac(val) {
		// checks value and attempts to convert to decimal between 0 and 1

		// convert to numeric
		val = Helper.checkAsNum(val)

		// if val greater than 1, set to 1
		if(val < -1) {
			val = -1
		} 

		// if val less than 0, set to 0
		if(val > 0) {
			val = 0
		} 

		return val
	},

	checkAsTextN(val, maxLen) {
		// convert to string without special chars and max length of 25

		// convert val to str
		val = val.toString()

		// list of special chars to remove _ char is allowed
		let regex = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/g;

		// strip out specal chars
		val = val.replace(regex, '');

		// reduce length to 25 max
		if(val.length > maxLen) {
			val = val.slice(0,maxLen);
		}

		return val
	},

	checkAsText25(val) {
		// convert to string without special chars and max length of 25

		return Helper.checkAsTextN(val, 25)

	},

	checkAsText50(val) {
		// convert to string without special chars and max length of 25

		return Helper.checkAsTextN(val, 50)

	},

	checkByType(val, valType) {
		// takes val and valType
		// attempts to find type-checker function
		// and applies to value
		// returnes "checked" value

		// type can be num, int, pos, bin, frac, text
		const typeCheckers = {
			num: Helper.checkAsNum, 
			int: Helper.checkAsInt,
			pos: Helper.checkAsPos, 
			bin: Helper.checkAsBin, 
			frac: Helper.checkAsFrac, 
			text: Helper.checkAsText25,
			text50: Helper.checkAsText50,
			nfrac: Helper.checkAsNegFrac,
			pint: Helper.checkAsPosInt
		}

		// console.log(`applying ${valType} to value ${val}`)

		// if no type provided, default to num
		if(!Object.keys(typeCheckers).includes(valType)){
			valType = 'num'
		}

		// use corresponding checker func to convert event.target.value
		const checkedVal = typeCheckers[valType](val)

		return checkedVal

	},

	async getLoadProfileList() {

		// url to fetch data for building reference library
		let urlRdtLoadData = `${process.env.REACT_APP_URL_BASE}/data/load/list`;

		var loadList = [];

		let response1 = await fetch(urlRdtLoadData, {credentials: 'include'});

	    // if status GET request successful
	    if(response1.status===200) {
	      	loadList = await response1.json();
	      	loadList = loadList.bldgList
	    } else {
	    	console.log('load list request failed')
	    }

	    // build lists of building types, vintages, and climate zones
	    var bldgList = _.uniqBy(loadList, 'BLDG_TYPE_FULL').map(a => a.BLDG_TYPE_FULL);

	    var vintageList = _.uniqBy(loadList, 'VINTAGE_FULL').map(a => a.VINTAGE_FULL);

	    var climateList = _.uniqBy(loadList, 'CLIMATE_ZONE').map(a => a.CLIMATE_ZONE);

	    return [loadList, bldgList, vintageList, climateList]
	},

	async getPvDataList() {

		// url to fetch data for solar reference library
		let urlRdtPvData = `${process.env.REACT_APP_URL_BASE}/data/pv/list`;

		var pvList = [];


	    let response2 = await fetch(urlRdtPvData, {credentials: 'include'});

	    // if status GET request successful
	    if(response2.status===200) {
	      	pvList = await response2.json();
	      	pvList = pvList.pvList
	    } else {
	    	console.log('pv list request failed')
	    }

	    // get list of unique state (2-char abbrevs)
	    var stateList = _.uniqBy(pvList, 'STATE').map(a => a.STATE);

	    // get list of angles and orientations
	    const pvAngleList = ['lat', 0, 45, 90];
		const pvOrientList = ['S', 'SW', 'SE', 'W', 'E', 'N', 'NW', 'NE']; 

	    // build solar-data-location list
	    // object where keys are state abbrevs, values are lists of stations in that state
	    const pvStateMap = {}
	    for (let pvIndex in pvList) {
	    	let pp = pvList[pvIndex];
		  // if state list not in pv map, add it
		  if(!pvStateMap[pp.STATE]) {
		  	// console.log(`${pp.STATE} not in map yet`)
		  	pvStateMap[pp.STATE] = [pp.STATION]
		  } else {
		  	// else add station to existing list for state
		  	let statePvList = pvStateMap[pp.STATE];
			statePvList.push(pp.STATION)
			pvStateMap[pp.STATE] = statePvList
		  }
		}


	    return [pvList, stateList, pvStateMap, pvAngleList, pvOrientList]
	},

	getSelectedBuilding(loadList, bldg_type, bldg_vint, bldg_climateZone) { 
		// takes bldg_type, bldg_vint, bldg_climateZone
		// checks in passed loadList, if corresponding extry found
		// returns bldgId, size, annElec, and annNg

		// init return values to default (no bldg found)
		var  bldg_bldgId = -999;
		var  bldg_size = '';
		var  bldg_annElec = '';
		var  bldg_annNg = '';	   

	    // check if building can be id'ed with current selected info
	    if((bldg_type != null) && (bldg_vint != null) && (bldg_climateZone != null)) {
	    	// console.log('all bldg params selected')

	    	var selectedBldg = loadList.filter(function(v, i) {
			  return ((v["BLDG_TYPE_FULL"] == bldg_type) && (v["VINTAGE_FULL"] == bldg_vint) && (v["CLIMATE_ZONE"] == bldg_climateZone));
			})

			console.log(selectedBldg)

	    	// if search returns a building, extract data
	    	if (selectedBldg.length > 0){
	    		selectedBldg = selectedBldg[0];

	    		bldg_bldgId = selectedBldg.ID;
				bldg_size = selectedBldg.SIZE;
				bldg_annElec = (selectedBldg.ELEC/1000).toFixed(2);
				bldg_annNg = (selectedBldg.NG/1000).toFixed(2);


	    	} else {
	    		selectedBldg = null;
	    	}
	    	
			// console.log(selectedBldg)
	    }

	    return [selectedBldg, bldg_bldgId, bldg_size, bldg_annElec, bldg_annNg]
	
	},

	getSelectedPv(pvStateMap, pvList, loc_stateUs, loc_city) { 
		// takes state code and station location, along with pv-state-location map obj

		// returns list of stations for selected state, and selected station, 
		// and station list for selected state

		// initialize vars to default/failed search values
		var selectedPv = null;
	    var loc_stationId = -999;
	   

	    //update list of available solar locations based on current US state
	    var stationList = pvStateMap[loc_stateUs]

	    //if current station not in station list, set to null
	    if(stationList){
		    if(!stationList.includes(loc_city) && (loc_city!='' && loc_city!=null)){
		    	loc_city = null;
		    }
		} else {
			stationList = ['Select state to see station list']
			loc_city=null
		}

	    // check if pv station id can be id'ed with current selected info
	    if((loc_city!='' && loc_city!=null) && (loc_stateUs!='' && loc_stateUs!=null)) {
	    	console.log('all pv params selected')

	    	var selectedPv = pvList.filter(function(v, i) {
			  return ((v["STATION"] == loc_city));
			})

	    	// if search returns a station, extract data
	    	if (selectedPv.length > 0){
	    		selectedPv = selectedPv[0];
	    		console.log(selectedPv)
	    		loc_stationId = selectedPv.ID;


	    	} else {
	    		console.log('selected PV not being set!')
	    	}

	    } else {
	    	console.log(loc_city)
	    	console.log(loc_stateUs)
	    }

	    return [ stationList, selectedPv, loc_stationId ]
	
	}

} 

export default Helper;