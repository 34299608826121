import React from 'react';
import { withRouter } from 'react-router-dom';

import { cloneDeep } from 'lodash';

import Helper from '../../helper';

import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as HelpIcon } from '../../icons/help.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';

class ResetPwForm extends React.Component{ 
	constructor(props) {
		super(props)

		this.state = {
			
			showPw: false,
			showPwRules: false,

			// initilize state of form
			formData: {
		    password:'',
		    // password2:'',
		  },

		  formDataValid: {
		    password: false,
		    // password2:false,
		  },

		  formValid:false,
		  formBlank:true,
		  showInvalid: false,
			errors: {},
		}

  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.checkValidForm = this.checkValidForm.bind(this);

	this.togglePwVis = this.togglePwVis.bind(this);
	this.togglePwRulesVis = this.togglePwRulesVis.bind(this);

	this.redirectToRegister = this.redirectToRegister.bind(this);

	}


  // Form submitting logic, prevent default page refresh  
  async handleSubmit(event) { 

    const { password, password2 } = this.state.formData;
    event.preventDefault()

    // if form is not valid, do nothing
  	if(!this.state.formValid) {
  		// if attempting to submit before form is valid, show invalid fields
  		this.setState({showInvalid: true})
  		console.log('cannot submit invalid form')
  		return;
  	}

    let data =  {
        password:password,
        token: this.props.match.params.resetToken
    }

    console.log('attempting pw reset POST')
    console.log(data)

    let urlAddUser = `${process.env.REACT_APP_URL_BASE}/auth/reset`;

    let response = await fetch(urlAddUser, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    });

    if(response.status===200) {
      // new account request successfully received by server
      // redirect to req (successful request) page
      console.log('pw reset req successful')
	    this.props.history.push({
		    pathname: '/acct/resetsuc',
		  })
    // } else if(response.status===409) {
    //   // console.log(response.status)
    //   // console.log(response.statusText)
    //   this.setState({errors:{duplicateError:true}})
    } else {
      console.log(response.status)
      console.log(response.statusText)
      this.setState({errors:{requestFailed:true}})
    }
    
  } 


  async checkValidForm(){
  	// this method checks the current content of the reg form
  	// and updates state after checking validity of each form field

  	// extract current form object
  	var curForm = this.state.formData
  	var curValid = this.state.formDataValid

  	// check data for each form
    curValid.password = Helper.pwIsValidComplex(curForm.password);
    // curValid.password2 = (Helper.pwIsValidComplex(curForm.password2)) && (curForm.password === curForm.password2);

    // check if all fiels in curValid are true
    var formValid = Object.values(curValid).every(item => item === true)

    this.setState(
    {
    	formValid: formValid,
    	formDataValid: curValid
    }
    )


  }

  
  handleChange(event){ 

    // initialize new form data as previous form data
    var curFormData = this.state.formData;

    // update form data with changed value
    if(event.target.name==='accept'){
      curFormData[event.target.name] = event.target.checked;
    } else {
      curFormData[event.target.name] = event.target.value;
    }

    // update state, then check validity
    this.setState({formData: curFormData}, this.checkValidForm)
    
  }




	togglePwVis() {
		this.setState({showPw:!this.state.showPw})
	}

	togglePwRulesVis() {
		this.setState({showPwRules:!this.state.showPwRules})
	}



	redirectToRegister() {
		this.props.history.push({
	    pathname: '/acct/register',
	  })
	}
	  

	styleSubmitButton(entryValid) {
		if(entryValid) {
			return 'form-button valid-form-button reg-new-button';
		} else {
			return 'form-button invalid-form-button reg-new-button';
		}
	}


	generateFooter(formType) {

		if(this.state.errors.requestFailed) {
			return (
				<div className='form-footer'>
					<ErrorIcon/>
			      	<p>Request failed. Please try again later.</p>
			    </div>
			)

		}
		
		if(!this.state.errors.duplicateError) {
			return (
				<div className='form-footer'>
			      	<p>Don't Have an Account? 
			      		<span className='form-footer-link' onClick={this.redirectToRegister}>Create New Account.</span>
			      	</p>
			    </div>
			)

		} else {
			return (
				<div className='form-footer'>
					<ErrorIcon/>
			      	<p>No account with this email found.
			      		<span className='form-footer-link' onClick={this.redirectToRegister}>Create New Account.</span>
			      	</p>
			    </div>
			)
		}

	}


	fieldStatusClass(validBool) {
		// method checks field status.
		// returns field-invalid if validBool is false
		// if this.state.showInvalid is also true

		if(this.state.showInvalid) {
			if(!validBool){
				return 'field-invalid'
			} else {
				return ''
			}
		} else {
			return ''
		}
	}


	

	renderResetForm() {


		return (
			<section className = 'form-section'>
				<h1>Reset DER-CAM Password</h1>
					
			      	<form  className='signup-form'  onSubmit={this.handleSubmit}> 
			      	

			        <div className='form-field'>
			        	<div className='form-spacer'></div> 
			          <label htmlFor='last' className='form-label'>New Password</label> 
			          <input 
			            name='password' 
			            className={`form-input ${this.fieldStatusClass(this.state.formDataValid.password)}`}
			            type={this.state.showPw ? 'text' : 'password'}
			            value={this.state.formData.password} 
			            onChange={this.handleChange} 
			            required
			          />
			          <div className='form-icons'>
			          	<EyeIcon onClick={this.togglePwVis}/>
			          	<HelpIcon onClick={this.togglePwRulesVis}/>
			          </div> 
			        </div>

			        

			        <div className={`form-text-toggle-div ${this.state.showPwRules ? ' ' : 'display-none'}`}>
			        	
			        	<div className='form-text-div'>
				        	<p>
				 	        	Password must be 8+ characters, and include uppercase, lowercase, numeric, and special characters
				 	        	or 14+ characters of any type.
				 	        </p>
				 	      </div>
			 	        
			        </div>


			        

			        <div className='form-field'> 
			        	<div className='form-spacer'></div>
			          <button className={this.styleSubmitButton(this.state.formValid)}><h2>Submit</h2></button> 
			        	<div className='form-spacer'></div>
			        </div> 

			        <div className={`form-text-toggle-div ${this.state.showInvalid ? ' ' : 'display-none'}`}>
			        	<div className='form-text-div'>
			        		<div className='form-text-div-icon'>
			        			<ErrorIcon/>
			        		</div>
				        	<h4>
				 	        	There is a problem with your submission.
				 	        </h4>
				 	        <p>
				 	        	Please enter a valid password to proceed.
				 	        	<br/><br/>
				 	        	Password Requirements:
				 	        </p>
				 	        <ul>
				 	        	<li>
				 	        		Password must be 8+ characters
				 	        	</li>
				 	        	<li>
				 	        		Passwords under 14 characters must include uppercase, lowercase, numeric, and special characters
				 	        	</li>
				 	        </ul>
			 	        </div>
			        </div>

			    </form> 
		      	{this.generateFooter('newAccount')}
		    </section>
		)
	}
  

  render(){ 
    return( 

			<>
				{this.renderResetForm()}   
			</>

    ) 
  } 
} 


export default withRouter(ResetPwForm);