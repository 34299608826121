import React from 'react';

import './Status.css';

class Status extends React.Component {

	displayStatus(field) {
		// this function returns a class name for status-item
		// and status-value depending on whether api server is online or not

		if(field==='value') {
			if(this.props.serverOnline) {
				return('status-online')
			} else {
				return('status-offline')
			}
		} else if(field==='item') {
			if(this.props.serverOnline) {
				return('status-display')
			} else {
				return('status-hidden')
			}
		}  else if(field==='status') {
			if(this.props.serverOnline) {
				return('Online')
			} else {
				return('Offline')
			}
		}
	}

	render() {
		return (
			<div className="status-container">

				<div className='status-item'>
	        		<span className='status-label'>Server Status:  </span>
	        		<span className={`status-value ${this.displayStatus('value')}`}>{this.displayStatus('status')}</span>
	        	</div>
	        	<div className={`status-item ${this.displayStatus('item')}`}>
	        		<span className='status-label'>Latest Upload:  </span>
	        		<span className='status-value'>{this.props.serverLatest.upload} PT</span>
	        	</div>
	        	<div className={`status-item ${this.displayStatus('item')}`}>
	        		<span className='status-label'>Latest Solve:  </span>
	        		<span className='status-value'>{this.props.serverLatest.solve} PT</span>
	        	</div>

		    </div>
		)
	}
}



export default Status;