import React from 'react';

import './Inputs.css';


class InputField extends React.Component{ 

  renderUnits() {
  	if(this.props.unitLabel){
  		return(
  			<div className='np-label'>	
				<p className='np-unit-label'>{this.props.unitLabel}</p>
			</div>
  		)
  	} else {
  		return null;
  	}
  }
	

  render(){ 

  	// create label if passed in props
  	var labelHtml = null
  	if(this.props.fieldLabel) {
  		labelHtml = (
  			<div className='np-label'>	
					<label htmlFor={this.props.fieldName} className='np-input-label'>
						{this.props.fieldLabel}: 
					</label>
				</div>
  		)  
  	}

  	if(!this.props.hideComponent){
  		    return( 
    	
			    	// props needed: fieldName, fieldLabel, formvalue, unitLabel, onChange
					
						<div className='np-data np-field'> 
							{labelHtml}
							<input 
								name={this.props.fieldName}
								className='np-input'
								value={this.props.formValue} 
								onChange={this.props.onChange} 
								onBlur={this.props.onExit}
								data-type={this.props.type}
								required
							/> 
							{this.renderUnits()}
				    </div> 
    	) 
  	} else {
  		return null;
  	}


  } 
} 


export default InputField;