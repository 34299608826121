import React from 'react';
import { BrowserRouter, Link, Route, Switch, withRouter} from 'react-router-dom';

import './HomeRdt.css';

import { ReactComponent as OutageIcon } from '../../icons/outage.svg';
var rdtLogo = '/icons/rdt-logo-v2.png';

class HomeRdt extends React.Component {

	render() {

		const url = this.props.match.url
		// get upload url by going one level up from url
  		const ulUrl = url.slice(0, url.lastIndexOf('/'))

		return (
			<div className="sub-nav-container">
				<div className='rdt-container'>

					<div className='rdt-header-container'>
						<div className='rdt-logo-container'>
							<img src={rdtLogo} alt="RDT logo" />
						</div>

						<h1 className='rdt-title-h1'>DER-CAM</h1>
						<h1 className='rdt-title-h2'>Resilience Design Tool</h1>

					</div>

					<div className='dash-sub-container'>
						<div className="dash-sub-menu">
				        	
				        	<div className='dash-btn dark-btn'>
				        		<Link to={`${url}/about`} className='react-link'><h2>About RDT</h2></Link>
				        	</div>
				        	<div className='dash-btn dark-btn'>
				        		<Link to={`${url}/projects`} className='react-link'><h2>My Projects</h2></Link>
				        	</div>
				        	<div className='dash-btn dark-btn'>
				        		<Link to={`${url}/new`} className='react-link'><h2>New Project</h2></Link>
				        	</div>
				        	<div className='dash-btn dark-btn'>
				        		<Link to={`${ulUrl}/upload`} className='react-link'><h2>Upload Project</h2></Link>
				        	</div>
				        	<div className='dash-btn dark-btn display-none'>
				        		<Link to={`${url}/data`} className='react-link'><h2>My Data</h2></Link>
				        	</div>

				        </div>
				    </div>

				</div>
			</div>
		)
	}
  
} 


export default withRouter(HomeRdt);