import React from 'react';

import Helper from '../../helper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

// import ResultsTable from '../ResultsTable/ResultsTable';
import ResultsStatusTable from '../ResultsStatusTable/ResultsStatusTable'
// import Chart from '../Chart/Chart'
// import ChartAnnual from '../ChartAnnual/ChartAnnual'
// import ChartMonthly from '../ChartMonthly/ChartMonthly'
// import ChartTimeseries from '../ChartTimeseries/ChartTimeseries'
// import ToggleSection from '../ToggleSection/ToggleSection'
// import ResultsInv from '../ResultsInv/ResultsInv'

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';


class SectionSummary extends React.Component {
	constructor(props){
		super(props)

		this.renderSection = this.renderSection.bind(this);
	}

	renderSection() {

		// create data download link if data has been loaded
		if(this.props.dataLoaded) {

			const str = JSON.stringify(this.props.rawAll);
			const bytes = new TextEncoder().encode(str);
			const blob = new Blob([bytes], {
			    type: "application/json;charset=utf-8"
			});

			var downloadLink = window.URL.createObjectURL(blob);
		} else {
			var downloadLink = ''
		}

	
		return (
			<div className='results-section'>
				<h2 className='results-heading'>1. Results Summary</h2>
				<h2 className='results-heading-2'>Model Details</h2>
				<table className='res-sum-table'>
					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Key:</div></td>
						<td><div className='res-sum-data'>{this.props.modelKey}</div></td>
					</tr>
					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Label:</div></td>
						<td><div className='res-sum-data'>{this.props.modelLabel}</div></td>
					</tr>
					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Description:</div></td>
						<td><div className='res-sum-data'>{this.props.modelDesc}</div></td>
					</tr>
				</table>

				<div className='results-subsection'>
					<h2 className='results-heading-2'>Solution Status</h2>
					<ResultsStatusTable 
						scenarios={this.props.scenarios}
					/>
					<div className='results-note'>
						<p>
							Note: if any scenario models have failed or are infeasible, 
							some results plots and tables may not display correctly. 
							If an error occurs, please try refreshing the page.
						</p>
					</div>
				</div>

				<div className='results-subsection'>
					<div className='dark-btn text-btn res-download-btn'>
						<a 
							download={`${this.props.modelKey}_results.json`}
				      // link to the download URL
				      href={downloadLink}
				    >
							<h2>Download Results Data</h2>
						</a>
					</div>
				</div>

			</div>
		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Model Status Summary'
			/>
		)
	}

}

export default SectionSummary;