

const doc = (
	<div className='doc-text doc-sec'>
		<h1> DER-CAM API Documentation </h1>
		<p><strong>date</strong>: 12/07/2020 <br/>
		<strong>version</strong>: 0.1.1</p>
		<p>The new DER-CAM API allows users to intereact directly with the DER-CAM server without using the DER-CAM Desktop user interface.</p>
		
		<h2 id="sec-contents" className='doc-content-header'>Contents:</h2>
		<ul className='doc-contents-list'>
			<li><a href="#sec-general">General Use</a></li>
			<li><a href="#sec-models">Reviewing Existing Models</a></li>
			<li><a href="#sec-new">Creating a New Model</a></li>
			<li><a href="#sec-results">Checking Model Results</a></li>
			<li><a href="#sec-data">Downloading Reference Data</a></li>
			<li><a href="#sec-error">Troubleshooting</a></li>
		</ul>

		<h2 id="features-">Features:</h2>
		<ul>
		<li>Directly send model files and retrieve results through HTTP requests</li>
		<li>Batch send multiple model files without direct user intervention</li>
		<li>Results are retained on the server and can be retrieved as needed for 14 days</li>
		<li>Curenntly compatible with DER-CAM v5.9 and v5.8 model files</li>
		</ul>

		<div id="sec-general" className='doc-sec'>
			<h3>How to use:</h3>
			<p>The DER-CAM API simply uses HTTP requests to send files and retrieve data. A variety of programing languages support this functionality. Example code to perform basic requests are provided below in Python 3.7.</p>
			<p>In order to use the API, you must have a valid key. Eventually creation of user accounts and key generation will be automated. For now, to request an API account and key, please email dercam@lbl.gov with the subject &quot;request API access&quot;.</p>
			<p>Using the API through python uses the requests library. Currently the API is hosted on Berkeley Lab's server microgrids1.lbl.gov which serves as the base url. The API listens for requests on port 4000, which must also be included in the request. To apply this code to your own use, substitute your API for the example user key used here.</p>
			
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
				<span className="code-comment"># import requests library</span><br/>
				<span className="hljs-keyword">import</span> requests <span className="hljs-keyword">as</span> req <br/><br/>

				<span className="code-comment"># define API base URL and your own API key</span><br/>
				urlBase = <span className="hljs-string">'https://microgrids1.lbl.gov:4000/api/xls'</span> <br/>
				userKey = <span className="hljs-string">'your_api_key_here'</span> <br/>
				</span></code></pre>
			</div>
		</div>

		<div id="sec-models" className='doc-sec'>
			<h3>Viewing existing models:</h3>
			<p>Users can access a list of uploaded projects using the following request:</p>
			<div className='doc-code-block'>
				<pre><code className="lang-python">
				<span className="code-comment"># make API request to 'model' endpoint</span><br/>
				<span className="hljs-attr">response</span> = req.get(f<span className="hljs-string">'{'{urlBase}/{userKey}/model'}'</span>)<br/>
				<br/>
				<span className="code-comment"># extract the 'model' field of json response</span><br/>
				myModels = response.json()[<span className="hljs-string">'models'</span>]
				</code></pre>
			</div>
			<p>The response will contain a list of model data in the 'models' field for all past models you have uploaded to the server, including queued, solved, failed, and expired models.</p>
			
			<p>Below is an example of two models returned for our example user.</p>
			<div className='doc-code-block'>
				<pre><code className="lang-json"><span>
					<span className="code-comment"># myModel is a list of information for all uploaded models</span><br/><br/>
					[
				    {"{"}<br/>
				        <span className="hljs-string">    u'datetime_added': u'2020-12-4 10:3:0'</span>, <br/>
				        <span className="hljs-string">    u'datetime_executed': u'2020-12-04, 10:08:08'</span>,<br/>
				        <span className="hljs-string">    u'expiration': u'2020-12-18, 10:08:08'</span>,<br/>
				        <span className="hljs-string">    u'label': u'my first api model'</span>,<br/>
				        <span className="hljs-string">    u'model_key': u'e21ebee2671'</span>,<br/>
				        <span className="hljs-string">    u'msg': u'model file successfully optimized'</span>,<br/>
				        <span className="hljs-string">    u'results_available': 1</span>,<br/>
				        <span className="hljs-string">    u'solve_status': u'optimized'</span>,<br/>
				        <span className="hljs-string">    u'solve_time': 22.13534</span>,<br/>
				        <span className="hljs-string">    u'status': u'solved'</span>,<br/>
				        <span className="hljs-string">    u'version': 'v59'</span><br/>
				    {"}"},<br/>
				    {"{"}<br/>
				        <span className="hljs-string">    u'datetime_added': u'2020-12-4 10:3:4'</span>,<br/>
				        <span className="hljs-string">    u'datetime_executed': u'2020-12-04, 10:10:20'</span>,<br/>
				        <span className="hljs-string">    u'expiration': u'2020-12-18, 10:10:20'</span>,<br/>
				        <span className="hljs-string">    u'label': u'my second api model'</span>,<br/>
				        <span className="hljs-string">    u'model_key': u'4a4fbee2671'</span>,<br/>
				        <span className="hljs-string">    u'msg': u'model file successfully optimized'</span>,<br/>
				        <span className="hljs-string">    u'results_available': 1</span>,<br/>
				        <span className="hljs-string">    u'solve_status': u'optimized'</span>,<br/>
				        <span className="hljs-string">    u'solve_time': 21.589653</span>,<br/>
				        <span className="hljs-string">    u'status': u'solved'</span>,<br/>
				        <span className="hljs-string">    u'version': 'v59'</span><br/>
				     {"}"}</span>
				  ]
				</code></pre>
			</div>

			<p>Each model object contains the following data fields:</p>
			<ul>
			<li><strong>datetime added</strong>: when the model was received by the server</li>
			<li><strong>datetime executed</strong>: when the server attempts to solve the model</li>
			<li><strong>expiration</strong>: datetime after which results will no longer be stored on the server</li>
			<li><strong>label</strong>: a user-provided description of the mode</li>
			<li><strong>model key</strong>: unique key to access the model results</li>
			<li><strong>msg</strong>: status message describing model status</li>
			<li><strong>results available</strong>: boolean indicating if results can be accessed</li>
			<li><strong>solve status</strong>: solver return status</li>
			<li><strong>solve time</strong>: time to find solution in seconds</li>
			<li><strong>version</strong>: model file and DER-CAM optimizer version</li>
			</ul>
		</div>

		<div id="sec-new" className='doc-sec'>
			<h3>Posting a new model:</h3>
			<p>Users can post a new model file using the following request:</p>
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
				<span className="code-comment"># define model file and type </span><br/>
				modelFilePath = <span className="hljs-string">'path/to/my/model/configuration.xlsx'</span><br/>
				fileMimeType = <span className="hljs-string">'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'</span><br/>
				
				myfiles = {"{"}'modelFile': ('model.xlsx', <br/>
				<span>    open(modelFilePath, 'rb'), <br/></span>
				<span>    fileMimeType, <br/></span>
				<span>    {"{"}'Expires': '0'{"}"}){"}"}<br/></span>
				<br/>
				<span className="code-comment"># define url for POST request</span><br/>
				url = f'<span className="hljs-string">{'{urlBase}/{userKey}/model'}</span>'<br/>
				<br/>
				<span className="code-comment"># add a model label and version to be included in data object (required)</span><br/>
				<span className="code-comment"># version must be supported (currently only 5.8 or 5.9)</span><br/>

				data={"{"}<br/>
				    <span className="hljs-string">    'label': "example model post request"</span>,<br/>
				    <span className="hljs-string">    'version': 5.9</span><br/>
				{"}"} <br/><br/>

				<span className="code-comment"># POST request returns data for new model</span><br/>
				response = req.post(url=url, data=data, <span className="hljs-keyword">files</span> = myfiles)<br/>
				myNewModel = response.json()[<span className="hljs-string">'model'</span>]<br/>
				</span></code></pre>
			</div>

			<p>Examining the response 'model' field will show data for the newly created model. Many fields will start off defined as None while the model is initially queued on the server, and will be updated once the server attempts to solve it. Note the model key field, which will allow you to access the new model's status and results later.</p>
			<div className='doc-code-block'>
				<pre><code className="lang-json"><span>
				<span className="code-comment"># myNewModel returns information for newly uploaded model</span><br/><br/>
				{"{"}<br/>
				    <span className="hljs-string">    u'datetime_added': u'2020-12-1 14:37:46'</span>,<br/>
				    <span className="hljs-string">    u'datetime_executed': None</span>,<br/>
				    <span className="hljs-string">    u'expiration': None</span>,<br/>
				    <span className="hljs-string">    u'label': u'example model post request'</span>,<br/>
				    <span className="hljs-string">    u'model_key': u'96177ef2671'</span>,<br/>
				    <span className="hljs-string">    u'msg': None</span>,<br/>
				    <span className="hljs-string">    u'results_available': 0</span>,<br/>
				    <span className="hljs-string">    u'solve_status': None</span>,<br/>
				    <span className="hljs-string">    u'solve_time': None</span>,<br/>
				    <span className="hljs-string">    u'status': u'queued'</span>,<br/>
				    <span className="hljs-string">    u'version': 'v59'</span><br/>
				{"}"} <br/>
				</span></code></pre>
			</div>

			<p><strong>Note</strong>: If you are posting multiple models at one time (e.g. in a loop), place a pause of at least 30 seconds between post requests (e.g using time.sleep). <strong>Violating this limit may result in a suspension of your API account.</strong></p>
			
		</div>

			
		<div id="sec-results" className='doc-sec'>
			<h3>Retrieving model status and results</h3>
			<p>Users can get status for a single existing models by appending the model key to the end of
			the previous url, as shown below. For your own use, substitute a real key for an existing model for the placeholder used here.</p>
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
					<span className="code-comment"># define model key (returned when originally sending model file)</span><br/>
					<span className="hljs-attr">modelKey</span> = <span className="hljs-string">'your_existing_model_key_here'</span><br/>
					<br/>
					<span className="code-comment"># define url by appending model key to 'model' endpoint</span><br/>
					<span className="hljs-attr">url</span> = f<span className="hljs-string">'{'{urlBase}/{userKey}/model/{modelKey}'}'</span><br/>
					<br/>
					<span className="code-comment"># model info and status will be returned in 'model' field</span><br/>
					<span className="hljs-attr">response</span> = req.get(url)<br/>
					<span className="hljs-attr">myExistingModel</span> = response.json()[<span className="hljs-string">'model'</span>]<br/>
				</span></code></pre>
			</div>
			<p>The response 'model' field will include data for this specific model. If the 'results_available' field value is 1, then results data can be downloaded using the following commands:</p>
			
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
				<span className="code-comment"># define model key for solved model</span><br/>
				modelKey = <span className="hljs-string">'your_solved_model_key_here'</span><br/>
				url = f<span className="hljs-string">'{'{urlBase}/{userKey}/model/{modelKey}/results'}'</span><br/>
				response = req.<span className="hljs-built_in">get</span>(url)<br/>
				mySolvedModel = response.json()[<span className="hljs-string">'model'</span>]<br/><br/>

				<span className="code-comment"># write returned results data to files</span><br/>
				resultsFile = <span className="hljs-string">'./path/to/results/directory/results.csv'</span><br/>
				<span className="hljs-keyword">with</span> <span className="hljs-built_in">open</span>(resultsFile, <span className="hljs-string">'w'</span>) <span className="hljs-keyword">as</span> f:<br/>
				<span>    f.<span className="hljs-built_in">write</span>(mySolvedModel[<span className="hljs-string">'results'</span>])</span><br/><br/>

				resultsFileNodes = <span className="hljs-string">'./path/to/results/directory/results_nodes.csv'</span><br/>
				<span className="hljs-keyword">with</span> <span className="hljs-built_in">open</span>(resultsFileNodes, <span className="hljs-string">'w'</span>) <span className="hljs-keyword">as</span> f:<br/>
				<span>    f.<span className="hljs-built_in">write</span>(mySolvedModel[<span className="hljs-string">'resultsNodes'</span>])</span><br/>
				</span></code></pre>
			</div>
			<p>The response 'model' field will now include 'results' and 'resultsNodes' fields, which contain the optimization results as a string for the aggregated and nodal results data, respectively. These can be directly written to .csv files using the code provided above, and should be identical to detailed results delivered by the DER-CAM Desktop user interface.</p>
			<p><strong>Note</strong>: Depending on the version of python you are running, the write commands above may insert additional line breaks between each line of these .csv files. To correct for this, use the following code for results file write statements:</p>
			<div className='doc-code-block'>
				<pre><code className="lang-python">f.<span className="hljs-built_in">write</span>(mySolvedModel[<span className="hljs-string">'results'</span>].<span className="hljs-built_in">replace</span>(<span className="hljs-string">'\r\n'</span>, <span className="hljs-string">'\n'</span>))
				</code></pre>
			</div>
		</div>

		<div id='sec-data' className='doc-sec'>
			<h3>Downloading Reference Data:</h3>
			<p>Users building models locally can download DER-CAM formatted datasets for load profiles and normalized PV generation profiles from the DER-CAM API reference data libarary. Users can retrieve a full list of available datasets, as well as specific data, which can be copied directly into their own DER-CAM models.</p>
			
			<h4 className='doc-subsec-title'>Retrieving List of Building Loads:</h4>
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
				<span className="hljs-attr">response</span> = req.get(f<span className="hljs-string">'{'{urlBase}/{userKey}/data/load/list'}'</span>)<br/>
				bldgLoadList = response.json()['bldgList']
			 	</span></code></pre>
			</div>

			<p>Below is an example of two of them many buildings returned when requesting the building load list.</p>
			<div className='doc-code-block'>
				<pre><code className="lang-json"><span>
					<span className="code-comment"># bldgLoadList contains info for all available building loads</span><br/><br/>
					[
				    {"{"}<br/>
				        <span className="hljs-string">    'BLDG_TYPE': 'largeoffice'</span>, <br/>
						<span className="hljs-string">    'BLDG_TYPE_FULL': 'Large Office'</span>, <br/>
						<span className="hljs-string">    'CLIMATE_ZONE': '1A'</span>, <br/>
						<span className="hljs-string">    'ID': 1</span>, <br/>
						<span className="hljs-string">    'LOCATION': 'miami'</span>, <br/>
						<span className="hljs-string">    'LOCATION_FULL': 'Miami'</span>, <br/>
						<span className="hljs-string">    'VINTAGE': 'new'</span> <br/>
				    {"}"},<br/>
				    {"{"}<br/>
				        <span className="hljs-string">    'BLDG_TYPE': 'largeoffice'</span>, <br/>
						<span className="hljs-string">    'BLDG_TYPE_FULL': 'Large Office'</span>, <br/>
						<span className="hljs-string">    'CLIMATE_ZONE': '2A'</span>, <br/>
						<span className="hljs-string">    'ID': 2</span>, <br/>
						<span className="hljs-string">    'LOCATION': 'houston'</span>, <br/>
						<span className="hljs-string">    'LOCATION_FULL': 'Houston'</span>, <br/>
						<span className="hljs-string">    'VINTAGE': 'new'</span> <br/>
				     {"}"},<br/>...</span>
				  ]
				</code></pre>
			</div>


			<h4 className='doc-subsec-title'>Downloading Specific Building Loads:</h4>
			
			<p>To download specific building data, users can identify which building id they should use by examining the list returned above, and pass it as the 'bldgId' query term in the URL of their request</p>
			

			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	<span className="code-comment"># Fetching load profile using building id</span><br/><br/>
				bldgId = 39<br/>
				<span className="hljs-attr">response</span> = req.get(f<span className="hljs-string">'{'{urlBase}/{userKey}/data/load/dl/?bldgId={bldgId}'}'</span>)<br/>
			 	</span></code></pre>
			</div>
			<br/>
			<p>Alternatively, they can provide building parameters as query terms. Users must provide a building type ('bldg'), vintage ('vint'), and either a location name ('loc') or climate zone ('cz') in their query terms.
			Query terms are not case sensitive, and should match the fields 'BLDG_TYPE', 'VINTAGE', 'LOCATION', and 'CLIMATE_ZONE' returned in the building list. Do not use the full names (i.e. with spaces or special chars) for
			locations or building types.</p>
			
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	<span className="code-comment"># Fetching load profile using building properties</span><br/><br/>
				loc = 'sanfrancisco'<br/>
				bldg = 'smalloffice'<br/>
				vint = 'new'<br/>
				<span className="hljs-attr">response</span> = req.get(f<span className="hljs-string">'{'{urlBase}/{userKey}/data/load/dl/?loc={loc}&bldg={bldg}&vint={vint}'}'</span>)<br/>
			 	</span></code></pre>
			</div>

			<p>If a user provides too many query terms (e.g. a building id and building parameters) or too few query terms, the request will fail. Examine the response 'reason' field for more information if this occurs.</p>

			<p>A successful request will return the building description info in the 'bldg' field and the load data in the 'data' field</p>
			<p>Both of the requests above should return the following</p>
			<div className='doc-code-block'>
				<pre><code className="lang-json"><span>
					<span className="code-comment"># response.json()['bldg'] should return:</span><br/><br/>
					
				    {"{"}<br/>
				        <span className="hljs-string">    'BLDG_TYPE': 'smalloffice'</span>, <br/>
						<span className="hljs-string">    'BLDG_TYPE_FULL': 'Small Office'</span>, <br/>
						<span className="hljs-string">    'CLIMATE_ZONE': '3C'</span>, <br/>
						<span className="hljs-string">    'ID': 39</span>,<br/>
						<span className="hljs-string">    'LOCATION': 'sanfrancisco'</span>, <br/>
						<span className="hljs-string">    'LOCATION_FULL': 'San Francisco'</span>, <br/>
						<span className="hljs-string">    'VINTAGE': 'new'</span> <br/>
				    {"}"}</span>
				  
				</code></pre>
			</div>

			<h4 className='doc-subsec-title'>Saving Load Data Locally:</h4>

			<p>This request also returns a string-formatted copy of the load profile data in the 'csv' field. This can be written directly to a text file and used in DER-CAM model construction in Excel or other programs.</p>

			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
				<span className="code-comment"># define location to write load data</span><br/>
				loadDataFile = <span className="hljs-string">'./path/to/load_data.csv'</span><br/><br/>
    			<span className="hljs-keyword">with</span> <span className="hljs-built_in">open</span>(loadDataFile, <span className="hljs-string">'w'</span>) <span className="hljs-keyword">as</span> f:<br/>
				<span>    for row in response.json()['csv']:<br/></span>
				<span>        <span className="code-comment"># convert csv data to string</span><br/></span>
				<span>        row = [str(val) for val in row]<br/></span>
				<span>        f.write(','.join(row)+'\n')<br/></span>
				</span></code></pre>
			</div>

			
			<h4 className='doc-subsec-title'>Retrieving List of Solar Generation Locations:</h4>
			<p>Similar to the process of accessing load data, users can download DER-CAM formatted PV generation profiles for hundreds of U.S. locations.</p>

			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
				<span className="hljs-attr">response</span> = req.get(f<span className="hljs-string">'{'{urlBase}/{userKey}/data/pv/list'}'</span>)<br/>
				pvLocList = response.json()['pvList']
			 	</span></code></pre>
			</div>

			<p>The request above will return a list of all PV locations. However, if a user prefers to return only stations located in a specific state, they can
			pass the two-letter abbreviation for the target state as a query term.</p>

			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
				<span className="code-comment"># define state to search for</span><br/>
				stateAbbr = 'CA'<br/><br/>
				<span className="hljs-attr">response</span> = req.get(f<span className="hljs-string">'{'{urlBase}/{userKey}/data/pv/list?state={stateAbbr}'}'</span>)<br/>
				pvList = response.json()['pvList']
			 	</span></code></pre>
			</div>

			<p>The request above will return only a list of PV locations in California (CA). In either case, the response 'pvList' field will return a list of locations
			 that resembles the following:</p>

			<div className='doc-code-block'>
				<pre><code className="lang-json"><span>
					<span className="code-comment"># pvList contains info for all available pv locations</span><br/><br/>
					[
				    {"{"}<br/>
				        <span className="hljs-string">    'ID': 127,</span> <br/>
						<span className="hljs-string">    'STATE': 'CA',</span> <br/>
						<span className="hljs-string">    'STATE_NAME': 'California',</span> <br/>
						<span className="hljs-string">    'STATION': 'ALTURAS'</span> <br/>
				    {"}"},<br/>
				    {"{"}<br/>
				        <span className="hljs-string">    'ID': 128,</span> <br/>
						<span className="hljs-string">    'STATE': 'CA',</span> <br/>
						<span className="hljs-string">    'STATE_NAME': 'California',</span> <br/>
						<span className="hljs-string">    'STATION': 'ARCATA AIRPORT'</span> <br/>
				    {"}"},<br/>
				    {"{"}<br/>
				        <span className="hljs-string">    'ID': 129,</span> <br/>
						<span className="hljs-string">    'STATE': 'CA',</span> <br/>
						<span className="hljs-string">    'STATE_NAME': 'California',</span> <br/>
						<span className="hljs-string">    'STATION': 'BAKERSFIELD MEADOWS FIELD'</span> <br/>
				     {"}"},<br/>...</span>
				  ]
				</code></pre>
			</div>

			<h4 className='doc-subsec-title'>Downloading Specific Solar Profile:</h4>
			<p>With data from the PV location list, users can use either the location id or station name to download the 
			pv profile data itself. Examples for both approach are given below.</p>

			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	<span className="code-comment"># Fetching pv profile using location id</span><br/><br/>
				locId= 127<br/>
				<span className="hljs-attr">response</span> = req.get(f<span className="hljs-string">'{'{urlBase}/{testKey}/data/pv/dl?locId={locId}'}'</span>)<br/>
			 	
			 	</span></code></pre>
			</div>

			<p>When searching with station, the 'station' query term is not case-sensitive, but it must match any spaces or special chars included in the 'STATION' field of the pv list.</p>

			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	<span className="code-comment"># Fetching pv profile using station name</span><br/><br/>
				station = 'BAKERSFIELD MEADOWS FIELD'<br/>
				<span className="hljs-attr">response</span> = req.get(f<span className="hljs-string">'{'{urlBase}/{testKey}/data/pv/dl?station={station}'}'</span>)<br/>
			 	
			 	</span></code></pre>
			</div>

			<p>In both cases, the request should return the location information in the 'loc' field, a dictionary of the pv data in the 'data' field, and a csv formatted copy of the pv data in the 
			'csv' field. The csv data can be written to a local file using a similar method as used with the load data: </p>


			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
				<span className="code-comment"># define location to write pv data</span><br/>
				pvDataFile = <span className="hljs-string">'./path/to/pv_data.csv'</span><br/><br/>
    			<span className="hljs-keyword">with</span> <span className="hljs-built_in">open</span>(pvDataFile, <span className="hljs-string">'w'</span>) <span className="hljs-keyword">as</span> f:<br/>
				<span>    for row in response.json()['csv']:<br/></span>
				<span>        <span className="code-comment"># convert csv data to string</span><br/></span>
				<span>        row = [str(val) for val in row]<br/></span>
				<span>        f.write(','.join(row)+'\n')<br/></span>
				</span></code></pre>
			</div>

		</div>

		


		<div id='sec-error' className='doc-sec'>
			<h3>Troubleshooting:</h3>
			<p>There are numerous small errors that can cause your API request to fail. If you make a valid request, but do not receive the response you were expecting,
			 examine the response status code and reason fields. These fields should be helpful in directing you to the underlying error.</p>

			 <div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	print(response.status_code, response.reason)
			 	</span></code></pre>
			</div>

			<p>This information should be provide some helpful guidance on the underlying error in your request. E.g.</p>

			<p>Using an incorrect user key:</p>
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	404 No user found with API key: example_incorrect_api_key
			 	</span></code></pre>
			</div>
			
			<p>Using an incorrect model key:</p>
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	404 No model found with key: example_incorrect_model_key
			 	</span></code></pre>
			</div>
			
			<p>Trying to access a model that does not belong to your account:</p>
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	403 You do not have permission to access model example_not_your_model_key
			 	</span></code></pre>
			</div>

			<p>Other errors with your request URL:</p>
			<div className='doc-code-block'>
				<pre><code className="lang-python"><span>
			 	404 Not Found
			 	</span></code></pre>
			</div>
		</div>
		
	</div>
)

export default doc;