import React from 'react';
import { BrowserRouter, Link, Route, Switch, Redirect } from 'react-router-dom';

import './HomeApps.css';

import Navbar from '../Navbar/Navbar'
import ModelList from '../ModelList/ModelList'

import Helper from '../../helper';

var dercamLogo = '/icons/dercam-logo.png';

class HomeApps extends React.Component{
	constructor(props) {
		super(props)

		this.state = {
			
		}

	}

	componentDidMount() {
	    	this.props.checkAuth()
	  }

	
  

  	render(){ 

  		// if fetching auth, return blank page
  		if(this.props.authFetch) {
  			return (
  				<div className="page-container">
					<Navbar 
						activateHome = {this.props.activateHome}
						auth = {this.props.auth}
						userLogout = {this.props.userLogout}
					/>
				</div>
  			)
  		}



  		// if not auth, redirect to home
  		if(!this.props.auth) {
  			console.log(`accesing /u without auth. auth status: ${this.props.auth}`)
  			return(
  				<Redirect from='/u/' to="/" />
  			)
  		}

	    return( 
	    	
				<div className='sub-nav-container'>
					<div className='dash-menu-container'>

						<div className='apps-header-container'>

							<h1 className='apps-title-h2'>Welcome to</h1>

							<div className='apps-inline-logo-div'>

								<div className='apps-logo-container'>
									<img src={dercamLogo} alt="dercam logo" />
								</div>

								<h1 className='apps-title-h1'>DER-CAM</h1>

							</div>

						</div>

						<div className='dash-sub-container'>
							<h2 className='sec-title-h2 dash-sub-head'>Select Resource</h2>
							<div className="dash-sub-menu">
					        	
					        	<div className='dash-btn dark-btn'>
					        		<Link to="/u/rdt" className='react-link'><h2>Resilience Design Tool</h2></Link>
					        	</div>
					        	<div className='dash-btn dark-btn'>
					        		<Link to="/u/dercam" className='react-link'><h2>DER-CAM Web UI</h2></Link>
					        	</div>
					        	<div className='dash-btn dark-btn'>
					        		<Link to="/u/api" className='react-link'><h2>DER-CAM API</h2></Link>
					        	</div>
					        </div>
					    </div>
					    <div className='dash-sub-container'>
							<h2 className='sec-title-h2 dash-sub-head'>Learn More</h2>
							<div className="dash-sub-menu">

								<div className='dash-btn dark-btn'>
					        		<Link to="/u/updates" className='react-link'><h2>Updates & Changes</h2></Link>
					        	</div>
					        	
					        	<div className='dash-btn dark-btn'>
					        		<a href="https://dercam.lbl.gov" ><h2>DER-CAM Publications</h2></a>
					        	</div>
					        </div>
					    </div>
					    <div className='dash-sub-container display-none'>
							
							<div className="dash-sub-menu">
					        	
					        	<div className='dash-btn dark-btn'>
					        		<Link to="/u/test" className='react-link'><h2>Testing</h2></Link>
					        	</div>
					        </div>
					    </div>
				    </div>
			    </div>
	    ) 
  	} 

} 


export default HomeApps;