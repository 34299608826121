import React from 'react';

import './DocRdt.css';

const rdtDocHtml = (

	<div className='rdt-doc-container'>

		<section className='rdt-doc-sec'>
			<h2 className='rdt-doc-h2'>What is Resilience Design Tool?</h2>

			<p className='rdt-doc-text'>
				Resilience Design Tool (RDT) is a web application that assists with the design process for distributed energy systems and microgrids. It allows users to explore the trade-off between cost and resilience of different system capacities; taking into account multiple value streams for each energy asset.
			</p>

		</section>

		<section className='rdt-doc-sec'>
			<h2 className='rdt-doc-h2'>What energy assets can RDT model?</h2>
			<p className='rdt-doc-text'>
				RDT allows users to model new and existing assets, including solar PV, battery energy storage, and generator of various types, including combined heat and power (CHP) units. RDT also allows users to define energy efficiency investment options, which reduce overall energy consumption and help support resilient performance.
			</p>
			</section>
			<section className='rdt-doc-sec'>
			<h2 className='rdt-doc-h2'>What kind of analysis does RDT support?</h2>
			<p className='rdt-doc-text'>
				RDT allows users to quickly generate multiple resilience scenarios (i.e. the level of critical load that must be supported during outages) and options to invest in distributed energy resources (DERs) to survive outages. RDT uses the DER-CAM optimization energy to generate the optimal design solution to each of those scenarios, and returns those design recommendations, along with key financial and technical performance metrics to the user.
				<br/><br/>
				By exploring multiple critical-load scenarios, RDT allows users to see the tradeoff between increasing levels of resilience performance (i.e. higher levels of critical load supported) and the increased capital costs needed to deploy those systems. 
				<br/><br/>
				RDT is a useful tool for exploratory design for public entities, like states and municipalities looking to deploy microgrids for community support and public safety, as well as private entities looking to bolster resilience operation in the face of prolonged outage events.
			</p>
		</section>

		<section className='rdt-doc-sec'>
			<h2 className='rdt-doc-h2'>How does RDT differ from DER-CAM?</h2>
			<p className='rdt-doc-text'>
				RDT is an application of DER-CAM specifically designed for the problem of quickly assessing the tradeoff of cost and resilience. It provides a streamlined project-construction process, requiring users to input only essential data. It automatically generates multiple resilience scenarios and uses the DER-CAM optimization engine to solve each scenario.
				<br/><br/>
				RDT is intended to provide a quick user-friendly introduction to using DER-CAM. However, it does not include the all modeling features included in DER-CAM. Experienced RDT users may wish to model their projects in greater detail, at which point they may transition to using the original DER-CAM tool. 
			</p>
			</section>
			<section className='rdt-doc-sec'>
			<h2 className='rdt-doc-h2'>What do I need to get started?</h2>
			<p className='rdt-doc-text'>
				To build a new project, users need to define the following general data inputs. RDT is designed to minimize the data collection burden for users, but below is a list of general data inputs users might try to collect before getting started.
			</p>
			<h4 className='rdt-doc-h4'>Location</h4>
			<p className='rdt-doc-text'>
				This data is used to scale building loads and define solar PV potential
			</p>
			<h4 className='rdt-doc-h4'>Building type & size</h4>
			<p className='rdt-doc-text'>
				RDT uses reference building data to define loads. Users only need to define the building type and size to get started
			</p>
			<h4 className='rdt-doc-h4'>Annual energy consumption</h4>
			<p className='rdt-doc-text'>
				If additional consumption data is available, users can use their annual consumption amounts to scale building loads to better fit their application
			</p>
			<h4 className='rdt-doc-h4'>Energy costs & tariffs</h4>
			<p className='rdt-doc-text'>
				RDT provides a comprehensive list of electricity rate structures to capture the economics of importing electricity and fuels
			</p>
			<h4 className='rdt-doc-h4'>Electricity export options</h4>
			<p className='rdt-doc-text'>
				Users are also able to define electricity export options, rates, and structures
			</p>
			<h4 className='rdt-doc-h4'>Existing DER assets</h4>
			<p className='rdt-doc-text'>
				The capacities and characteristics of existing solar PV, batteries, and generators can be defined. Default starting values for many of these are available, but can each be customized to fit users’ needs
			</p>
			<h4 className='rdt-doc-h4'>New DER assets</h4>
			<p className='rdt-doc-text'>
				These are assets that are available for deployment. User can customize their costs and technical characteristics, but default values are provided
			</p>
			<h4 className='rdt-doc-h4'>Energy efficiency options</h4>
			<p className='rdt-doc-text'>
				Users can also define options to invest in energy efficiency improvements that reduce overall energy consumption. Suggested EE options are provided in the help menu, but these options vary significantly from application to application. It is recommended that these options are customized to a particular project need in order to remain accurate.
			</p>
			<h4 className='rdt-doc-h4'>Resilience options</h4>
			<p className='rdt-doc-text'>
				Here users can define the occurrences and frequency of full-day grid outages, the level of critical load that must be supported during outage, and the costs associated with unserved load (i.e. value of lost load). Users can define these values however suits their modeling needs.
			</p>
		</section>

		<section className='rdt-doc-sec'>
			<h2 className='rdt-doc-h2'>Something go wrong?</h2>
			<p className='rdt-doc-text'>
				It is possible that something could go wrong with your project: constructing your project input data, transmitting the model to our server, or in attempting to find a solution. When exploring your submitted projects and results, RDT will indicate when models failed to complete. Some guidance on potential failures outlined below:
			</p>
			<h4 className='rdt-doc-h4'>Failed Project</h4>
			<p className='rdt-doc-text'>
				If an entire project model failed to solve, then something has gone wrong with defining inputs. Please review the project inputs to ensure the correct and complete data types are submitted.
			</p>
			<h4 className='rdt-doc-h4'>Queued Project</h4>
			<p className='rdt-doc-text'>
				Nothing is wrong here, you project is just waiting to solve. The time it takes for a model to solve may vary a lot depending on its complexity and the number of models ahead of it in the queue. 
			</p>
			<h4 className='rdt-doc-h4'>Infeasible Scenario</h4>
			<p className='rdt-doc-text'>
				If you’re able to explore the results of a particular project, then the model has been successfully processed and executed on the server. This however does not guarantee that a solution to every scenario could be found. If some of your scenarios are returned as infeasible, that means the requirements are too high, the investment options are too strict, or both. Consider revising your solution to reduce critical load requirements and/or enabling investment in more DER assets or types.
			</p>
			<h4 className='rdt-doc-h4'>Failed Scenario</h4>
			<p className='rdt-doc-text'>
				If only a subset of your project scenarios fails, this means incorrect or incomplete data has been passed in some of the scenario inputs. Review DER and resilience inputs of your model to identify potential issues here. In any case, this outcome should be a rare occurrence. 
				<br/><br/>
				If none of these tips help you resolve your issue, please contact support at <span className='rdt-doc-link'>dercam@lbl.gov</span> with the subject “RDT technical support”.
			</p>
		</section>

		<section className='rdt-doc-sec'>
			<h2 className='rdt-doc-h2'>Something missing?</h2>
			<p className='rdt-doc-text'>
				RDT has been designed with input from a variety of stakeholders, but it’s possible that some important features are missing, limiting RDT’s usefulness for your application. If you feel like RDT would benefit from the addition of new features, we want to hear from you.
				<br/><br/>
				Please contact us at <span className='rdt-doc-link'>dercam@lbl.gov</span> with the subject “RDT features” and tell us more.
			</p>
		</section>

	</div>
)


class DocRdt extends React.Component {

	

	

	render() {

		return (
			<div className="page-container">
				<div className='page-scroll-container'>
					
					{rdtDocHtml}

				</div>
		    </div>
		)
	}
}

export default DocRdt;