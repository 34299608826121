import React from 'react';
import { BrowserRouter, Link, Route, Switch, withRouter } from 'react-router-dom';

import './RouterRdt.css';

import HomeRdt from '../HomeRdt/HomeRdt';
import Projects from '../Projects/Projects';
import NewProject from '../NewProject/NewProject';
import Results from '../Results/Results';

import DocRdt from '../DocRdt/DocRdt';


import Helper from '../../helper';
import ProjectUtils from '../../ProjectUtils'
import initProject from '../NewProject/initProject.js';

class RouterRdt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }

    this.openProjectCopy= this.openProjectCopy.bind(this);

  }


  async openProjectCopy(modelKey) {

    // const url = this.props.match.url
    console.log('calling openProjectCopy')

    // fetch project from server
    let urlModel = `${process.env.REACT_APP_URL_BASE}/json/rdt/model/${modelKey}/copy`;
    let response = await fetch(urlModel, {credentials: 'include'});

    // if status GET request successful
    if(response.status===200) {
      let data = await response.json();
      let nestedProject = data.model;
    
      // get initial project template
      let flatProject = initProject.createNew();

      // repackage project for editing
      flatProject = ProjectUtils.unpackProject(nestedProject, flatProject);

      // add -copy suffix to name
      let curProjName = flatProject.gen_name;
      curProjName += ' - COPY';
      flatProject.gen_name = curProjName;

      // save project to app state
      await this.props.saveProject('rdtProj', flatProject)

      // redirect to project editor
      this.props.history.push(`${this.props.match.url}/new`);


    } else {
      console.log(`could not retrieve model: ${modelKey}`)
    }


  }

  

  render() {
    const url = this.props.match.url

    return (
          

            <Switch>
              <Route exact path={url}>
                <HomeRdt
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                  userLogout = {this.props.userLogout}
                  appUrl={url}
                />
              </Route>
              <Route exact path={`${url}/about`}>
                <DocRdt 
                  appUrl={url}
                />
              </Route>
              <Route exact path={`${url}/projects`}>
                <Projects 
                  appUrl={url}
                  openProjectCopy={this.openProjectCopy}
                  modelType='rdt'
                  apiProjectUrl={`${process.env.REACT_APP_URL_BASE}/json/rdt/model`}
                />
              </Route>
              <Route exact path={`${url}/projects/:modelKey`}>
                <Results 
                  appUrl={url}
                />
              </Route>
              <Route exact path={`${url}/new`}>
                <NewProject 
                  appUrl={url}
                  saveProject = {this.props.saveProject}
                  clearSavedProject = {this.props.clearSavedProject}
                  rdtProj = {this.props.rdtProj}
                />
              </Route>
              <Route exact path={`${url}/data`}>
                <div className='sub-nav-container'>
                  <h1 className='sec-title-h1'>RDT DATA</h1>
                </div>              </Route>
            </Switch>
    )
  }
}

export default withRouter(RouterRdt);
