import React from 'react';

import './Inputs.css';


class InputTable extends React.Component{ 


	generateHeader() {
		return(
			<tr className='input-table-head'>
		    	<td className='input-table-header'></td>
			    {this.props.colLabels.map((colLabel) => (
					<td className='input-table-header-value'>{colLabel}</td>
			    ))}
			</tr>
		)
	}

	generateRowN(ii) {

		let nCols = this.props.colLabels.length;
		let colNums = [...Array(nCols).keys()]

		return(
			<tr className='input-table-row'>
			    <td className='input-table-row-label'>{this.props.rowLabels[ii]}</td>
			    {colNums.map((jj) => (
					<td className='input-table-data-cell'>
				    	<input 
				    		className='input-table-data-field'
				            name={`'item-${ii}-${jj}' `}
				            value={this.props.data[ii][jj]} 
				            onChange={this.props.handleChange} 
				            onBlur={this.props.onExit}
				            data-row-num={ii}
				            data-col-num={jj}
				            data-name={this.props.dataName}
				            required
				        />
				    </td>
			    ))}
			  </tr>
		)
	}

	generateRowAll() {
		let nRows = this.props.rowLabels.length;
		let rowNums = [...Array(nRows).keys()]

		return(
			<React.Fragment>
				{rowNums.map((ii) => (
					this.generateRowN(ii)
			    ))}
			</React.Fragment>
		)
	}
	

  render(){ 
  	if(!this.props.hideComponent){
  		return( 
	    	<section className = 'input-table-container'>
				<h3 className='input-title'>{this.props.tableName}</h3>
		      	<form className='input-table-form'> 

			      	<table className='input-table'>
			      	  {this.generateHeader()}
			      	  {this.generateRowAll()}
					</table>			      	
			    </form> 
		    </section>
	    )
  	} else {
  		return null;
  	}
     
  } 
} 


export default InputTable;