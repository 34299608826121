import React from 'react';

import './HelpBox.css';


import { ReactComponent as CaretIcon } from '../../icons/caret.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevron.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as HelpIcon } from '../../icons/help.svg';


class HelpBox2 extends React.Component {

	// component renders a toggable box 
	// toggle state here is managed by internal component state
	// that typically contains additiona help text

	constructor(props) {
		super(props)

		this.state ={
			expanded: false
		}

		this.toggleExpandInt = this.toggleExpandInt.bind(this);
	}

	toggleExpandInt() {
		this.setState({expanded:!this.state.expanded})
	}


	selectIcon() {
		// method to return either open or close icon based on current open value passed in props

		const closeIcon = <CloseIcon/>;
		const openIcon = <HelpIcon/>;

		return this.state.expanded ? closeIcon: openIcon
		
	}

	selectLabelText() {
		// returns text for open/close cases

		
		return this.state.expanded ? 'Close' : 'More Info'
		

	}

	renderTopBar() {
		// method to render the top "button" portion of the collapisble help menu

		return(
			<div className='np-help2-toggle-section-bar'>
				<div className='np-help2-toggle-btn' onClick={this.toggleExpandInt}>
					<div className='np-help2-toggle-icon'>{this.selectIcon()}</div>
					<h2 className='np-help2-toggle-section-label'>{this.selectLabelText()}</h2>
				</div>
			</div>
		)
	}

	render() {

		// content passed to component that is only displayed if 'expanded' prop is true
		var content = <div className='np-help2-toggle-section-content'>{this.props.children}</div>

		return(
			<div className='np-help2-toggle-section'>
				{this.renderTopBar()}
				{this.state.expanded ? content : null}
			</div>
		)
	}

}

export default HelpBox2;