import React from 'react';

import './ResultsTable.css';

class ResultsTable extends React.Component {

	makeTableTitle() {
		if(this.props.title){
			return <tr className='res-table-title'><th></th><th colspan="100%">{this.props.title}</th></tr>
		}
	}

	makeTableHeader(headerLabelList) {

		// if unit is provided, process unit string
		if(this.props.unit){
			var unitStr = `units: ${this.props.unit}`
		} else {
			var unitStr = null
		}

		// process col label if passed in props
		 if(this.props.procColLabels){
		 	// console.log('procColLabels passed in props')
		 	var procColLabels = this.props.procColLabels
		 } else {
		 	// console.log('procColLabels NOT passed in props')
		 	var procColLabels = (x) => x
		 }

		// dynamically generate status table header based on list of scenario names
		const tableHeader = (
			<tr className='res-table-header-row'>
					<th className='res-table-unit'>{unitStr}</th>
					{headerLabelList.map((label) => (
						<th>{procColLabels(label)}</th>
		    ))}
			</tr>
		);

		return tableHeader

	}

	buildTableRow(data, colLabels, rowLabel) {

		 var tableCells = [];

		 // process row label if passed in props
		 if(this.props.procRowLabels){
		 	var rowLabelFormatted = this.props.procRowLabels(rowLabel)
		 } else {
		 	var rowLabelFormatted = rowLabel
		 }

		 tableCells.push(<td className='res-table-row-label'>{rowLabelFormatted}</td>);

		 for (let j = 0; j < colLabels.length; j++) {

		 		let colLabel = colLabels[j];

		 		let newCellData = data[colLabel][rowLabel];

		 		// if TRUE preserveType not passed, try to convert type to int
		 		if (!this.props.preserveType){
			 		try {
			 			newCellData = parseInt(newCellData)
			 		} catch {

			 		}
			 	}

		 		tableCells.push(<td className='res-table-data'>{newCellData}</td>);

		 }

		 return <tr className='res-table-data-row'> {tableCells} </tr>


	}


	buildTableContent(data, colLabels, rowLabels) {

		var tableRows = []

		for (let i = 0; i < rowLabels.length; i++) {
		  let rowLabel = rowLabels[i];
		  // console.log(`on index ${i} row label is ${rowLabel}`)
		  // console.log(`first data value should be ${data[colLabels[0]][rowLabel]}`)
		  let newRow = this.buildTableRow(data, colLabels, rowLabel);

		  tableRows.push(newRow);

		}

		return tableRows;

	}


	makeScenarioTable() {

		const colLabels = Object.keys(this.props.data)

		const firstCol = colLabels[0]

		const rowLabels = Object.keys(this.props.data[firstCol])

		// console.log(colLabels)
		// console.log(firstCol)
		// console.log(rowLabels)
		// console.log(this.props.data)

		const resultsTable = (
			<table className='res-table'>
			  {this.makeTableTitle()}
				{this.makeTableHeader(colLabels)}
				{this.buildTableContent(this.props.data, colLabels, rowLabels)}
			</table>

		)

		return resultsTable

	}

  render(){ 

    return( 
    	<div className="res-table-container">
				{this.props.data ? this.makeScenarioTable() : null}
			</div>
    ) 
  }
} 


export default ResultsTable;