import React from 'react';
import { withRouter } from 'react-router-dom';

import './NewProject.css';

import InputField from '../Inputs/InputField';
import InputTextArea from '../Inputs/InputTextArea';
import InputSelect from '../Inputs/InputSelect';
import InputCheckbox from '../Inputs/InputCheckbox';
import InputTable from '../Inputs/InputTable';
import InputArray from '../Inputs/InputArray';

import ModelAssetList from '../ModelAssetList/ModelAssetList';

import NavbarProject from '../NavbarProject/NavbarProject';

import HelpBox from '../HelpBox/HelpBox'

import initProject from './initProject.js';
import HelpText from './HelpText.js';

import Modal from '../Modal/Modal'

import Helper from '../../helper';
import ProjectUtils from '../../ProjectUtils';

import countryList from '../../resources/countries.js';
import vectors from '../../resources/vectors.js'

import { ReactComponent as LoadIcon } from '../../icons/loading.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

const _ = require('lodash');


// const stateList = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI',
// 				   'ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI',
// 				   'MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC',
// 				   'ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT',
// 				   'VT','VA','WA','WV','WI','WY'];

// var stationList = ['Solar-station list not loaded'];


const pvAngleList = ['lat', 0, 45, 90];
const pvOrientList = ['S', 'SW', 'SE', 'W', 'E', 'N', 'NW', 'NE']; 

const enduseList = ['Electricity','Cooling','Heating','Water Heating','Refrigeration','Natural Gas']

const seasonList = ['Summer', 'Winter'];
const monthList = ["January","February","March","April","May","June","July",
            	   "August","September","October","November","December"]
const seasonDaysList = ['Summer-weekday', 'Summer-peak day', 'Summer-weekend',
						'Winter-weekday', 'Winter-peak day', 'Winter-weekend']

const daysList = ['weekday', 'peak day', 'weekend'];
const periodsList = ['onpeak', 'midpeak', 'offpeak'];
const demandPeriodsList = ['onpeak', 'midpeak', 'offpeak', 'noncoindicent', 'coincident'];
const tierList = ['tier 1', 'tier 2', 'tier 3'];
const fuelList = ['natural gas', 'diesel', 'biodiesel', 'hydrogen'];
const feeList = ['electricity', 'natural gas', 'diesel', 'hydrogen'];
const hourList = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
const scenList = ['scenario 1', 'scenario 2', 'scenario 3', 'scenario 4'];

const pageList = ['Creating a Project', 'Project Info', 'Location Data', 'Building Data', 'Electricity Tariff Structure', 
				  'Electricity Tariff Data', 'Electricity Export Options', 'Fuel Prices', 'Existing DER Assets',
				  'New DER Options', 'Energy Efficieny Investments', 'Resilience Scenarios',
				  'Review Project']

class NewProject extends React.Component{ 
	constructor(props) {
		super(props)

		this.state = {

			// state vars related to project submission
			submitPending: false,
			submitSuccess: false,
			submitFailure: false,
			submitDelay: 1000, 

			pageList: pageList,
			pageIndex: 0,
			pageIndexMax:(pageList.length-1),
			displayHelp: true,

			projectData: initProject.createNew(),
			projectDataNested: {},

			curExDg: initProject.createDefaultExDg(),
			curNewDg: initProject.createDefaultNewDg(),
			curEe: initProject.createDefaultNewEe(),

			// bldg load selecter vars
			loadList: [],
			bldgList: [],
		    vintageList: [], 
		    climateList: [],
		    selectedBldg: null,

		    // solar selecter vars
			pvList: [],
			pvStateMap: {},
			stateList: [],
		    stationList: ['Solar-station list not loaded'],
			selectedPv: null
			
		}

		this.submitProject = this.submitProject.bind(this);
		this.retryProject = this.retryProject.bind(this);
		this.goToProjects = this.goToProjects.bind(this);

		this.handleChange = this.handleChange.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.handleTableChange = this.handleTableChange.bind(this);
		this.handleArrayChange = this.handleArrayChange.bind(this);
		this.handleChangePvStation = this.handleChangePvStation.bind(this);
		this.handleChangeBldg = this.handleChangeBldg.bind(this);
		this.handleTextExit = this.handleTextExit.bind(this);
		this.handleExitNumeric = this.handleExitNumeric.bind(this);
		this.handleTableExitNumeric = this.handleTableExitNumeric.bind(this);
		this.handleArrayExitNumeric = this.handleArrayExitNumeric.bind(this);

		this.renderFormPage = this.renderFormPage.bind(this);
		this.renderProjectForm = this.renderProjectForm.bind(this);
		this.renderLocationForm = this.renderLocationForm.bind(this);
		this.renderLoadsForm = this.renderLoadsForm.bind(this);
		this.renderTariffBuilderForm = this.renderTariffBuilderForm.bind(this);
		this.renderTariffForm = this.renderTariffForm.bind(this);
		this.renderExportForm = this.renderExportForm.bind(this);
		this.renderFuelsForm = this.renderFuelsForm.bind(this);
		this.renderExDersForm = this.renderExDersForm.bind(this);
		this.renderNewDersForm = this.renderNewDersForm.bind(this);
		this.renderEeForm = this.renderEeForm.bind(this);
		this.renderOutageForm = this.renderOutageForm.bind(this);
		this.renderReviewForm = this.renderReviewForm.bind(this);

		this.deleteAsset = this.deleteAsset.bind(this);
		this.addAsset = this.addAsset.bind(this);

		this.changePage = this.changePage.bind(this);
		this.toggleHelp = this.toggleHelp.bind(this);
	}

	async componentDidMount() {

		// when new project component mounts, check if saved project is passed in props
		var projectData = null;
		if(!this.props.rdtProj){
			// if no project found, use default initial project
			projectData = initProject.createNew()
			console.log('no project passed, initializing')
		} else {
			// if rdtProj is passed in props, use passed proj
			projectData  = this.props.rdtProj
			console.log('existing project passed')

			// check if src is 'upload'. if so, unpack project
			if (projectData.src == 'upload') {

				console.log(`unpacking RDT project from src: ${projectData.src}`)

				// get initial project template
		        let flatProject = initProject.createNew();

		        // repackage project for editing
		        projectData = ProjectUtils.unpackProject(projectData, flatProject);
			}
		}

		// fetch list of building data from server
		// along with list of unique bldg types, vints, and climate zones
		var [loadList, bldgList, vintageList, climateList] = await Helper.getLoadProfileList();

	    
		// fetch list of pv station data from server	
		// along with states and pv-state-station map obj	
		var [pvList, stateList, pvStateMap] = await Helper.getPvDataList();
	    
		// station list starts as null until state is selected
	    // stationList = pvList.map(a => a.STATION);
	    var stationList = ['Select state to see station list']

	    this.setState(
		    {
		    	projectData: projectData,
		    	// bldg load data
		    	loadList: loadList,
		    	bldgList: bldgList,
		    	vintageList: vintageList, 
		    	climateList: climateList,
		    	// solar location data
		    	pvList: pvList,
		    	pvStateMap: pvStateMap,
		    	stateList: stateList,
		    	stationList: stationList
		    }
	    )


	}

	componentWillUnmount() {
		// save current project data on unmount, if not in submitSuccess state
		if(!this.state.submitSuccess){
			this.props.saveProject('rdtProj', this.state.projectData)
			console.log('saving current project data')
		} else {
			console.log('project submitted. clearing project data')
		}
	}

	async submitProject() {
		
		let projectDataNested = ProjectUtils.packageProject(this.state.projectData)
		await this.setState({projectDataNested:projectDataNested})

		let reflattenedProject = ProjectUtils.unpackProject(projectDataNested, initProject.createNew())

		console.log(reflattenedProject)

		// set submitPending to true
		await this.setState({submitPending: true})

		// console.log('submission http request disabled for testing')

	    let urlNewModel = `${process.env.REACT_APP_URL_BASE}/json/rdt/model`;
	    let data = {
	    	name: this.state.projectData.gen_name,
	    	model: this.state.projectDataNested
	    }
	    let response = await fetch(urlNewModel, {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json;charset=utf-8'
	      },
	      credentials: 'include',
	      body: JSON.stringify(data)
	    });

	    // if status GET request successful
	    console.log(response)
	    if(response.status===201) {
	      console.log('new model posted')
	      // console.log(`${this.props.appUrl}/Projects`)
	      // // window.location.href = `${this.props.appUrl}/Projects`;
	      // this.props.history.push(`${this.props.appUrl}/projects`);

	      await Helper.sleep(this.state.submitDelay);

	      await this.setState({submitSuccess: true, submitPending:false})

	      // if project submit successful, clear saved project data from App compoent
	      await this.props.clearSavedProject('rdtProj')

	    } else {
	      console.log('new model post failed')

	      await this.setState({submitFailure: true, submitPending:false})
	    }
	}

	goToProjects() {
		console.log('going to projects list')
		this.props.history.push(`${this.props.appUrl}/projects`);
	}

	retryProject() {
		this.setState({
			submitPending: false,
			submitSuccess: false,
			submitFailure: false
		})
	}

	changePage(delta) {
		// get current page num from state
		let curPage = this.state.pageIndex;
		// change page by delta value
		curPage += delta;

		// if moving beyond first page, confirm project names has been provided
		// if not, generate new project name
		var curProj = this.state.projectData
		if (curPage > 1) {
			if (!Helper.projectNameValid(curProj.gen_name)){
				// if name is missing, generate new generic name
				var projStr = Helper.genString(6);
				curProj.gen_name = `Project-${projStr}`
			}
		}

		// make sure cur page is within allowed bonds
		curPage = Math.max(0, curPage)
		curPage = Math.min(curPage, this.state.pageIndexMax)

		this.setState({pageIndex:curPage, projectData:curProj, displayHelp: false})
	}

	toggleHelp() {

		this.setState({displayHelp: !this.state.displayHelp})

	}

	handleChangePvStation(event){ 

	    // initialize new form data as previous form data
	    var newProject = this.state.projectData;

	    //update form data with changed value
	    newProject[event.target.name] = event.target.value;

	    // find solar station based on selectors
	    var { pvStateMap, pvList } = this.state
	    var { loc_stateUs, loc_city } = newProject
	    var [ stationList, selectedPv, loc_stationId ] = Helper.getSelectedPv(pvStateMap, pvList, loc_stateUs, loc_city)

	    // update pv station id
	    newProject.loc_stationId = loc_stationId;

	    //update state to reflect change
	    this.setState({
	    	projectData: newProject,
	    	selectedPv: selectedPv,
	    	stationList: stationList,
	    })
	
	}

	handleChangeBldg(event){ 

		// initialize new form data as previous form data
		var newProject = this.state.projectData;

		//update form data with changed value
		newProject[event.target.name] = event.target.value;

		// extract bldg selector fields from newProject
		var { bldg_type, bldg_vint, bldg_climateZone } = newProject;

		// use selectors to find bldg id and properties from loadList
		var [selectedBldg, bldg_bldgId, bldg_size, bldg_annElec, bldg_annNg] = Helper.getSelectedBuilding(
																this.state.loadList, bldg_type, bldg_vint, bldg_climateZone)
		// update fields after selection
		newProject.bldg_bldgId = bldg_bldgId;
		newProject.bldg_size = bldg_size;
		newProject.bldg_annElec = bldg_annElec;
		newProject.bldg_annNg = bldg_annNg;

	    //update state to reflect change
	    this.setState({
	    	projectData: newProject,
	    	selectedBldg: selectedBldg
	    })
	
	}

	async handleChange(event){ 

		// console.log(`${event.target.name} : ${this.state.projectData[event.target.name]}`)

	    // initialize new form data as previous form data
	    var newProject = this.state.projectData;

	    //update form data with changed value
	    newProject[event.target.name] = event.target.value;



	    //update state to reflect change
	    await this.setState({projectData: newProject})

	    // console.log(`${event.target.name} : ${this.state.projectData[event.target.name]}`)
	
	}

	handleExitNumeric(event){ 
		// console.log(`converting ${event.target.name} to numeric`)
	    // initialize new form data as previous form data
	    var newProject = this.state.projectData;

	    //update form data with changed value
	    // try to convert entered data into a number
	    let numericVal = parseFloat(event.target.value);
	    // if conversion fails, replace value with 0
	    if(isNaN(numericVal)){ numericVal = 0}
	    newProject[event.target.name] = numericVal;

	    //update state to reflect change
	    this.setState({projectData: newProject})
	
	}

	handleTextExit(event){
		// console.log(`converting ${event.target.name} to numeric`)
	    // initialize new form data as previous form data
	    var newProject = this.state.projectData;

	    //update form data with changed value
	    let newString = event.target.value.replace(/[^A-Z0-9]+/ig, "_");
	    newProject[event.target.name] = newString;

	    //update state to reflect change
	    this.setState({projectData: newProject})
	}

	handleTableExitNumeric(event){ 
		// extract new value and location within table from event
		let rowNum = parseInt(event.target.dataset.rowNum);
		let colNum = parseInt(event.target.dataset.colNum);
		let dataName = event.target.dataset.name;

		// extract project data and specific table data from state
		let curProject = this.state.projectData;
		let newData = this.state.projectData[dataName];

		// update table and project data with new value
		let numericVal = parseFloat(event.target.value);
		if(isNaN(numericVal)){ numericVal = 0}
		newData[rowNum][colNum] = numericVal;
		curProject[dataName] = newData;

		// set state with new value
		this.setState({projectData: curProject});
	
	}

	handleArrayExitNumeric(event){ 
		// extract new value and location within table from event
		let indexNum = parseInt(event.target.dataset.indexNum);
		let dataName = event.target.dataset.name;

		// extract project data and specific table data from state
		let curProject = this.state.projectData;
		let newData = this.state.projectData[dataName];

		// update table and project data with new value
		let numericVal = parseFloat(event.target.value);
		if(isNaN(numericVal)){ numericVal = 0}
		newData[indexNum] = numericVal;
		curProject[dataName] = newData;

		// set state with new value
		this.setState({projectData: curProject});	
	}

	handleCheckboxChange(event){ 

	    // initialize new form data as previous form data
	    var newProject = this.state.projectData;
	    
	    //update form data with changed value
	    newProject[event.target.name] = event.target.checked;

	    //update state to reflect change
	    this.setState({projectData: newProject})
	
	}

	handleTableChange(event) {
		// extract new value and location within table from event
		let rowNum = parseInt(event.target.dataset.rowNum);
		let colNum = parseInt(event.target.dataset.colNum);
		let dataName = event.target.dataset.name;

		// extract project data and specific table data from state
		let curProject = this.state.projectData;
		let newData = this.state.projectData[dataName];

		// update table and project data with new value
		newData[rowNum][colNum] = event.target.value;
		curProject[dataName] = newData;

		// set state with new value
		this.setState({projectData: curProject});
	} 

	handleArrayChange(event) {
		// extract new value and location within table from event
		let indexNum = parseInt(event.target.dataset.indexNum);
		let dataName = event.target.dataset.name;

		// extract project data and specific table data from state
		let curProject = this.state.projectData;
		let newData = this.state.projectData[dataName];

		// update table and project data with new value
		newData[indexNum] = event.target.value;
		curProject[dataName] = newData;

		// set state with new value
		this.setState({projectData: curProject});
	}

	addAsset(newAssetName, assetListName, assetLabel) {
		// add asset to existing asset list using newAsset (obj) 
		// and assetListName (str of state variable to add to)

		// use default assetLabel if none provided
		if (assetLabel === undefined) assetLabel = "asset";

		// extract asset from state
		const newAsset = _.cloneDeep(this.state[newAssetName])
		const curProj = this.state.projectData
		const curAssetList = curProj[assetListName]

		// loop through keys in object and update values
		for (const key in newAsset) {

			var newAssetProp = this.state.projectData[key];

			// confirm that asset has 'name field'
			if(key.toLowerCase().indexOf("name") >= 0) {
				// validate asset name, and use updated version if invalid
				newAssetProp = Helper.validateAssetName(newAssetProp, assetLabel)
				
			}

			// update value based on current value in state projectData
			newAsset[key] = newAssetProp;
			// refresh inputfield values back to default
			curProj[key] = this.state[newAssetName][key]
		}

		// // add setName based on number of assets already in list
		// var nAsset = curAssetList.length + 1;
		// var assetSetLabel = `${assetLabel}${nAsset}`

		// newAsset['setLabel'] = assetSetLabel
			

		// add new asset object into projectData[assetListName]
		curAssetList.push(newAsset)

		// with new asset in curAssetList
		// loop through assest to add 'setLabel' for use on server
		for(let aa=0; aa<curAssetList.length; aa++) {
			// update set label of each asset
			curAssetList[aa]['setLabel'] = `${assetLabel}${aa+1}`
		}

		// update project and state
		curProj[assetListName] = curAssetList
		this.setState({
			projectData: curProj
		})

	}


	deleteAsset(assetListName, assetIndex, edit=false) {
		// remove object of index [assetIndex] from state variable with name
		// [assetListName]
		// moves removed asset to current input fields for modificition

		// get current asset list from state/projectData
		var curProj = this.state.projectData
		var curList = curProj[assetListName]

		// remove asset of specified index and assing to currentAsset
		var curAsset = curList.splice(assetIndex, 1);
		curAsset = curAsset[0]

		// update state with modified curList
		curProj[assetListName] = curList

		// if edit is requested in input, move deleted asset to input fields
		if(edit) {
			// assign elemnts of curAsset to state newProject
			// loop through keys in object and update values
			for (const key in curAsset) {
				// update inputfield values back to current asset
				curProj[key] = curAsset[key]
			}
		}
		

		this.setState({
			projectData: curProj
		})

	}

	renderHelpToggle(content) {
		return(
			<HelpBox
				label='More Info'
				expanded={this.state.displayHelp}
				toggleExpand={this.toggleHelp}
			>

				{content}

			</HelpBox>
		)
	}


	renderProjectForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.project)}

				<div className='np-project-form'>
					
					<InputField
						fieldName='gen_name'
						fieldLabel='Project Name'
						formValue={this.state.projectData.gen_name}
						onChange={this.handleChange}
						onExit={this.handleTextExit}
					/>
					<InputTextArea
						fieldName='gen_description'
						fieldLabel='Project Description'
						formValue={this.state.projectData.gen_description}
						onChange={this.handleChange}
					/>
				</div>
				
			</div>
		)
	}


	renderLocationForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.loc)}

				<div className='np-location-form'>
					<InputSelect
						// props needed: itemList, fieldName, formValue, onChange
						itemList={this.state.stateList}
						fieldName='loc_stateUs'
						fieldLabel='State'
						formValue={this.state.projectData.loc_stateUs}
						onChange={this.handleChangePvStation}
					/>
					<InputSelect
						// props needed: itemList, fieldName, formValue, onChange
						itemList={this.state.stationList}
						fieldName='loc_city'
						fieldLabel='Solar Data'
						formValue={this.state.projectData.loc_city}
						onChange={this.handleChangePvStation}
					/>
				</div>
			</div>
		)
	}


	renderLoadsForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.bldg)}

				<div className='np-loads-form'>

					<InputSelect
						// props needed: itemList, fieldName, formValue, onChange
						itemList={this.state.bldgList}
						fieldName='bldg_type'
						fieldLabel='Building Type'
						formValue={this.state.projectData.bldg_type}
						onChange={this.handleChangeBldg}
					/>
					<InputSelect
						// props needed: itemList, fieldName, formValue, onChange
						itemList={this.state.vintageList}
						fieldName='bldg_vint'
						fieldLabel='Vintage'
						formValue={this.state.projectData.bldg_vint}
						onChange={this.handleChangeBldg}
					/>
					<InputSelect
						// props needed: itemList, fieldName, formValue, onChange
						itemList={this.state.climateList}
						fieldName='bldg_climateZone'
						fieldLabel='Climate Zone'
						formValue={this.state.projectData.bldg_climateZone}
						onChange={this.handleChangeBldg}
					/>
					<InputCheckbox
						fieldLabel='Scale by Annual Energy'
						fieldName='bldg_scaleEnergy'
						formValue={this.state.projectData.bldg_scaleEnergy}
						onChange={this.handleCheckboxChange}
					/>
					<InputField
						fieldName='bldg_size'
						fieldLabel='Building Size'
						formValue={this.state.projectData.bldg_size}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='m2'
					/>
					<div className={`np-subsection ${Helper.showHideHtml(this.state.projectData.bldg_scaleEnergy)}`}>
					
						<h2 className='np-subheading'>Annual Energy Consumption</h2>
						<InputField
							fieldName='bldg_annElec'
							fieldLabel='Electricity'
							formValue={this.state.projectData.bldg_annElec}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='MWh'
						/>
						<InputField
							fieldName='bldg_annNg'
							fieldLabel='Natural Gas'
							formValue={this.state.projectData.bldg_annNg}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='MWh'
						/>
					</div>
				</div>
				
			</div>
		)
	}

	renderTariffBuilderForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.tariff)}
				
				<div className='np-tariff-form'>
					<h2 className='np-subheading'>Select Tariff Components</h2>
					<InputCheckbox
						fieldLabel='TOU Energy Rates'
						fieldName='tariff_select_touRate'
						formValue={this.state.projectData.tariff_select_touRate}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Tiered Energy Rates'
						fieldName='tariff_select_tieredERate'
						formValue={this.state.projectData.tariff_select_tieredERate}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Hourly Energy Rates'
						fieldName='tariff_select_hourlyRate'
						formValue={this.state.projectData.tariff_select_hourlyRate}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Monthly Demand Charges'
						fieldName='tariff_select_monDmd'
						formValue={this.state.projectData.tariff_select_monDmd}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Daily Demand Charges'
						fieldName='tariff_select_dayDmd'
						formValue={this.state.projectData.tariff_select_dayDmd}
						onChange={this.handleCheckboxChange}
					/>
					
					<InputCheckbox
						fieldLabel='Monthly Fees'
						fieldName='tariff_select_monFees'
						formValue={this.state.projectData.tariff_select_monFees}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Standby Fees'
						fieldName='tariff_select_standby'
						formValue={this.state.projectData.tariff_select_standby}
						onChange={this.handleCheckboxChange}
					/>

				</div>
					
			</div>
		)
	}


	renderTariffForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.rates)}

				<div className='np-tariff-form'>
					<InputTable 
						tableName='Tariff Seasons'
						data={this.state.projectData.tariff_seasons}
						dataName='tariff_seasons'
						colLabels = {seasonList}
						rowLabels = {monthList}
						handleChange={this.handleTableChange}
						onExit={this.handleTableExitNumeric}
					/>

					<div className='input-table-inline-container'>
						<InputTable 
							tableName='TOU Hours - Summer'
							data={this.state.projectData.tariff_hoursSum}
							dataName='tariff_hoursSum'
							colLabels = {daysList}
							rowLabels = {hourList}
							handleChange={this.handleTableChange}
							onExit={this.handleTableExitNumeric}
							hideComponent={!this.state.projectData.tariff_select_touRate}
						/>
						<InputTable 
							tableName='TOU Hours - Winter'
							data={this.state.projectData.tariff_hoursWin}
							dataName='tariff_hoursWin'
							colLabels = {daysList}
							rowLabels = {hourList}
							handleChange={this.handleTableChange}
							onExit={this.handleTableExitNumeric}
							hideComponent={!this.state.projectData.tariff_select_touRate}
						/>

					</div>

					<InputTable 
						tableName='TOU Energy Rates'
						data={this.state.projectData.tariff_touRates}
						dataName='tariff_touRates'
						colLabels = {periodsList}
						rowLabels = {seasonList}
						handleChange={this.handleTableChange}
						onExit={this.handleTableExitNumeric}
						hideComponent={!this.state.projectData.tariff_select_touRate}
					/>

					<div className='input-table-inline-container'>
						<InputTable 
							tableName='Hourly Energy Rates - Summer'
							data={this.state.projectData.tariff_hourlyRateSum}
							dataName='tariff_hourlyRateSum'
							colLabels = {daysList}
							rowLabels = {hourList}
							handleChange={this.handleTableChange}
							onExit={this.handleTableExitNumeric}
							hideComponent={!this.state.projectData.tariff_select_hourlyRate}
						/>
						<InputTable 
							tableName='Hourly Energy Rates - Winter'
							data={this.state.projectData.tariff_hourlyRateWin}
							dataName='tariff_hourlyRateWin'
							colLabels = {daysList}
							rowLabels = {hourList}
							handleChange={this.handleTableChange}
							onExit={this.handleTableExitNumeric}
							hideComponent={!this.state.projectData.tariff_select_hourlyRate}
						/>

					</div>

					<div className='input-table-inline-container'>
						<InputTable 
							tableName='Tiered Rates - Energy Limits'
							data={this.state.projectData.tariff_tierElecLimit}
							dataName='tariff_tierElecLimit'
							colLabels = {tierList}
							rowLabels = {monthList}
							handleChange={this.handleTableChange}
							onExit={this.handleTableExitNumeric}
							hideComponent={!this.state.projectData.tariff_select_tieredERate}
						/>
						<InputTable 
							tableName='Tiered Rates - Energy Rates'
							data={this.state.projectData.tariff_tierElecRate}
							dataName='tariff_tierElecRate'
							colLabels = {tierList}
							rowLabels = {monthList}
							handleChange={this.handleTableChange}
							onExit={this.handleTableExitNumeric}
							hideComponent={!this.state.projectData.tariff_select_tieredERate}
						/>

					</div>

					<InputTable 
						tableName='Monthly Demand Charges'
						data={this.state.projectData.tariff_monthlyDmd}
						dataName='tariff_monthlyDmd'
						colLabels = {seasonList}
						rowLabels = {demandPeriodsList}
						handleChange={this.handleTableChange}
						onExit={this.handleTableExitNumeric}
						hideComponent={!this.state.projectData.tariff_select_monDmd}
					/>

					<InputTable 
						tableName='Daily Demand Charges'
						data={this.state.projectData.tariff_dailyDmd}
						dataName='tariff_dailyDmd'
						colLabels = {seasonList}
						rowLabels = {demandPeriodsList}
						handleChange={this.handleTableChange}
						onExit={this.handleTableExitNumeric}
						hideComponent={!this.state.projectData.tariff_select_dayDmd}
					/>

					<InputField
						fieldName='tariff_elecServiceFee'
						fieldLabel='Monthly Service Fee'
						formValue={this.state.projectData.tariff_elecServiceFee}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/kW'
						hideComponent={!this.state.projectData.tariff_select_monFees}
					/>

					<InputField
						fieldName='tariff_standbyFee'
						fieldLabel='Standby Fee'
						formValue={this.state.projectData.tariff_standbyFee}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/kW'
						hideComponent={!this.state.projectData.tariff_select_standby}
					/>

				</div>

			</div>
		)
	}


	renderExportForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.export)}

				<div className='np-export-form'>

					<InputCheckbox
						fieldLabel='Enable PV Export'
						fieldName='export_enablePV'
						formValue={this.state.projectData.export_enablePV}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Enable Other Export'
						fieldName='export_enableOther'
						formValue={this.state.projectData.export_enableOther}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Allow Annual Net Exports'
						fieldName='export_allowNetExport'
						formValue={this.state.projectData.export_allowNetExport}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Limit Export Power'
						fieldName='export_limitExportBool'
						formValue={this.state.projectData.export_limitExportBool}
						onChange={this.handleCheckboxChange}
					/>
					<InputField
						fieldName='export_limitExportPower'
						fieldLabel='Max Export Power'
						formValue={this.state.projectData.export_limitExportPower}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='kW'
					/>
					<InputCheckbox
						fieldLabel='Use Net-Energy Metering Price'
						fieldName='export_useNem'
						formValue={this.state.projectData.export_useNem}
						onChange={this.handleCheckboxChange}
					/>
					<InputField
						fieldName='export_nemFraction'
						fieldLabel='NEM Price Fraction'
						formValue={this.state.projectData.export_nemFraction}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='-'
					/>
					<InputField
						fieldName='export_nemReduction'
						fieldLabel='NEM Price Reduction'
						formValue={this.state.projectData.export_nemReduction}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/kWh'
					/>
					<InputField
						fieldName='export_flatRate'
						fieldLabel='Export - Flat Price'
						formValue={this.state.projectData.export_flatRate}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/kWh'
					/>

				</div>
				
			</div>
		)
	}


	renderFuelsForm() {
		// dumping unused components here for now
		var extraComponents = (<div>
			<InputCheckbox
				fieldLabel='Flat Fuel Rates'
				fieldName='tariff_select_flatFuel'
				formValue={this.state.projectData.tariff_select_flatFuel}
				onChange={this.handleCheckboxChange}
				onExit={this.handleExitNumeric}
			/>
			<InputCheckbox
				fieldLabel='Tiered Fuel Rates'
				fieldName='tariff_select_tieredFuel'
				formValue={this.state.projectData.tariff_select_tieredFuel}
				onChange={this.handleCheckboxChange}
				onExit={this.handleExitNumeric}
			/>
			<InputTable 
				tableName='Fuel Rates - Flat'
				data={this.state.projectData.tariff_fuelFlat}
				dataName='tariff_fuelFlat'
				colLabels = {monthList}
				rowLabels = {fuelList}
				handleChange={this.handleTableChange}
				onExit={this.handleTableExitNumeric}
				hideComponent={!this.state.projectData.tariff_select_flatFuel}
			/>

			<div className='input-table-inline-container'>
				<InputTable 
					tableName='Tiered Natural Gas Limits'
					data={this.state.projectData.tariff_tierNgLimit}
					dataName='tariff_tierNgLimit'
					colLabels = {tierList}
					rowLabels = {monthList}
					handleChange={this.handleTableChange}
					onExit={this.handleTableExitNumeric}
					hideComponent={!this.state.projectData.tariff_select_tieredFuel}
				/>
				<InputTable 
					tableName='Tiered Natural Gas Rates'
					data={this.state.projectData.tariff_tierNgRate}
					dataName='tariff_tierNgRate'
					colLabels = {tierList}
					rowLabels = {monthList}
					handleChange={this.handleTableChange}
					onExit={this.handleTableExitNumeric}
					hideComponent={!this.state.projectData.tariff_select_tieredFuel}
				/>

			</div>
		</div>)


		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.fuels)}

				<div className='np-export-form'>

					<h2 className='np-subheading'>Flat Rates Fuel Prices</h2>
					<InputField
						fieldName='fuels_ng_flatRate'
						fieldLabel='Natural Gas'
						formValue={this.state.projectData.fuels_ng_flatRate}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/therm'
					/>
					<InputField
						fieldName='fuels_diesel_flatRate'
						fieldLabel='Diesel'
						formValue={this.state.projectData.fuels_diesel_flatRate}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/gal'
					/>
					<InputField
						fieldName='fuels_biodiesel_flatRate'
						fieldLabel='Biodiesel'
						formValue={this.state.projectData.fuels_biodiesel_flatRate}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/gal'
					/>
					<InputField
						fieldName='fuels_hydrogen_flatRate'
						fieldLabel='Hydrogen'
						formValue={this.state.projectData.fuels_hydrogen_flatRate}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/kg'
					/>

					<h2 className='np-subheading'>Monthly Service Fees</h2>
					<InputField
						fieldName='fuels_ng_fees'
						fieldLabel='Natural Gas'
						formValue={this.state.projectData.fuels_ng_fees}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/month'
					/>
					<InputField
						fieldName='fuels_diesel_fees'
						fieldLabel='Diesel'
						formValue={this.state.projectData.fuels_diesel_fees}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/month'
					/>
					<InputField
						fieldName='fuels_biodiesel_fees'
						fieldLabel='Biodiesel'
						formValue={this.state.projectData.fuels_biodiesel_fees}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/month'
					/>
					<InputField
						fieldName='fuels_hydrogen_fees'
						fieldLabel='Hydrogen'
						formValue={this.state.projectData.fuels_hydrogen_fees}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/month'
					/>

				</div>
				
			</div>
		)
	}


	renderExDersForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.exDers)}

				<div className='np-ex-ders-form'>		

					<h2 className='np-subheading'>Select Existing Resources</h2>
					<InputCheckbox
						fieldLabel='Existing PV'
						fieldName='exDers_exPv'
						formValue={this.state.projectData.exDers_exPv}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Existing Battery'
						fieldName='exDers_exEs'
						formValue={this.state.projectData.exDers_exEs}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Existing Generators'
						fieldName='exDers_exDg'
						formValue={this.state.projectData.exDers_exDg}
						onChange={this.handleCheckboxChange}
					/>

					<div className={`np-subsection ${Helper.showHideHtml(this.state.projectData.exDers_exPv)}`}>
					
						<h2 className='np-subheading'>Existing PV</h2>
						<InputField
							fieldName='exDers_pvCap'
							fieldLabel='PV Capacity'
							formValue={this.state.projectData.exDers_pvCap}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW'
						/>
						<InputSelect
							itemList={pvAngleList}
							fieldName='ders_pvAngle'
							fieldLabel='Angle'
							formValue={this.state.projectData.ders_pvAngle}
							onChange={this.handleChange}
						/>
						<InputSelect
							itemList={pvOrientList}
							fieldName='ders_pvOrient'
							fieldLabel='Orientation'
							formValue={this.state.projectData.ders_pvOrient}
							onChange={this.handleChange}
						/>
					</div>

					<div className={`np-subsection ${Helper.showHideHtml(this.state.projectData.exDers_exEs)}`}>
						
						<h2 className='np-subheading'>Existing Battery</h2>

						
						<InputField
							fieldName='exDers_esCap'
							fieldLabel='Energy Capacity'
							formValue={this.state.projectData.exDers_esCap}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kWh'
						/>
						<InputField
							fieldName='ders_esChargeRate'
							fieldLabel='Max Charge Rate'
							formValue={this.state.projectData.ders_esChargeRate}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW/kWh'
						/>
						<InputField
							fieldName='ders_esDischargeRate'
							fieldLabel='Max Discharge Rate'
							formValue={this.state.projectData.ders_esDischargeRate}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW/kWh'
						/>							
						<InputField
							fieldName='ders_esChargeEff'
							fieldLabel='Charging Efficiency'
							formValue={this.state.projectData.ders_esChargeEff}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
						<InputField
							fieldName='ders_esMinSoc'
							fieldLabel='Minimum State-of-Charge'
							formValue={this.state.projectData.ders_esMinSoc}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
						<InputField
							fieldName='ders_esMaxSoc'
							fieldLabel='Maximum State-of-Charge'
							formValue={this.state.projectData.ders_esMaxSoc}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
					</div>
					
					<div className={`np-subsection ${Helper.showHideHtml(this.state.projectData.exDers_exDg)}`}>
						<h2 className='np-subheading'>Existing Generators</h2>

						<h2 className='np-subheading-2'>List of Existing Generator Units</h2>

						<ModelAssetList
							assetList={this.state.projectData.exDers_dgList}
							assetListName={'exDers_dgList'}
							visKeys={['exDers_dgName', 'exDers_dgUnits', 'exDers_dgCap', 'exDers_dgFuel',
							 	'exDers_dgEff', 'exDers_dgBackupOnly', 'exDers_dgEnableChp']}
							keyLabels={['Name', 'Units','Capacity', 'Fuel',
								'Efficiency', 'Backup', 'CHP']}
							units = {['', '#', 'kW', '', 
								'', '', '']}
							deleteAsset = {this.deleteAsset}
						/>

						<h2 className='np-subheading-2'>Add Existing Generator</h2>

						<InputField
							fieldName='exDers_dgName'
							fieldLabel='Generator Name'
							formValue={this.state.projectData.exDers_dgName}
							onChange={this.handleChange}
							onExit={this.handleTextExit}
							unitLabel=''
						/>
						<InputField
							fieldName='exDers_dgCap'
							fieldLabel='Generator Capacity'
							formValue={this.state.projectData.exDers_dgCap}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW'
						/>
						<InputField
							fieldName='exDers_dgUnits'
							fieldLabel='Number of Units'
							formValue={this.state.projectData.exDers_dgUnits}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel=''
						/>
						<InputSelect
							itemList={fuelList}
							fieldName='exDers_dgFuel'
							fieldLabel='Fuel Type'
							formValue={this.state.projectData.exDers_dgFuel}
							onChange={this.handleChange}
						/>

						<InputField
							fieldName='exDers_dgEff'
							fieldLabel='Efficiency'
							formValue={this.state.projectData.exDers_dgEff}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
						<InputCheckbox
							fieldLabel='Back-up Only'
							fieldName='exDers_dgBackupOnly'
							formValue={this.state.projectData.exDers_dgBackupOnly}
							onChange={this.handleCheckboxChange}
						/>
						<InputCheckbox
							fieldLabel='CHP Enabled'
							fieldName='exDers_dgEnableChp'
							formValue={this.state.projectData.exDers_dgEnableChp}
							onChange={this.handleCheckboxChange}
						/>
						<InputField
							fieldName='exDers_dgHeatAlpha'
							fieldLabel='Heat Recovery Fraction'
							formValue={this.state.projectData.exDers_dgHeatAlpha}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
						<InputField
							fieldName='exDers_dgOmRate'
							fieldLabel='O&M Cost'
							formValue={this.state.projectData.exDers_dgOmRate}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$/kWh'
						/>

						<div className='np-btn-container'>
							<div 
								className='dark-btn add-asset-btn'
								onClick={()=>{this.addAsset('curExDg', 'exDers_dgList', 'exDg')}}
							>
								Save Asset
							</div>
						</div>
					</div>
				</div>
				
			</div>
		)
	}


	renderNewDersForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.newDers)}

				<div className='np-new-ders-form'>
					<h2 className='np-subheading'>Select New Resource Options</h2>
					<InputCheckbox
						fieldLabel='Enable New PV'
						fieldName='newDers_newPv'
						formValue={this.state.projectData.newDers_newPv}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Enable New Battery'
						fieldName='newDers_newEs'
						formValue={this.state.projectData.newDers_newEs}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Enable New Generators'
						fieldName='newDers_newDg'
						formValue={this.state.projectData.newDers_newDg}
						onChange={this.handleCheckboxChange}
					/>

					<div className={`np-subsection ${Helper.showHideHtml(this.state.projectData.newDers_newPv)}`}>
						<h2 className='np-subheading'>New PV Properties</h2>

						<InputField
							fieldName='newDers_pvFixedCost'
							fieldLabel='Fixed Capital Cost'
							formValue={this.state.projectData.newDers_pvFixedCost}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$'
						/>
						<InputField
							fieldName='newDers_pvVarCost'
							fieldLabel='Variable Capital Cost'
							formValue={this.state.projectData.newDers_pvVarCost}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$/kW'
						/>
						<InputField
							fieldName='newDers_pvLifetime'
							fieldLabel='Lifetime'
							formValue={this.state.projectData.newDers_pvLifetime}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='years'
						/>
						<InputField
							fieldName='newDers_pvOmCost'
							fieldLabel='O&M Cost'
							formValue={this.state.projectData.newDers_pvOmCost}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$/kW'
						/>
						<InputSelect
							itemList={pvAngleList}
							fieldName='ders_pvAngle'
							fieldLabel='Angle'
							formValue={this.state.projectData.ders_pvAngle}
							onChange={this.handleChange}
						/>
						<InputSelect
							itemList={pvOrientList}
							fieldName='ders_pvOrient'
							fieldLabel='Orientation'
							formValue={this.state.projectData.ders_pvOrient}
							onChange={this.handleChange}
						/>
						<InputCheckbox
							fieldLabel='Limit PV Capacity'
							fieldName='ders_maxPvBool'
							formValue={this.state.projectData.ders_maxPvBool}
							onChange={this.handleCheckboxChange}
						/>
						<InputField
							fieldName='ders_maxPvCap'
							fieldLabel='Max PV Capacity'
							formValue={this.state.projectData.ders_maxPvCap}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW'
						/>
					</div>

					<div className={`np-subsection ${Helper.showHideHtml(this.state.projectData.newDers_newEs)}`}>
						<h2 className='np-subheading'>New Battery</h2>
						<InputField
							fieldName='newDers_esFixedCost'
							fieldLabel='Fixed Capital Cost'
							formValue={this.state.projectData.newDers_esFixedCost}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$'
						/>
						<InputField
							fieldName='newDers_esVarCost'
							fieldLabel='Variable Capital Cost'
							formValue={this.state.projectData.newDers_esVarCost}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$/kWh'
						/>
						<InputField
							fieldName='newDers_esLifetime'
							fieldLabel='Lifetime'
							formValue={this.state.projectData.newDers_esLifetime}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='years'
						/>
						<InputField
							fieldName='newDers_esOmCost'
							fieldLabel='O&M Cost'
							formValue={this.state.projectData.newDers_esOmCost}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$/kWh'
						/>
						<InputField
							fieldName='ders_esChargeRate'
							fieldLabel='Max Charge Rate'
							formValue={this.state.projectData.ders_esChargeRate}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW/kWh'
						/>
						<InputField
							fieldName='ders_esDischargeRate'
							fieldLabel='Max Discharge Rate'
							formValue={this.state.projectData.ders_esDischargeRate}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW/kWh'
						/>
						<InputField
							fieldName='ders_esChargeEff'
							fieldLabel='Charging Efficiency'
							formValue={this.state.projectData.ders_esChargeEff}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
						<InputField
							fieldName='ders_esMinSoc'
							fieldLabel='Minimum State-of-Charge'
							formValue={this.state.projectData.ders_esMinSoc}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
						<InputField
							fieldName='ders_esMaxSoc'
							fieldLabel='Maximum State-of-Charge'
							formValue={this.state.projectData.ders_esMaxSoc}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
						<InputCheckbox
							fieldLabel='Limit Battery Capacity'
							fieldName='ders_maxEsBool'
							formValue={this.state.projectData.ders_maxEsBool}
							onChange={this.handleCheckboxChange}
						/>
						<InputField
							fieldName='ders_maxEsCap'
							fieldLabel='Max Battery Capacity'
							formValue={this.state.projectData.ders_maxEsCap}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kWh'
						/>
					</div>

					<div className={`np-subsection ${Helper.showHideHtml(this.state.projectData.newDers_newDg)}`}>
						<h2 className='np-subheading'>New Generators</h2>

						<h2 className='np-subheading-2'>List of New Generator Unit Options</h2>

						<ModelAssetList
							assetList={this.state.projectData.newDers_dgList}
							assetListName={'newDers_dgList'}
							visKeys={['newDers_dgName', 'newDers_dgCap', 'newDers_dgCapCost', 'newDers_dgLifetime',
								'newDers_dgFuel',
							 	'newDers_dgEff', 'newDers_dgBackupOnly', 'newDers_dgEnableChp']}
							keyLabels={['Name', 'Capacity', 'Cost', 'Lifetime', 'Fuel',
								'Efficiency', 'Backup', 'CHP']}
							units = {['', 'kW', '$/kW', 'years', '',
								'', '', '']}
							deleteAsset = {this.deleteAsset}
						/>

						
						<InputCheckbox
							fieldLabel='Limit Total Genset Capacity'
							fieldName='ders_maxDgBool'
							formValue={this.state.projectData.ders_maxDgBool}
							onChange={this.handleCheckboxChange}
						/>
						<InputField
							fieldName='ders_maxDgCap'
							fieldLabel='Max Genset Capacity'
							formValue={this.state.projectData.ders_maxDgCap}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW'
						/>
						<InputField
							fieldName='ders_maxIdenUnits'
							fieldLabel='Max. Identical Units'
							formValue={this.state.projectData.ders_maxIdenUnits}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='units'
						/>

						<h2 className='np-subheading-2'>Add New Generator Option</h2>

						<InputField
							fieldName='newDers_dgName'
							fieldLabel='Generator Name'
							formValue={this.state.projectData.newDers_dgName}
							onChange={this.handleChange}
							onExit={this.handleTextExit}
							unitLabel=''
						/>
						<InputField
							fieldName='newDers_dgCapCost'
							fieldLabel='Unit Capital Cost'
							formValue={this.state.projectData.newDers_dgCapCost}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$/kW'
						/>
						<InputField
							fieldName='newDers_dgCap'
							fieldLabel='Generator Capacity'
							formValue={this.state.projectData.newDers_dgCap}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='kW'
						/>
						<InputField
							fieldName='newDers_dgLifetime'
							fieldLabel='Lifetime'
							formValue={this.state.projectData.newDers_dgLifetime}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='years'
						/>
						<InputField
							fieldName='newDers_dgOmCost'
							fieldLabel='O&M Cost'
							formValue={this.state.projectData.newDers_dgOmCost}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='$/kWh'
						/>
						<InputSelect
							itemList={fuelList}
							fieldName='newDers_dgFuel'
							fieldLabel='Fuel Type'
							formValue={this.state.projectData.newDers_dgFuel}
							onChange={this.handleChange}
						/>

						<InputField
							fieldName='newDers_dgEff'
							fieldLabel='Efficiency'
							formValue={this.state.projectData.newDers_dgEff}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>
						<InputCheckbox
							fieldLabel='Back-up Only'
							fieldName='newDers_dgBackupOnly'
							formValue={this.state.projectData.newDers_dgBackupOnly}
							onChange={this.handleCheckboxChange}
						/>
						<InputCheckbox
							fieldLabel='CHP Enabled'
							fieldName='newDers_dgEnableChp'
							formValue={this.state.projectData.newDers_dgEnableChp}
							onChange={this.handleCheckboxChange}
						/>
						<InputField
							fieldName='newDers_dgHeatAlpha'
							fieldLabel='Heat Recovery Fraction'
							formValue={this.state.projectData.newDers_dgHeatAlpha}
							onChange={this.handleChange}
							onExit={this.handleExitNumeric}
							unitLabel='-'
						/>

						<div className='np-btn-container'>
							<div 
								className='dark-btn add-asset-btn'
								onClick={()=>{this.addAsset('curNewDg', 'newDers_dgList', 'newDg')}}
							>
								Save Asset
							</div>
						</div>
					</div>
				</div>
				
			</div>
		)
	}


	renderEeForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.eff)}

				<div className='np-ee-form'>

					<h2 className='np-subheading-2'>List of New Efficiency Investment Options</h2>

					<ModelAssetList
						assetList={this.state.projectData.ee_eeList}
						assetListName={'ee_eeList'}
						visKeys={['ee_name', 'ee_capCost', 'ee_lifetime',
							'ee_timeVar', 'ee_fractional']}
						keyLabels={['Name', 'Cost', 'Lifetime',
							'Time Variable', 'Proportional Impact']}
						units = {['', '$',  'years',
							'', '']}
						deleteAsset = {this.deleteAsset}
					/>

					<h2 className='np-subheading-2'>New Efficiency Investment Options</h2>

					<InputField
						fieldName='ee_name'
						fieldLabel='Name'
						formValue={this.state.projectData.ee_name}
						onChange={this.handleChange}
						onExit={this.handleTextExit}
						unitLabel=''
					/>
					<InputField
						fieldName='ee_capCost'
						fieldLabel='Capital Cost'
						formValue={this.state.projectData.ee_capCost}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$'
					/>
					<InputField
						fieldName='ee_lifetime'
						fieldLabel='Lifetime'
						formValue={this.state.projectData.ee_lifetime}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='years'
					/>
					<InputCheckbox
						fieldLabel='Time Variable Impact'
						fieldName='ee_timeVar'
						formValue={this.state.projectData.ee_timeVar}
						onChange={this.handleCheckboxChange}
					/>
					<InputCheckbox
						fieldLabel='Fractional Impact'
						fieldName='ee_fractional'
						formValue={this.state.projectData.ee_fractional}
						onChange={this.handleCheckboxChange}
					/>
					
					<InputArray
						tableName='Uniform Impact Values'
						data={this.state.projectData.ee_impactsUniform}
						dataName='ee_impactsUniform'
						labels = {enduseList}
						vertical={true}
						handleChange={this.handleArrayChange}
						onExit={this.handleArrayExitNumeric}
						hideComponent={this.state.projectData.ee_timeVar}
					/>

					<InputTable 
						tableName='Variable Impact Values'
						data={this.state.projectData.ee_impactsVariable}
						dataName='ee_impactsVariable'
						colLabels = {enduseList}
						rowLabels = {hourList}
						handleChange={this.handleTableChange}
						onExit={this.handleTableExitNumeric}
						hideComponent={!this.state.projectData.ee_timeVar}
					/>

					<div className='np-btn-container'>
						<div 
							className='dark-btn add-asset-btn'
							onClick={()=>{this.addAsset('curEe', 'ee_eeList', 'EEMeasure')}}
						>
							Save Asset
						</div>
					</div>
				</div>
				
			</div>
		)
	}


	renderOutageForm() {
		return(
			<div className='np-section'>
				{this.renderHelpToggle(HelpText.res)}

				<div className='np-outage-form'>

					<InputArray
						tableName='Grid Outage Events'
						data={this.state.projectData.res_outageDays}
						dataName='res_outageDays'
						labels = {monthList}
						vertical={true}
						handleChange={this.handleArrayChange}
						onExit={this.handleArrayExitNumeric}
					/>
					<InputArray
						tableName='Critical Load Level Scenarios'
						data={this.state.projectData.res_critLoads}
						dataName='res_critLoads'
						labels = {scenList}
						vertical={true}
						handleChange={this.handleArrayChange}
						onExit={this.handleArrayExitNumeric}
					/>

					<h2 className='np-subheading-3'>Value of Lost Load (VoLL)</h2>
					<InputCheckbox
						fieldLabel='Allow Critical Load Shed'
						fieldName='res_allowCritShed'
						formValue={this.state.projectData.res_allowCritShed}
						onChange={this.handleCheckboxChange}
					/>
					<InputField
						fieldName='res_voll'
						fieldLabel='Non-Critical'
						formValue={this.state.projectData.res_voll}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/kWh'
					/>
					<InputField
						fieldName='res_vollCrit'
						fieldLabel='Critical'
						formValue={this.state.projectData.res_vollCrit}
						onChange={this.handleChange}
						onExit={this.handleExitNumeric}
						unitLabel='$/kWh'
					/>
				</div>

			</div>
		)
	}

	renderReviewForm() {

		// perform check of project data
		var projectCheck = ProjectUtils.checkRdtProject(this.state.projectData)

		// generate review section
		var reviewSection = (
			<div className='np-text-sec'>
				<h2 className='np-subheading'>Automated data checking</h2>

				<div className='np-check-box'>

				{
					projectCheck.map((checkItem)=>{

						if(checkItem.status=='valid'){
							var checkChar = '✓'
						} else {
							var checkChar = '!'
						}

						let statusIcon = <span className={`np-check-${checkItem.status}`}> [{checkChar}] </span>;

						return (
							<p className='np-check-msg'>
								>>
								{statusIcon}
								{checkItem.msg}
							</p>
						)
					})
				}

				</div>

	        </div>	
		)

		console.log(projectCheck)

		return(
			<div className='np-section'>
				<div >

					<h2 className='np-heading'>Project building complete</h2>

					<div className='dark-btn np-submit-btn' onClick={this.submitProject}>
		        		<h2>Submit Project</h2>
		        	</div>

		        	{reviewSection}

		        	<div className='np-text-sec'>
						<h2 className='np-subheading'>Reviewing this project model</h2>
			        	<p className='np-text'>
			        		You can review and modify
			        		your project inputs at this time 
			        		by navigating previous input pages. If your new project is complete,
			        		you may submit it to the DER-CAM Optimizer by pressing the submit button 
			        		below.
			        	</p>
			        </div>			        	

			        <div className='np-text-sec'>
			        	<h2 className='np-subheading'>Checking your model status</h2>
			        	<p className='np-text'>
			        		You can check the status of submitted projects at any time on your
			        		projects page. Solve time may vary depending on the complexity of your
			        		project, and the volume of models currently being solved by ther DER-CAM server.
			        		<br/><br/>
			        		If your model is queued for 24 hours or more, and you think something
				        	has gone wrong, please contact support at <span className='np-text-highlight'>dercam@lbl.gov</span>.
			        	</p>
			        </div>

			        <div className='np-text-sec'>
			        	<h2 className='np-subheading'>Viewing model results</h2>
			        	<p className='np-text'>
			        		Once solved, you can explore and download model results by examining the project on
			        		your projects page. Model results can be explored through this app and downloaded to
			        		your own computer for additional exploration.
			        	</p>
			        </div>

			        <div className='np-text-sec'>
			        	<h2 className='np-subheading'>Problems with your model</h2>
			        	<p className='np-text'>
			        		Your projects page will also show the  status of models that failed to solve due to input errors, or models where a
			        		solution could not be found. If you have questions about failed models,
			        		please contact us at <span className='np-text-highlight'>dercam@lbl.gov</span>
			        	</p>
			        </div>

		        	<div className='dark-btn np-submit-btn' onClick={this.submitProject}>
		        		<h2>Submit Project</h2>
		        	</div>

				</div>
			</div>
		)
	}


	renderIntroPage() {
		return(
			<div className='np-section'>
				<div >

					<h2 className='np-heading'>Getting Started with RDT</h2>

					<div className='np-text-sec'>	
						<h2 className='np-subheading'>How Do I Use the Project Creator?</h2>
			        	<p className='np-text'>
			        		The RDT app provides a series of input pages to define your specific project.
			        		Use the arrows in the top-right to navigate forward and back through these pages. Once
			        		your project is defined to your needs, submit your project data using the submission 
			        		button on the final page.
			        		<br/><br/>
			        		Click the <span className='np-text-highlight'>More Info</span> button on each page to learn more about each input category.
			        	</p>
			        </div>


			        <div className='np-text-sec'>
						<h2 className='np-subheading'>What Data Do I Need?</h2>
			        	<p className='np-text'>
			        		When building your first project, the following data items will be helpful
			        		to ensure a useful and accurate model:
			        	</p>
			        	<p className='np-text-subsec-head'>Important items:</p>
			        	<ul className='np-text-ul'>
			        		<li>building type & location</li>
			        		<li>electricity tariff & rates</li>
			        		<li>current fuel prices (e.g. natural gas or diesel)</li>
			        		<li>electricity export rules & rates</li>
			        		<li>info on any existing DERs (e.g. diesel back-up)</li>
			        		<li>outage scenarios you'd like to model</li>
			        	</ul>

			        	<p className='np-text-subsec-head'>Optional items:</p>
			        	<ul className='np-text-ul'>
			        		<li>specific prices and efficiencies for DERs (defaults are provided)</li>
			        		<li>details for fees related to electricity export</li>
			        		<li>costs and impacts for potential energy efficiency retrofits</li>
			        		<li>values of lost load for critical and non-critical loads</li>
			        	</ul>

			        	<p className='np-text'>
			        		We do our best to provide users with good estimates for default data wherever possible. 
			        		When in doubt, the default settings should provide a reasonable starting point. You can 
			        		almost always come back to a previous model to update or modify input data.
			        	</p>
			        </div>			        

			        <div className='np-text-sec'>
			        	<h2 className='np-subheading'>Is My Model Saved?</h2>
			        	<p className='np-text'>
			        		The RDT app will store your current unsubmitted project while you are using the site. If you
			        		refresh the page, or navigate away from the site, any unsubmitted project changes will be lost.
			        		<br/><br/>
			        		You can retrieve any submitted project data as a copy, to change to rerun your model. If you need
			        		to save your model, just press submit and you can return to a copy of it at a later date.
			        	</p>
			        </div>

			        <div className='np-text-sec'>
			        	<h2 className='np-subheading'>How Long Does It Take?</h2>
			        	<p className='np-text'>
			        		Submitting your model and wondering when your results will be ready? When our optimization server is
			        		online, most model results should be available within a few minutes. 
			        		<br/><br/>
			        		However, the overall time needed
			        		to solve the model depends on its complexity, and the number of other jobs in the queue. It could take 
			        		up to several hours for your model to be solved, so please be patient. All models, solved or otherwise
			        		will be shown on your projects page. 
			        		<br/><br/>
			        		If your model is queued for 24 hours or more, and you think something
			        		has gone wrong, please contact support at <span className='np-text-highlight'>dercam@lbl.gov</span>.
			        	</p>
			        </div>

			        <div className='np-text-sec'>
			        	<h2 className='np-subheading'>Having Trouble?</h2>
			        	<p className='np-text'>
			        		If you run into any other issues building or submitting your project, please contact us at <span className='np-text-highlight'>dercam@lbl.gov</span>.
			        	</p>
			        </div>
				</div>
			</div>
		)
	}


	renderFormPage() {

		let renderFuncList = [
			this.renderIntroPage,
			this.renderProjectForm,
			this.renderLocationForm,
			this.renderLoadsForm,
			this.renderTariffBuilderForm,
			this.renderTariffForm,
			this.renderExportForm,
			this.renderFuelsForm,
			this.renderExDersForm,
			this.renderNewDersForm,
			this.renderEeForm,
			this.renderOutageForm,
			this.renderReviewForm
		];

		let curRenderFunc = renderFuncList[this.state.pageIndex];
		return curRenderFunc()

	}



  	render(){ 

  		// if submission pending
  		if(this.state.submitPending){
  			return(
  				<Modal
	  				icon={<LoadIcon />}
					label={<>Model Uploading to Server</>}
					rotating={true}
	  			>

	  			</Modal>
	  		)
  		}


  		// if submission failed
  		if(this.state.submitFailure){
  			return(
	  			<Modal
	  				icon={<ErrorIcon />}
					label={<>Model Upload Failed</>}
					rotating={false}
					modalText={'An error occurred when sending the model to the server. Please return the project editor and retry after a few minutes.'}
	  			>
	  				<div className='modal-icon-btn dark-btn' onClick={this.retryProject}>
						<h2 className='modal-btn-text'> Return </h2>
					</div>

	  			</Modal>
	  		)
  		}


  		// if submission succeeded
  		if(this.state.submitSuccess){
  			return(
	  			<Modal
	  				icon={<SuccessIcon />}
					label={<>Model Upload Successful</>}
					rotating={false}
					modalText={'Your model has been uploaded to the server. Please see the My Projects page to check model status and results when they are available.'}
	  			>
	  				<div className='modal-btn dark-btn' onClick={this.goToProjects}>
						<h2 className='modal-btn-text'> My Projects </h2>
					</div>

	  			</Modal>
	  		)
  		}


  		// else, display project builder
	  	if(!this.state.submitPending) {
		    return( 
		    	<>
		    	<NavbarProject
					projectName={this.state.projectData.gen_name}
					prevPage={()=>{this.changePage(-1)}}
					nextPage={()=>{this.changePage(1)}}
					exitProject={()=>{console.log('trying to quit project')}}
					pageName={this.state.pageList[this.state.pageIndex]}
					firstPage={(this.state.pageIndex===0)}
					lastPage={(this.state.pageIndex===this.state.pageIndexMax)}
					fieldName='gen_name'
					updateName={this.handleChange}
				/>
		    	<div className="page-scroll-container-new-project">
		    		
					<div className='np-container'>
						{this.renderFormPage()}						
					</div>			
				</div>
				</>

		    )
	    }

	}

} 


export default withRouter(NewProject);