import React from 'react';

import Helper from '../../helper.js';

const tableList = [];

class ProjectPageSubmit extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			tablesChecked: false,
			alteredTables: [],
			warningMsgs: []
		}

		this.checkTables = this.checkTables.bind(this); 
		this.renderAltTableList = this.renderAltTableList.bind(this); 
		this.renderWarningList = this.renderWarningList.bind(this);
		this.checkMultiNode = this.checkMultiNode.bind(this);
	}


	async checkTables() {
		// returns a list of data tables that have been modified from their default starting values

		// get table data from getAlteredTables method
		var alteredTables = this.props.getAlteredTables(this.props.projData);
		// reduce to keys
		alteredTables = Object.keys(alteredTables)
		// use list of keys to get table data (in proper order)
		alteredTables = Helper.filterObjListWithList(this.props.tableList, 'key', alteredTables)

		// console.log(alteredTables)

		await this.setState({alteredTables: alteredTables, tablesChecked: true})

		// check multi-node consistency
		await this.checkMultiNode()
	
	}

	checkMultiNode() {
		// method checks matches between props.multiNode and props.nNodes with values in props.projData

		const warningMsgs = []

		const projData = this.props.projData;

		const multiNodeTable = Helper.getTableObj(projData.powerflowoptions, 'Enable');

		const nNodesTable = Helper.getTableObj(projData.powerflowparams, 'NoOfNodes');

		if (multiNodeTable != this.props.multiNode){
			warningMsgs.push('Multi-node option in Table "Power Flow Options" does not match selection on Configuration page. \
				Please check your selections. If values do not match, option provided in Table will be used.')
		}

		if (nNodesTable != this.props.nNodes){
			warningMsgs.push('Number of nodes in Table "Power Flow Parameters" does not match selection on Configuration page. \
				Please check your selections. If values do not match, option provided in Table will be used.')
		}

		console.log(warningMsgs)

		this.setState({warningMsgs:warningMsgs})

		
	}

	renderAltTableList() {

		// if no check has been run, return nothing
		if(!this.state.tablesChecked) {
			return <br/>
		}

		// if no tables altered 
		if(this.state.alteredTables.length==0) {
			return(
				<div className='npdc-submit-alt-tables'>
					<h2 className="npdc-subheader">Updated Tables</h2>
					<p className='npdc-submit-alt-tables-text'>
						No changes have been found in your current model
					</p>
				</div>
			)
		}

		var tableList = this.state.alteredTables.map((tableItem) => (
			<li className='npdc-submit-alt-tables-item'>
				{tableItem.label}
			</li>
		))

		// otherwise, generate list of altered tables
		return (
			<div className='npdc-submit-alt-tables'>
				<h2 className="npdc-subheader">Updated Tables</h2>
				<p className='npdc-submit-alt-tables-text'>
					Updates have been found in the following data tables:
				</p>
				<ul className='npdc-submit-alt-tables-list'>
					{tableList}
				</ul>
				<p className='npdc-submit-alt-tables-text'>
					Only the data in these tables will be transmitted to the server
					upon submission. All other data will remain as their default values.
					<br/><br/>
					You may continue to update data tables prior to submitting your model.
				</p>
			</div>
		)
	}

	renderWarningList() {

		// if no check has been run, return nothing
		if(!this.state.tablesChecked) {
			return null
		}

		// if no tables altered 
		if(this.state.warningMsgs.length==0) {
			return(
				<div className='npdc-submit-alt-tables'>
					<h2 className="npdc-subheader">Model Warnings</h2>
					<p className='npdc-submit-alt-tables-text'>
						No warnings have been found during model check.
					</p>
				</div>
			)
		}

		var warningList = this.state.warningMsgs.map((warnItem) => (
			<p className='npdc-submit-alt-tables-text'>
				{warnItem}
			</p>
		))

		var warningSummary = `${this.state.warningMsgs.length} warnings have been found during model check.`

		// otherwise, generate list of altered tables
		return (
			<div className='npdc-submit-alt-tables'>
				<h2 className="npdc-subheader">Model Warnings</h2>
				{warningSummary}
				{warningList}
			</div>
		)
	}

	render() {

		return(
			<div className='page-scroll-container-new-project project-main-container'>

				<div className='npdc-container'>

					<div className='npdc-panel'>
						<div className='npdc-section'>

		    				<h1 className='npdc-header'> Project Model Submission </h1>

		    				<p className='npdc-text'>
		    					When you have completed your project model construction, you may submit the project model to the DER-CAM server
		    					by using the button below. The project will be added to the DER-CAM optimization queue. You can check
		    					the status of this and other projects at you My Projects page. Once optimized, model results can also be
		    					access from the My Projects page.
		    					<br/><br/>
		    					If you wish to continue modifying your project before submission, you may return to 
		    					previous editor pages using the navigation bar at the top of the page.
		    				</p>

		    				<div className='text-button npdc-button' onClick={this.props.submitProject}><h2>Submit Model</h2></div>

		    				<p className='npdc-text'>
		    					You may check which DER-CAM input tables have been modified from their default values by clicking the button
		    					below. If the results of this check do not match your expectations, please check the data entered in 
		    					the corresponding tables in the Tables page of the project editor before submitting.
		    				</p>

		    				<div className='text-button npdc-button' onClick={this.checkTables}><h2>Check Tables</h2></div>

		    				{this.renderAltTableList()}

		    				{this.renderWarningList()}
		    				
		    			</div>
	    			</div>

	    		</div>



	    	</div>
		)

		
	}
}



export default ProjectPageSubmit;