import Helper from '../../helper';

const testData01 = {
			new_bs: {
				ex_pv: 10,
				ex_es: 8,
				ex_dg: 50,
				new_pv: 80,
				new_es: 0,
				new_dg: 0,
			},
			new_o1: {
				ex_pv: 10,
				ex_es: 8,
				ex_dg: 50,
				new_pv: 80,
				new_es: 25,
				new_dg: 30,
			},
			new_o2: {
				ex_pv: 10,
				ex_es: 8,
				ex_dg: 50,
				new_pv: 110,
				new_es: 40,
				new_dg: 40,
			},
			new_o3: {
				ex_pv: 10,
				ex_es: 8,
				ex_dg: 50,
				new_pv: 130,
				new_es: 60,
				new_dg: 55,
			}
		}

const testData02 = {
			new_bs: {
				ex_pv: 101,
				ex_es: 82,
				ex_dg: 504,
				new_pv: 803,
				new_es: 34,
				new_dg: 34,
			},
			new_o1: {
				ex_pv: 130,
				ex_es: 83,
				ex_dg: 530,
				new_pv: 830,
				new_es: 235,
				new_dg: 330,
			},
			new_o2: {
				ex_pv: 140,
				ex_es: 84,
				ex_dg: 560,
				new_pv: 110,
				new_es: 470,
				new_dg: 470,
			},
			new_o3: {
				ex_pv: 170,
				ex_es: 88,
				ex_dg: 580,
				new_pv: 1830,
				new_es: 680,
				new_dg: 585,
			}
		}

const testData03 = {
			new_bs: {
				ex_pv: 1000,
				ex_es: 800,
				ex_dg: 5000,
				new_pv: 8000,
				new_es: 0,
				new_dg: 0,
			},
			new_o1: {
				ex_pv: 1000,
				ex_es: 800,
				ex_dg: 5000,
				new_pv: 8000,
				new_es: 2500,
				new_dg: 3000,
			},
			new_o2: {
				ex_pv: 1000,
				ex_es: 800,
				ex_dg: 5000,
				new_pv: 110,
				new_es: 4000,
				new_dg: 4000,
			},
			new_o3: {
				ex_pv: 1000,
				ex_es: 800,
				ex_dg: 5000,
				new_pv: 13000,
				new_es: 6000,
				new_dg: 5005,
			}
		}

const testDataInv = {
	cap: {
		new_bs: {
			ex_pv: 10,
			ex_es: 8,
			ex_dg: 50,
			new_pv: 80,
			new_es: 0,
			new_dg: 0,
		},
		new_o1: {
			ex_pv: 10,
			ex_es: 8,
			ex_dg: 50,
			new_pv: 80,
			new_es: 25,
			new_dg: 30,
		},
		new_o2: {
			ex_pv: 10,
			ex_es: 8,
			ex_dg: 50,
			new_pv: 110,
			new_es: 40,
			new_dg: 40,
		},
		new_o3: {
			ex_pv: 10,
			ex_es: 8,
			ex_dg: 50,
			new_pv: 130,
			new_es: 60,
			new_dg: 55,
		}
	},
	annEx: {
		new_bs: {
			new_pv: 803,
			new_es: 34,
			new_dg: 34,
			new_ee: 1010
		},
		new_o1: {
			new_pv: 830,
			new_es: 235,
			new_dg: 330,
			new_ee: 1010
		},
		new_o2: {
			new_pv: 110,
			new_es: 470,
			new_dg: 470,
			new_ee: 1010
		},
		new_o3: {
			new_pv: 1830,
			new_es: 680,
			new_dg: 585,
			new_ee: 1010
		}
	},

	capEx: {
		new_bs: {
			ex_dg: 5000,
			new_pv: 8000,
			new_es: 0,
			new_dg: 0,
			new_ee: 8000
		},
		new_o1: {
			new_pv: 8000,
			new_es: 2500,
			new_dg: 3000,
			new_ee: 8000
		},
		new_o2: {
			new_pv: 110,
			new_es: 4000,
			new_dg: 4000,
			new_ee: 8000
		},
		new_o3: {
			new_pv: 13000,
			new_es: 6000,
			new_dg: 5005,
			new_ee: 8000
		}
	}
};

const annualMetrics = {
	fin: {
		totalCost: {
			ex_bs: 123456,
			ex_o1: 140000,
			ex_o2: 150000,
			ex_o3: 160000,
			new_bs: 113456,
			new_o1: 125678,
			new_02: 135678,
			new_03: 145678,
		},
		elecEnergyCost: {
			ex_bs: 11234,
			ex_o1: 21400,
			ex_o2: 11500,
			ex_o3: 21600,
			new_bs: 11134,
			new_o1: 21256,
			new_02: 11356,
			new_03: 21456,
		},
		elecPowerCost: {
			ex_bs: Helper.randBetween(5000, 6000),
			ex_o1: Helper.randBetween(5000, 6000),
			ex_o2: Helper.randBetween(5000, 6000),
			ex_o3: Helper.randBetween(5000, 6000),
			new_bs: Helper.randBetween(5000, 6000),
			new_o1: Helper.randBetween(5000, 6000),
			new_02: Helper.randBetween(5000, 6000),
			new_03: Helper.randBetween(5000, 6000),
		}
	},
	energy: {
		elecImport: {
			ex_bs: Helper.randBetween(5000, 6000),
			ex_o1: Helper.randBetween(5000, 6000),
			ex_o2: Helper.randBetween(5000, 6000),
			ex_o3: Helper.randBetween(5000, 6000),
			new_bs: Helper.randBetween(5000, 6000),
			new_o1: Helper.randBetween(5000, 6000),
			new_02: Helper.randBetween(5000, 6000),
			new_03: Helper.randBetween(5000, 6000),
		},
		loadElec: {
			ex_bs: Helper.randBetween(500, 600),
			ex_o1: Helper.randBetween(500, 600),
			ex_o2: Helper.randBetween(500, 600),
			ex_o3: Helper.randBetween(500, 600),
			new_bs: Helper.randBetween(500, 600),
			new_o1: Helper.randBetween(500, 600),
			new_02: Helper.randBetween(500, 600),
			new_03: Helper.randBetween(500, 600),
		},
		loadHeat: {
			ex_bs: Helper.randBetween(100, 200),
			ex_o1: Helper.randBetween(100, 200),
			ex_o2: Helper.randBetween(100, 200),
			ex_o3: Helper.randBetween(100, 200),
			new_bs: Helper.randBetween(100, 200),
			new_o1: Helper.randBetween(100, 200),
			new_02: Helper.randBetween(100, 200),
			new_03: Helper.randBetween(100, 200),
		}

	},
	power: {
		maxImport: {
			ex_bs: Helper.randBetween(500, 600),
			ex_o1: Helper.randBetween(500, 600),
			ex_o2: Helper.randBetween(500, 600),
			ex_o3: Helper.randBetween(500, 600),
			new_bs: Helper.randBetween(500, 600),
			new_o1: Helper.randBetween(500, 600),
			new_02: Helper.randBetween(500, 600),
			new_03: Helper.randBetween(500, 600),
		},
		maxExport: {
			ex_bs: Helper.randBetween(100, 200),
			ex_o1: Helper.randBetween(100, 200),
			ex_o2: Helper.randBetween(100, 200),
			ex_o3: Helper.randBetween(100, 200),
			new_bs: Helper.randBetween(100, 200),
			new_o1: Helper.randBetween(100, 200),
			new_02: Helper.randBetween(100, 200),
			new_03: Helper.randBetween(100, 200),
		}
	}
}

const annMetric01 = [
      {
        name: 'Blue Sky',
        'Existing Assets': 123456,
        'New Investments': 103000
      },
      {
        name: '25% Critical Load',
        'Existing Assets': 133000,
        'New Investments': 123000
      },
      {
        name: '50% Critical Load',
        'Existing Assets': 155555,
        'New Investments': 140000
      },
       {
        name: '100% Critical Load',
        'Existing Assets': 177777,
        'New Investments': 166000
      },
    ]

const monthlyData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const tsData00 = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const tsData = [
  {
    name: '1',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '2',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '3',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: '4',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: '5',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '6',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '7',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: '8',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '9',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '10',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: '11',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: '12',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '13',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '14',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: '15',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '16',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '17',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: '18',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: '19',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '20',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '21',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: '22',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '23',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '24',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  }
];

export { testData01, testData02, testData03, testDataInv, annualMetrics, annMetric01, monthlyData, tsData }