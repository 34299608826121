import React from 'react';

import InputField from '../Inputs/InputField';
import InputSelect from '../Inputs/InputSelect';
import InputCheckbox from '../Inputs/InputCheckbox';

import Helper from '../../helper';

// const _ = require('lodash');

// projName={this.state.projName}
// nNodes={this.state.nNodes}
// nodeListAll={this.state.nodeListAll}
// nodeList={this.state.nodeList}

// // input binaries
// optDerSolar={this.state.optDerSolar}
// optDerStorage={this.state.optDerStorage}
// optDerGensets={this.state.optDerGensets}
// optDerLFGensets={this.state.optDerLFGensets}
// optDerCentralTech={this.state.optDerCentralTech}
// optDerEnergyConv={this.state.optDerEnergyConv}
// optDerEvs={this.state.optDerEvs}
// optRes={this.state.optRes}
// optDmdMgmt={this.state.optDmdMgmt}
// optAS={this.state.optAS}
// optThermalLoop={this.state.optThermalLoop}
// optEE={this.state.optEE}

class ProjectPageConfig extends React.Component {



	render() {

		return(
			<div className='page-scroll-container-new-project project-main-container'>

				<div className='npdc-container'>

					<h1 className='npdc-header'> New Model Configuration </h1>
	    			
	    			<div className='npdc-panel'>
			
						<div className='npdc-section'>

							<h2 className='npdc-subheader'>General Project Info</h2>

							<table className='npdc-grid-table'>

					
								<tr>
									<td className='npdc-input-label'>
										Project Name
									</td>
									<td>
										<InputField
											fieldName='projName'
											fieldLabel=''
											formValue={this.props.projName}
											onChange={this.props.handleChange}
											onExit={this.props.handleExit}
											type='text50'
										/>
									</td>
									<td className='npdc-unit-label'>
										
									</td>
								</tr>

								<tr>
									<td className='npdc-input-label'>
										Enable Multi-Node Model
									</td>
									<td>
										<InputCheckbox
											fieldLabel=''
											fieldName='multiNode'
											formValue={this.props.multiNode}
											onChange={this.props.handleChangeNodesNum}
											type='bin'
										/>
									</td>
									
								</tr>

								<tr className={Helper.showHideHtml(this.props.multiNode)}>
									<td className='npdc-input-label'>
										Number of Nodes
									</td>
									<td>
										<InputSelect
											// props needed: itemList, fieldName, formValue, onChange
											itemList={this.props.nodeListAll}
											fieldName='nNodes'
											fieldLabel={null}
											formValue={this.props.nNodes}
											onChange={this.props.handleChangeNodesNum}
											inTable={true}
											data-type='int'
										/>
									</td>
									
								</tr>

							</table>

							<br/><br/>

							<h2 className='npdc-subheader'>Project Options</h2>


							<table className='npdc-grid-table'>

							<tbody className='npdc-grid-subsection'>

								<tr>
									<td colSpan="2">
										<div className='npdc-grid-header'>
											DER Technology Selection
										</div>
									</td>
								</tr>
					
								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable Solar
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optDerSolar'
												formValue={this.props.optDerSolar}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable Energy Storage
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optDerStorage'
												formValue={this.props.optDerStorage}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable Energy Conversion
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optDerEnergyConv'
												formValue={this.props.optDerEnergyConv}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable Gensets
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optDerGensets'
												formValue={this.props.optDerGensets}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable Wind & Hydro
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optDerWindHydro'
												formValue={this.props.optDerWindHydro}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable Central HVAC Techs
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optDerCentralTech'
												formValue={this.props.optDerCentralTech}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable EVs (beta)
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optDerEvs'
												formValue={this.props.optDerEvs}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								</tbody>

								<tr>
									<td><br/></td>
								</tr>

								<tbody className='npdc-grid-subsection'>


								<tr>
									<td colSpan="2">
										<div className='npdc-grid-header'>
											Resilience & Reliability
										</div>
									</td>
								</tr>
					
								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable Resilence Options
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optRes'
												formValue={this.props.optRes}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								</tbody>

								<tr>
									<td><br/></td>
								</tr>

								<tbody className='npdc-grid-subsection'>


								<tr>
									<td colSpan="2">
										<div className='npdc-grid-header'>
											Energy Efficiency Investments
										</div>
									</td>
								</tr>

								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable EE Options
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optEE'
												formValue={this.props.optEE}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								</tbody>

								<tr>
									<td><br/></td>
								</tr>

								<tbody className='npdc-grid-subsection'>


								<tr>
									<td colSpan="2">
										<div className='npdc-grid-header'>
											Demand-Side Management
										</div>
									</td>
								</tr>
					
								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable DSM Options
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optDmdMgmt'
												formValue={this.props.optDmdMgmt}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								</tbody>

								<tr>
									<td><br/></td>
								</tr>

								<tbody className='npdc-grid-subsection'>


								<tr>
									<td colSpan="2">
										<div className='npdc-grid-header'>
											Ancillary Services
										</div>
									</td>
								</tr>
					
								<tr>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable AS Options
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optAS'
												formValue={this.props.optAS}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								</tbody>

								<tr className={Helper.showHideHtml(this.props.multiNode)}>
									<td><br/></td>
								</tr>					
								

								<tbody className={`npdc-grid-subsection ${Helper.showHideHtml(this.props.multiNode)}`}>

								<tr className={Helper.showHideHtml(this.props.multiNode)}>
									<td colSpan="2">
										<div className='npdc-grid-header'>
											District Heating
										</div>
									</td>
								</tr>
					
								<tr className={Helper.showHideHtml(this.props.multiNode)}>
									<td className='npdc-input-label'>
										<div className='npdc-grid-label'>
											Enable Thermal Network
										</div>
									</td>
									<td>
										<div className='npdc-grid-data'>
											<InputCheckbox
												fieldLabel=''
												fieldName='optThermalLoop'
												formValue={this.props.optThermalLoop}
												onChange={this.props.handleChange}
												type='bin'
											/>
										</div>
									</td>
									
								</tr>

								</tbody>

							</table>

						</div>

					</div>	
				
				</div>

	    	</div>
		)
		
	}
	    
}



export default ProjectPageConfig;