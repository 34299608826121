import React from 'react';
import { BrowserRouter,  Route, Switch } from 'react-router-dom';

import './App.css';
import Navbar from '../Navbar/Navbar'
import Home from '../Home/Home';
import Status from '../Status/Status';
// import UserRegister from '../UserRegister/UserRegister';
// import Activate from '../Activate/Activate';
import Login from '../UserAccount/UserLogin';

import RouterApps from '../RouterApps/RouterApps';
// import HomeUser from '../HomeUser/HomeUser';
// import HomeRdt from '../HomeRdt/HomeRdt';
// import HomeApi from '../HomeApi/HomeApi';
// import HomeDercam from '../HomeDercam/HomeDercam';

import AccountRouter from '../UserAccount/AccountRouter';


// import Helper from '../../helper';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serverOnline:false,
      serverLatest: {
        upload:null,
        solve:null
      },

      auth: false,
      authUser: {},
      authFetch: true,
      authA: false,

      projTypeList: ['rdtProj', 'dercamProj', 'networkModel'],
      rdtProj: null,
      dercamProj: null,
      networkModel: null

    }

    this.checkStatus = this.checkStatus.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
    this.userLogout = this.userLogout.bind(this);
    this.saveProject = this.saveProject.bind(this);
    this.clearSavedProject = this.clearSavedProject.bind(this);

  }


  async checkStatus() { 
    // function to check if API server is running
    // and retrieve lastest task timestamps
    // console.log('checking server status')
    let urlStatus = `${process.env.REACT_APP_URL_BASE}/queue/status`;
    try{
      let response = await fetch(urlStatus);

      // if status GET request successful
      if(response.status===200) {
        let result = await response.json();
        this.setState({
          serverOnline:true,
          serverLatest: {
            upload:result.serverStatus.latestUpload,
            solve:result.serverStatus.latestSolve
          }
        })
      } else {
        this.setState({
          serverOnline:false,
          serverLatest: {
            upload:null,
            solve:null
          }
        })
      }
    } catch (e) {
      console.log(e)
      this.setState({
        serverOnline:false,
        serverLatest: {
          upload:null,
          solve:null
        }
      })
    }
  }


  async checkAuth() { 
    // function to check if API server is running
    // and retrieve lastest task timestamps
    // console.log('checking server status')
    let urlStatus = `${process.env.REACT_APP_URL_BASE}/auth`;
    try{
      let response = await fetch(urlStatus, {credentials: 'include'});

      await this.setState({authFetch: true})

      // if status GET request successful
      if(response.status===200) {
        let result = await response.json();
        
        // console.log(`Auth status: ${result.auth}`)
        // console.log(result.user)
        this.setState({
          auth: result.auth,
          authUser: result.user,
          authFetch: false
        })
        
      } else {
        this.setState({
          auth: false,
          authUser: {},
          authFetch: false
        })
      }
    }
    catch (e) {
      console.log(e)
      this.setState({
        auth: false,
        authUser: {},
        authFetch: false
      })
    }
  }

  async userLogout() { 
    // 
    let urlStatus = `${process.env.REACT_APP_URL_BASE}/auth/logout`;
    let response = await fetch(urlStatus, {credentials: 'include'});

    // 
    this.setState({
      auth: false,
      authUser: {}
    })

    // redirect to home page after logout
    window.location.href='/';
  }


  async componentDidMount() {
    this.checkStatus();
    await this.checkAuth();
  }

  saveProject(projType, projData) {
    // method saves project data to state value proj name
    // if proj name is is state var with list of proj types

    if(this.state.projTypeList.includes(projType)){
      this.setState({[projType]:projData})
    } else {
      console.log(`cannot save to project type: ${projType}`)
    }
    
  }

  clearSavedProject(projType) {
    // method clears saved project from state value projName

    if(this.state.projTypeList.includes(projType)){
      console.log(`clearing data for ${projType}`)
      this.setState({[projType]:null})
    } else {
      console.log(`cannot clear data from project type: ${projType}`)
    }

  }

              //   <Route path="/register">
              //   <UserRegister 
              //     handleChange = {this.handleChange}
              //     handleSubmit = {this.handleSubmit}
              //     handleResendSubmit = {this.handleResendSubmit}
              //     switchForm = {this.switchForm}
              //     form = {this.state.form}
              //     errors = {this.state.errors}
              //   />
              // </Route>

  render() {

    return (
      <div className='App theme-dark'>
          
          <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <Home
                  auth={this.state.auth}
                  userLogout = {this.userLogout}
                />
              </Route>

              <Route path="/acct">
                <AccountRouter
                />
              </Route>
              <Route path="/login">
                <Login 
                  handleChange = {this.handleChange}
                  handleSubmit = {this.handleSubmit}
                  checkAuth = {this.checkAuth}
                  auth = {this.state.auth}
                  handleResendSubmit = {this.handleResendSubmit}
                  switchForm = {this.switchForm}
                  form = {this.state.form}
                  errors = {this.state.errors}
                />
              </Route>
              <Route path="/u">
                <div className="page-container">
                  <Navbar 
                    auth = {this.state.auth}
                    userLogout = {this.userLogout}
                  />
                  <RouterApps
                    checkAuth = {this.checkAuth}
                    authFetch = {this.state.authFetch}
                    auth = {this.state.auth}
                    authUser = {this.state.authUser}
                    userLogout = {this.userLogout}
                    saveProject = {this.saveProject}
                    clearSavedProject = {this.clearSavedProject}
                    rdtProj = {this.state.rdtProj}
                    dercamProj = {this.state.dercamProj}
                    networkModel = {this.state.networkModel}
                  />
                </div>
              </Route>
            </Switch>
          </BrowserRouter>
          <Status 
            serverOnline = {this.state.serverOnline}
            serverLatest = {this.state.serverLatest}
          />
      </div>
    )
  }
}

export default App;
