import React from 'react';

import './Admin.css';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';


class PendingUserCard extends React.Component {

	// renders a card with user data and approval btn

	render() {

		// unpack userdata from props
		const user = this.props.user

		// if no user provided in props, return nothing
		if(!user){
			return null;
		}

		return (
			<div className='user-card'>
				<div className='user-card-data-section'>
					<table className='user-data-table'>
						<tr className='user-data-table-row'>
						    <td><div className='user-data-label'>Name</div></td> 
						    <td><div className='user-data-val'>{user.firstname} {user.lastname}</div></td>
						</tr>
						<tr className='user-data-table-row'>
						    <td><div className='user-data-label'>Email</div></td> 
						    <td><div className='user-data-val'>{user.email}</div></td>
						</tr>
						<tr className='user-data-table-row'>
						    <td><div className='user-data-label'>Country</div></td> 
						    <td><div className='user-data-val'>{user.country}</div></td>
						</tr>
						<tr className='user-data-table-row'>
						    <td><div className='user-data-label'>Industry</div></td> 
						    <td><div className='user-data-val'>{user.industry}</div></td>
						</tr>
					</table>
				</div>
				<div className='user-card-data-section'>
					<table className='user-data-table'>
						<tr className='user-data-table-row'>
						    <td><div className='user-text-val'>{user.user_text}</div></td>
						</tr>
					</table>
				</div>
				<div className='user-card-btn-section'>
					<div className='user-card-btn text-button'  onClick={()=>{this.props.approveUser(user.email)}}>
						<h2 className='user-card-btn-text'>Approve</h2>
					</div>
				</div>
			</div>
		)
	}

}



class PendingUsers extends React.Component {
	constructor(props) {
    	super(props);

	    this.state = {
	      pendingList:[]
	    }

	    this.fetchPendingList = this.fetchPendingList.bind(this);
	    this.approveUser = this.approveUser.bind(this);

	  }

	async componentDidMount() {
		// fetch list of pending users on mount

		await this.fetchPendingList()
	}

	async fetchPendingList() {
		// fetch list of pending users on mount

		let urlPending = `${process.env.REACT_APP_URL_BASE}/admin/users/new`;
		try{
			let response = await fetch(urlPending, {credentials: 'include'});

			// if status GET request successful
			if(response.status===200) {
				let result = await response.json();

				this.setState({pendingList:result.userList})
		    
			  } else {
			    console.log('fetch failed')
			  }
		}
		catch (e) {
		  console.log(e)
		}
	}	  

	async approveUser(userEmail) {

		// console.log(`attempting user approved: ${userEmail}`)

		// sends request to server to approve user accnt with given email
		let urlApprove = `${process.env.REACT_APP_URL_BASE}/admin/users/activate`;

		let data = {
			email: userEmail,
			notify: true
		}

		let response = await fetch(urlApprove, {
	      method: 'put',
	      headers: {
	        'Content-Type': 'application/json'
	      },
	      credentials: 'include',
	      body: JSON.stringify(data)
	    });
	    
	    // if new user POST request successful
	    if(response.status===200) {
	    	console.log(`user approved: ${userEmail}`)
	    	this.fetchPendingList();

		} else {
			console.log(`user approval failed: ${userEmail}`)
		}
	      

	}  


	renderUserList() {
		
		// define default value for user data if empty
		// is replaced if pending list contains any items
		var userData = <h2 className='user-list-div-h2'> No Pending Users</h2>
				
		// console.log(this.state.pendingList.length)

		if(this.state.pendingList.length > 0){
			// if pendingList has items, the create cards
			userData = this.state.pendingList.map((user) => (
				<PendingUserCard
					user={user}
					approveUser={this.approveUser}
				/>
			))
		}

		return (
			<div className='user-list-div'>
				{userData}
			</div>
		)
	}


	render() {
		return(
		    <div className='page-scroll-container'>
			    <div className='admin-page-container'>
			    	{this.props.adminLinkBar}
				    <div className='admin-header-row-div'>
				      <h2 className='admin-header'> Pending User Requests </h2>
				      <div className='admin-refresh-div dark-btn' onClick={this.fetchPendingList}>
			        		<RefreshIcon/>
			        	</div>
			        </div>
			      {this.renderUserList()}
		      </div>
		    </div>
		)
	}
}



export default PendingUsers;