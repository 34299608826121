import React from 'react';

import './Admin.css';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as UpDownIcon } from '../../icons/upDown.svg';

const _ = require('lodash');

class DashUsersAll extends React.Component {
	constructor(props) {
    	super(props);

	    this.state = {
	      allUserList:[]
	    }

	    this.fetchUserList = this.fetchUserList.bind(this);
	    this.sortUserData = this.sortUserData.bind(this);
	    

	  }

	sortUserData(fieldName) {
		// method sorts the list of user objects by the field name
		// does ascending and descending

		console.log(`sorting by ${fieldName}`)

		const allUserList = this.state.allUserList
		var newList = _.cloneDeep(allUserList)

		const sortFunc = function(a, b, attrName) {
			// sort function for numbers and chars

			var aVal = a[attrName]
			var bVal = b[attrName]

			// treat null values as 0
			aVal = !aVal? 0 : aVal;
			bVal = !bVal? 0 : bVal;

			if(typeof(aVal)==='number'){
				return parseFloat(aVal) - parseFloat(bVal);
			}

			if(typeof(aVal)==='string'){
				return aVal.localeCompare(bVal);
			}
		}

		// sort ascending
		newList = newList.sort((a, b) => sortFunc(a, b, fieldName));


		// check if already ascending
		if(JSON.stringify(newList) === JSON.stringify(allUserList)){

			console.log('list already in ascending order')
			console.log(newList[0])
			console.log(allUserList[0])

			// if identical, sort descending
			newList = newList.sort((a, b) => sortFunc(b, a, fieldName));

		}

		// update state
		this.setState({allUserList:newList})
	}

	async componentDidMount() {
		// fetch list of pending users on mount

		await this.fetchUserList()
	}

	async fetchUserList() {
		// fetch list of pending users on mount

		let urlPending = `${process.env.REACT_APP_URL_BASE}/admin/users/all`;
		try{
			let response = await fetch(urlPending, {credentials: 'include'});

			// if status GET request successful
			if(response.status===200) {
				let result = await response.json();

				this.setState({allUserList:result.userList})
		    
			  } else {
			    console.log('fetch failed')
			  }
		}
		catch (e) {
		  console.log(e)
		}
	}	  



	renderUserList() {
		
		// define default value for user data if empty
		// is replaced if pending list contains any items
		var userData = <h2 className='user-list-div-h2'> User List Loading...</h2>
				
		// console.log(this.state.allUserList.length)

		if(this.state.allUserList.length > 0){
			// if allUserList has items, the create cards
			userData = this.state.allUserList.map((user, index) => {
				if(!user){
					return null;
				}

				return (

					<tr className='admin-data-table-row'>
						<td>{user.id}</td> 
					    <td>{user.firstname} {user.lastname}</td> 
					    <td>{user.email}</td> 
					    <td>{user.country}</td> 
					    <td>{user.usage}</td> 
					</tr>
				)

			})

			
		}

		const sortIcon = <UpDownIcon className='admin-data-table-header-icon'/>

		return (
			
				
						<table className='admin-data-table'>
							<tr className='admin-data-table-row'>
								<th onClick={()=>{this.sortUserData('id')}}><div >Id {sortIcon}</div></th> 
							    <th onClick={()=>{this.sortUserData('lastname')}}><div >Name {sortIcon}</div></th> 
							    <th onClick={()=>{this.sortUserData('email')}}><div >Email {sortIcon}</div></th> 
							    <th onClick={()=>{this.sortUserData('country')}}><div >Country {sortIcon}</div></th> 
							    <th onClick={()=>{this.sortUserData('usage')}}><div >Usage {sortIcon}</div></th> 
							</tr>
							{userData}
						</table>
					
			
		)
	}


	render() {
		return(
		    <div className='page-scroll-container'>
			    <div className='admin-page-container'>
			    	{this.props.adminLinkBar}
				    <div className='admin-header-row-div'>
				      <h2 className='admin-header'> All User Accounts </h2>
				      <div className='admin-refresh-div dark-btn' onClick={this.fetchUserList}>
			        		<RefreshIcon/>
			        	</div>
			        </div>
			      {this.renderUserList()}
		      </div>
		    </div>
		)
	}
}



export default DashUsersAll;