import React from 'react';

import Helper from '../../helper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

import ResultsTable from '../ResultsTable/ResultsTable';
// import ResultsStatusTable from '../ResultsStatusTable/ResultsStatusTable'
// import Chart from '../Chart/Chart'
// import ChartAnnual from '../ChartAnnual/ChartAnnual'
// import ChartMonthly from '../ChartMonthly/ChartMonthly'
// import ChartTimeseries from '../ChartTimeseries/ChartTimeseries'
// import ToggleSection from '../ToggleSection/ToggleSection'
// import ResultsInv from '../ResultsInv/ResultsInv'

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';


class SectionObjective extends React.Component {
	constructor(props){
		super(props)

		this.renderSection = this.renderSection.bind(this);
	}

	renderSection() {

		let costOpt = this.props.optSummary.totalCost
		let costRef = this.props.optSummary.refCost
		let costDiff = (costRef - costOpt)/costRef;

		let co2Opt = this.props.optSummary.totalCo2
		let co2Ref = this.props.optSummary.refCo2
		let co2Diff = (co2Ref - co2Opt)/co2Ref;

		let objData = {
			'Total Cost': {
				'unit': 'k$/yr',
				'optimized': parseInt(costOpt / 1000),
				'reference': parseInt(costRef / 1000),
				'% difference': parseFloat(costDiff * 100).toFixed(1)+"%"
			},
			'Total CO2': {
				'unit': 't/yr',
				'optimized': parseInt(co2Opt / 1000),
				'reference': parseInt(co2Ref / 1000),
				'% difference': parseFloat(co2Diff * 100).toFixed(1)+"%"
			},
		}
		
		// transpose object for plotting
		objData = Helper.transposeObj(objData)
		
		return (
			<div className='results-section'>
				<h2 className='results-heading'>2. Optimization Objective</h2>
				
				<ResultsTable
					data={objData}
					title=''
					unit=''
					preserveType={true}
				/>

				<div className='results-note'>
					<p>
						Note: The reference values shown here were submitted in the inputs
						of this DER-CAM project. If you did not update these values when defining
						your model to correspond to a specific reference case, they should be ignored.
					</p>
				</div>

			</div>
		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Model Status Summary'
			/>
		)
	}

}

export default SectionObjective;