import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Helper from '../../helper';

import './Results.css';

// import results section components
import SectionSummary from './SectionSummary';
import SectionInv from './SectionInv';
import SectionAnn from './SectionAnn';
import SectionMon from './SectionMon';
import SectionTs from './SectionTs';

import Modal from '../Modal/Modal'


import { ReactComponent as LoadIcon } from '../../icons/loading.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';

import { convertAnnDataForPlot, convertMonDataForPlot, processMonTable, processTsPlotData,
				 getMetricOptions, getMetricFromKey, transposeData,
				 filterFirst, filterMetricCat, processInvPlot, unpackInvData, unpackAnnData, unpackMonData
			 } from './resultsProcessing'

import { testData01, testData02, testData03, 
	       testDataInv, annualMetrics, annMetric01,
	       monthlyData, tsData } from './DummyData'

const _ = require('lodash');

const colorMap = {
	ex_pv: '#c4941d',
	ex_es: '#1d4cc4',
	ex_dg: '#999999',
	new_pv: '#e1af33',
	new_es: '#3364e1',
	new_dg: '#555555',
	new_ee: '#228b22',
}

// const monColorMap = {
// 	1: {
// 		'bs': '#6262ff'
// 	},
// 	2: {
// 		'bs': '#6262ff',
// 		'o1': '#ff6262'
// 	},
// 	3: {
// 		'bs': '#6262ff',
// 		'o1': '#ff6262',
// 		'o2': '#c40000'
// 	},
// 	4: {
// 		'bs': '#6262ff',
// 		'o1': '#ff8989',
// 		'o2': '#ff3b3b',
// 		'o3': '#eb0000'
// 	},
// 	5: {
// 		'bs': '#6262ff',
// 		'o1': '#ff8989',
// 		'o2': '#ff3b3b',
// 		'o3': '#eb0000',
// 		'o4': '#9d0000',
// 	}
// }


const monColorMap = {
	1: {
		'bs': '#de7d7e'
	},
	2: {
		'bs': '#de7d7e',
		'o1': '#377eb8',
	},
	3: {
		'bs': '#de7d7e',
		'o1': '#377eb8',
		'o2': '#4daf4a',
	},
	4: {
		'bs': '#de7d7e',
		'o1': '#377eb8',
		'o2': '#4daf4a',
		'o3': '#984ea3',
	},
	5: {
		'bs': '#de7d7e',
		'o1': '#377eb8',
		'o2': '#4daf4a',
		'o3': '#984ea3',
		'o4': '#ff7f00',
	}
}

const monMapStatic = {
	"Existing Assets - Blue Sky" : '#c4941d',
	"Existing Assets - 20% Critical Load" : '#c4941d',
	"Existing Assets - 30% Critical Load" : '#c4941d',
	"Existing Assets - 50% Critical Load" : '#c4941d',
	"Existing Assets - 70% Critical Load" : '#c4941d',
	"New Investments - Blue Sky" : '#c4941d',
	"New Investments - 20% Critical Load" : '#c4941d',
	"New Investments - 30% Critical Load" : '#c4941d',
	"New Investments - 50% Critical Load" : '#c4941d',
	"New Investments - 70% Critical Load" : '#c4941d'
}

const assetNameMap = {
	ex_pv: 'Existing PV',
	ex_es: 'Existing Battery',
	ex_dg: 'Existing Gensets',
	new_pv: 'New PV',
	new_es: 'New battery',
	new_dg: 'New Gensets',
	new_ee: 'Energy Efficieny',
}




class Results extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			errorServer: null,
			errorResults: false,
			// resultsLoaded: false,
			dataLoaded: false,
			modelKey: null,
			modelLabel: null,
			modelDesc: null,
			results: null,

			// hard coded values for grouping and plotting
			metricCategories: [
				{value: 'fin', label: 'Financial'},
				{value: 'energy', label: 'Energy'},
				{value: 'power', label: 'Power'},
			],

			// raw data extracted from server respons
			rawAll: null,
			rawStatus: null, // raw data for model statuses
			rawInv: null, // raw data for investment results
			rawAnn: null, // raw data for annual metrics
			rawMon: null, // raw data for monthly metrics
			rawTs: null, // raw data for timeseries data

			// data to display for results
			model: null, // obj of model status data from queue db
			scenarios: null, // map of scenarios, keys, and labels generated by server

			// solution summary
			dataStatus: null,

			// investment results
			dataInv: null, // full dataset for display in investment table
			dataInvPlot: null, // full dataset for display in investment plot
			keyInvMetric: 'capacity', // key indicating which inv metric to be displayed 
			keyInvScen: null, // keu indicating the scenario for investment report to display 
			dataInvDetails: null,

			dataInvDetails: null, // full dataset for investment details
			keyInvDetails: null, // key indicating which scenario to show details for

			// annual metric results
			dataAnnTable: null, // full dataset for annual metrics for table display
			dataAnnPlot: null, // full dataset for annual metrics for plot display
			metricsAnn: null,
			keyAnnCat: null, // key indicating the category of ann metric to display
			keyAnnMetric: null, // key indicating the specific ann metric to display

			// monthly metric results
			dataMonTable: null, // full dataset for monthly metrics for table display
			dataMonPlot: null, // full dataset for monthly metrics for plot display
			keyMonCat: null, // key indicating the category of monthly metric to display
			keyMonMetric: null, // key indicating the specific monthly metric to display
			metricsMon: null,

			// timeseries results
			dataTs: null, // full dataset for timeseries plot
			metaTs: null, // meta data (color and labels) for ts metrics
			keyTsInv: 'ex', // key indicating which der scenario to display
			keyTsOutage: 'bs', // key indicating which outage scenario to display
			keyTsMon: 'january', // key indicating which outage scenario to display
			keyTsDaytype: 'week', // key indicating which outage scenario to display

		}


		this.getOutageScenario = this.getOutageScenario.bind(this);
		this.getMetricLabel = this.getMetricLabel.bind(this);
		this.updateSelectSingle = this.updateSelectSingle.bind(this);
		this.updateInvSelect = this.updateInvSelect.bind(this);
		this.updateInvDetailSelect = this.updateInvDetailSelect.bind(this);
		this.updateAnnSelect = this.updateAnnSelect.bind(this);
		this.updateMonSelect = this.updateMonSelect.bind(this);

	}

	async componentDidMount() {

		// implement tiny delay to show loading
		await Helper.sleep(2000)

		// extract key from router params prop
		const { modelKey } = this.props.match.params;

		// fetch project results data
    let urlResults = `${process.env.REACT_APP_URL_BASE}/json/rdt/model/${modelKey}/results`;
    let response = await fetch(urlResults, {credentials: 'include'});

    // if status GET request successful
    if(response.status===200) {
      let data = await response.json();

      // initialize data object for state update
			let dataToState = {};
			dataToState.errorServer = false;
			dataToState.dataLoaded = false;

      try {
				
				// extract general model data and remove full results
				let model = _.cloneDeep(data.model)
				delete model.results;

      	dataToState.model = model

      	// update scenario list
      	dataToState.scenarios = data.model.results.meta.scenariosAll;
      	dataToState.investmentOptions = data.model.results.meta.scenariosInv;
      	dataToState.outageOptions = data.model.results.meta.scenariosOutage;
      	dataToState.monthOptions = data.model.results.meta.monthList;
      	dataToState.daytypeOptions = data.model.results.meta.dayList;

      	// update model key, name, and description
      	dataToState.modelKey = data.model.model_key;
				dataToState.modelLabel = data.model.label;
				dataToState.modelDesc = data.model.results.general.description;
      	
      	// unpack data from server and store in 'raw data' state objects
      	dataToState.rawAll = data
      	dataToState.rawStatus = data.model.results.model
				dataToState.rawInv = data.model.results.investments
				dataToState.dataInvDetails = data.model.results.investmentDetails
				dataToState.rawAnn = data.model.results.annualData
				dataToState.rawMon = data.model.results.monthlyData
				dataToState.rawTs = data.model.results.timeseriesData

				// if try clause works, set dataLoad flag to true
				dataToState.dataLoaded = true;

      } catch (error) {
      	// if unpack fails, log error
			  console.error(error);
			  console.log('Failed to unpack model data')

			  // if try clause fails, set dataLoad flag to false
				dataToState.dataLoaded = false;
				dataToState.errorResults = true;
			}

			// if data successfully loaded, try to process for results displays
			if (dataToState.dataLoaded) {

				// try to unpack investment data
				try{
					dataToState.dataInv = unpackInvData(dataToState.rawInv)
				} catch (error) {
					// if unpack fails, log error
				  console.error(error);
				  console.log('Failed to process investment data')
				}
				
				// try to unpack annual metric data
				try {
					// unpack annual data, metric list, default metric and category, and starting plot data
					const annDataObj = unpackAnnData(dataToState.rawAnn, dataToState.scenarios)

					// add unpacked ann data to state-update obj
					dataToState.dataAnnTable = annDataObj.dataAnnTable
					dataToState.metricsAnn = annDataObj.metricsAnn	
					dataToState.keyAnnCat = annDataObj.keyAnnCat
					dataToState.keyAnnMetric  = annDataObj.keyAnnMetric
					dataToState.dataAnnPlot = annDataObj.dataAnnPlot

				} catch (error) {
					// if unpack fails, log error
				  console.error(error);
				  console.log('Failed to process annual data')
				}

				// try to unpack monthly metric data
				try {
					// unpack annual data, metric list, default metric and category, and starting plot data
					const monDataObj = unpackMonData(dataToState.rawMon, dataToState.scenarios)

					// add unpacked Mon data to state-update obj
					dataToState.dataMonTable = monDataObj.dataMonTable
					dataToState.metricsMon = monDataObj.metricsMon	
					dataToState.keyMonCat = monDataObj.keyMonCat
					dataToState.keyMonMetric  = monDataObj.keyMonMetric
					dataToState.dataMonPlot = monDataObj.dataMonPlot

				} catch (error) {
					// if unpack fails, log error
				  console.error(error);
				  console.log('Failed to process monthly data')
				}

				try{
					dataToState.metaTs = data.model.results.general.tsMetaData;
				} catch (error) {
					// if unpack fails, log error
				  console.error(error);
				  console.log('Failed to process timeseries data')
				}

			}
			

			// update state
			this.setState(dataToState)

    } else {
      this.setState({
      	errorServer:true,
      	dataLoaded: false,
      	modelKey: modelKey,
      	model: null,
      	scenarios: null,
      	dataRawStatus: null,
				dataRawInv: null,
				dataRawAnn: null,
				dataRawMon: null,
				dataRawTs: null

      })
    }
  	
	}

	updateSelectSingle(event) {

		// 

		// update date in state to reflect selection fron user input
		let dataName = event.target.value
		let stateName = event.target.name

		let newState = {}
		newState[stateName] = dataName

		this.setState(newState)


	}

	updateInvSelect(event){ 

			// update date in state to reflect selection fron user input
			let dataName = event.target.value
			let stateName = event.target.name

			let dataSet = testDataInv[dataName]

			let newState = {}

			newState[stateName] = dataSet

	    // //update state to reflect change
	    this.setState(newState)
	
	}

	updateInvDetailSelect(event){ 

			// update date in state to reflect selection fron user input
			let dataName = event.target.value
			let stateName = event.target.name

			let dataSet = testDataInv[dataName]

			let newState = {}

			newState[stateName] = dataSet

	    // //update state to reflect change
	    this.setState(newState)
	
	}


	async updateAnnSelect(event) {

		// this function uses that value from the category selection to update
		// the list of values available for the adjacent selection window

		// get selected category from user input
		let catName = event.target.value

		// get key of state object to change
		let stateKey = event.target.name

		// initialize object for state update
		let newState = {}

		newState[stateKey] = catName

    // //update state to reflect change
    await this.setState(newState)

    // if both cat and metric have valid selections, update selected data
    if(this.state.keyAnnCat && this.state.keyAnnMetric) {
			let metricKey = this.state.keyAnnMetric
			const newAnnData = convertAnnDataForPlot(this.state.dataAnnTable[metricKey], this.state.scenarios)
		
			this.setState({dataAnnPlot:newAnnData})

		} else {

			this.setState({dataAnnPlot:null})
		}

	}

	async updateMonSelect(event) {

		// this function uses that value from the category selection to update
		// the list of values available for the adjacent selection window

		// get selected category from user input
		let catName = event.target.value

		// get key of state object to change
		let stateKey = event.target.name

		// initialize object for state update
		let newState = {}

		newState[stateKey] = catName

    // //update state to reflect change
    await this.setState(newState)

    // if both cat and metric have valid selections, update selected data
    if(this.state.keyMonCat && this.state.keyMonMetric) {
			let metricKey = this.state.keyMonMetric
			const newMonData = convertMonDataForPlot(this.state.dataMonTable[metricKey], this.state.scenarios)
		
			this.setState({dataMonPlot:newMonData})

		} else {

			this.setState({dataMonPlot:null})
		}

	}


	getDerScenario(label) {



		// split label by _ char and use first segment of results arrray
		label = label.split("_")[0];

		// use label to extract full scenario name:
		// label = derNameMap[label]

		return label
	}


	getOutageScenario(label) {

		// get outagelabel from item in state.scenarios
		// where scenario.key == label

		if(!this.state.scenarios){
			return null
		}

		let scenList = Helper.filterObjList(this.state.scenarios, 'key', label);
		// console.log(scenList)
		if(!scenList){
			return label
		} else {
			if(scenList.length>0){
				return scenList[0].outageLabel
			} else {
				return label
			}
			
		}

	}

	getMetricLabel(label) {

		if(!label || !this.state.metricsAnn) {
			return null
		}

		// get full metric object from label
		let metric = getMetricFromKey(this.state.metricsAnn, label)

		return metric.label

	}


	getDerTypeLabel(label) {
		

		// use label to extract full scenario name:
		label = assetNameMap[label]

		return label

	}


  render(){ 

  	// if server error, return server error modal
  	if(this.state.errorServer){
  		return ( 

					<Modal 
						icon={<ErrorIcon />}
						label={<>Failed to Retrieve<br/>Data from Server</>}
						rotating={false}
						modalText={'The server failed to return any results data. Please try again after a few minutes.'}
					/>

	    )
  	}

  	// if results-processing error error, return results error modal
  	if(this.state.errorResults){
  		return ( 

					<Modal 
						icon={<ErrorIcon />}
						label={<>Failed to Process<br/>Data from Server</>}
						rotating={false}
						modalText={'The results data returned by the server could not be processed. You may need to re-run this model for results to be available.'}
					/>

	    )
  	}

  	// if results not loaded yet and no errors, return loading modal
  	if(!this.state.dataLoaded && !this.state.errorServer){
	    return ( 

					<Modal 
						icon={<LoadIcon />}
						label={<>Retrieving Results Data<br/>from Server</>}
						rotating={true}
					/>

	    )
	  } 

	  // if results loaded, return results objects
  	if(this.state.dataLoaded){
	    return ( 
	    	<div className="page-container">
					<div className='page-scroll-container'>
						<div className='results-container'>	

							<SectionSummary
								dataLoaded={this.state.dataLoaded}
								rawAll={this.state.rawAll}
								modelKey={this.state.modelKey}
								modelLabel={this.state.modelLabel}
								modelDesc={this.state.modelDesc}
								scenarios={this.state.scenarios}
							/>

							<SectionInv
								dataLoaded={this.state.dataLoaded}
								dataInv={this.state.dataInv}
								dataInvDetails={this.state.dataInvDetails}
								scenarios={this.state.scenarios}
								keyInvMetric={this.state.keyInvMetric}
								keyInvScen={this.state.keyInvScen}
								getOutageScenario={this.getOutageScenario}
								getDerTypeLabel={this.getDerTypeLabel}
								updateSelectSingle={this.updateSelectSingle}
								colorMap={colorMap}
							/>

							<SectionAnn
								dataLoaded={this.state.dataLoaded}
								metricCategories={this.state.metricCategories}
								metricsAnn={this.state.metricsAnn}
								keyAnnMetric={this.state.keyAnnMetric}
								keyAnnCat={this.state.keyAnnCat}
								dataAnnPlot={this.state.dataAnnPlot}
								dataAnnTable={this.state.dataAnnTable}
								colorMap={colorMap}
								updateAnnSelect={this.updateAnnSelect}
								getOutageScenario={this.getOutageScenario}
								getMetricLabel={this.getMetricLabel}
							/>

							<SectionMon
								dataLoaded={this.state.dataLoaded}
								scenarios={this.state.scenarios}
								metricCategories={this.state.metricCategories}
								metricsMon={this.state.metricsMon}
								keyMonMetric={this.state.keyMonMetric}
								keyMonCat={this.state.keyMonCat}
								dataMonPlot={this.state.dataMonPlot}
								dataMonTable={this.state.dataMonTable}
								monColorMap={monColorMap}
								updateMonSelect={this.updateMonSelect}
								getOutageScenario={this.getOutageScenario}
							/>

							<SectionTs
								dataLoaded={this.state.dataLoaded}
								rawTs={this.state.rawTs}
								metaTs={this.state.metaTs}
								investmentOptions={this.state.investmentOptions}
								outageOptions={this.state.outageOptions}
								daytypeOptions={this.state.daytypeOptions}
								monthOptions={this.state.monthOptions}
								keyTsInv={this.state.keyTsInv}
								keyTsOutage={this.state.keyTsOutage}
								keyTsDaytype={this.state.keyTsDaytype}
								keyTsMon={this.state.keyTsMon}
								updateSelectSingle={this.updateSelectSingle}
							/>

						</div>
					</div>			
				</div>
	    )
	  }

  }
} 


export default withRouter(Results);