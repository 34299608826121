import React from 'react';
import { Link } from 'react-router-dom';

import './Projects.css';

import Navbar from '../Navbar/Navbar'
import ModelList from '../ModelList/ModelList'

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';

import Helper from '../../helper';

const statusList = ['solved', 'queued', 'expired', 'failed', 'deleted', 'pending'];

class Projects extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			myProjects: [],
			projectsByType: {},
			serverError: false
		}

		this.fetchProjects = this.fetchProjects.bind(this);
		this.deleteProject = this.deleteProject.bind(this);
		this.restoreProject = this.restoreProject.bind(this);
	}

	categorizeProjectsOLD(projectList) {
		// separates projects into sublists based on their status
		let projectCategories = {}

		// loop through status names
		for (let ii=0; ii<statusList.length; ii++) {
			// filter list where project.status equals the current status name
			let projSubList = Helper.filterObjList(projectList, 'status', statusList[ii])
			// write status-specific sublist to corresponding key in obj
			projectCategories[statusList[ii]] = projSubList;
		}

		return projectCategories;

	}

	categorizeProjects(projectList) {
		// separates projects into sublists based on their status
		let projectCategories = {}

		console.log('Here is the fetched project list:')
		console.log(projectList)

		// initialize key-val pairs for each list type in projectCategories
		for (let ii=0; ii<statusList.length; ii++) {
			projectCategories[statusList[ii]] = []
		}

		// loop returned projects and add to category lists
		for (let pp=0; pp<projectList.length; pp++) {

			// extract project from list, and category from project
			var proj = projectList[pp];
			var projCat = proj.status;

			// check if deleted
			if(proj.in_trash){
				// added deleted models to deleted cat list
				projectCategories.deleted.push(proj)
			} else {
				// otherwise, add to project's listed category
				projectCategories[projCat].push(proj)
			}

		}

		return projectCategories;

	}


	async fetchProjects() {
		// function to check if API server is running
    // and retrieve lastest task timestamps
    // console.log('checking server status')
    let urlFetch = this.props.apiProjectUrl;
    let response = await fetch(urlFetch, {credentials: 'include'});
    console.log('fetching project list')
    // if status GET request successful
    if(response.status===200) {
      let result = await response.json();
      console.log('fetch successful')
      // get projects categorized by type
      let projectsByType = this.categorizeProjects(result.models)

      this.setState({
        serverError:false,
        myProjects: result.models,
        projectsByType: projectsByType
      })
    } else {
    	console.log(response)
      this.setState({
      	serverError:true,
        myProjects: [],
        projectsByType: {}
      })
    }
	}

	async deleteProject(modelKey) {
		// function to send delete request
		// to move model with modelKey to deletion queue
    
    let urlModel = `${this.props.apiProjectUrl}/${modelKey}`;
    let response = await fetch(urlModel, {credentials: 'include', method: 'DELETE'});

    // if status GET request successful
    if(response.status===204) {
      // this.fetchProjects();
      console.log(`Model deleted: ${modelKey}`)
      // remove project from proj list without pinging server by removing from state
      // list should automatically update on next refresh
      // get current project list
      let myProjects = this.state.myProjects;
      myProjects = Helper.filterExcludeObjList(myProjects, 'model_key', modelKey)
      this.setState({myProjects:myProjects})
            
    } else {

    	console.log(`Model deleted failed`)
      
    }
	}

	async restoreProject(modelKey) {
		// function to send delete request
		// to move model with modelKey to deletion queue

    let urlModel = `${this.props.apiProjectUrl}/restore/${modelKey}`;
    let response = await fetch(urlModel, {credentials: 'include', method: 'POST'});

    // if status GET request successful
    if(response.status===200) {
      // this.fetchProjects();
      console.log(`Model restored: ${modelKey}`)
      // // remove project from proj list without pinging server by removing from state
      // // list should automatically update on next refresh
      // // get current project list
      // let myProjects = this.state.myProjects;
      // myProjects = Helper.filterExcludeObjList(myProjects, 'model_key', modelKey)
      // this.setState({myProjects:myProjects})
            
    } else {
    	// console.log(response)
    	console.log(`Model restore failed`)
      
    }
	}

	componentDidMount() {
		this.fetchProjects();
	}

	renderSubLists() {

		return statusList.map((statusVal) => (
				<ModelList 
        	modelList={this.state.projectsByType[statusVal]}
        	listName={statusVal}
        	fetchProjects={this.fetchProjects}
        	deleteProject={this.deleteProject}
        	restoreProject={this.restoreProject}
        	openProjectCopy={this.props.openProjectCopy}
        	appUrl={this.props.appUrl}
        	apiProjectUrl={this.props.apiProjectUrl}
        	modelType={this.props.modelType}
        />
		    ))
	}

  render(){ 

  	
  	// get upload url by going one level up from url
  	let url = this.props.appUrl
  	const ulUrl = url.slice(0, url.lastIndexOf('/'));

    return( 
    	<div className="page-scroll-container centered-col-container">

    	<div className='proj-menu-container'>
			
					<div className='centered-row-container'>
						<div className="proj-section">
							<div className='proj-row-div'>
								<h2 className='proj-section-header'>Create New Project Model</h2>
							</div>
		        	<div className='dark-btn proj-new-button'>
		        		<Link to={`${this.props.appUrl}/new`} className='react-link'><h2>New Project</h2></Link>
		        	</div>
		        </div>

		        <div className="proj-section">
							<div className='proj-row-div'>
								<h2 className='proj-section-header'>Upload Project from File</h2>
							</div>
		        	<div className='dark-btn proj-new-button'>
		        		<Link to={`${ulUrl}/upload`} className='react-link'><h2>Upload Project</h2></Link>
		        	</div>
		        </div>
		    </div>

	        <div className="proj-section">
	        	<div className='proj-row-div'>
		        	<h2 className='proj-section-header'>Explore Existing Project Models</h2>
		        	<div className='proj-refresh-div dark-btn' onClick={this.fetchProjects}>
		        		<h2>Refresh List</h2>
		        		<RefreshIcon/>
		        	</div>
	        	</div>
	       		{this.renderSubLists()}		

	       	</div>
       	</div>
						
		</div>
    ) 
  }
} 


export default Projects;