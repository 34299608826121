import React from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { withRouter } from 'react-router-dom';

import Helper from '../../helper';

import ApiHelper from '../../apiHelper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

import ResultsStatusTable from '../ResultsStatusTable/ResultsStatusTable'

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';

var rerunDelay = 0; //ms to delay rerun action


class SectionSummary extends React.Component {
	constructor(props){
		super(props)

		this.renderSection = this.renderSection.bind(this);
		this.submitRerun = this.submitRerun.bind(this);
	}

	renderDlButton(data, fileName, btnLabel, dataLoaded, hideStatus=false){

		// if hideStatus is true, return nothing
		if(hideStatus){
			return null
		}

		// if data not loaded, return disabled btn
		if(!dataLoaded) {
			return(
				<div className='dark-btn text-btn res-dl-btn'>
					Data Not Available
				</div>
			)
		}

		return(		
			
			<div className='res-dl-btn-container'>
				<CSVLink data={data} filename={fileName}>
					<div className='dark-btn text-btn res-dl-btn'>
						{btnLabel}
					</div>
				</CSVLink>
			</div>		

		)
	}

	renderJsonDlButton(){

		var dlLink = ApiHelper.generateJsonDownloadLink(this.props.modelData)

		return(		
			
			<div className='res-dl-btn-container'>
					<div className='dark-btn text-btn res-dl-btn' >
						<a  href={dlLink}
						download={`${this.props.modelKey}_results.json`}>
							JSON Results
						</a>
					</div>
				
			</div>		

		)
	}

	async downloadResultsJson() {
		

		// attempt to download
		var dlLink = ApiHelper.generateJsonDownloadLink(this.props.modelData)

		
	}

	renderRerunButton(){

		return(		
			
			<div className='res-dl-btn-container' onClick={this.submitRerun}>
					<div className='dark-btn text-btn res-dl-btn'>
						Re-run Model
					</div>
			</div>		

		)
	}

	async submitRerun() {


	    let urlNewModel = `${process.env.REACT_APP_URL_BASE}/json/dercam/model/rerun/${this.props.modelKey}`;

	    let response = await fetch(urlNewModel, {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json;charset=utf-8'
	      },
	      credentials: 'include'
	    });

	    // if status GET request successful
	    console.log(response)
	    if(response.status===200) {
	      console.log('model returned to queue')

	      await Helper.sleep(rerunDelay);

	      this.props.history.push(".");

	    } else {
	      console.log('model re-run trigger failed')
	    }
	}

	renderSection() {


		var activeStatus = this.props.dataLoaded

		var data1 = this.props.results
		var fileName1 = `${this.props.modelKey}_results.csv`
		var btnLabel1 = 'Site Results CSV'

		var data2 = this.props.resultsNode
		var fileName2 = `${this.props.modelKey}_results_nodes.csv`
		var btnLabel2 = 'Node Results CSV'
		var hideStatus2 = !this.props.multiNode
	
		return (
			<div className='results-section'>
				<h2 className='results-heading'>1. Results Summary</h2>
				<h2 className='results-heading-2'>Model Details</h2>
				<table className='res-sum-table'>
					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Name:</div></td>
						<td><div className='res-sum-data'>{this.props.modelLabel}</div></td>
					</tr>

					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Key:</div></td>
						<td><div className='res-sum-data'>{this.props.modelKey}</div></td>
					</tr>

					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>No. of Nodes:</div></td>
						<td><div className='res-sum-data'>{this.props.nNodes}</div></td>
					</tr>

					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Objective:</div></td>
						<td><div className='res-sum-data'>{this.props.optSummary.objective}</div></td>
					</tr>

					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Optimization Runtime:</div></td>
						<td><div className='res-sum-data'>{this.props.optSummary.runtime}</div></td>
					</tr>

					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Model Status:</div></td>
						<td><div className='res-sum-data'>{this.props.optSummary.modelCondition}</div></td>
					</tr>

					<tr className='res-sum-data-row'>
						<td><div className='res-sum-data-label'>Solver Status:</div></td>
						<td><div className='res-sum-data'>{this.props.optSummary.solverCondition}</div></td>
					</tr>

				</table>

				<div className='results-subsection'>
					<h2 className='results-heading-2'>Results Download</h2>
					
					<div className='results-row-container'>
						{this.renderDlButton(data1, fileName1, btnLabel1, activeStatus)}
						{this.renderDlButton(data2, fileName2, btnLabel2, activeStatus, hideStatus2)}
						{this.renderJsonDlButton()}
						{this.renderRerunButton()}
					</div>

						<div className='results-note'>
							<p>
								Note: DER-CAM results data visualization under development.
								You may download full model result files as .csv using the
								button above. DER-CAM results may be missing some fields if solved
								prior to recent changes. To see the latest output versions, 
								use the button above to regenerate results data.
							</p>
						</div>

				</div>

			</div>
		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Model Status Summary'
			/>
		)
	}

}

export default withRouter(SectionSummary);