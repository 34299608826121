import React from 'react';
import { BrowserRouter, Link, Route, Switch, withRouter, Redirect } from 'react-router-dom';

import './RouterApps.css';

import HomeApps from '../HomeApps/HomeApps';

import RouterRdt from '../RouterRdt/RouterRdt';
import HomeApi from '../HomeApi/HomeApi';
import RouterDercam from '../RouterDercam/RouterDercam';
import Upload from '../Upload/Upload'
import ChangeLog from '../ChangeLog/ChangeLog'

import AdminRouter from '../Admin/AdminRouter';

import NodeSandBox from '../NodeSandBox/NodeSandBox';


import Helper from '../../helper';

// import template for project data
// remove after dev complete
const projTemplate = require('../NewProjectDercam/tables.json')
const projData = projTemplate.data

class RouterApps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }

  }


  

  render() {
    const url = this.props.match.url

    // if not auth, redirect to home
    if(!this.props.auth && !this.props.authFetch) {
      console.log(`accesing /u without auth. auth status: ${this.props.auth}`)
      return(
        <Redirect from='/u/' to="/" />
      )
    }

    return (
          

            <Switch>
              <Route exact path={url}>
                <HomeApps 
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                  userLogout = {this.props.userLogout}
                />
              </Route>
              <Route path={`${url}/rdt`}>
                <RouterRdt
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                  userLogout = {this.props.userLogout}
                  saveProject = {this.props.saveProject}
                  clearSavedProject = {this.props.clearSavedProject}
                  rdtProj = {this.props.rdtProj}
                />
              </Route>
              <Route exact path={`${url}/api`}>
                <HomeApi
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                  userLogout = {this.props.userLogout}
                />
              </Route>
              <Route path={`${url}/dercam`}>
                <RouterDercam
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                  userLogout = {this.props.userLogout}
                  saveProject = {this.props.saveProject}
                  clearSavedProject = {this.props.clearSavedProject}
                  dercamProj = {this.props.dercamProj}
                  networkModel = {this.props.networkModel}
                />
              </Route>

              <Route path={`${url}/upload`}>
                <Upload
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                  saveProject = {this.props.saveProject}
                  baseUrl={url}
                />
              </Route>

              <Route path={`${url}/admin`}>
                <AdminRouter
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                />
              </Route>

              <Route path={`${url}/updates`}>
                <ChangeLog
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                />
              </Route>

              <Route path={`${url}/test`}>
                <NodeSandBox
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                  saveProject = {this.props.saveProject}
                  networkModel = {this.props.networkModel}
                  
                  projData = {projData}
                />
              </Route>

            </Switch>
    )
  }
}

export default withRouter(RouterApps);
