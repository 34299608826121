const _ = require('lodash');

const ApiHelper = {

	

	async getLoadProfileList() {

		// url to fetch data for building reference library
		let urlRdtLoadData = `${process.env.REACT_APP_URL_BASE}/data/load/list`;

		var loadList = [];

		let response1 = await fetch(urlRdtLoadData, {credentials: 'include'});

	    // if status GET request successful
	    if(response1.status===200) {
	      	loadList = await response1.json();
	      	loadList = loadList.bldgList
	    } else {
	    	console.log('load list request failed')
	    }

	    // build lists of building types, vintages, and climate zones
	    var bldgList = _.uniqBy(loadList, 'BLDG_TYPE_FULL').map(a => a.BLDG_TYPE_FULL);

	    var vintageList = _.uniqBy(loadList, 'VINTAGE_FULL').map(a => a.VINTAGE_FULL);

	    var climateList = _.uniqBy(loadList, 'CLIMATE_ZONE').map(a => a.CLIMATE_ZONE);

	    return [loadList, bldgList, vintageList, climateList]
	},

	async getPvDataList() {

		// url to fetch data for solar reference library
		let urlRdtPvData = `${process.env.REACT_APP_URL_BASE}/data/pv/list`;

		var pvList = [];


	    let response2 = await fetch(urlRdtPvData, {credentials: 'include'});

	    // if status GET request successful
	    if(response2.status===200) {
	      	pvList = await response2.json();
	      	pvList = pvList.pvList
	    } else {
	    	console.log('pv list request failed')
	    }

	    // get list of unique state (2-char abbrevs)
	    var stateList = _.uniqBy(pvList, 'STATE').map(a => a.STATE);

	    // get list of angles and orientations
	    const pvAngleList = ['lat', 0, 45, 90];
		const pvOrientList = ['S', 'SW', 'SE', 'W', 'E', 'N', 'NW', 'NE']; 

	    // build solar-data-location list
	    // object where keys are state abbrevs, values are lists of stations in that state
	    const pvStateMap = {}
	    for (let pvIndex in pvList) {
	    	let pp = pvList[pvIndex];
		  // if state list not in pv map, add it
		  if(!pvStateMap[pp.STATE]) {
		  	// console.log(`${pp.STATE} not in map yet`)
		  	pvStateMap[pp.STATE] = [pp.STATION]
		  } else {
		  	// else add station to existing list for state
		  	let statePvList = pvStateMap[pp.STATE];
			statePvList.push(pp.STATION)
			pvStateMap[pp.STATE] = statePvList
		  }
		}


	    return [pvList, stateList, pvStateMap, pvAngleList, pvOrientList]
	},

	getSelectedBuilding(loadList, bldg_type, bldg_vint, bldg_climateZone) { 
		// takes bldg_type, bldg_vint, bldg_climateZone
		// checks in passed loadList, if corresponding extry found
		// returns bldgId, size, annElec, and annNg

		// init return values to default (no bldg found)
		var  bldg_bldgId = -999;
		var  bldg_size = '';
		var  bldg_annElec = '';
		var  bldg_annNg = '';	   

	    // check if building can be id'ed with current selected info
	    if((bldg_type != null) && (bldg_vint != null) && (bldg_climateZone != null)) {
	    	// console.log('all bldg params selected')

	    	var selectedBldg = loadList.filter(function(v, i) {
			  return ((v["BLDG_TYPE_FULL"] == bldg_type) && (v["VINTAGE_FULL"] == bldg_vint) && (v["CLIMATE_ZONE"] == bldg_climateZone));
			})

			console.log(selectedBldg)

	    	// if search returns a building, extract data
	    	if (selectedBldg.length > 0){
	    		selectedBldg = selectedBldg[0];

	    		bldg_bldgId = selectedBldg.ID;
				bldg_size = selectedBldg.SIZE;
				bldg_annElec = (selectedBldg.ELEC/1000).toFixed(2);
				bldg_annNg = (selectedBldg.NG/1000).toFixed(2);


	    	} else {
	    		selectedBldg = null;
	    	}
	    	
			// console.log(selectedBldg)
	    }

	    return [selectedBldg, bldg_bldgId, bldg_size, bldg_annElec, bldg_annNg]
	
	},

	getSelectedPv(pvStateMap, pvList, loc_stateUs, loc_city) { 
		// takes state code and station location, along with pv-state-location map obj

		// returns list of stations for selected state, and selected station, 
		// and station list for selected state

		// initialize vars to default/failed search values
		var selectedPv = null;
	    var loc_stationId = -999;
	   

	    //update list of available solar locations based on current US state
	    var stationList = pvStateMap[loc_stateUs]

	    //if current station not in station list, set to null
	    if(stationList){
		    if(!stationList.includes(loc_city) && (loc_city!='' && loc_city!=null)){
		    	loc_city = null;
		    }
		} else {
			stationList = ['Select state to see station list']
			loc_city=null
		}

	    // check if pv station id can be id'ed with current selected info
	    if((loc_city!='' && loc_city!=null) && (loc_stateUs!='' && loc_stateUs!=null)) {
	    	console.log('all pv params selected')

	    	var selectedPv = pvList.filter(function(v, i) {
			  return ((v["STATION"] == loc_city));
			})

	    	// if search returns a station, extract data
	    	if (selectedPv.length > 0){
	    		selectedPv = selectedPv[0];
	    		console.log(selectedPv)
	    		loc_stationId = selectedPv.ID;


	    	} else {
	    		console.log('selected PV not being set!')
	    	}

	    } else {
	    	console.log(loc_city)
	    	console.log(loc_stateUs)
	    }

	    return [ stationList, selectedPv, loc_stationId ]
	
	},

	async generateModelDownloadLink(modelKey, apiProjectUrl, modelType) {
	    // method generates a download link to a model json file
	    // given modelKey, fetches model data from server

	    // init link to blank
	    var downloadLink = ''

	    // fetch project from server
	    let urlModel = `${apiProjectUrl}/${modelKey}/copy`;
	    let response = await fetch(urlModel, {credentials: 'include'});

	    // if status GET request successful
	    if(response.status===200) {
	      let data = await response.json();
	      let serverProject = data.model;

	      // package model as json and download
	      // create data download link if data has been loaded
	      if(serverProject) {

	      	var dlData = {
	      		modelType: modelType,
	      		modelData: serverProject
	      	}

	        const str = JSON.stringify(dlData);
	        const bytes = new TextEncoder().encode(str);
	        const blob = new Blob([bytes], {
	            type: "application/json;charset=utf-8"
	        });

	        downloadLink = window.URL.createObjectURL(blob);
	      } else {
	        console.log(`No data retrieved for model: ${modelKey}`)
	      }


	    } else {
	      console.log(`could not retrieve model: ${modelKey}`)

	    }

	    return downloadLink;


	},

	generateJsonDownloadLink(jsonData) {
	    // method generates a download link for a json file
	    // packages data provided in arg jsonData

	    // init link to blank
	    var downloadLink = ''

        const str = JSON.stringify(jsonData);
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
            type: "application/json;charset=utf-8"
        });

        downloadLink = window.URL.createObjectURL(blob);

	    return downloadLink;


	}

} 

export default ApiHelper;