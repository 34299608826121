import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

class ChartTimeseries extends PureComponent {

  generateAreas() {

    if(!this.props.meta) {
      return null
    }

    var areaList = this.props.meta.map((metric) => (
      <Area 
        type="monotone" 
        dataKey={metric.label} 
        stackId="1" 
        stroke={this.props.colors[metric.color]} 
        fill={this.props.colors[metric.color]} />
    ))

    return areaList;

  }

  relabelData() {


  }
  
  render() {

    var metricList1 = ['utilPurch', 'PVpotential', 'batteryOuput', 'dgOutput', 'eeReduction', 'loadShedCritical', 'loadShedNonCritical']
    var metricList2 = ['totalLoad', 'PVexport', 'PVcurtail', 'batteryInput']


    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={this.props.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {this.generateAreas()}
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}


export default ChartTimeseries
