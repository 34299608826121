import React from 'react';

import './ModelList.css'

import ModelCard from '../ModelCard/ModelCard.js'

import Helper from '../../helper';

import { ReactComponent as CaretSolidIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronSolidIcon } from '../../icons/chevronSolid.svg';

class ModelList extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			collapsed: true
		}

		this.toggleCollapse = this.toggleCollapse.bind(this);
	}

	toggleCollapse(){
		this.setState({collapsed: !this.state.collapsed})
	}

	renderCardList(){
		// renders an array of ModelCard Components for a give Model List
		var modelList = this.props.modelList

		return modelList.map((modelData) => (
			<ModelCard
				modelData={modelData}
				listName={this.props.listName}
				fetchProjects={this.props.fetchProjects}
				deleteProject={this.props.deleteProject}
				restoreProject={this.props.restoreProject}
				openProjectCopy={this.props.openProjectCopy}
				appUrl={this.props.appUrl}
				apiProjectUrl={this.props.apiProjectUrl}
				modelType={this.props.modelType}
			/>
	    ))

	}

	render() {
		// Renders either a list of Model Cards by calling the renderCardList method
		// if list is empty or lacks modelList prop, renderes nothing for the list
		var modelList = this.props.modelList
		if(!modelList){
			// console.log(`No model list for ${this.props.listName}`)
			modelList = []
		}
		if(modelList.length>0) {

			var listNameFormatted = `${Helper.capitalizeFirst(this.props.listName)} Models`

			// get list of models to display from render method
			var modelListDisplay = this.renderCardList();
			var modelListIcon = <CaretSolidIcon/>

			// if list is collapsed, change display to null
			if(this.state.collapsed){
				modelListDisplay = null;
				modelListIcon = <ChevronSolidIcon/>
			}

			return (
				<div className='model-sub-list' >
					<div className={`model-collapsible-btn list-toggle-${this.props.listName}`} onClick={this.toggleCollapse}>
						<div className='model-collapsible-btn-left'>
							{modelListIcon}
							<h3 className='model-sub-list-head'>{listNameFormatted}</h3>
						</div>
						<div className='model-collapsible-btn-right'>
							<div className={`model-list-count-container count-${this.props.listName}`}>
								<h3 className='model-list-count'>{modelList.length}</h3>
							</div>
						</div>
					</div>

					<div className='model-collapsible-content'>
						{modelListDisplay}
					</div>

				</div>
			)

		} else {
			return null
		}
	}

};

export default ModelList;

				