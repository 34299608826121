import Helper from '../../helper';
const _ = require('lodash');

const  monLabels = [
	'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
	'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const convertLabel = (label, convMap, splitLabel=false, segment=0) => {

	
	if (splitLabel) {
		// split label by _ char and use first segment of results arrray
		label = label.split("_")[segment];
	}
	
	if (convMap) {
		// use label to extract full scenario name:
		label = convMap[label]
	} else {
		console.log(`Conversion map missing for ${label}`)
	}	

	return label
}

const getUnique = (arr) => {
	// returns only unique elements of array
	return arr.filter((e, i) => arr.indexOf(e) === i);
}


const unpackAnnData  = (rawData, scenarios) => {

	// processes annual data for display tables and plots
	// returns object containing:
	//		dataAnnTable: formatted data for tables
	//		dataAnnPlot: formatted data for plots
	//		metricsAnn: list of available metrics for annual plots
	//		keyAnnCat: current key for Annual Plot category
	//		keyAnnMetric: current key for Annual Plot metric

	// initialize data object to return
	const processedData = {};

	// get list of scenarios from annual data
	let scenList = Object.keys(rawData);
	let metricList = _.cloneDeep(rawData[scenList[0]]);

	// initialize tableData, plotData, and metricList objects
	// tableData starts a clone of first entry in raw data list
	let tableData = {};
	let plotData = [];
	
	let emptyMetricInds = [];



	// loop through metrics
	for(let mm=0; mm<metricList.length; mm++) {
		// remove val, which contains data for scenario 1
		delete metricList[mm].val

		let metricName = metricList[mm].name;

		// add vals object to current tableData row
		tableData[metricName] = {}

		// loop through scenarios and add data
		for(let ss=0; ss<scenList.length; ss++) {
			// extract scenario key
			let scen = scenList[ss]

			// add data from rawData to tableData
			tableData[metricName][scen] = parseFloat(rawData[scen][mm].val)
			// tableData[mm].vals[scen] = ss
		}

		// if every value in the metric is zero, drop it from metric list
		let metricEmpty = Object.values(tableData[metricName]).every(o => o === 0);
		if(metricEmpty) {
			// add mm to list of empty metrics, to remove later
			emptyMetricInds.push(mm)
			delete tableData[metricName]
			// console.log(`${metricName} is empty`)
		}

	}

	// update dataAnnTable
	processedData.dataAnnTable = tableData

	// drop empty metrics
	metricList = metricList.filter((element, index) => !emptyMetricInds.includes(index));

	// update metricsAnn
	processedData.metricsAnn = metricList

	// update default keys keyAnnCat, keyAnnMetric
	processedData.keyAnnCat = processedData.metricsAnn[0].cat
	processedData.keyAnnMetric  = processedData.metricsAnn[0].name

	// generate formatted data for annual plots dataAnnPlot
	processedData.dataAnnPlot = convertAnnDataForPlot(
		processedData.dataAnnTable[processedData.keyAnnMetric], 
		scenarios
	)

	// console.log(rawData)
	return processedData

}

const unpackMonData  = (rawData, scenarios) => {

	// processes annual data for display tables and plots
	// returns object containing:
	//		dataAnnTable: formatted data for tables
	//		dataAnnPlot: formatted data for plots
	//		metricsAnn: list of available metrics for annual plots
	//		keyAnnCat: current key for Annual Plot category
	//		keyAnnMetric: current key for Annual Plot metric

	// initialize data object to return
	const processedData = {};

	// get list of scenarios from annual data
	let scenList = Object.keys(rawData);
	let metricList = _.cloneDeep(rawData[scenList[0]]);

	// initialize tableData, plotData, and metricList objects
	// tableData starts a clone of first entry in raw data list
	let tableData = {};
	let plotData = [];
	let colors = {}
	
	let emptyMetricInds = [];


	// loop through metrics
	for(let mm=0; mm<metricList.length; mm++) {
		// remove val, which contains data for scenario 1
		delete metricList[mm].val

		let metricName = metricList[mm].name;

		// add vals object to current tableData row
		tableData[metricName] = {}

		// loop through scenarios and add data
		for(let ss=0; ss<scenList.length; ss++) {
			// extract scenario key
			let scen = scenList[ss]

			// add data from rawData to tableData
			tableData[metricName][scen] = rawData[scen][mm].val.map(Number) 
			// tableData[mm].vals[scen] = ss
		}

		// if every value in the metric is zero, drop it from metric list
		let metricEmpty = Object.values(tableData[metricName]).every(o => o.every(item => item === 0));
		if(metricEmpty) {
			// add mm to list of empty metrics, to remove later
			emptyMetricInds.push(mm)
			delete tableData[metricName]
			// console.log(`${metricName} is empty`)
		}

	}

	// update dataAnnTable
	processedData.dataMonTable = tableData

	// drop empty metrics
	metricList = metricList.filter((element, index) => !emptyMetricInds.includes(index));

	// update metricsAnn
	processedData.metricsMon = metricList

	// update default keys keyAnnCat, keyAnnMetric
	processedData.keyMonCat = processedData.metricsMon[0].cat
	processedData.keyMonMetric  = processedData.metricsMon[0].name


	// generate formatted data for annual plots dataAnnPlot
	processedData.dataMonPlot = convertMonDataForPlot(
		processedData.dataMonTable[processedData.keyMonMetric], 
		scenarios
	)

	// console.log(processedData)
	return processedData

}

const convertAnnDataForPlot = (metricData, scenarios,) => {

	// initialize object and list
	const plotDataObj = {}
	const plotData = []

	// loop through data
	for(let ss=0; ss<scenarios.length; ss++){

		// split key into scenario and der
		let key = scenarios[ss].key;
		let outageLabel = scenarios[ss].outageLabel
		let derLabel = scenarios[ss].derLabel

		// if scenario key not in obj, initialize
		if(!plotDataObj[outageLabel]){
			plotDataObj[outageLabel] = {};
		}

		// set der key to value
		if(metricData[key]){
			plotDataObj[outageLabel][derLabel] = metricData[key]
		}
	}

	const outageKeys = Object.keys(plotDataObj)
	// loop through obj data
	for(let oo=0; oo<outageKeys.length; oo++){

		let name = outageKeys[oo]
		let data = plotDataObj[name]

		// convert key to 'name' att
		data.name = name

		// add to plotData
		plotData.push(data)

	}

	// return processed plotData
	return plotData

}

const convertMonDataForPlot = (metricData, scenarios) => {

	// takes input like : {scenKey:[list of monthly metric], ...}
	// returns output like: [{name:'1', 'dd_oo': value, ...}, ...]

	// initialize object and list
	const plotDataObj = {}
	const plotData = []

	// loop through months
	for(let mm=0; mm<12; mm++){

		var newEntry = {name:monLabels[mm]};

		// loop through data
		for(let ss=0; ss<scenarios.length; ss++){

			// split key into scenario and der
			let key = scenarios[ss].key;
			let outageLabel = scenarios[ss].outageLabel
			let derLabel = scenarios[ss].derLabel

			let combinedLabel = `${derLabel} - ${outageLabel}`

			// set der key to value
			if(metricData[key]){
				newEntry[combinedLabel] = metricData[key][mm]
			}
		}

		plotData.push(newEntry)
	}

	// console.log(Object.keys(plotData[0]))

	// console.log(plotData)

	// const scenKeys = Object.keys(plotDataObj)
	// // loop through obj data
	// for(let ss=0; ss<scenKeys.length; ss++){

	// 	let name = scenKeys[ss]
	// 	let data = plotDataObj[name]

	// 	// convert key to 'name' att
	// 	data.name = name

	// 	// add to plotData
	// 	plotData.push(data)

	// }

	// return processed plotData
	return plotData

}

const getMetricOptions = (metricList, catLabel) => {

	// filters items in metricList to those belong to category catLabel
	// reformats metric options as list for use in resultsSelect component
	if(!metricList || !catLabel) {
		return [{'value': null, 'label': 'Select Category'}]
	}

	metricList = Helper.filterObjList(metricList, 'cat', catLabel)
	// const optionList = [{value:null, label:'Select Metric'}];
	const optionList = [];

	for(let mm=0; mm<metricList.length; mm++) {
		let newOption = {
			'value': metricList[mm].name,
			'label': metricList[mm].label
		}

		optionList.push(newOption)
	}

	return optionList
}


const getMetricFromKey = (metricList, metricKey) => {
	// returns metric obj from list of metric objects
	// where metric.name == metricKey
	// uses generic helper function

	if(!metricList || !metricKey) {
		return null
	}

	var filteredList = Helper.filterObjList(metricList, 'name', metricKey)

	return filteredList[0]

}

const filterFirst = (data, filterStr) => {
	// filters obj and returns only items where filterStr is keyname of first level obj

	let allKeys = Object.keys(data);

	let returnKeys = allKeys.filter(s => s.includes(filterStr));

	let filteredData = _.pick(data, returnKeys);

	return filteredData

}

const filterMetricCat = (data, metricList, cat) => {
	// filters metrics data based on category string 

	// filters items in metricList to those belong to category catLabel
	// reformats metric options as list for use in resultsSelect component
	if(!data || !metricList || !cat) {
		return null
	}

	metricList = Helper.filterObjList(metricList, 'cat', cat)

	// erduce metric list to names only
	metricList = metricList.map((metric) => (
		metric.name
	))

	let filteredData = _.pick(data, metricList);

	return filteredData	

}

const transposeData = (data) => {

	// reverses the order of keys of 2-layer nested object

	if(!data) {
		return null
	}

	let firstKeyList = Object.keys(data)
	let secKeyList = Object.keys(data[firstKeyList[0]])
	var transData = {}

	for(let ii=0; ii<firstKeyList.length; ii++){

		let firstKey = firstKeyList[ii];

		for(let jj=0; jj<secKeyList.length; jj++){

			let secKey = secKeyList[jj];

			// initialize transData[secKey] if it doesn't already exist
			if(!transData[secKey]){
				transData[secKey] = {}
			}

			// copy value from original data to transData
			transData[secKey][firstKey] = data[firstKey][secKey]
		}
	}

	return transData

}

const unpackInvData = (data) => {

	// return nothing if no data passed
	if(!data) {
		return null
	}

	// convert the scen-indexed obj into list of dict-like objs
	var dataList = []

	// get list of scenarios
	var scenKeys = Object.keys(data)

	for(let ss=0; ss<scenKeys.length; ss++) {

		let scen = scenKeys[ss]
		// proceed only if scenarios is 'new'
		if(scen.indexOf('new') !== -1) {

			let scenData = data[scen]
			// loop through data, adding scen key and data
			for(let dd=0; dd<scenData.length; dd++) {
				let dataItem = scenData[dd]

				dataItem['scen'] = scen
				// dataItem['der'] = 0
				// dataItem['outage'] = 0
				// dataItem['derLabel'] = 0
				// dataItem['outageLabel'] = 0

				// add dataItem to flat list
				dataList.push(dataItem)
			}
		}

	}

	// return flat list
	return dataList

}

const processMonTable = (data) => {
	// reorganizes filtered flat-list data for Inv plotting

	// return nothing if no data passed
	if(!data) {
		return null
	}

	// init objs to return
	var dataObjNew = {}
	var dataObjEx = {}

	// get list of scenarios from data keys
	var scenarios = Object.keys(data)

	// loop through data items
	for(let ss=0; ss<scenarios.length; ss++) {

		let scen = scenarios[ss];
		
		// loop through months
		for(let mm=0; mm<12; mm++){
			// get 3-char month label
			let mon = monLabels[mm]


			// process data if scen label contains 'new'
			if(scen.indexOf('new') !== -1){
				
				// check if scen entry exists in dataObj
				if(!dataObjNew[scen]) {
					// if missing, initialize as empty obj
					dataObjNew[scen] = {}
				}

				dataObjNew[scen][mon] = data[scen][mm]
			}

			// process data if scen label contains 'ex'
			if(scen.indexOf('ex') !== -1){
				if(!dataObjEx[scen]) {
					// if missing, initialize as empty obj
					dataObjEx[scen] = {}
				}
				dataObjEx[scen][mon] = data[scen][mm]
			}


		}

	}

	return {'ex': dataObjEx, 'new':dataObjNew}
}


const processInvPlot = (data) => {
	// reorganizes filtered flat-list data for Inv plotting

	// return nothing if no data passed
	if(!data) {
		return null
	}

	// init obj to return
	var dataObj = {}

	// loop through data items
	for(let dd=0; dd<data.length; dd++) {

		// extract scen key, der type, and value
		let scen = data[dd].scen;
		let der = data[dd].type;
		let val = parseInt(data[dd].val)

		// check if scen entry exists in dataObj
		if(!dataObj[scen]) {
			// if missing, initialize as empty obj
			dataObj[scen] = {}
		}

		// add der-val pair to obj
		dataObj[scen][der] = val

	}

	return dataObj
}

const processTsPlotData = (data, metricList, metaData) => {
	// function to transform ts data into format that can be plotted
	// in recharts timeseries plot

	// return nothing if no data passed
	if(!data) {
		return null
	}

	// initialize plotData as list of obj, where obj.name equals hour num
	var plotData = [];
	for(let hh=1; hh<=24; hh++){
		plotData.push({name:hh.toString()})
	}

	// get list of all metrics in data input
	var allMetrics = Object.keys(data);

	// loop through metrics and hours
	for(let mm=0; mm<allMetrics.length; mm++) {

		let curMetric = allMetrics[mm];

		// use curMetric to extract the formal name of the metric from the metaData
		
		var metricLabel = curMetric
		try {
			metricLabel = Helper.filterObjList(metaData, 'name', curMetric)
			metricLabel = metricLabel[0].label
		} catch {

		}

		// only proceed in current metric is in metricList
		if(metricList.includes(curMetric)){

			for(let hh=0; hh<24; hh++){
				// move value from input data to output data
				plotData[hh][metricLabel] = parseFloat(data[curMetric][hh]);
			}

		}
	}

	return plotData
}

const processTsTableData = (data, metricList, metaData) => {
	// function to transform ts data into format that can be displayed in table

	// return nothing if no data passed
	if(!data) {
		return null
	}

	// initialize plotData as list of obj, where obj.name equals hour num
	var plotData = [];
	for(let hh=1; hh<=24; hh++){
		plotData.push({name:hh.toString()})
	}

	// get list of all metrics in data input
	var allMetrics = Object.keys(data);

	// loop through metrics and hours
	for(let mm=0; mm<allMetrics.length; mm++) {

		let curMetric = allMetrics[mm];

		// use curMetric to extract the formal name of the metric from the metaData
		
		var metricLabel = curMetric
		try {
			metricLabel = Helper.filterObjList(metaData, 'name', curMetric)
			metricLabel = metricLabel[0].label
		} catch {

		}

		// only proceed in current metric is in metricList
		if(metricList.includes(curMetric)){

			for(let hh=0; hh<24; hh++){
				// move value from input data to output data
				plotData[hh][metricLabel] = parseFloat(data[curMetric][hh]);
			}

		}
	}

	return plotData
}



export { convertAnnDataForPlot, convertMonDataForPlot, processMonTable, processTsPlotData, processTsTableData,
	     getMetricOptions, getMetricFromKey, transposeData,
	     filterFirst, filterMetricCat, unpackInvData, processInvPlot, unpackAnnData, unpackMonData }