import React from 'react';

import Helper from '../../helper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

import ResultsTable from '../ResultsTable/ResultsTable';
import ChartTimeseries from '../ChartTimeseries/ChartTimeseries'
import ToggleSection from '../ToggleSection/ToggleSection'
import ResultsSelect from './ResultsSelect'

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';

import { convertAnnDataForPlot, convertMonDataForPlot, processMonTable, processTsPlotData, processTsTableData,
				 getMetricOptions, getMetricFromKey, transposeData,
				 filterFirst, filterMetricCat, processInvPlot, unpackInvData, unpackAnnData, unpackMonData
			 } from './resultsProcessing'

const _ = require('lodash');


const tsColorMap = {
	lt_gray: '#a4b3c2',
	dk_gray: '#5c7389',
	blue: '#3680ca',
	green: '#006400',
	gold: '#e1af33',
	orange: '#da7720',
	red: '#da3920',
}


class SectionTs extends React.Component {

	constructor(props){
		super(props)



		this.renderSection = this.renderSection.bind(this);
	}


	lookupLabelFromValue(val, dataList) {
		// returns the "label" field from object where "value" matches val

		let matches = Helper.filterObjList(dataList, 'value', val)

		if( matches.length>0){
			return(matches[0].label)
		} else {
			return null
		}
	}


	constructScenLabel() {
		// function creates a nice formatted string for displaying current scenario
		// {Inv} - {Outage}
		// {Month} - {DayType}

		return (
			<div className='res-ts-scen-label'>
				<h2 className='res-ts-scen-label-text'>
					Scenario: &nbsp;
					{this.lookupLabelFromValue(this.props.keyTsInv, this.props.investmentOptions)} -&nbsp; 
					{this.lookupLabelFromValue(this.props.keyTsOutage, this.props.outageOptions)} - &nbsp;
					{this.lookupLabelFromValue(this.props.keyTsMon, this.props.monthOptions)} - &nbsp;
					{this.lookupLabelFromValue(this.props.keyTsDaytype, this.props.daytypeOptions)}
				</h2>
			</div>
		)
	}

	formatTableData(tableCat) {
		// formats a ts table based on keys
		// for tableCat = 'supply' or 'dmd'

		// first isolate data from keys
		if(this.props.keyTsInv && this.props.keyTsOutage && 
			 this.props.keyTsMon && this.props.keyTsDaytype) {
			var scenKey = `${this.props.keyTsInv}_${this.props.keyTsOutage}`
			var tsData = this.props.rawTs[scenKey][this.props.keyTsMon][this.props.keyTsDaytype]
			
			// if nothing found when subsetting scenario/month/daytype then return null
			if(!tsData){
				return null
			}

			// get supply and demand metrics from state var metaTs
			var metaTsTable= Helper.filterObjList(this.props.metaTs, 'type', tableCat);

			// isolate name and labels of metric for filtering
			var metricList = metaTsTable.map((meta)=>({'name':meta.name, 'label':meta.label}));

			// init reformatted dict
			var tsDataTable = {}
			// loop through metrics to consider
			for(let mm=0; mm<metricList.length; mm++) {
				let mName = metricList[mm].name
				let mLabel = metricList[mm].label

				// find data in raw ts data
				let mData = tsData[mName]
				let mDataDict = {}

				// convert mData array to hour-indexed obj
				for(let hh=0; hh<24; hh++){
					mDataDict[hh+1] = mData[hh]
				}

				tsDataTable[mLabel] = mDataDict
			}

			// console.log(metricList)
			// console.log(tsDataTable)

			return tsDataTable

		}
		

	}

	checkDaytypeData(tsPlotData){
		// function to check if data for plot is empty

		// if tsPlotsData is null, then return false
		if(!tsPlotData) {
			return false
		}

		// loop through keys
		const dataKeys = Object.keys(tsPlotData)
		for(let kk=0; kk<dataKeys.length; kk++) {

			var curKey = dataKeys[kk]

			if(curKey==='PVpotential' || curKey=='PVused') {
				// skip pv potential, as it shows up even when other data is missing
				continue
			}

			var sumData = tsPlotData[curKey].map(Number)
			sumData = _.sum(sumData);

			console.log(`sum of ${curKey} is ${sumData}`)

			if (sumData > 0){
				return true
			}
		}

		// if no non-zero data found after loop, return false
		return false
	}


	renderSection() {

		// initialize ts plot data to empty
		var tsPlotData = null;
		var tsPlotDataSupply = null;
		var tsPlotDataDemand = null;

		// select data if all keys are set
		if(this.props.keyTsInv && this.props.keyTsOutage && 
			 this.props.keyTsMon && this.props.keyTsDaytype) {
			var scenKey = `${this.props.keyTsInv}_${this.props.keyTsOutage}`
			tsPlotData = this.props.rawTs[scenKey][this.props.keyTsMon][this.props.keyTsDaytype]

			console.log(tsPlotData)

			const tsDataExists = this.checkDaytypeData(tsPlotData)

			// if tsPlotData is empty, return text explaination
			if(!tsDataExists){
				var sectionContent = (
					<div className='results-ts-empty'>
						<ErrorIcon/>
						<h4> 
							No data found for this scenario, month, and day-type.<br/>
							Please update selection to view timeseries profiles.
						</h4>
					</div>
				)
			} else {

				// get supply and demand metrics from state var metaTs
				var metaTsSupply = Helper.filterObjList(this.props.metaTs, 'type', 'supply');
				var metaTsDemand = Helper.filterObjList(this.props.metaTs, 'type', 'demand');

				// isolate name of metric for filtering
				var metricListSupply = metaTsSupply.map((meta)=>(meta.name));
				var metricListDemand = metaTsDemand.map((meta)=>(meta.name));

				tsPlotDataSupply = processTsPlotData(tsPlotData, metricListSupply, this.props.metaTs)
				tsPlotDataDemand = processTsPlotData(tsPlotData, metricListDemand, this.props.metaTs)

				// console.log(scenKey)
				// console.log(this.props.keyTsMon)
				// console.log(this.props.keyTsDaytype)
				// console.log(this.props.rawTs)
				// console.log(tsPlotData)
				// console.log(metricListSupply)
				// console.log(metricListDemand)

				var sectionContent = (
					<>
					<h2 className='results-heading-2'>Electricity Demand Profile</h2>	
					<div className='results-chart-container'>
						< ChartTimeseries 
							data={tsPlotDataDemand}
							meta={metaTsDemand}
							colors={tsColorMap}
						/>
					</div>
					<br/>
					<h2 className='results-heading-2'>Electricity Supply Profile</h2>
					<div className='results-chart-container'>
						< ChartTimeseries 
							data={tsPlotDataSupply}
							meta={metaTsSupply}
							colors={tsColorMap}
						/>
					</div>
					<br/><br/>

					<ToggleSection
						label='Timeseries Data Tables'
					>

						<div className='results-subsection'>
							<h3 className='chart-title'>Power Demand - Daily Profile</h3>
							<ResultsTable
								data={this.formatTableData('demand')}
							/>
						</div>

						<div className='results-subsection'>
							<h3 className='chart-title'>Power Supply - Daily Profile</h3>
							<ResultsTable
								data={this.formatTableData('supply')}
							/>
						</div>


						<div className='results-note-table'>
							<p>
								Note: data tables can be highlighted, copied, and pasted
								into Excel or other spreadsheet applications.
								<br/><br/>
								These tables show data for the metric selected above.
								They will update if a new metric is selected.
							</p>			
						</div>
					</ToggleSection>
					</>
				)

			}			

		}



		return(
			<div className='results-section'>
				<h2 className='results-heading'>5. Timeseries data</h2>
				
					

				<ResultsSelect 
					name='keyTsInv'
					label='Investment Scenario'
					onChange={this.props.updateSelectSingle}
					optionList={this.props.investmentOptions}
					minWidths={['160px', '160px']}
				/>
				<ResultsSelect 
					name='keyTsOutage'
					label='Resilience Scenario'
					onChange={this.props.updateSelectSingle}
					optionList={this.props.outageOptions}
					minWidths={['160px', '160px']}
				/>
				<ResultsSelect 
					name='keyTsMon'
					label='Month'
					onChange={this.props.updateSelectSingle}
					optionList={this.props.monthOptions}
					minWidths={['160px', '160px']}
				/>
				<ResultsSelect 
					name='keyTsDaytype'
					label='Day-type'
					onChange={this.props.updateSelectSingle}
					optionList={this.props.daytypeOptions}
					minWidths={['160px', '160px']}
				/>

				{this.constructScenLabel()}

				{sectionContent}
					
			</div>
		
		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Timeseries Data'
			/>
		)
	}

}

export default SectionTs;