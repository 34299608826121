import React from 'react';

import Helper from '../../helper';

import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';
import { ReactComponent as CaretSolidIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as CircleIcon } from '../../icons/circle.svg';
import { ReactComponent as CircleOutlineIcon } from '../../icons/circleOutline.svg';


class CatBox extends React.Component{
	// collapsible box for table categories in sidepanel

	constructor(props) {
		super(props)

		this.state = {
			expanded: false
		}

		this.renderContent = this.renderContent.bind(this);
		this.toggleExpand = this.toggleExpand.bind(this);

	}

	toggleExpand() {
		this.setState({expanded:!this.state.expanded})
	}

	renderContent() {
		// method renders the tables in category, if expanded
		// else returns nothing
		if(this.state.expanded){
			return(
				<div className='cat-box-content'>
					<ul className='cat-box-list'>
						{this.props.children}
					</ul>
				</div>

			)
		} else {
			return null
		}
	}

	render() {

		var curIcon = this.state.expanded ? <CaretSolidIcon/> : <ChevronIcon/>

		return(
			<div className='cat-box'>
				<div className='cat-box-label' onClick={this.toggleExpand}>
					<div className='cat-box-label-icon'>
						{curIcon}
					</div>
					<h4>{this.props.catLabel}</h4>
				</div>

				{this.renderContent()}

			</div>
		)
	}

}

class SidePanel extends React.Component{ 

	getListOfCategories(tableList) {

	}

	generateSublistHtml(tableSubList, catLabel) {
		// method generates header and list of linked tables
		// for the category sublist passed as arg

		var props = this.props

		// generate item for each table in list
		var paramHtml = tableSubList.map((paramItem)=>{


			// select class name, and icon depending on if table is currently selected
			if(paramItem.index===this.props.tableIndex){
				var tableClassName = 'proj-param-list-item proj-param-list-item-active'
				var tableIcon = (
					<div className='proj-param-list-item-icon'>
						<CircleIcon/>
					</div>
				)
			} else {
				var tableClassName = 'proj-param-list-item'
				var tableIcon = (
					<div className='proj-param-list-item-icon proj-param-list-item-icon-hidden'>
						<CircleOutlineIcon/>
					</div>
				)
			}
			
			// run eval on paramItem.displayKey to see if item should be displayed
			if(!eval(paramItem.displayKey)) {
				return null
			}

			// return nothing if hide attr is true
			if(paramItem.hide) {
				return null
			}

			// otherwise, return list item with param details
			return (

				<li 
					className={tableClassName}
					onClick={()=>{this.props.goToTable(paramItem.index)}}
				>	
					{tableIcon}
					{paramItem.label}
				</li>
			)

		})

		// remove all null items from paramHtml
		paramHtml = paramHtml.filter(function(val){if(val)return val})

		// if sublist if empty, return nothing
		if (paramHtml.length === 0) {
			return
		}

		// return(
		// 	<>
		// 	<h4 className='proj-param-list-title'>{catLabel}</h4>
		// 	<ul className='proj-param-list'>
		// 		{paramHtml}
		// 	</ul>
		// 	</>
		// )

		return(
			<CatBox
				catLabel={catLabel}
			>
				{paramHtml}
			</CatBox>
		)
	}



	render() {

		// return nothing if tableList not passed
		if(!this.props.tableList) {
			return null
		}

		// intialize html of table list
		const tableListHtml = []
		tableListHtml.push(<div className='cat-box-spacer'></div>)

		// extract table data from props
		const tableList = this.props.tableList

		// get list of categories
		const catList = [...new Set(tableList.map(item => item.cat))];

		// console.log('heres the full catergory list')
		// console.log(catList)

		// for each category
		for(let cc=0; cc< catList.length; cc++){

			// init new data list to add to panel
			let newHtml = [];
			let visibleData = false;

			// get current category name
			var curCat = catList[cc]

			// console.log(`current cat is ${curCat}`)

			// filter table data to get only current category
			var catTableList = Helper.filterObjList(tableList, 'cat', curCat)

			// get list of subcategories in current cat
			var subcatList = [...new Set(catTableList.map(item => item.subcat))];

			// remove whitespace only subcats from list
			subcatList = subcatList.filter(function(entry) { return /\S/.test(entry); });

			// console.log(`${subcatList.length} found`)

			// if subcategories found, generate boxes for each sub cat
			if(subcatList.length > 0){
				
				// console.log(`current sub cat is ${subcatList}`)
				// add category title to html list

				let sectionTitle = <h4 className='cat-list-sub-title'>{curCat}</h4>
				newHtml.push(sectionTitle)

				// for each subcat
				for(let ss=0; ss< subcatList.length; ss++){

					// get current subcategory name
					var curSubcat = subcatList[ss];

					// filter sublist of tables in that category
					var subcatTableList = Helper.filterObjList(catTableList, 'subcat', curSubcat)

					// generate html for tables in that sublist
					var catListHtml = this.generateSublistHtml(subcatTableList, curSubcat)

					// check if catListHtml contains any new visible tables
					if (catListHtml){
						visibleData = true;
					}

					// console.log('adding data')
					// console.log(catListHtml)

					// add to list of items
					newHtml.push(catListHtml)
				}

				newHtml.push(<div className='cat-box-spacer'></div>)

			} else {
				// otherwise, generate html for full categorey
				var catListHtml = this.generateSublistHtml(catTableList, curCat)

				// check if catListHtml contains any new visible tables
				if (catListHtml){
					visibleData = true;
				}

				// add to list of items
				newHtml.push(catListHtml)
				newHtml.push(<div className='cat-box-spacer'></div>)



				// console.log(`adding simple data for ${curCat}`)

				// console.log('adding data')
				// console.log(catListHtml)
			}

			// add data to table if any table data was found
			if(visibleData){
				tableListHtml.push(newHtml)
			}
		}

			

		return(
			<div className='project-side-panel'>
				<h4 className='cat-list-title'> Select Table to Edit</h4>
				{tableListHtml}

				<br/>
			</div>
		)
	}
	
} 


export default SidePanel;