import React from 'react';


class NewSent extends React.Component{ 

  

  render(){ 
    return( 
      <div className="acct-msg-container">
        <h2 className="account-h2"> New Account Request Successful </h2>

        <p className="account-text"> 
          Your request has been received. An activation
          link has been created and sent to the email address provided.

          Please check for the activation message from address &nbsp;
          <span className="account-highlight">dercam.mailer@gmail.com</span>. 
          <br/><br/> 
          If you do not receive a message after 1-2 hours, please contact support
          at &nbsp;
          <span className="account-highlight">dercam@lbl.gov</span>. 
          <br/><br/> 
          You may safely close this window or leave this page.
        </p>
      </div>
    ) 
  } 
} 


export default NewSent;