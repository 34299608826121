import React from 'react';
import { withRouter } from 'react-router-dom';

import SidePanel from './SidePanel';
import InputTableFlex from './InputTableFlex';

import Helper from '../../helper';
import ProjectUtils from '../../ProjectUtils';

import NavbarDercam from '../NavbarDercam/NavbarDercam';
import Modal from '../Modal/Modal';

import ProjectPageIntro from './ProjectPageIntro';
import ProjectPageConfig from './ProjectPageConfig';
import ProjectPageLoads from './ProjectPageLoads';
import ProjectPageSolar from './ProjectPageSolar';
import ProjectPageNetwork from './ProjectPageNetwork';
import ProjectPageTables from './ProjectPageTables';
import ProjectPageSubmit from './ProjectPageSubmit';


import { ReactComponent as LoadIcon } from '../../icons/loading.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

import './NewProjectDercam.css';

const _ = require('lodash');


class NewProjectDercam extends React.Component{ 
	constructor(props) {
		super(props)

		this.state = {

			// // state vars related to current editing mode
			// introPage: true,
			// configurePage: false,
			// loadsPage: false,
			// solarPage: false,
			// networkPage: false,
			// tablesPage: false,
			// submitPage: false,

			dowList: ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'],
			endUseLabels: [
				'Elec', 'Cool', 'Heat', 'Water', 'Ref', 'Ng'
			],
			dayTypeList: ['week', 'peak', 'weekend'],

			// refactoring page state
			currentPage: 'introPage' ,
			pageList: [
				'introPage',
				'configurePage',
				'loadsPage',
				'solarPage',
				'networkPage',
				'tablesPage',
				'submitPage'
			],

			// state vars related to project submission
			submitPending: false,
			submitSuccess: false,
			submitFailure: false,
			submitDelay: 1000, 

			// project input data
			tableIndex: 0,
			projData: null,
			tableList: [],

			// config project options
			projName: '',
			nNodes: 1,
			nNodesMax: 20,
			nodeListAll: Helper.intRange(1, 20),
			nodeList: Helper.intRange(1, 1),
			multiNode: false,

			// input binaries
			optDerSolar: true,
			optDerStorage: true,
			optDerGensets: true,
			// optDerLFGensets: false,
			optDerCentralTech: false,
			optDerEnergyConv: false,
			optDerWindHydro: false,
			optDerEvs: false,
			optRes: false,
			optDmdMgmt: false,
			optAS: false,
			optThermalLoop: false,
			optEE: false,
			
			// bldg load selecter vars
			loadList: [],
			bldgList: [],
		    vintageList: [], 
		    climateList: [],
		    selectedBldg: null,

		    // solar selecter vars
			pvList: [],
			pvStateMap: {},
			stateList: [],
		    stationList: ['Solar-station list not loaded'],
			selectedPv: null,

			pvAngleList: [], 
	    	pvOrientList: [],

	    	// state vars for pv importer
	    	pvAngle: '', 
	    	pvOrient: '',

			loc_stateUs: '',
			loc_station: '',
			loc_stationId: -999,

			// state vars for load importer
			bldg_type: null,
			bldg_vint: null,
			bldg_climateZone: null,
			bldg_scaleBin: true,
			bldg_annElec: null,
			bldg_annNg: null,
			bldg_bldgId: -999,
			bldg_node: 1,

			// bldg load 8760 vars
		   	bldg_initDow: 'Su',
			bldg_tsElec: null,
			bldg_tsCool: null,
			bldg_tsHeat: null,
			bldg_tsWater: null,
			bldg_tsRef: null,
			bldg_tsNg: null,	
			bldg_tsDaytype: null,

			bldg_dataElec: null,
			bldg_dataCool: null,
			bldg_dataHeat: null,
			bldg_dataWater: null,
			bldg_dataRef: null,
			bldg_dataNg: null,	
			bldg_dataDaytype: null,

			bldg_lenElec: null,
			bldg_lenCool: null,
			bldg_lenHeat: null,
			bldg_lenWater: null,
			bldg_lenRef: null,
			bldg_lenNg: null,	
			bldg_lenDaytype: null,

			isFormatted: false,
			bldg_tsAll: []
			
			
		}

		this.submitProject = this.submitProject.bind(this);
		this.retryProject = this.retryProject.bind(this);
		this.incrementTable = this.incrementTable.bind(this);
		this.goToTable = this.goToTable.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleExit = this.handleExit.bind(this);
		this.handleChangeNodesNum = this.handleChangeNodesNum.bind(this);
		
		this.handleChangeBldg = this.handleChangeBldg.bind(this);
		this.handleChangePvStation = this.handleChangePvStation.bind(this);
		this.process8760 = this.process8760.bind(this);
		this.format8760 = this.format8760.bind(this);

		this.handleTableChange = this.handleTableChange.bind(this);
		this.handleTableCellExit = this.handleTableCellExit.bind(this);
		this.replaceTable = this.replaceTable.bind(this);
		this.revertTable = this.revertTable.bind(this);

		this.activatePage = this.activatePage.bind(this);
		this.toggleScaling = this.toggleScaling.bind(this);

		this.goToProjects = this.goToProjects.bind(this);
		this.packageProject = this.packageProject.bind(this);

		this.writeNetworkModel = this.writeNetworkModel.bind(this);

	}

	async componentDidMount() {

		// extract default data from template json
		var templateData = _.cloneDeep(require('./tables.json'));

		var projData = templateData.data;
		var projDataDefault = _.cloneDeep(projData);
		var tableList = templateData.tables

		// when new project component mounts, check if saved project is passed in props
		if(this.props.dercamProj){
			// if dercamProj is passed in props, use passed proj
			var copiedData  = this.props.dercamProj
			console.log('existing project passed')

			const stateUpdate = {}

			// update meta data options
			for (const key in copiedData.meta) {
				// if key exists in state, copy from meta to state
				if (key in this.state){
					stateUpdate[key] = copiedData.meta[key]
				}

				// workaround for name/projName inconsistency
				if (key === 'name'){
					stateUpdate['projName'] = copiedData.meta[key]
				}
			}

			// write copied metadata to state
			await this.setState(stateUpdate)

			// copy table data to projData
			for (const key in copiedData.input) {
				// if key exists in state, copy from meta to state
				if (key in projData){
					projData[key] = copiedData.input[key]
				}
			}
		}

		// add table index to list of tables
		// index generated dynamically so that if tables added/dropped no manaul update required
		for(let tt=0; tt<tableList.length; tt++){
			var tableItem = tableList[tt]
			tableItem.index = tt;
			tableList[tt] = tableItem
		}

		// get list of ref bldg loads
		var [loadList, bldgList, vintageList, climateList] = await Helper.getLoadProfileList();

		// fetch list of pv station data from server	
		// along with states and pv-state-station map obj	
		var [pvList, stateList, pvStateMap, pvAngleList, pvOrientList] = await Helper.getPvDataList();
	    
		// station list starts as null until state is selected
	    // stationList = pvList.map(a => a.STATION);
	    var stationList = ['Select state to see station list']

	    // get template of load input for load creator
	    var loadTemplate = _.cloneDeep(projData.loadinput_n1_p)
	    var pvTemplate = _.cloneDeep(projData.normalizedpvoutput)

		this.setState({
			projData: projData,
			projDataDefault: projDataDefault,
			tableList: tableList,
			// bldg load data
	    	loadList: loadList,
	    	bldgList: bldgList,
	    	vintageList: vintageList, 
	    	climateList: climateList,
	    	// solar location data
	    	pvList: pvList,
	    	pvStateMap: pvStateMap,
	    	stateList: stateList,
	    	stationList: stationList,
	    	pvAngleList: pvAngleList, 
	    	pvOrientList: pvOrientList,
	    	// templates for importer menus
	    	loadTemplate: loadTemplate,
	    	pvTemplate: pvTemplate
		})


	}

	componentWillUnmount() {
		// save current project data on unmount, if not in submitSuccess state
		if(!this.state.submitSuccess){

			// package project data/meta data for save
			let model = this.packageProject()

			this.props.saveProject('dercamProj', model)
			console.log('saving current project data')
		} else {
			console.log('project submitted. clearing project data')
		}
	}

	getAlteredTables(tableData){

		// extract default data from template json
		var templateData = _.cloneDeep(require('./tables.json'));
		templateData = templateData.data

		// intialize obj with altered tables
		var alteredTables = {}

		// loop through keys
		var tableKeys = Object.keys(templateData)

		for(let kk=0; kk<tableKeys.length; kk++) {

			let curKey = tableKeys[kk]

			if(!_.isEqual(tableData[curKey], templateData[curKey])){
				// console.log(`table delta: ${curKey}`)

				alteredTables[curKey] = tableData[curKey]
			}
		}

		return alteredTables
	}

	activatePage(activePage) {

		// turns all items off, then turns on active page
		// console.log(`changing active page to: ${activePage}`)

		// check if pass activePage is in list of acceptable pages
		if(this.state.pageList.includes(activePage)) {
			// if pass page in list, switch to that page
			this.setState({currentPage: activePage})
		} else {
			// otherwise, switch to first page in acceptable pageList
			this.setState({currentPage: this.state.pageList[0]})
		}

		// check if project has been named, if on submission page
		var pageIndex = this.state.pageList.indexOf(activePage);

		// if current page is later than page 2 (configuration), generate a random project name
		if(pageIndex > 1){
			if (!Helper.projectNameValid(this.state.projName)){
				// if name is missing, generate new generic name
				var projStr = Helper.genString(6);
				var projName = `Project-${projStr}`

				this.setState({projName: projName})
			}
		}

	}

	packageProject() {
		// packages table data and meta data for submit or saving

		// get alter-tables only for submission
		const submitData = this.getAlteredTables(this.state.projData)

		// package user input table data and meta data into 'model' object
		let model = {
			input: submitData,
			meta: {
				name: this.state.projName,

				multiNode: this.state.multiNode,
				nNodes: this.state.nNodes,

				optDerSolar: this.state.optDerSolar,
				optDerStorage: this.state.optDerStorage,
				optDerGensets: this.state.optDerGensets,
				optDerCentralTech: this.state.optDerCentralTech,
				optDerEnergyConv: this.state.optDerEnergyConv,
				optDerWindHydro: this.state.optDerWindHydro,
				optDerEvs: this.state.optDerEvs,
				optRes: this.state.optRes,
				optDmdMgmt: this.state.optDmdMgmt,
				optAS: this.state.optAS,
				optThermalLoop: this.state.optThermalLoop,
				optEE: this.state.optEE,
			}

		}

		return model

	}

	async submitProject() {

		// set submitPending to true
		await this.setState({submitPending: true})

		// packge project for submission
		let model = this.packageProject()

	    let urlNewModel = `${process.env.REACT_APP_URL_BASE}/json/dercam/model`;
	    let data = {
	    	name: this.state.projName,
	    	model: model
	    }
	    let response = await fetch(urlNewModel, {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json;charset=utf-8'
	      },
	      credentials: 'include',
	      body: JSON.stringify(data)
	    });

	    // if status GET request successful
	    console.log(response)
	    if(response.status===201) {
	      console.log('new model posted')

	      await Helper.sleep(this.state.submitDelay);

	      await this.setState({submitSuccess: true, submitPending:false})

	      // if project submit successful, clear saved project data from App compoent
	      await this.props.clearSavedProject('dercamProj')

	    } else {
	      console.log('new model post failed')

	      await this.setState({submitFailure: true, submitPending:false})
	    }
	}

	retryProject() {
		this.setState({
			submitPending: false,
			submitSuccess: false,
			submitFailure: false
		})
	}

	incrementTable(delta) {

		var tableIndex = this.state.tableIndex
		// add given delta to current page index
		tableIndex += delta
		// ensure new index is between zero and max allowed
		tableIndex = Math.max(0, tableIndex)
		tableIndex = Math.min(tableIndex, this.state.tableList.length-1)

		// write new tableIndex to state
		this.setState({tableIndex:tableIndex})

	}

	goToTable(tableIndex) {

		// ensure new index is between zero and max allowed
		tableIndex = Math.max(0, tableIndex)
		tableIndex = Math.min(tableIndex, this.state.tableList.length-1)

		// write new tableIndex to state
		this.setState({tableIndex:tableIndex})

	}

	handleChange(event){ 
		// console.log(`handling ${event.target.name} to ${event.target.value}`)

		// init obj for state update
		const stateUpdate = {}

		// update form data with changed value
		if(event.target.dataset.type==='bin'){
			// use 'checked' attr if change field is checkbox
			stateUpdate[event.target.name] = event.target.checked;

		} else {
			// use 'value' attr if change field is not checkbox
			stateUpdate[event.target.name] = event.target.value;
		}

	    //update state to reflect change
	    this.setState(stateUpdate)

	}

	toggleScaling(event) {
		// handler used for toggling scaling factors

		this.setState({bldg_scaleBin: !this.state.bldg_scaleBin})

	}

	handleExit(event) {
		// method handles exit from input cell by checking
		// that entered data conforms to type
		// console.log(`handling exit from ${event.target.name} with type ${event.target.dataset.type}`)

		// get checked value 
		var val = event.target.value;
		var valType = event.target.dataset.type;
		const checkedVal = Helper.checkByType(val, valType)

		
		// set state with new value
		this.setState({[event.target.name]: checkedVal});

	}

	handleTableChange(event) {

		let projData = this.updateTableDataGeneric(event)
		// set state with new value
		this.setState({projData: projData});

	}

	revertTable(tableKey) {
		// method reverts single data table back to default values
		
		// get default data from state using input tablekey
		var defData = _.cloneDeep(this.state.projDataDefault[tableKey]);

		// get current modified project data from state
		var projData = this.state.projData;

		// update projData with reverted default table
		projData[tableKey] = defData;

		// update state
		this.setState({projData: projData});

		console.log(`reverting table ${tableKey}`)

	}

	handleTableCellExit(event) {
		// method handles exit from input cell by checking
		// that entered data conforms to type

		// get checked value 
		var val = event.target.value;
		var valType = event.target.dataset.type;
		const checkedVal = Helper.checkByType(val, valType)

		// update event.target table using checkedVal
		let projData = this.updateTableDataGeneric(event, checkedVal)
		
		// set state with new value
		this.setState({projData: projData});

	}


	async handleChangePvStation(event){ 
		
		// set state for new change
		await this.setState({ [event.target.name]: event.target.value });

		// find solar station based on selectors
	    var { pvStateMap, pvList, loc_stateUs, loc_station } = this.state
	    var [ stationList, selectedPv, loc_stationId ] = Helper.getSelectedPv(pvStateMap, pvList, loc_stateUs, loc_station)

	    //update state to reflect change
	    this.setState({
	    	selectedPv: selectedPv,
	    	stationList: stationList,
	    	loc_stationId: loc_stationId
	    })
	
	}

	async handleChangeBldg(event){ 

		// set state for new change
		await this.setState({ [event.target.name]: event.target.value });

		// extract bldg selector fields from state
		var { bldg_type, bldg_vint, bldg_climateZone } = this.state;

		// initialize empty object for state update
		var stateUpdate = {}		

		// use selectors to find bldg id and properties from loadList
		var [selectedBldg, bldg_bldgId, bldg_size, bldg_annElec, bldg_annNg] = Helper.getSelectedBuilding(
																this.state.loadList, bldg_type, bldg_vint, bldg_climateZone)
		// update fields after selection
		stateUpdate.bldg_bldgId = bldg_bldgId;
		stateUpdate.bldg_size = bldg_size;
		stateUpdate.bldg_annElec = bldg_annElec;
		stateUpdate.bldg_annNg = bldg_annNg;
		stateUpdate.selectedBldg = selectedBldg;

	    //update state to reflect change
	    this.setState(stateUpdate)
	
	}

	handleChangeNodesNum (event){ 
		// method to change the number of nodes in the model
		// and the array of nodes for assigned

		// checks if multinode option is enbabled/disabled
		// a restricts nodes displayed accordingly

		// init obj for state update
		const stateUpdate = {}

		const projData = this.state.projData

		// update if node number changed
		if(event.target.name === 'multiNode'){

			// use 'checked' attr if change field is checkbox
			stateUpdate.multiNode = event.target.checked;

			// update projData table to enable powerflow
			let multiNode = Number(stateUpdate.multiNode)
			projData.powerflowoptions = Helper.updateTableObj(projData.powerflowoptions, 'Enable', multiNode)

			if(!event.target.checked){
				// if not checked, set nNode and nodeList to 1, [1]
				stateUpdate.nNodes = 1
				stateUpdate.nodeList = Helper.intRange(1, 1)
				projData.powerflowparams = Helper.updateTableObj(projData.powerflowparams, 'NoOfNodes', 1)
			}

		} else if (event.target.name === 'nNodes'){
		
			// if not checked, set nNode and nodeList to 1, [1]
			stateUpdate.nNodes = event.target.value
			stateUpdate.nodeList = Helper.intRange(1, event.target.value)

			// update projData to match NoOfNodes
			projData.powerflowparams = Helper.updateTableObj(projData.powerflowparams, 'NoOfNodes', parseInt(event.target.value))

		}

		stateUpdate.projData = projData

	    //update state to reflect change
	    this.setState(stateUpdate)

	}

	async process8760() {
		// method converts user-provided data string into
		// data table to placement into DER-CAM input

		// also formats user-provided data in comma-sep
		// and provided length of values found for validation
		const endUseLabels = this.state.endUseLabels

		// init object for state update
		const stateUpdate = {}

		// for each enduse
		for(let ee=0; ee<endUseLabels.length; ee++) {

			// get state keynames from curent enduse
			let endUse = endUseLabels[ee]
			let listKey = `bldg_ts${endUse}`
			let lenKey = `bldg_len${endUse}`
			let dataKey = `bldg_data${endUse}`


			// convert user text to list
			let listData = this.state[listKey]
			if (listData) {
				listData = Helper.numericalArray(listData)
			} else {
				listData = []
			}

			// get length of user input
			let listLen = listData.length

			// add or remove values so list in len 8760
			if(listData.length > 8760) {
				listData.length = 8760
			} else if(listData.length < 8760) {

				let len = 8760 - listData.length

				let padding = new Array(len).fill(0);

				listData = listData.concat(padding)
			}			

			// store in var for state
			stateUpdate[listKey] = listData
			stateUpdate[lenKey] = listLen
		}

		await this.setState(stateUpdate)	

		// run format method (here for now)
		this.format8760()

	}


	async format8760() {

		// method takes data input from user
		// then formats and writes the data to the specified table

		const stateUpdate = {}
		const loadData = []

		// get initial day-of-week index from user
		let initDayNum = this.state.dowList.indexOf(this.state.bldg_initDow);
		if(initDayNum < 0) {initDayNum = 0};

		// get indices for month and day-of-week
		var indexMonth = Helper.generateMonIndex()
		var [indexDow, indexIsWd] = Helper.generateDayOfWeekIndex(initDayNum)

		// add current index arrays to state vars
		stateUpdate['indexMonth'] = indexMonth
		stateUpdate['indexDow'] = indexDow
		stateUpdate['indexIsWd'] = indexIsWd

		// define parameters for formatting daytype date
		const dayTypeData = {
			week: {
				isWd: 1,
				percentile: 0.5
			},
            peak: {
				isWd: 1,
				percentile: 0.9
			},
            weekend: {
				isWd: 0,
				percentile: 0.5
			},
		}

		const endUseLabels = this.state.endUseLabels

		// for each enduse
		for(let ee=0; ee<endUseLabels.length; ee++) {

			// add enduse array to loadData array
			loadData.push([])

			// get state keynames from curent enduse
			let endUse = endUseLabels[ee]
			let listKey = `bldg_ts${endUse}`

			// extract list data from state var
			let listData = this.state[listKey]

			// process list to structured input
			let procData = _.cloneDeep(listData)
			procData = Helper.splitArray(procData, 24)

			// loop through each month, daytpe
			for(let mm=0; mm<12; mm++) {

				// add month array to loadData[ee] array
				loadData[ee].push([])

				for(let dd=0; dd<3; dd++) {

					// add daytype array to loadData[ee][mm] array
					loadData[ee][mm].push([])

					let dayTypeLabel = this.state.dayTypeList[dd]
					let quantileVal = dayTypeData[dayTypeLabel].percentile

					// filter data based on month and daytype
					let listDataFilt = procData.filter((val, ind) =>{
						let monTest = indexMonth[ind] == mm+1

						let dowTest = dayTypeData[dayTypeLabel].isWd == indexIsWd[ind]

						return (monTest && dowTest)
					})

					// console.log(`month:${mm+1}, dayType:${dayTypeLabel}, endUse:${endUse}`)
					// console.log(listDataFilt)

					// loop through each hour to get aggregated load value
					for(let hh=0;hh<24; hh++) {

						// get only hh_th hour from each remaining list
						let hourData = listDataFilt.map((arr) => (parseFloat(arr[hh])))

						// aggregate data using appropriate percentile
						let hourVal = Helper.quantile(hourData, quantileVal)

						// round value to 2-decimals
						hourVal = parseFloat(hourVal.toFixed(2))

						// add data to 4-d array
						loadData[ee][mm][dd].push(hourVal)
					}

				}

			}

		}

		// add formatted data to state var
		this.setState({loadDataFormatted:loadData})
		console.log(loadData)
	}

	// async write8760() {
	// 	// method writes the user-provide 8760 to the load input table selected by user

	// 	// confirm that selected node is in current list of nodes
	// 	if(!this.state.bldg_node || this.state.nodeList){
	// 		console.log('selected node or node list not currently defined')
	// 		return
	// 	}

	// 	if(this.state.nodeList.indexOf(this.state.bldg_node) < 0 ) {
	// 		console.log('selected node not found in node list')
	// 		return
	// 	}

	// 	// get current project data

	// 	// define key for target load sheet

	// 	replaceTable(tableData, tableKey)

	// }

	updateTableDataGeneric(event, manualVal=null) {

		// determine the type of table being edited
		let tableDims = parseInt(event.target.dataset.dims);

		// extract new value and location within table from event
		let rowNum = parseInt(event.target.dataset.rowNum);
		let row2Num = parseInt(event.target.dataset.row2Num);
		let row3Num = parseInt(event.target.dataset.row3Num);
		let colNum = parseInt(event.target.dataset.colNum);
		let dataName = event.target.dataset.name;


		// if manualVal provided use it, else use event.target.value
		if(manualVal===null){
			var updateVal = event.target.value
		} else {
			var updateVal = manualVal
		}

		// extract project data and specific table data from state
		let projData = this.state.projData;

		// response for 1-d table event
		if(tableDims===1){
			projData[dataName].data[rowNum] = updateVal;
		}
		// response for 2-d table event
		if(tableDims===2){
			projData[dataName].data[rowNum][colNum] = updateVal;
		}
		// response for 3-d table event
		if(tableDims===3){			
			projData[dataName].data[rowNum][row2Num][colNum] = updateVal;
		}
		// response for 4-d table event
		if(tableDims===4){
			projData[dataName].data[rowNum][row2Num][row3Num][colNum] = updateVal;
		}

		return projData

	}	

	replaceTable(tableData, tableKey) {
		// updates this.state.projData by replacing entire table located at tableKey 
		

		// get current projData
		var { projData } = this.state

		// confirm that tableKey already exists
		const tableKeys = Object.keys(projData)
		if(!tableKeys.includes(tableKey)){
			return ({
				success: false,
				msg: `Project Data does not contain table ${tableKey}`
			})
		}

		// otherwise, update table and update state
		projData[tableKey] = tableData

		this.setState({projData:projData})

		return({
			success: true,
			msg: `Table ${tableKey} successfully updated`
		})
	}

	writeNetworkModel(nodeData, lineData) {
		// method writes data describing nodes and line created in NodeSandbox component
		// to corresponding tables in project data

		// returns success status as bool

		// tables to modify:
		//    Power Flow Paramters - # of nodes
		//    Node Types - pcc, load, slack
		//    Cable Lengths
		//    Branch Type - Cables
		//    Branch Type - Transformers
		//    HT Pipe Lengths
		//    LT Pipe Lengths
		//    HT Pipe Types
		//    LT Pipe Types


		console.log('running writeNetworkModel method in NewProjectDercam')

		console.log('Node Data:')
		console.log(nodeData)

		console.log('Line Data:')
		console.log(lineData)

		if(!nodeData || !lineData){
			console.log('cannot write network data: missing required items')
			return false
		}

		// extract current project data
		const projData = this.state.projData;

		// get n Nodes from network data
		const nNodes = Object.keys(nodeData).length;

		// update PowerFlow Parameters
		var table1 = projData['powerflowparams'];
		table1 = Helper.updateTableObj(table1, 'NoOfNodes', nNodes);
		projData['powerflowparams'] = table1;

		// update Node Types table
		var table2 = projData['nodetype']

		// update data based on node values
		// var table2Data = [2, 0, 0]
		
		// initialize empty/default node data
		var table2Data = this.state.nodeListAll.map(nodeNum => ([2, 0, 0]));

		// get order of node properties to ensure they go in the right spot
		var nodeDataLabels = table2['colLabels']
		var nodePropOrder = {
			'slack': nodeDataLabels.indexOf('Type'),
			'pcc': nodeDataLabels.indexOf('PCC'),
			'load': nodeDataLabels.indexOf('EnableLoad')
		}

		for (let [nodeKey, nodeItem] of Object.entries(nodeData)) {
		    
		    let nodeIndex = nodeItem.nodeIndex - 1;
		    let nodePcc = Helper.boolToInt(nodeItem.pcc);
		    let nodeLoad = Helper.boolToInt(nodeItem.load);
		    let nodeSlack = nodeItem.slack;

		    // convert node slack to 3 if true, else 2
		    nodeSlack = nodeSlack ? 3 : 2;

		    // create empty nodetype data array
		    let newNodeTypeData = [0,0,0];
		    // upate values based on node item data
		    newNodeTypeData[nodePropOrder['slack']] = nodeSlack;
		    newNodeTypeData[nodePropOrder['pcc']] = nodePcc;
		    newNodeTypeData[nodePropOrder['load']] = nodeLoad;

		    // replace default node type data with new item for index
		    table2Data[nodeIndex] = newNodeTypeData;
		}

		// replace data in table2
		table2['data'] = table2Data
		projData['nodetype'] = table2;


		// replace node-node table data
		const propExtractList = [
			{
				tableKey: 'cablelen',
				lineType: 'cable',
				propName: 'length',
				incrementVal: 0
			},
			{
				tableKey: 'branchtype_cable',
				lineType: 'cable',
				propName: 'modelIndex',
				incrementVal: 1
			},
			{
				tableKey: 'branchtype_transformer',
				lineType: 'transformer',
				propName: 'modelIndex',
				incrementVal: 1
			},
			{
				tableKey: 'htpipelen',
				lineType: 'HT Pipe',
				propName: 'length',
				incrementVal: 0
			},
			{
				tableKey: 'ltpipelen',
				lineType: 'LT Pipe',
				propName: 'length',
				incrementVal: 0
			},
			{
				tableKey: 'htpipetype',
				lineType: 'HT Pipe',
				propName: 'modelIndex',
				incrementVal: 1
			},
			{
				tableKey: 'ltpipetype',
				lineType: 'LT Pipe',
				propName: 'modelIndex',
				incrementVal: 1
			},
		]


		for(let pp=0; pp<propExtractList.length; pp++) {
			let propExtract = propExtractList[pp];

			let lineType = propExtract.lineType;
			let propName = propExtract.propName;
			let tableKey = propExtract.tableKey;
			let incrementVal = propExtract.incrementVal;


			var newNodeTableData = ProjectUtils.createNodeTable(
									nodeData, lineData, lineType, 
									propName, incrementVal, this.state.nNodesMax)

			let tableData = projData[tableKey];

			tableData.data = newNodeTableData;
			projData[tableKey] = tableData;
		}

		console.log('end of writeNetworkModel func')

		this.setState({
			nNodes: nNodes,
			projData: projData
		})

		return true
	}

	goToProjects() {
		console.log('going to projects list')
		this.props.history.push(`${this.props.appUrl}/projects`);
	}


	render(){ 

  		// if submission pending
  		if(this.state.submitPending){
  			return(
  				<Modal
	  				icon={<LoadIcon />}
					label={<>Model Uploading to Server</>}
					rotating={true}
	  			>

	  			</Modal>
	  		)
  		}


  		// if submission failed
  		if(this.state.submitFailure){
  			return(
	  			<Modal
	  				icon={<ErrorIcon />}
					label={<>Model Upload Failed</>}
					rotating={false}
					modalText={'An error occurred when sending the model to the server. Please return the project editor and retry after a few minutes.'}
	  			>
	  				<div className='modal-icon-btn dark-btn' onClick={this.retryProject}>
						<h2 className='modal-btn-text'> Return </h2>
					</div>

	  			</Modal>
	  		)
  		}


  		// if submission succeeded
  		if(this.state.submitSuccess){
  			return(
	  			<Modal
	  				icon={<SuccessIcon />}
					label={<>Model Upload Successful</>}
					rotating={false}
					modalText={'Your model has been uploaded to the server. Please see the My Projects page to check model status and results when they are available.'}
	  			>
	  				<div className='modal-btn dark-btn' onClick={this.goToProjects}>
						<h2 className='modal-btn-text'> My Projects </h2>
					</div>

	  			</Modal>
	  		)
  		}


  		// else, display project builder
	  	if(!this.state.submitPending) {
		    // return( 
		    // 	this.renderTables()
		    // )

		    const pageComponentList = {
		    	introPage: <ProjectPageIntro/>,
				configurePage: <ProjectPageConfig
									projName={this.state.projName}
									multiNode={this.state.multiNode}
									nNodes={this.state.nNodes}
									nodeListAll={this.state.nodeListAll}
									nodeList={this.state.nodeList}

									handleChange={this.handleChange}
									handleExit={this.handleExit}
									handleChangeNodesNum={this.handleChangeNodesNum}

									// input binaries
									optDerSolar={this.state.optDerSolar}
									optDerStorage={this.state.optDerStorage}
									optDerGensets={this.state.optDerGensets}
									optDerLFGensets={this.state.optDerLFGensets}
									optDerCentralTech={this.state.optDerCentralTech}
									optDerEnergyConv={this.state.optDerEnergyConv}
									optDerWindHydro={this.state.optDerWindHydro}
									optDerEvs={this.state.optDerEvs}
									optRes={this.state.optRes}
									optDmdMgmt={this.state.optDmdMgmt}
									optAS={this.state.optAS}
									optThermalLoop={this.state.optThermalLoop}
									optEE={this.state.optEE}
								/>,
				loadsPage: <ProjectPageLoads
								dowList={this.state.dowList}
								loadList={this.state.loadList}
								bldgList={this.state.bldgList}
								vintageList={this.state.vintageList}
								climateList={this.state.climateList}
								nNodes={this.state.nNodes}
								nodeList={this.state.nodeList}

								selectedBldg={this.state.selectedBldg}

								loadTemplate={this.state.loadTemplate}
								replaceTable={this.replaceTable}

								handleChangeBldg={this.handleChangeBldg}
								handleChange={this.handleChange}
								handleExit={this.handleExit}
								toggleScaling={this.toggleScaling}
								process8760={this.process8760}

								bldg_type={this.state.bldg_type}
								bldg_vint={this.state.bldg_vint}
								bldg_climateZone={this.state.bldg_climateZone}
								bldg_scaleBin={this.state.bldg_scaleBin}
								bldg_size={this.state.bldg_size}
								bldg_annElec={this.state.bldg_annElec}
								bldg_annNg={this.state.bldg_annNg}
								bldg_bldgId={this.state.bldg_bldgId}
								bldg_node={this.state.bldg_node}
								
								bldg_initDow={this.state.bldg_initDow}
								bldg_tsElec={this.state.bldg_tsElec}
								bldg_tsCool={this.state.bldg_tsCool}
								bldg_tsHeat={this.state.bldg_tsHeat}
								bldg_tsWater={this.state.bldg_tsWater}
								bldg_tsRef={this.state.bldg_tsRef}
								bldg_tsNg={this.state.bldg_tsNg}
								bldg_tsDaytype={this.state.bldg_tsDaytype}

								bldg_lenElec={this.state.bldg_lenElec}
								bldg_lenCool={this.state.bldg_lenCool}
								bldg_lenHeat={this.state.bldg_lenHeat}
								bldg_lenWater={this.state.bldg_lenWater}
								bldg_lenRef={this.state.bldg_lenRef}
								bldg_lenNg={this.state.bldg_lenNg}	

								isFormatted={this.state.isFormatted}
								bldg_tsAll={this.state.bldg_tsAll}
								loadDataFormatted={this.state.loadDataFormatted}
							/>,
				solarPage: <ProjectPageSolar
								stateList={this.state.stateList}
							    stationList={this.state.stationList}
								selectedPv={this.state.selectedPv}
								pvAngleList={this.state.pvAngleList}
	    						pvOrientList={this.state.pvOrientList}

								pvTemplate={this.state.pvTemplate}
								replaceTable={this.replaceTable}
								handleChangePvStation={this.handleChangePvStation}
								handleChange={this.handleChange}
								handleExit={this.handleExit}

								loc_stateUs={this.state.loc_stateUs}
								loc_station={this.state.loc_station}
								loc_stationId={this.state.loc_stationId}
								pvAngle={this.state.pvAngle} 
	    						pvOrient={this.state.pvOrient}
							/>,
				networkPage: <ProjectPageNetwork
								multiNode={this.state.multiNode}
								networkModel = {this.props.networkModel}
								projData={this.state.projData}
								saveProject = {this.props.saveProject}
								writeNetworkModel={this.writeNetworkModel}
							/>,
				tablesPage: <ProjectPageTables
								projData={this.state.projData}
								tableList={this.state.tableList}
								tableIndex={this.state.tableIndex}
								goToTable={this.goToTable}
								handleTableChange={this.handleTableChange}
								handleExit={this.handleTableCellExit}
								revertTable={this.revertTable}

								multiNode={this.state.multiNode}
								nNodes={this.state.nNodes}

								optDerSolar={this.state.optDerSolar}
								optDerStorage={this.state.optDerStorage}
								optDerGensets={this.state.optDerGensets}
								optDerLFGensets={this.state.optDerLFGensets}
								optDerCentralTech={this.state.optDerCentralTech}
								optDerEnergyConv={this.state.optDerEnergyConv}
								optDerWindHydro={this.state.optDerWindHydro}
								optDerEvs={this.state.optDerEvs}
								optRes={this.state.optRes}
								optDmdMgmt={this.state.optDmdMgmt}
								optAS={this.state.optAS}
								optThermalLoop={this.state.optThermalLoop}
								optEE={this.state.optEE}
							/>,
				submitPage: <ProjectPageSubmit
								submitProject = {this.submitProject}
								getAlteredTables = {this.getAlteredTables}
								projData = {this.state.projData}
								tableList={this.state.tableList}
								multiNode={this.state.multiNode}
								nNodes={this.state.nNodes}
							/>,
		    }


		    return (
		    	<>
			    	<NavbarDercam
			    		activatePage = {this.activatePage}
						currentPage = {this.state.currentPage}	
						pageList={this.state.pageList}

						projectName = {this.state.projName}
						onNameChange={this.handleChange}
						onNameExit={this.handleExit}					
						
			    	/>

			    	{pageComponentList[this.state.currentPage]}
		    	</>
		    )

	    }

	}
} 


export default withRouter(NewProjectDercam);