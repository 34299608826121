import React from 'react';
// import parse from 'html-react-parser';

import SidePanel from './SidePanel';
import InputTableFlex from './InputTableFlex';

import { ReactComponent as HelpIcon } from '../../icons/help.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as RevertIcon } from '../../icons/revert.svg';

const helpDoc = require('./HelpDoc.json');

class ProjectPageTables extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			displayHelp: false
		}

		this.toggleHelp = this.toggleHelp.bind(this);

	}

	componentDidMount() {
		this.setState({toggleHelp:false})
	}

	toggleHelp() {
		// method toggles displayHelp state item

		this.setState({displayHelp:!this.state.displayHelp});

		// console.log('printing helpdoc list')
		// console.log(helpDoc)

		// console.log('printing table key')
		// console.log(this.props.tableList[this.props.tableIndex].key)
	}

	renderHelpHtml(helpContent) {
		// method renders help text html from json help defintion
		// console.log(helpContent)

    	// define data keys in order they should appear
	    const keyList = ['name',
	               'description',
	                'type',
	                'units',
	                'notes'
	                ]
	    
	    // generate input summary, if present
	    var summaryHtml = null;
	    if(helpContent.summary){
	        summaryHtml = <div className='input-help-sum'><p>{helpContent.summary}</p></div>
	    }

	    // console.log(summaryHtml)
	    
	    // loop through data and format each item
	    var dataHtml = helpContent.data.map(dataItem => {

	    	var itemHtml = []

	    	for(let kk=0; kk<keyList.length; kk++){
	    		let curKey = keyList[kk];

	    		if(dataItem[curKey]){
	    			if(dataItem[curKey].length>0){
	    				let lineHtml = <p><span className='input-help-data-item-label'>{curKey}: </span>{dataItem[curKey]}</p>
	    				itemHtml.push(lineHtml)
	    			}
	    		}
	    	}

	    	return (
	    		<div className='input-help-data-item'>
	    			{itemHtml}
	    		</div>
	    	)

	    })       

	    const sectionHtml = (
	    	<div className='input-help-container'>
	    		<div className='input-help-title'>Input Documentation</div>

	    		{summaryHtml}

	    		{dataHtml}

	    	</div>
	    )

	    // console.log(sectionHtml)

	    return sectionHtml

	}



	renderCurrentTablePage() {


		if(this.props.projData){

			var tableKey = this.props.tableList[this.props.tableIndex].key

			var paramData = this.props.projData[tableKey]

			// generate help html
			try {

				// if table key starts if loadinput, simplified to general loadinput key
				if(tableKey.startsWith('loadinput')) {
					tableKey = 'loadinput'
				}

				// var helpHtml = parse(helpDoc[tableKey])
				var helpHtml = this.renderHelpHtml(helpDoc[tableKey])
			} catch {
				var helpHtml = null
			}

			if(!this.state.displayHelp){

				return(
					<>
						<div className='input-flex-title-div'>
							<h2 className='input-flex-title'>{paramData.label}</h2>
							<div className='dark-btn flex-table-help-btn'
								onClick={this.toggleHelp}
								title='Toggle Help Documentation'
							>
								<HelpIcon/>
							</div>
							<div className='dark-btn flex-table-help-btn'
								onClick={()=>{this.props.revertTable(paramData.key)}}
								title='Revert to Default Values'
							>
								<RevertIcon/>
							</div>
						</div>

						
						
							<InputTableFlex
								paramData={paramData}
								onChange={this.props.handleTableChange}
								onExit={this.props.handleExit}
								nNodes={this.props.nNodes}
							/>

						

					</>
				)

			} else {
				// return help documenttation for current input
				return(
				<>
					<div className='input-flex-title-div'>
						<h2 className='input-flex-title'>{paramData.label}</h2>
						<div className='dark-btn flex-table-help-btn'
							onClick={this.toggleHelp}
						>
							<CloseIcon/>
						</div>
					</div>

					
					
						{helpHtml}

					

				</>
			)
			}
		} else {
			return (
				<h2>No data loaded</h2>
			)
		}
	}

	
	render(){ 

		// storing this here for now
		// var pageselect = (
		// 	<div className='page-select-container'>
		//     			<div className='text-button' onClick={()=>{this.props.incrementTable(-1)}}><h2>Prev</h2></div>
		//     			<div className='text-button' onClick={this.props.submitProject}><h2>Submit</h2></div>
		//     			<div className='text-button' onClick={()=>{this.props.incrementTable(1)}}><h2>Next</h2></div>
		//     		</div>
		// )

		return(
			<div className='page-fixed-container-new-project project-main-container'>

	    		<SidePanel
	    			tableList={this.props.tableList}
	    			tableIndex={this.props.tableIndex}
	    			goToTable={this.props.goToTable}

	    			multiNode={this.props.multiNode}
					nNodes={this.props.nNodes}

	    			optDerSolar={this.props.optDerSolar}
					optDerStorage={this.props.optDerStorage}
					optDerGensets={this.props.optDerGensets}
					optDerLFGensets={this.props.optDerLFGensets}
					optDerCentralTech={this.props.optDerCentralTech}
					optDerEnergyConv={this.props.optDerEnergyConv}
					optDerWindHydro={this.props.optDerWindHydro}
					optDerEvs={this.props.optDerEvs}
					optRes={this.props.optRes}
					optDmdMgmt={this.props.optDmdMgmt}
					optAS={this.props.optAS}
					optThermalLoop={this.props.optThermalLoop}
					optEE={this.props.optEE}
	    		/>

	    		<div className='project-data-pane'>

		    		{this.renderCurrentTablePage()}

	    		</div>

	    	</div>
		)

		
	}
}



export default ProjectPageTables;