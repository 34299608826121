import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import './ResultsInv.css';

import Navbar from '../Navbar/Navbar'

import vectors from '../../resources/vectors.js'
const downloadSvg = vectors.downloadSvg
const fileSvg = vectors.fileSvg

class ResultsInv extends React.Component {

  render(){ 

  	// if no data passed in props, return nothing
  	if(!this.props.data) {
  		return null
  	}

  	// confirm that dataset has necessary items
  	if(!this.props.data.ex.genset){
  		this.props.data.ex.genset = []
  	}
  	if(!this.props.data.new.genset){
  		this.props.data.new.genset = []
  	}
  	if(!this.props.data.new.ee){
  		this.props.data.new.ee = []
  	}

  	// unpack values and round to in
  	const exPvCap = Math.round(this.props.data.ex.pv.cap)
  	const newPvCap = Math.round(this.props.data.new.pv.cap)
  	const newPvCapEx = Math.round(this.props.data.new.pv.capEx)
  	const newPvAnnEx = Math.round(this.props.data.new.pv.annEx)

  	const exEsCap = Math.round(this.props.data.ex.es.cap)
  	const newEsCap = Math.round(this.props.data.new.es.cap)
  	const newEsCapPower = Math.round(this.props.data.new.es.cap * 0.3)
  	const newEsCapEx = Math.round(this.props.data.new.es.capEx)
  	const newEsAnnEx = Math.round(this.props.data.new.es.annEx)


  	console.log('Investment Details data:')
  	console.log(this.props.data)

  	// initialize der-specfic sections as empty
  	var exPv = null;
  	var exEs = null;
  	var exDg = null;
  	var newPv = null;
  	var newEs = null;
  	var newDg = null;
  	var newEe = null;

  	// if results passed in props for each der-type, replace empty section with data to display
  	if(this.props.data.ex.pv.cap >0) {
  		exPv = (
  			<div className='res-inv-der-section'>
  				<h2 className='res-inv-der-header'>Existing PV</h2>
  				<div className='res-inv-data-container'>
	  				<p className='res-inv-metric-label'>capacity:</p>
	  				<p className='res-inv-metric-data'>{exPvCap}</p>
	  				<p className='res-inv-metric-unit'>kW</p>
  				</div>
  			</div>
  		)
  	}

  	if(this.props.data.ex.es.cap >0) {
  		exEs = (
  			<div className='res-inv-der-section'>
  				<h2 className='res-inv-der-header'>Existing Energy Storage</h2>
  				<div className='res-inv-data-container'>
	  				<p className='res-inv-metric-label'>capacity:</p>
	  				<p className='res-inv-metric-data'>{exEsCap}</p>
	  				<p className='res-inv-metric-unit'>kWh</p>
  				</div>
  			</div>
  		)
  	}

  	if(Object.entries(this.props.data.ex.genset).length > 0) {

  		let exGensetKeys = Object.keys(this.props.data.ex.genset);

  		exDg = (
  			<div className='res-inv-der-section'>
  				<h2 className='res-inv-der-header'>Existing Gensets</h2>

  				{exGensetKeys.map((gensetKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>name:</p>
							<p className='res-inv-metric-data'>{gensetKey}</p>
							<p className='res-inv-metric-unit'></p>
  					</div>
  				))}

  				{exGensetKeys.map((gensetKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>capacity:</p>
							<p className='res-inv-metric-data'>{Math.round(this.props.data.ex.genset[gensetKey].cap)}</p>
							<p className='res-inv-metric-unit'>kW</p>
  					</div>
  				))}

  				{exGensetKeys.map((gensetKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>units:</p>
							<p className='res-inv-metric-data'>{this.props.data.ex.genset[gensetKey].units}</p>
							<p className='res-inv-metric-unit'>#</p>
  					</div>
  				))}

  			</div>
  		)
  	}

  	// if results passed in props for each der-type, replace empty section with data to display
  	if(this.props.data.new.pv.cap >0) {
  		newPv = (
  			<div className='res-inv-der-section'>
  				<h2 className='res-inv-der-header'>New PV</h2>
  				<div className='res-inv-data-container'>
	  				<p className='res-inv-metric-label'>capacity:</p>
	  				<p className='res-inv-metric-data'>{newPvCap}</p>
	  				<p className='res-inv-metric-unit'>kW</p>
	  			</div>
	  			<div className='res-inv-data-container'>
	  				<p className='res-inv-metric-label'>capital cost:</p>
	  				<p className='res-inv-metric-data'>{newPvCapEx}</p>
	  				<p className='res-inv-metric-unit'>$</p>
	  			</div>
	  			 <div className='res-inv-data-container'>
	  				<p className='res-inv-metric-label'>annualized cost:</p>
	  				<p className='res-inv-metric-data'>{newPvAnnEx}</p>
	  				<p className='res-inv-metric-unit'>$/yr</p>
	  			</div>
  			</div>
  		)
  	}

  	if(this.props.data.new.es.cap >0) {
  		newEs = (
  			<div className='res-inv-der-section'>
  				<h2 className='res-inv-der-header'>New Energy Storage</h2>
  				<div className='res-inv-data-container'>
	  				<p className='res-inv-metric-label'>energy capacity:</p>
	  				<p className='res-inv-metric-data'>{newEsCap}</p>
	  				<p className='res-inv-metric-unit'>kWh</p>
	  			</div>
	  			 <div className='res-inv-data-container display-none'>
	  				<p className='res-inv-metric-label'>power capacity: UPDATE!</p>
	  				<p className='res-inv-metric-data'>{newEsCapPower}</p>
	  				<p className='res-inv-metric-unit'>kW</p>
	  			</div>
	  			<div className='res-inv-data-container'>
	  				<p className='res-inv-metric-label'>capital cost:</p>
	  				<p className='res-inv-metric-data'>{newEsCapEx}</p>
	  				<p className='res-inv-metric-unit'>$</p>
	  			</div>
	  			 <div className='res-inv-data-container'>
	  				<p className='res-inv-metric-label'>annualized cost:</p>
	  				<p className='res-inv-metric-data'>{newEsAnnEx}</p>
	  				<p className='res-inv-metric-unit'>$/yr</p>
	  			</div>
  			</div>
  		)
  	}

  	if(Object.entries(this.props.data.new.genset).length > 0) {

  		let gensetKeys = Object.keys(this.props.data.new.genset);

  		newDg = (
  			<div className='res-inv-der-section'>
  				<h2 className='res-inv-der-header'>New Gensets</h2>

  				{gensetKeys.map((gensetKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>name:</p>
							<p className='res-inv-metric-data'>{gensetKey}</p>
							<p className='res-inv-metric-unit'></p>
  					</div>
  				))}

  				{gensetKeys.map((gensetKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>capacity:</p>
							<p className='res-inv-metric-data'>{Math.round(this.props.data.new.genset[gensetKey].cap)}</p>
							<p className='res-inv-metric-unit'>kW</p>
  					</div>
  				))}

  				{gensetKeys.map((gensetKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>units:</p>
							<p className='res-inv-metric-data'>{this.props.data.new.genset[gensetKey].units}</p>
							<p className='res-inv-metric-unit'>#</p>
  					</div>
  				))}

  				{gensetKeys.map((gensetKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>capital cost:</p>
							<p className='res-inv-metric-data'>{Math.round(this.props.data.new.genset[gensetKey].capEx)}</p>
							<p className='res-inv-metric-unit'>$</p>
  					</div>
  				))}

  				{gensetKeys.map((gensetKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>annualized cost:</p>
							<p className='res-inv-metric-data'>{Math.round(this.props.data.new.genset[gensetKey].annEx)}</p>
							<p className='res-inv-metric-unit'>$/yr</p>
  					</div>
  				))}

  			</div>
  		)
  	}

  	if(Object.entries(this.props.data.new.ee).length > 0) {

  		let eeKeys = Object.keys(this.props.data.new.ee);

  		newEe = (
  			<div className='res-inv-der-section'>
  				<h2 className='res-inv-der-header'>New Energy Efficiency</h2>

  				{eeKeys.map((eeKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>name:</p>
							<p className='res-inv-metric-data'>{eeKey}</p>
							<p className='res-inv-metric-unit'></p>
  					</div>
  				))}

  				{eeKeys.map((eeKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>capital cost:</p>
							<p className='res-inv-metric-data'>{Math.round(this.props.data.new.ee[eeKey].capEx)}</p>
							<p className='res-inv-metric-unit'>$</p>
  					</div>
  				))}

  				{eeKeys.map((eeKey)=>(
  					<div className='res-inv-data-container'>
  						<p className='res-inv-metric-label'>annualized cost:</p>
							<p className='res-inv-metric-data'>{Math.round(this.props.data.new.ee[eeKey].annEx)}</p>
							<p className='res-inv-metric-unit'>$/yr</p>
  					</div>
  				))}

  			</div>
  		)
  	}

  	return(
	  	<div className='res-inv-report-div'>
	  		<h2 className='res-inv-header'>Scenario: {this.props.label}</h2>	
	  		{exPv}
	  		{exEs}
	  		{exDg}	
	  		{newPv}
	  		{newEs}
	  		{newDg}
	  		{newEe}

			</div>
    ) 
  }
} 


export default withRouter(ResultsInv);