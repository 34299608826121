import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Helper from '../../helper';

import './Results.css';

// import results section components
import SectionSummary from './SectionSummary';
import SectionObjective from './SectionObjective';
import SectionInvest from './SectionInvest';
import SectionAnn from './SectionAnn';

import Modal from '../Modal/Modal'


import { ReactComponent as LoadIcon } from '../../icons/loading.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';



const _ = require('lodash');


class ResultsDercam extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			errorServer: null,
			errorResults: false,
			// resultsLoaded: false,
			dataLoaded: false,
			modelKey: null,
			modelLabel: null,
			modelDesc: null,
			results: null,

			// data to display for results
			model: null, // obj of model status data from queue db
			
			optSummary: null, // model summary data

			dataInvestments: null, // investment table for cont techs
			// dataInvestmentCont: null, // investment table for cont techs
			// dataInvestmentDg: null, // investment table for simple dg techs
			// dataInvestmentLfDg: null, // investment table for LF dg techs

			dataAnnual: null, // annual results metrics
			metaAnnual: null, // meta data for annual metrics

			// solution summary
			dataStatus: null,

		}

	}

	async componentDidMount() {

		// implement tiny delay to show loading
		await Helper.sleep(2000)

		// extract key from router params prop
		const { modelKey } = this.props.match.params;

		// fetch project results data
    let urlResults = `${process.env.REACT_APP_URL_BASE}/json/dercam/model/${modelKey}/results`;
    let response = await fetch(urlResults, {credentials: 'include'});

    // if status GET request successful
    if(response.status===200) {
      let data = await response.json();

      // initialize data object for state update
			let dataToState = {};
			dataToState.errorServer = false;
			dataToState.dataLoaded = false;


      try {
				
				// extract general model data and remove full results
				let model = _.cloneDeep(data.model)
				delete model.results;

      	dataToState.model = model
      	// dataToState.data = data
      	dataToState.meta = data.model.results.meta
      	dataToState.multiNode = data.model.results.meta.multiNode
      	dataToState.nNodes = data.model.results.meta.nNodes

      	dataToState.results = data.model.results.results

      	// if model is multiNode, extract node results
      	if (dataToState.multiNode) {
      		dataToState.resultsNode = data.model.results.resultsNode
      	} else {
      		dataToState.resultsNode = null
      	}

      	dataToState.resultsJson = data.model.results.resultsJson

      	dataToState.optSummary = dataToState.resultsJson.model

      	dataToState.dataInvestments = dataToState.resultsJson.investments  
      	// dataToState.dataInvestmentCont = dataToState.resultsJson.investmentCont  
      	// dataToState.dataInvestmentDg = dataToState.resultsJson.investmentDg  
				// dataToState.dataInvestmentLfDg = dataToState.resultsJson.investmentLfDg

      	dataToState.dataAnnual = dataToState.resultsJson.annualData  
      	dataToState.metaAnnual = dataToState.resultsJson.annualMeta    	

      	// update model key, name, and description
      	dataToState.modelKey = data.model.model_key;
				dataToState.modelLabel = data.model.label;
				// dataToState.modelDesc = data.model.results.general.description;

				// if try clause works, set dataLoad flag to true
				dataToState.dataLoaded = true;

      } catch (error) {
      	// if unpack fails, log error
			  console.error(error);
			  console.log('Failed to unpack model data')

			  // if try clause fails, set dataLoad flag to false
				dataToState.dataLoaded = false;
				dataToState.errorResults = true;
			}
			

			// update state
			this.setState(dataToState)

    } else {
      this.setState({
      	errorServer:true,
      	dataLoaded: false,
      	modelKey: modelKey,
      	model: null,
      	scenarios: null,
      	dataRawStatus: null,
				dataRawInv: null,
				dataRawAnn: null,
				dataRawMon: null,
				dataRawTs: null

      })
    }
  	
	}




  render(){ 

  	// if server error, return server error modal
  	if(this.state.errorServer){
  		return ( 

					<Modal 
						icon={<ErrorIcon />}
						label={<>Failed to Retrieve<br/>Data from Server</>}
						rotating={false}
						modalText={'The server failed to return any results data. Please try again after a few minutes.'}
					/>

	    )
  	}

  	// if results-processing error error, return results error modal
  	if(this.state.errorResults){
  		return ( 

					<Modal 
						icon={<ErrorIcon />}
						label={<>Failed to Process<br/>Data from Server</>}
						rotating={false}
						modalText={'The results data returned by the server could not be processed. You may need to re-run this model for results to be available.'}
					/>

	    )
  	}

  	// if results not loaded yet and no errors, return loading modal
  	if(!this.state.dataLoaded && !this.state.errorServer){
	    return ( 

					<Modal 
						icon={<LoadIcon />}
						label={<>Retrieving Results Data<br/>from Server</>}
						rotating={true}
					/>

	    )
	  } 

	  // if results loaded, return results objects
  	if(this.state.dataLoaded){
	    return ( 
	    	<div className="page-container">
					<div className='page-scroll-container'>
						<div className='results-container'>	

							<SectionSummary
								dataLoaded={this.state.dataLoaded}
								
								modelKey={this.state.modelKey}
								modelLabel={this.state.modelLabel}
								modelDesc={this.state.modelDesc}
								modelData={this.state.resultsJson}

								multiNode={this.state.multiNode}
				      	nNodes={this.state.nNodes}
				      	results={this.state.results}
				      	resultsNode={this.state.resultsNode}

				      	optSummary={this.state.optSummary}
								
							/>

							<SectionObjective
								dataLoaded={this.state.dataLoaded}
								optSummary={this.state.optSummary}
							/>

							<SectionInvest
								dataLoaded={this.state.dataLoaded}
								dataInvestments={this.state.dataInvestments}
							/>

							<SectionAnn
								dataLoaded={this.state.dataLoaded}
								dataAnnual={this.state.dataAnnual}
								metaAnnual={this.state.metaAnnual}
							/>

						</div>
					</div>			
				</div>
	    )
	  }

  }
} 


export default withRouter(ResultsDercam);