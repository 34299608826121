import React from 'react';
import { BrowserRouter, Link, Route, Switch, withRouter } from 'react-router-dom';

import './RouterDercam.css';

import HomeDercam from '../HomeDercam/HomeDercam';

import Projects from '../Projects/Projects';
import NewProjectDercam from '../NewProjectDercam/NewProjectDercam';
import ResultsDercam from '../ResultsDercam/ResultsDercam';


import Helper from '../../helper';

class RouterDercam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }

    this.openProjectCopy= this.openProjectCopy.bind(this);

  }

  async openProjectCopy(modelKey) {

    // const url = this.props.match.url
    console.log('calling openProjectCopy')

    // fetch project from server
    let urlModel = `${process.env.REACT_APP_URL_BASE}/json/dercam/model/${modelKey}/copy`;
    let response = await fetch(urlModel, {credentials: 'include'});

    // if status GET request successful
    if(response.status===200) {
      let data = await response.json();
      let serverProject = data.model;
    
      // get initial project template
      // let flatProject = initProject.createNew();

      // repackage project for editing
      // flatProject = ProjectUtils.unpackProject(nestedProject, flatProject);

      // add -copy suffix to name
      let curProjName = serverProject.meta.name;
      curProjName += ' - COPY';
      serverProject.meta.name = curProjName;

      // save project to app state
      await this.props.saveProject('dercamProj', serverProject)

      // redirect to project editor
      this.props.history.push(`${this.props.match.url}/new`);


    } else {
      console.log(`could not retrieve model: ${modelKey}`)
    }


  }
  

  render() {
    const url = this.props.match.url

    return (
          

            <Switch>
              <Route exact path={url}>
                <HomeDercam
                  checkAuth = {this.props.checkAuth}
                  authFetch = {this.props.authFetch}
                  auth = {this.props.auth}
                  authUser = {this.props.authUser}
                  userLogout = {this.props.userLogout}
                />
              </Route>
              <Route exact path={`${url}/about`}>
                <div className='sub-nav-container'>
                  <h1 className='sec-title-h1'>RDT ABOUT</h1>
                </div>
              </Route>
              <Route exact path={`${url}/projects`}>
                <Projects 
                  appUrl={url}
                  apiProjectUrl={`${process.env.REACT_APP_URL_BASE}/json/dercam/model`}
                  openProjectCopy={this.openProjectCopy}
                  modelType='dercam'
                />
              </Route>
              <Route exact path={`${url}/projects/:modelKey`}>
                <ResultsDercam 
                  appUrl={url}
                />
              </Route>
              <Route exact path={`${url}/new`}>
                <NewProjectDercam 
                  appUrl={url}
                  dercamProj = {this.props.dercamProj}
                  saveProject = {this.props.saveProject}
                  clearSavedProject = {this.props.clearSavedProject}
                  networkModel = {this.props.networkModel}
                />
              </Route>

              <Route exact path={`${url}/data`}>
                <div className='sub-nav-container'>
                  <h1 className='sec-title-h1'>RDT DATA</h1>
                </div>              </Route>
            </Switch>
    )
  }
}

export default withRouter(RouterDercam);
