import React from 'react';

import './Results.css';

class ResultsSection extends React.Component {
	// this is a wrapper component for rendering result section
	// include conditional to render nothing if data has not loaded
	// try catch clause that renders error if renderFunc call fails
	// wraps all results sections

	render() {

		// console.log(this.props)

		// if data is not loaded, return nothing
		if(!this.props.dataLoaded) {
			return null
		}

		try{

			// call function to rendered section contents passed in props
			return(this.props.renderFunc())

			
		} catch(error) {
			console.log(error)

			return (
				<div className='results-section'>
					<h2 className='results-heading'>Warning: Could not generate {this.props.sectionName}</h2>
				</div>
			)
		}
		
	}

}

export default ResultsSection;