import React from 'react';
import { withRouter } from 'react-router-dom';


class NewActivate extends React.Component {

	async requestActivate(token) {

		let data =  {
	        'token': token
	    }

	    let urlActivate = `${process.env.REACT_APP_URL_BASE}/auth/activate`;

	    let response = await fetch(urlActivate, {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json;charset=utf-8'
	      },
	      body: JSON.stringify(data)
	    });

      // check if response is 200, if successful, redirect
      console.log(response.status)
      if(response.status===201) {
        console.log('new acct activation successful')

        this.props.history.push({
          pathname: '/acct/created',
        })
      } else if(response.status===409) {
        // console.log(response.status)
        // console.log(response.statusText)
      }else {
        console.log(response.status)
        console.log(response.statusText)
      }

	}

	render() {

		const {token} = this.props.match.params;

		return (
      <div className="acct-msg-container">
        <h2 className="account-h2"> Account Activation </h2>

        <div className='text-button activate-button' onClick={()=>{this.requestActivate(token)}}>
          <h2>Activate Account</h2>
        </div>

        <p className="account-text"> 
          Please note, for security purposes, new accounts are inactive, 
          pending approval by the DER-CAM team. We appreciate your understanding.
        </p>

      </div>
		)
	}
}



export default withRouter(NewActivate);