import React from 'react';

import Helper from '../../helper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

import ResultsTable from '../ResultsTable/ResultsTable';
import Chart from '../Chart/Chart'
import ToggleSection from '../ToggleSection/ToggleSection'
import ResultsInv from '../ResultsInv/ResultsInv'
import ResultsSelect from './ResultsSelect'

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';

import { convertAnnDataForPlot, convertMonDataForPlot, processMonTable, processTsPlotData,
				 getMetricOptions, getMetricFromKey, transposeData,
				 filterFirst, filterMetricCat, processInvPlot, unpackInvData, unpackAnnData, unpackMonData
			 } from './resultsProcessing'


class SectionInv extends React.Component {
	constructor(props){
		super(props)

		this.renderSection = this.renderSection.bind(this);
	}

	renderSection() {

		const invMetricLabels = [
			{value:'capacity', label:'Capacity', unit: 'kW'},
			{value:'capEx', label:'Capital Cost', unit: '$'},
			{value:'annEx', label:'Annualized Cost', unit: '$/yr'},
		]


		// generate investment plot title, based on selected inv metric key
		if(this.props.keyInvMetric) {
			var invMetric = Helper.filterObjList(invMetricLabels, 'value', this.props.keyInvMetric)[0]
			// console.log(this.props.keyInvMetric)
			// console.log(invMetric)
			if(invMetric) {
				var invPlotTitle = `${invMetric.label} [${invMetric.unit}]`
			} else {
				var invPlotTitle = 'Select Investment Metric'
			}
		}

		// extract data for plotting, based on the value of keyInvMetric
		var invData = this.props.dataInv
		var invDataPlot = null;

		// filter data by selected category
		if(invData && this.props.keyInvMetric) {
			invDataPlot = Helper.filterObjList(invData, 'name', this.props.keyInvMetric)
		}

		// reorganize inv data for plotting
		var invDataPlot = processInvPlot(invDataPlot)
		// console.log(invDataPlot)

		// extract data for investment details report
		var dataInvReport = null;
		if(this.props.dataInvDetails){
			dataInvReport = this.props.dataInvDetails[this.props.keyInvScen]
		}

		// get list of outage scenarios for selection
		var scenOptions = [{label:'Select Scenario', 'value':null}];
		if(this.props.scenarios){
			var scenList = this.props.scenarios;
			// filter for 'new only'
			scenList = Helper.filterObjList(scenList, 'dd', 'new')
			// reorganize of use as optionList prop
			for(let oo=0;oo<scenList.length;oo++){
				let optionItem = {
					label: scenList[oo].outageLabel,
					value: scenList[oo].key
				}
				scenOptions.push(optionItem)
			}
			// console.log(scenOptions)
		}

		// generate data for tables
		var invDataTableCap = null;
		var invDataTableCapEx = null;
		var invDataTableAnnEx = null;

		if(invData) {
			// filter inv data by each type
			invDataTableCap = Helper.filterObjList(invData, 'name', 'capacity')
			invDataTableCapEx = Helper.filterObjList(invData, 'name', 'capEx')
			invDataTableAnnEx = Helper.filterObjList(invData, 'name', 'annEx')

			// process filtered data for table-writing
			invDataTableCap = processInvPlot(invDataTableCap)
			invDataTableCapEx = processInvPlot(invDataTableCapEx)
			invDataTableAnnEx = processInvPlot(invDataTableAnnEx)

			// console.log(invDataTableCap)
		}

		

		// determine selected scenario name
		var selectedScenarioLabel = this.props.getOutageScenario(this.props.keyInvScen)

		return(
			<div className='results-section'>
				<h2 className='results-heading'>2. Investments</h2>

				<ResultsSelect 
					name='keyInvMetric'
					label='Investment Metric'
					onChange={this.props.updateSelectSingle}
					optionList={
						[
							{'value': null, 'label': 'Select Metric'},
							{value:'capacity', label:'Capacity'},
							{value:'capEx', label:'Capital Cost'},
							{value:'annEx', label:'Annualized Cost'},
						]
					}
				/>

				<div className='results-chart-container'>
					<h3 className='chart-title'>{invPlotTitle}</h3>
					<Chart 
						data={invDataPlot}
						procColLabels={this.props.getOutageScenario}
						procRowLabels={this.props.getDerTypeLabel}
						colorMap={this.props.colorMap}
					/>
				</div>

				<ToggleSection
					label='Investment Metric Tables'
				>	

					<div className='results-subsection'>
						<h3 className='chart-title'>New Investment Results - Capacity [kW]</h3>
						<ResultsTable
							data={invDataTableCap}
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getDerTypeLabel}
						/>
						<h3 className='chart-title'>New Investment Results - Capital Cost [$]</h3>
						<ResultsTable
							data={invDataTableCapEx}
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getDerTypeLabel}
						/>
						<h3 className='chart-title'>New Investment Results - Annualized Cost [$/yr]</h3>
						<ResultsTable
							data={invDataTableAnnEx}
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getDerTypeLabel}
						/>
						<div className='results-note-table'>
							<p>
								Note: data tables can be highlighted, copied, and pasted
								into Excel or other spreadsheet applications.
							</p>
						</div>
					</div>

				</ToggleSection>

				<div className='results-subsection'>
					<ToggleSection
						label='Investment Details'
					>	
						<div className='results-note'>
							<p>
								Select <strong>Outage Scenario</strong> to view detailed
								investment results.
							</p>
						</div>
						<ResultsSelect 
							name='keyInvScen'
							label='Outage Scenario'
							onChange={this.props.updateSelectSingle}
							optionList={scenOptions}
						/>
						< ResultsInv 
							data={dataInvReport}
							label={selectedScenarioLabel}
						/>
					</ToggleSection>
				</div>
			</div>
		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Investment Results'
			/>
		)
	}

}

export default SectionInv;