import React from 'react';

import './ToggleSection.css';


import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as HelpIcon } from '../../icons/help.svg';


class ToggleSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
			closeIcon: <CaretIcon/>,
			openIcon: <ChevronIcon/>,
			classPrefix: '',
			onClickBody: null,
			onClickIcon: null,
			contentLabel: null,
		}

		this.toggleExpand = this.toggleExpand.bind(this);
	}

	componentDidMount() {
		// check if type is passed in props
		// if so, update className prefix and icons

		var closeIcon = <CaretIcon/>;
		var openIcon = <ChevronIcon/>;
		var onClickBody = this.toggleExpand;
		var onClickIcon = null;

		if(this.props.type==='help') {
			closeIcon = <CloseIcon/>;
			openIcon = <HelpIcon/>;
			onClickBody = null;
			onClickIcon = this.toggleExpand;
		}

		var classPrefix = ''
		if(this.props.type){
			classPrefix = `${this.props.type}-`
		}

		this.setState({
			closeIcon: closeIcon,
			openIcon: openIcon,
			classPrefix: classPrefix,
			onClickBody: onClickBody,
			onClickIcon: onClickIcon
		})

	}

	toggleExpand() {

		this.setState({expanded: !this.state.expanded})
	}

	selectIcon() {
		
		return this.state.expanded ? this.state.closeIcon: this.state.openIcon	
		
	}

	renderTopBar() {

		return(
			<div className={`${this.state.classPrefix}toggle-section-bar`} onClick={this.state.onClickBody}>
				<div className={`${this.state.classPrefix}toggle-btn` }  onClick={this.state.onClickIcon}>{this.selectIcon()}</div>
				<h2 className={`${this.state.classPrefix}toggle-section-label`}>{this.props.label}</h2>
			</div>
		)
	}

	render() {


		var content = <div className={`${this.state.classPrefix}toggle-section-content`}>{this.props.children}</div>


		return(
			<div className={`${this.state.classPrefix}toggle-section`}>
				{this.renderTopBar()}
				{this.state.expanded ? content : null}
			</div>
		)
	}

}

export default ToggleSection;