import Helper from '../../helper';

const _ = require('lodash');

const initialSeasons = [[0,0,0,0,1,1,1,1,1,1,0,0],[1,1,1,1,0,0,0,0,0,0,1,1]]
const initHours = [[3,3,3,3,3,3,3,3,2,2,2,2,1,1,1,1,1,1,2,2,2,2,3,3],
					[3,3,3,3,3,3,3,3,2,2,2,2,1,1,1,1,1,1,2,2,2,2,3,3],
					[3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3],
					[3,3,3,3,3,3,3,3,2,2,2,2,2,2,2,2,2,2,2,2,2,2,3,3],
					[3,3,3,3,3,3,3,3,2,2,2,2,2,2,2,2,2,2,2,2,2,2,3,3],
					[3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3]]
const initHoursSum = [[3,3,3,3,3,3,3,3,2,2,2,2,1,1,1,1,1,1,2,2,2,2,3,3],
					[3,3,3,3,3,3,3,3,2,2,2,2,1,1,1,1,1,1,2,2,2,2,3,3],
					[3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3]]
const initHoursWin = [[3,3,3,3,3,3,3,3,2,2,2,2,2,2,2,2,2,2,2,2,2,2,3,3],
					[3,3,3,3,3,3,3,3,2,2,2,2,2,2,2,2,2,2,2,2,2,2,3,3],
					[3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3]];

const testExDgList = [
	{
		exDers_dgName: 'test unit 01',
		exDers_dgCap: 10,
		exDers_dgFuel: 'natural gas',
		exDers_dgEff: 0.65,
		exDers_dgBackupOnly: false,
		exDers_dgEnableChp: true,
		exDers_dgHeatAlpha: 0.3,
		exDers_dgOmRate: 0.001,
		exDers_dgUnits: 1
	},
	{
		exDers_dgName: 'test unit 02',
		exDers_dgCap: 22,
		exDers_dgFuel: 'diesel',
		exDers_dgEff: 0.40,
		exDers_dgBackupOnly: true,
		exDers_dgEnableChp: false,
		exDers_dgHeatAlpha: 0,
		exDers_dgOmRate: 0.01,
		exDers_dgUnits: 2
	}
]

const testNewDgList = [
	{
		newDers_dgName: 'new unit #1',
		newDers_dgCapCost: 2200,
		newDers_dgLifetime: 20,
		newDers_dgOmCost: 0.02,
		newDers_dgCap: 300,
		newDers_dgFuel: 'natural gas',
		newDers_dgEff: 0.55,
		newDers_dgBackupOnly: false,
		newDers_dgEnableChp: true,
		newDers_dgHeatAlpha: 0.25,
	},
	{
		newDers_dgName: 'new unit #2',
		newDers_dgCapCost: 9999,
		newDers_dgLifetime: 99,
		newDers_dgOmCost: 0.009,
		newDers_dgCap: 999,
		newDers_dgFuel: 'magic dust',
		newDers_dgEff: 0.99,
		newDers_dgBackupOnly: false,
		newDers_dgEnableChp: false,
		newDers_dgHeatAlpha: 0,
	},
	
]

const initTouRates = [
	[0.16, 0.14, 0.12],
	[0, 0.13, 0.11]
];

const initDmdRates = [
	[18,0],
	[6,10],
	[2,2],
	[0,0],
	[0,0],
];

const initCritLoads = [0.25, 0.5, 0, 0]



const initProject = {

	initializeUniformTable(nRows, nCols, iVal=0) {
		// creates an empty 2d array for initilizing a blank project input
		return [...Array(nRows)].map(e => Array(nCols).fill(iVal));
	},

	initializeUniformArray(nVals, iVal=0) {
		// creates an empty 2d array for initilizing a blank project input
		return Array(nVals).fill(iVal);
	},

	transposeArray(m) {
		let mT = m[0].map((x,i) => m.map(x => x[i]));
		return mT
	},

	createDefaultExDg() {
		// creates a default, empty existing Dg asset
		return(
			{
				exDers_dgName: '',
				exDers_dgCap: '',
				exDers_dgFuel: '',
				exDers_dgEff: '',
				exDers_dgBackupOnly: false,
				exDers_dgEnableChp: false,
				exDers_dgHeatAlpha: '',
				exDers_dgOmRate: '',
				exDers_dgUnits: ''
			}
		)
	},

	createDefaultNewDg() {
		// creates a default, empty new Dg asset
		return(
			{
				newDers_dgName: '',
				newDers_dgCapCost: 0,
				newDers_dgLifetime: 0,
				newDers_dgOmCost: 0,
				newDers_dgCap: 0,
				newDers_dgFuel: null,
				newDers_dgEff: 0,
				newDers_dgBackupOnly: false,
				newDers_dgEnableChp: false,
				newDers_dgHeatAlpha: 0,
			}
		)
	},

	createDefaultNewEe() {
		return (
			{
				ee_name: '',
				ee_capCost: 0,
				ee_lifetime: 0,
				ee_timeVar: false,
				ee_fractional: true,
				ee_impactsUniform: this.initializeUniformArray(6),
				ee_impactsVariable: this.initializeUniformTable(24,6)
			}
		)
	},

	createTestEe() {
		const testNewEeList = [
			{
				ee_name: 'fake EE 01',
				ee_capCost: 45000,
				ee_lifetime: 20,
				ee_timeVar: false,
				ee_fractional: true,
				ee_impactsUniform: this.initializeUniformArray(6, 0.3),
				ee_impactsVariable: this.initializeUniformTable(24,6)
			},
			{
				ee_name: 'fake EE 02',
				ee_capCost: 999999,
				ee_lifetime: 20,
				ee_timeVar: false,
				ee_fractional: true,
				ee_impactsUniform: this.initializeUniformArray(6, 0.66),
				ee_impactsVariable: this.initializeUniformTable(24,6)
			},
			{
				ee_name: 'fake EE 03',
				ee_capCost: 123456,
				ee_lifetime: 20,
				ee_timeVar: true,
				ee_fractional: true,
				ee_impactsUniform: this.initializeUniformArray(6, 0.3),
				ee_impactsVariable: this.initializeUniformTable(24,6, 0.99)
			},
			
		]
		 return testNewEeList
	},

	createNew() {
		// returns an initial project object
		return (
			{
				gen_name: '',
				gen_description: '',

				loc_stateUs: '',
				loc_city: '',
				// loc_climateZone: null,
				loc_stationId: -999,

				bldg_type: '',
				bldg_vint:'',
				bldg_climateZone: null,
				bldg_bldgId: -999,
				bldg_scaleEnergy: false,
				bldg_size: '',
				bldg_annElec: '',
				bldg_annNg: '',

				
				tariff_select_touRate: true,
				tariff_select_tieredERate: false,
				tariff_select_hourlyRate: false,
				tariff_select_monDmd: true,
				tariff_select_dayDmd: false,
				tariff_select_flatFuel: true,
				tariff_select_tieredFuel: false,
				tariff_select_monFees: false,
				tariff_select_standby: false,

				tariff_seasons: this.transposeArray(initialSeasons),
				tariff_hours: this.transposeArray(initHours),
				tariff_hoursSum: this.transposeArray(initHoursSum),
				tariff_hoursWin: this.transposeArray(initHoursWin),
				tariff_touRates: initTouRates, //this.initializeUniformTable(2,3),
				tariff_tierElecLimit: this.initializeUniformTable(12,3),
				tariff_tierElecRate: this.initializeUniformTable(12,3),
				tariff_hourlyRateSum: this.initializeUniformTable(24,3),
				tariff_hourlyRateWin: this.initializeUniformTable(24,3),
				tariff_monthlyDmd: initDmdRates, //this.initializeUniformTable(5,2),
				tariff_dailyDmd: this.initializeUniformTable(5,2),
				// tariff_fuelFlat: this.initializeUniformTable(4,12),
				// tariff_tierNgLimit: this.initializeUniformTable(12,3),
				// tariff_tierNgRate: this.initializeUniformTable(12,3),
				// tariff_monFees: this.initializeUniformArray(4),
				tariff_elecServiceFee: 0,
				tariff_standbyFee: 0,

				fuels_ng_flatRate: 10, // $/therm
				fuels_diesel_flatRate: 3.50, // $/gal
				fuels_biodiesel_flatRate: 4.50, // $/gal
				fuels_hydrogen_flatRate: 16.500, // $/kg
				fuels_ng_conversion: 30.77, // kWh/therm
				fuels_diesel_conversion: 35.75, // kWh/gal
				fuels_biodiesel_conversion: 35.75, // kWh/gal
				fuels_hydrogen_conversion: 36.11, // kWh/kg
				fuels_ng_fees: 0,
				fuels_diesel_fees: 0,
				fuels_biodiesel_fees: 0,
				fuels_hydrogen_fees: 0,

				export_enablePV: false,
				export_enableOther: false,
				export_allowNetExport: false,
				export_useNem: false,
				export_nemFraction: 1.0,
				export_nemReduction: 0.0,
				export_flatRate: 0,
				export_limitExportBool: false,
				export_limitExportPower: 0,

				ders_pvAngle: null,
				ders_pvOrient: null,
				ders_esChargeRate: 0.3,
				ders_esDischargeRate: 0.3,
				ders_esChargeEff: 0.9,
				ders_esMinSoc: 0,
				ders_esMaxSoc: 1.0,
				ders_maxIdenUnits: 2,
				ders_maxPvBool: false,
				ders_maxPvCap: 0,
				ders_maxEsBool: false,
				ders_maxEsCap: 0,
				ders_maxDgBool: false,
				ders_maxDgCap: 0,


				exDers_exPv: false,
				exDers_exEs: false,
				exDers_exDg: false,
				exDers_pvCap: 0,
				exDers_esCap: 0,
				
				exDers_dgList:[],
				exDers_dgName: null,
				exDers_dgCap: 0,
				exDers_dgFuel: '',
				exDers_dgEff: 0,
				exDers_dgBackupOnly: false,
				exDers_dgEnableChp: false,
				exDers_dgHeatAlpha: 0,
				exDers_dgOmRate: 0,
				exDers_dgUnits: 0,

				newDers_newPv: false,
				newDers_newEs: false,
				newDers_newDg: false,

				newDers_pvFixedCost: 1000,
				newDers_pvVarCost: 2800,
				newDers_pvLifetime: 20,
				newDers_pvOmCost: 5,

				newDers_esFixedCost: 1000,
				newDers_esVarCost: 350,
				newDers_esLifetime: 10,
				newDers_esOmCost: 20,

				newDers_dgList: [],
				newDers_dgName: null,
				newDers_dgCapCost: 0,
				newDers_dgLifetime: 0,
				newDers_dgOmCost: 0,
				newDers_dgCap: 0,
				newDers_dgFuel: null,
				newDers_dgEff: 0,
				newDers_dgBackupOnly: false,
				newDers_dgEnableChp: false,
				newDers_dgHeatAlpha: 0,

				ee_eeList: [],
				ee_name: null,
				ee_capCost: 0,
				ee_lifetime: 0,
				ee_timeVar: false,
				ee_fractional: true,
				ee_impactsUniform: this.initializeUniformArray(6),
				ee_impactsVariable: this.initializeUniformTable(24,6),

				res_outageDays: this.initializeUniformArray(12),
				res_critLoads: _.cloneDeep(initCritLoads), //this.initializeUniformArray(4),
				res_allowCritShed: false,
				res_voll: 1.0,
				res_vollCrit: 30,
				
			}
		)

	}
				
			
}

export default initProject;