import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';


import './AccountForms.css';
import Navbar from '../Navbar/Navbar'
import Helper from '../../helper';

import vectors from '../../resources/vectors.js'

const warningSvg = vectors.warningSvg

class Login extends React.Component{ 
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      formValid: true,
      loginFail: false,
      loginSuccess: false,
      loginMsg: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirectReset = this.redirectReset.bind(this);
  }



  // Form submitting logic, prevent default page refresh  
  async handleSubmit(event) { 

    event.preventDefault()

    // if form is not valid, do nothing
    if(!this.state.formValid) {
      return;
    }

    let data =  {
        'email': this.state.email,
        'password': this.state.password
    }

    let urlAddUser = `${process.env.REACT_APP_URL_BASE}/auth/login`;

    let response = await fetch(urlAddUser, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });
    
    // if new user POST request successful
    if(response.status===200) {
      let result = await response.json();

      if(result.auth) {

        // if login successful, redirect to user home page
        this.setState({loginSuccess: true, loginMsg: null})
        await this.props.checkAuth();
        return;

      } else {
        this.setState({loginFail: true, loginMsg: 'Account not authenticated'})
      }
      
    } else {
      this.setState({loginFail: true, loginMsg: response.statusText})
      // console.log(response.statusText)
    }
    
  }
  
  // Method causes to store all the values of the  
  // input field in react state single method handle  
  // input changes of all the input field using ES6  
  // javascript feature computed property names 
  handleChange(event){     

    this.setState({[event.target.name]: event.target.value}, () => {
        // validate new inputs
        let validEmail = Helper.emailIsValid(this.state.email);
        let validPassword = Helper.pwIsLongEnough(this.state.password);
        
        // validate entries for new account submission
        if(validEmail && validPassword){
          this.setState({formValid: true, loginMsg: ''})
        } else {
          this.setState({formValid: false, loginMsg: ''})
        }
    }) 
  }
    
  styleSubmitButton(entryValid) {
    if(entryValid) {
      return 'form-button valid-form-button';
    } else {
      return 'form-button invalid-form-button';
    }
  }

  redirectReset() {
    this.props.history.push({
      pathname: '/acct/reset',
    })
  }

  renderNewForm() {
    return (
      <section className = 'form-section-signin'>
        <h1>Sign Into Account</h1>
              <form  className='signin-form'  onSubmit={this.handleSubmit}> 

              <div className='signin-field'> 
                <label htmlFor='email' className='signin-label'>Email Address</label> 
                <input  
                  name='email'
                  className='signin-input'
                  value = {this.state.email} 
                  onChange={this.handleChange} 
                  required
                /> 
              </div> 

              <div className='signin-field'> 
                <label htmlFor='last' className='signin-label'>Password</label> 
                <input 
                  name='password' 
                  className='signin-input'
                  type="password"
                  value={this.state.password} 
                  onChange={this.handleChange} 
                  required
                /> 
              </div>

              
              <button className={this.styleSubmitButton(this.state.formValid)}><h2>Submit</h2></button> 
              

          </form> 
          <div className='form-field'> 
            <p className='form-msg'>{this.state.loginMsg}</p>
          </div> 

          <div className='form-footer'>
              <p>Forgot Password? 
                <span className='form-footer-link' onClick={(this.redirectReset)}>Request Password Reset.</span>
              </p>
          </div>
        </section>
    )
  }
  

  render(){ 
    return(
      <>
        <Navbar/> 
        <div className="page-scroll-container">
          <div className='form-container'>
            {(this.state.loginSuccess && this.props.auth)? <Redirect  to="/u" /> : this.renderNewForm()}  
          </div>
        </div>
        </>
    ) 
  } 
} 


export default withRouter(Login);