import React from 'react';

import './Inputs.css';


class InputTextArea extends React.Component{
	

  render(){ 
  	// create label if passed in props
  	var labelHtml = null
  	if(this.props.fieldLabel) {
  		labelHtml = (
  			<div className='np-label'>	
					<label htmlFor={this.props.fieldName} className='np-input-label'>
						{this.props.fieldLabel}: 
					</label>
				</div>
  		)  
  	}

    return( 
    	
    	// props needed: fieldName, fieldLabel, formvalue, unitLabel, onChange
		
		<div className='np-data np-field'> 
				<div className='np-label'>	
	        	{labelHtml}
	      </div>
        <textarea 
          name={this.props.fieldName}
          className='np-input np-text-area'
          value={this.props.formValue} 
          onChange={this.props.onChange} 
          required
        /> 
    </div> 
		
    ) 
  } 
} 


export default InputTextArea;