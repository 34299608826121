import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class ChartAnnual extends PureComponent {

  render() {

    // // initialize data as an empty array
    // var data = []
    // var barList = []

    // process data into correct form for plotting
    // if (this.props.data) {
    //     // if data is in props, extract keys which rep labels of each group
    //     const groupLabels = Object.keys(this.props.data)
        
    //     // extract the names of the data points within each group
    //     const itemLabelsRaw =  Object.keys(this.props.data[groupLabels[0]])
    //     // convert raw data item labels to processed labels
    //     var itemLabels = itemLabelsRaw.map((e) => ( 
    //        this.props.procRowLabels(e)
    //     ));
    //     console.log('raw item labels:')
    //     console.log(itemLabelsRaw)
        
    //     console.log('processed item labels:')
    //     console.log(itemLabels)

    //     // loop through props data objects, process, and add to list
    //     for (let i = 0; i < groupLabels.length; i++) {
    //       let groupLabel = groupLabels[i];
          
    //       // extract data from group. keys will need to be renamed
    //       let groupDataRaw = this.props.data[groupLabel]
    //       let groupData = {}

    //       // update data key labels with processed names
    //       for (let jj = 0; jj < itemLabelsRaw.length; jj++) {
    //             let itemLabelRaw = itemLabelsRaw[jj]
    //             let itemLabel = itemLabels[jj];

    //             groupData[itemLabel] = groupDataRaw[itemLabelRaw]
                
    //         }

    //         // add name to groupdata, used for plotting
    //         groupData['name'] = this.props.procColLabels(groupLabel)

    //         data.push(groupData)
    //     }

    //     // console.log(itemLabels)

    //     // build barchart items list
    //     for (let jj = 0; jj < itemLabels.length; jj++) {
    //       let itemLabel = itemLabels[jj];
    //       let itemLabelRaw = itemLabelsRaw[jj]
          
    //       let newBar =  <Bar dataKey={itemLabel} fill={this.props.colorMap[itemLabelRaw]}/>

    //       barList.push(newBar)

    //     }
    // }

    
    

    const barList = [
      <Bar dataKey='Existing Assets' fill='#449944'/>,
      <Bar dataKey='New Investments' fill='#444499'/>
    ]


    return (

      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {barList}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default ChartAnnual;
