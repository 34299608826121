import React from 'react';
import { withRouter } from 'react-router-dom';


import { cloneDeep } from 'lodash';

import Helper from '../../helper';

import { ReactComponent as ErrorIcon } from '../../icons/error.svg';

class ResetRequest extends React.Component{ 
  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      resetEmail: null,
      emailValid: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleResetSubmit = this.handleResetSubmit.bind(this);
    this.redirectNew = this.redirectNew.bind(this);

  }


  // Resend submitting logic, prevent default page refresh  
  async handleResetSubmit(event) { 
    
    const email = this.state.resetEmail;
    event.preventDefault()

    // if form is not valid, do nothing
    if(!this.state.emailValid) {
      // if attempting to submit before form is valid, show invalid fields
      console.log('cannot submit invalid form')
      return;
    }

    let data =  {
        'email': email
    }

    let urlReqReset = `${process.env.REACT_APP_URL_BASE}/auth/resetreq`;

    let response = await fetch(urlReqReset, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    });

    if(response.status===200) {
      // new account request successfully received by server
      // redirect to req (successful request) page
      console.log('pw reset req successful')
      this.props.history.push({
        pathname: '/acct/resetsent',
      })
    } else {
      console.log(response.status)
      console.log(response.statusText)
      // this.setState({errors:{requestFailed:true}})
    }
    
  } 
  

  handleChange(event){ 

    
      // extract data from input
      let curEmail = event.target.value;   

    
      // check if valid
      let emailValid = Helper.emailIsValid(curEmail);

      // update state
      this.setState({
        resetEmail: curEmail,
        emailValid: emailValid
      })
    
  }

    
  styleSubmitButton(entryValid) {
    if(entryValid) {
      return 'form-button valid-form-button';
    } else {
      return 'form-button invalid-form-button';
    }
  }

  redirectNew() {
    this.props.history.push({
      pathname: '/acct/register',
    })
  }


  generateFooter() {

    if(this.state.errors.requestFailed) {
      return (
        <div className='form-footer'>
          <ErrorIcon/>
              <p>Request failed. Please try again later.</p>
          </div>
      )

    }

    if(this.state.errors.missingEmail) {
      return(
        <div className='form-footer'>
          <ErrorIcon/>
              <p>No account found for this email.
                <span className='form-footer-link' onClick={this.redirectNew}>Create a new account here.</span>
              </p>
          </div>
      )
    } else if(this.state.errors.deletedUser) {
      return(
        <div className='form-footer'>
          <ErrorIcon/>
              <p>This account has been disabled or deleted.</p>
          </div>
      )
    } else {
      return(
        <div className='form-footer'>
              <p>Don't have an account?
                <span className='form-footer-link' onClick={this.redirectNew}>Create a new account here.</span>
              </p>
          </div>
      )
    }
    
  }

  

  render() {
    return (
      <div className="user-form-container">
        <section className = 'form-section'>
          <h1>Reset Account Password</h1>
              <form onSubmit={this.handleResetSubmit}> 
              
              <div className='form-field'> 
                <label htmlFor='resetEmail'>Email Address</label> 
                <input  
                  name='resetEmail'
                  
                  value = {this.state.resetEmail} 
                  onChange={this.handleChange} 
                  required
                /> 
              </div>

              <div className='form-field'> 
                <button className={this.styleSubmitButton(this.state.emailValid)}><h2>Submit</h2></button> 
              </div> 
            </form>
            {this.generateFooter()}
          </section>
        </div>
    )
  }

} 


export default withRouter(ResetRequest);