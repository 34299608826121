import React from 'react';

import Helper from '../../helper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

import ResultsTable from '../ResultsTable/ResultsTable';
import ChartAnnual from '../ChartAnnual/ChartAnnual'
import ToggleSection from '../ToggleSection/ToggleSection'

import ResultsSelect from './ResultsSelect'

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';

import { convertAnnDataForPlot, convertMonDataForPlot, processMonTable, processTsPlotData,
				 getMetricOptions, getMetricFromKey, transposeData,
				 filterFirst, filterMetricCat, processInvPlot, unpackInvData, unpackAnnData, unpackMonData
			 } from './resultsProcessing'


class SectionAnn extends React.Component {

	constructor(props){
		super(props)

		this.renderSection = this.renderSection.bind(this);
	}

	renderSection() {

		if(!this.props.metricsAnn){
		// if data isn't load yet, return nothing
			return null
		}

		// define annual metric title, if a valid metric has been selected
		var curAnnMetric = getMetricFromKey(this.props.metricsAnn, this.props.keyAnnMetric)
		
		if(curAnnMetric) {
			var annChartTitle = `${curAnnMetric.label} [Unit:${curAnnMetric.unit}]`;
		} else {
			var annChartTitle = 'Select Metric To Display';
		}

		// split data by category
		let finData = filterMetricCat(this.props.dataAnnTable, this.props.metricsAnn, 'fin')
		let energyData = filterMetricCat(this.props.dataAnnTable, this.props.metricsAnn, 'energy')
		let powerData = filterMetricCat(this.props.dataAnnTable, this.props.metricsAnn, 'power')

		
		// process data for tables
		let tableDataExFin = filterFirst(transposeData(finData), 'ex')
		let tableDataNewFin = filterFirst(transposeData(finData), 'new')

		let tableDataExEn = filterFirst(transposeData(energyData), 'ex')
		let tableDataNewEn = filterFirst(transposeData(energyData), 'new')

		let tableDataExPow = filterFirst(transposeData(powerData), 'ex')
		let tableDataNewPow = filterFirst(transposeData(powerData), 'new')

		return(
			<div className='results-section'>
				<h2 className='results-heading'>3. Annual Metrics</h2>
				
				<div className='res-select-multi-container'>
					<ResultsSelect 
						name='keyAnnCat'
						label='Category'
						onChange={this.props.updateAnnSelect}
						optionList={this.props.metricCategories}
					/>
					<ResultsSelect 
						name='keyAnnMetric'
						label='Metric'
						onChange={this.props.updateAnnSelect}
						optionList={getMetricOptions(this.props.metricsAnn, this.props.keyAnnCat)}
					/>
				</div>

				<div className='results-chart-container'>
					<h3 className='chart-title'>{annChartTitle}</h3>
					<ChartAnnual
						data={this.props.dataAnnPlot}
						colorMap={this.props.colorMap}
					/>
				</div>

				<ToggleSection
					label='Financial Metrics'
				>

					<div className='results-subsection'>
						<ResultsTable
							data={tableDataExFin}
							title='Existing Assets'
							unit='$/yr'
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getMetricLabel}
						/>

						<ResultsTable
							data={tableDataNewFin}
							title="New Investments"
							unit='$/yr'
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getMetricLabel}
						/>
					</div>
					<div className='results-note-table'>
						<p>
							Note: data tables can be highlighted, copied, and pasted
							into Excel or other spreadsheet applications.
						</p>
					</div>
	
				</ToggleSection>
				

				<ToggleSection
					label='Energy Metrics'
				>

					<div className='results-subsection'>
						<ResultsTable
							data={tableDataExEn}
							title='Existing Assets'
							unit='kWh/yr'
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getMetricLabel}
						/>

						<ResultsTable
							data={tableDataNewEn}
							title="New Investments"
							unit='kWh/yr'
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getMetricLabel}
						/>
					</div>
					<div className='results-note-table'>
						<p>
							Note: data tables can be highlighted, copied, and pasted
							into Excel or other spreadsheet applications.
						</p>
					</div>

				</ToggleSection>

				<ToggleSection
					label='Power Metrics'
				>

					<div className='results-subsection'>
						<ResultsTable
							data={tableDataExPow}
							title='Existing Assets'
							unit='kW'
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getMetricLabel}
						/>

						<ResultsTable
							data={tableDataNewPow}
							title="New Investments"
							unit='kW'
							procColLabels={this.props.getOutageScenario}
							procRowLabels={this.props.getMetricLabel}
						/>
					</div>
					<div className='results-note-table'>
						<p>
							Note: data tables can be highlighted, copied, and pasted
							into Excel or other spreadsheet applications.
						</p>
					</div>

				</ToggleSection>


			</div>
		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Annual Metrics'
			/>
		)
	}

}

export default SectionAnn;