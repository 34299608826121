import React from 'react';
import { Link, Route, Switch, withRouter, Redirect } from 'react-router-dom';

import './Admin.css';

// import Helper from '../../helper';

import PendingUsers from './PendingUsers'
import DashUsersAll from './DashUsersAll'
import DashStatus from './DashStatus'

class AdminRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }

  }

  renderAdminBar() {
    // renders horizontal bar of buttons to navigate between admin pages
    const url = this.props.match.url

    return(
      <div className='admin-link-bar'>

        <div className='text-button admin-link-btn'>
          <Link to={`${url}/status`} className='react-link'>
            <h2> Status </h2>
          </Link>
        </div>

        <div className='text-button admin-link-btn'>
          <Link to={`${url}/pending`} className='react-link'>
            <h2> Pending </h2>
          </Link>
        </div>

        <div className='text-button admin-link-btn'>
          <Link to={`${url}/users`} className='react-link'>
            <h2> Users </h2>
          </Link>
        </div>

        <div className='text-button admin-link-btn'>
          <Link to={`${url}`} className='react-link'>
            <h2> Back </h2>
          </Link>
        </div>
        
      </div>
    )
  }


  

  render() {
    const url = this.props.match.url
    // console.log(url)
    // check that url does not end with '/' char. is inconsistent?
    if(url.endsWith("/")){
      url = url.slice(0, -1)
    }
    // console.log(url)

    // if not auth, redirect to home
    if(!this.props.auth && !this.props.authFetch) {
      // console.log(`accesing page without auth. auth status: ${this.props.auth}`)
      return(
        <Redirect from={url} to="/" />
      )
    }

    // if not admin after fetch complete, redirect to home
    if(!this.props.authUser.admin && !this.props.authFetch) {
      console.log(`accesing page without authorization status`)
      return(
        <Redirect from={url} to="/u/" />
      )
    }

    // return blank if still fetching auth status
    if(this.props.authFetch) {
      return(null)
    }

    return (
          

            <Switch>
              <Route exact path={url}>
                <div className='admin-page-container'>
                  <div className='admin-menu-container'>
                    <h2 className='admin-h2'> Admin Dashboard </h2>
                    
                    <div className='text-button admin-btn'>
                      <Link to={`${url}/pending`} className='react-link'>
                        <h2> New User Requests </h2>
                      </Link>
                    </div>

                    <div className='text-button admin-btn'>
                      <Link to={`${url}/users`} className='react-link'>
                        <h2> Full User List </h2>
                      </Link>
                    </div>

                    <div className='text-button admin-btn'>
                      <Link to={`${url}/status`} className='react-link'>
                        <h2> Server Status </h2>
                      </Link>
                    </div>

                  </div>

                </div>
              </Route>

              <Route path={`${url}/pending`}>
                <PendingUsers
                  adminLinkBar={this.renderAdminBar()}
                />
              </Route>

              <Route path={`${url}/users`}>
                  <DashUsersAll
                  adminLinkBar={this.renderAdminBar()}
                />
              </Route>

               <Route path={`${url}/status`}>
                <DashStatus
                  adminLinkBar={this.renderAdminBar()}
                />
              </Route>

              <Route path={`${url}/solved`}>
                <div className='admin-page-container'>
                  <h2> Recent Model Solutions</h2>
                </div>
              </Route>
              
            </Switch>
    )
  }
}

export default withRouter(AdminRouter);
