import React from 'react';
import { BrowserRouter, Link, Route, Switch, withRouter, Redirect } from 'react-router-dom';

import './AccountForms.css';

import Navbar from '../Navbar/Navbar'

// new account pages
import NewRegister from './NewRegister';
import NewSent from './NewSent';
import NewActivate from './NewActivate';
import NewSuccess from './NewSuccess';

// pw reset pages
import ResetRequest from './ResetRequest';
import ResetSent from './ResetSent';
import ResetPwForm from './ResetPwForm';
import ResetSuccess from './ResetSuccess';


import Helper from '../../helper';

class UserNewRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }

  }


  render() {
    const url = this.props.match.url

    return (

      <>
        <Navbar/>
        <div className="page-scroll-container activate-container">

          <Switch>
            <Route exact path={`${url}/register`}>
              <NewRegister
              />
            </Route>
            <Route exact path={`${url}/newreq`}>
              <NewSent
              />
            </Route>
            <Route exact path={`${url}/activate/:token`}>
              <NewActivate 
              />
            </Route>
            <Route exact path={`${url}/created`}>
              <NewSuccess
              />
            </Route>

            <Route exact path={`${url}/reset`}>
              <ResetRequest
              />
            </Route>
            <Route exact path={`${url}/resetsent`}>
              <ResetSent
              />
            </Route>
            <Route exact path={`${url}/reset/:resetToken`}>
              <ResetPwForm 
              />
            </Route>
            <Route exact path={`${url}/resetsuc`}>
              <ResetSuccess
              />
            </Route>
          </Switch>
        </div>
      </>
    )
  }
}

export default withRouter(UserNewRouter);
