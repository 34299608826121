import React from 'react';



class ProjectPageIntro extends React.Component {



	render() {

		return(
			<div className='page-scroll-container-new-project project-main-container'>

				<div className='npdc-container'>

					<h1 className='npdc-header'> DER-CAM Model Constructor </h1>

					<div className='npdc-panel'>

	    				<div className='npdc-section'>

	    					<h2 className='npdc-subheader'> Getting Started </h2>

		    				<p className='npdc-text'>
		    					Welcome to the new web-based user interface for DER-CAM. This UI provides users
		    					with a structured environment for constructing and modifying DER-CAM project models.
		    					Use the project navigation bar at the top of the page to access different menus for entering
		    					project data.
		    					<br/><br/>
		    					Please see below for information on each of the project-builder pages. You may freely navigate
		    					between these pages as you construct your model. Navigating away from this page may cause your
		    					project data to be lost.
		    				</p>

	    				</div>

	    				<div className='npdc-section'>

	    					<h2 className='npdc-subheader'> Project Configuration Menu</h2>

		    				<p className='npdc-text'>
		    					Here users can enter general information about their project. Users can also select which DER-CAM
		    					features they would like to include in their projet construction. Inputs related to unselected options
		    					will not be shown to users when they define model data on the 
		    					<span className='npdc-emph'> Input Tables</span> page. 
		    					<br/><br/>
		    					If users wish to add hidden features, they can return to the 
		    					<span className='npdc-emph'> Project Configuration </span> 
		    					page, and enable those features. The inputs related to this items will then be accessible on the 
		    					<span className='npdc-emph'> Input Tables</span> page. 
		    				</p>

	    				</div>

	    				<div className='npdc-section'>

	    					<h2 className='npdc-subheader'> Load Profile Generator </h2>

		    				<p className='npdc-text'>
		    					Here users can define the load profiles used in their project model. Users can select from existing
		    					reference building types, and scale the load profiles to their building size or annual energy consumptions.
		    					<br/><br/>
		    					Users can also generate DER-CAM load profiles from their own annual energy consumption profiles by copying and
		    					pasting plain text data into the field provided.
		    					<br/><br/>
		    					For multi-node models, users can specify which node the load corresponds to, and define different building types
		    					and/or load profile for each node.
		    				</p>

	    				</div>

	    				<div className='npdc-section'>

	    					<h2 className='npdc-subheader'> Solar Profile Generator</h2>

		    				<p className='npdc-text'>
		    					Here users can define the solar profile (related to solar PV and solar thermal energy technologies) for their
		    					project location. DER-CAM offers a large set of typical solar profiles for locations in the United States.
		    					<br/><br/>
		    					Users can also provide their own annual hourly solar data to generate a custom (or non-U.S.) solar profile.
		    				</p>

	    				</div>

	    				<div className='npdc-section'>

	    					<h2 className='npdc-subheader'> Network Settings (under development)</h2>

		    				<p className='npdc-text'>
		    					Here users can define the topology of their project site's network. This includes the number of nodes,
		    					their electrical and thermal connections, and the physical properties of those connections.
		    				</p>

	    				</div>

	    				<div className='npdc-section'>

	    					<h2 className='npdc-subheader'> Input Tables</h2>

		    				<p className='npdc-text'>
		    					The model construction pages above provide methods to quickly define a model and import complex datasets.
		    					Once that is complete, the <span className='npdc-emph'> Input Tables</span> page allows users to review and
		    					update the model options and settings relevant to their project.
		    					<br/><br/>
		    					Users can navigate through these tables using the <span className='npdc-emph'> Table List</span> in the left-side
		    					panel. Users will only see tables based on the features selected in the 
		    					<span className='npdc-emph'> Project Configurations</span> page.
		    				</p>

	    				</div>

	    				<div className='npdc-section'>

	    					<h2 className='npdc-subheader'> Project Model Submission</h2>

		    				<p className='npdc-text'>
		    					Once the project model has been constructed, users can submit the model data to the DER-CAM server for solving
		    					from this page. The status of submitted models can be seen by navigating to the 
		    					<span className='npdc-emph'> My Projects</span> page of the DER-CAM application.
		    				</p>

	    				</div>

	    			</div>

	    		</div>



	    	</div>
		)

		
	}
}



export default ProjectPageIntro;