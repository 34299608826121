import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';

import './ModelCard.css'

import Helper from '../../helper';
import ApiHelper from '../../apiHelper';

import { ReactComponent as CaretIcon } from '../../icons/caret.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevron.svg';

import { ReactComponent as CaretSolidIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronSolidIcon } from '../../icons/chevronSolid.svg';


class ModelCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
			deleted: false,

			resultsAvailable: false,
			deletable: true,
			barInactive: false,
			barMsg: null,

			dlPending: false,
			dlSuccess: false,
			dlFail: false,
			dlLink: '',
		}

		this.toggleExpand = this.toggleExpand.bind(this);
		this.removeProjectCard = this.removeProjectCard.bind(this);
		this.restoreProjectCard = this.restoreProjectCard.bind(this);
		this.openProjectCopyCard = this.openProjectCopyCard.bind(this);
		this.moveToDeleted = this.moveToDeleted.bind(this);
		this.moveToRestored = this.moveToRestored.bind(this);
		this.initCopy = this.initCopy.bind(this);
		this.getModelAttrs = this.getModelAttrs.bind(this);
		this.downloadModelFile = this.downloadModelFile.bind(this);
	}


	componentDidMount(){
		this.getModelAttrs()
	}

	getModelAttrs() {
		// gets model attributes from props passed to Model Card component
		// set the status of the card based on the model category

		var modelStatus = {};

		// if solved resultsAvailable true, deletable true
		// for all new bars, bar is active, no bar msg
		if(this.props.listName==='solved' & this.props.modelData.results_available){
			modelStatus = {
				resultsAvailable: true,
				deletable: true,
				barInactive: false,
				barMsg: null,
				expanded: false,
			}
		// if model is deleted, no results, and model is not deletable
		} else if (this.props.listName==='deleted') {
			modelStatus = {
				resultsAvailable: false,
				deletable: false,
				barInactive: false,
				barMsg: null,
				expanded: false,
			}
		// for all other model cases, no results and model is deletable
		} else {
			modelStatus = {
				resultsAvailable: false,
				deletable: true,
				barInactive: false,
				barMsg: null,
				expanded: false,
			}
		}

		// update state with status items
		this.setState(modelStatus)
	}


	toggleExpand() {
		// function toggles expanded/minimized model card display
		this.setState({expanded:!this.state.expanded});
	}

	moveToDeleted() {
		// function sets state to delete, so that it cannot be interacted with
		const modelStatus = {
			barInactive: true,
			barMsg: 'Deleting...'
		}

		this.setState(modelStatus);
	}

	moveToRestored() {
		// function sets state to delete, so that it cannot be interacted with
		const modelStatus = {
			barInactive: true,
			barMsg: 'Restoring...'
		}

		this.setState(modelStatus);
	}

	initCopy() {
		// function sets state to delete, so that it cannot be interacted with
		const modelStatus = {
			barInactive: true,
			barMsg: 'Creating Copy...'
		}

		this.setState(modelStatus);
	}


	removeProjectCard() {
		// method sends request to delete project
		// if successful, minimizes card, the refreshes project list
		this.props.deleteProject(this.props.modelData.model_key)

		setTimeout(this.toggleExpand
		, 250);

		setTimeout(this.moveToDeleted
		, 500);

		setTimeout(this.props.fetchProjects
		, 1250);

		setTimeout(this.getModelAttrs
		, 1250);

	}

	restoreProjectCard() {
		// method sends request to restore project
		// if successful, minimizes card, the refreshes project list
		this.props.restoreProject(this.props.modelData.model_key)

		setTimeout(this.toggleExpand
		, 250);

		setTimeout(this.moveToRestored
		, 500);

		setTimeout(this.props.fetchProjects
		, 1250);

		setTimeout(this.getModelAttrs
		, 1250);

	}

	openProjectCopyCard() {
		// method sends request to restore project
		// if successful, minimizes card, the refreshes project list

		setTimeout(this.toggleExpand
		, 100);

		setTimeout(this.initCopy
		, 250);

		setTimeout(()=>{this.props.openProjectCopy(this.props.modelData.model_key)}
		, 1000);


	}

	async downloadModelFile() {
		
		// set state to dlpending
		await this.setState({dlPending:true})

		// set small delay
		await Helper.sleep(1500)

		// attempt to download
		var dlLink = await ApiHelper.generateModelDownloadLink(this.props.modelData.model_key, this.props.apiProjectUrl, this.props.modelType)

		// check if valid dlLink returned
		if(dlLink.length == '') {
			await this.setState({dlPending:false, dlFail:true})
		} else {
			await this.setState({dlPending:false, dlFail:false, dlSuccess: true, dlLink: dlLink})
		}
		
	}

	renderResultsButton() {
		// renders actionable button if results are available, that redirects to results page
		// or disabled button if they are not
		if(this.state.resultsAvailable) {
			return (
				<div className='model-card-btn dark-btn'>
	        		<Link to={`${this.props.appUrl}/projects/${this.props.modelData.model_key}`} className='react-link'><h2>View Results</h2></Link>
	        	</div>
			)
		} else {
			return (
				<div className='model-card-btn disabled-card-btn dark-btn'>
	        		<h2>Results Unavailable</h2>
	        	</div>
			)
		}
	}

	renderDeleteButton() {
		// renders actionable button to delete or restore model
		// depending on the current delete status of the model
		if(this.state.deletable) {
			return (
				<div 
					className='model-card-btn dark-btn' 
					onClick={this.removeProjectCard}
				>
	        		<h2>Delete Model</h2>
	        	</div>
			)
		} else {
			return (
				<div 
					className='model-card-btn dark-btn' 
					onClick={this.restoreProjectCard}
				>
					<h2>Restore Model</h2>
	        	</div>
			)
		}
	}

	renderEditButton() {
		// renders actionable button to edit btn if model is not in delete list
		// otherwise return inactive button
		if(this.state.deletable) {
			return (
				<div 
					className='model-card-btn dark-btn' 
					onClick={this.openProjectCopyCard}
				>
	        		<h2>Edit a Copy</h2>
	        	</div>
			)
		} else {
			return (
				<div 
					className='model-card-btn dark-btn disabled-card-btn' 
				>
					<h2>Edit a Copy</h2>
	        	</div>
			)
		}
	}

	renderDownloadButton() {
		// renders actionable button to download model input as json file
		
		const dlPlaceholder = () => {
			console.log('init model input download. Doesnt do anything yet.')
		}

		if(this.state.dlPending) {

			// if pending, showing loading icon
			return (
				<div 
					className='model-card-btn dark-btn disabled-card-btn' 
				>
	        		<h2>Loading</h2>
	        	</div>
			)
		} else if(this.state.dlSuccess) {
			
			// if succes, show save buton
			return (
				<div 
					className='model-card-btn dark-btn' 
					onClick={dlPlaceholder}
				>
					<a 
							download={`${this.props.modelType}_${this.props.modelData.model_key}_model.json`}
				      // link to the download URL
				      href={this.state.dlLink}
				    >
	        			<h2>Save File</h2>
	        		</a>
	        	</div>
			)
		} else if(this.state.dlFail) {
			return (
				<div 
					className='model-card-btn dark-btn disabled-card-btn'
				>
	        		<h2>Error</h2>
	        	</div>
			)
		} else {
			return (
				<div 
					className='model-card-btn dark-btn' 
					onClick={this.downloadModelFile}
				>
	        		<h2>Download</h2>
	        	</div>
			)
		} 
			
	}

	renderButtonRow() {
		// renders row of buttons displayed at bottom of each expanded model card

		return(
			<div className='model-card-btn-row'>
				{this.renderResultsButton()}
				{this.renderEditButton()}
	        	{this.renderDeleteButton()}
	        	{this.renderDownloadButton()}
			</div>
		)


	}


	renderModelBar() {

		// renders basic model info in top "bar" that can be expanded to full model content
		// or bar indicating that user has deleted model

		// set model interactivity/classname based on whether bar is inactive
		var barOnclick = null;
		var barActiveClassName = '';
		if(!this.state.barInactive){
			barOnclick = this.toggleExpand;
			barActiveClassName = '';
		} else {
			barOnclick = null;
			barActiveClassName = 'model-bar-inactive';
		}

		
		return (
			<div className={`model-bar model-status-${this.props.listName} ${barActiveClassName}`} onClick={barOnclick}>
				<div className={`model-bar-left`}>
					
					<div className='model-details-toggle-btn'>
						{this.state.expanded ? < CaretSolidIcon/> : < ChevronSolidIcon/>}
					</div>

					<div className='model-bar-segment'>
						<p className='model-bar-data'>{this.props.modelData.label}</p>
					</div>
					
				</div>

				<div className={`model-bar-center`}>

					<div className='model-bar-segment'>
						<p className='model-bar-data'>{this.state.barMsg}</p>
					</div>
					
				</div>

				<div className={`model-bar-right`}>
					<div className='model-bar-segment'>
						<p className='model-bar-data'>{`${this.props.modelData.datetime_added} PST`}</p>
					</div>
				</div>
				
			</div>
		)
	}


	createModelTable(tableName, dataArr) {
		// creates a data section table with given name and data

		return (
			<React.Fragment>
				<div className='model-content-row'>
					<h4 className='model-content-header'>{tableName}</h4>
				</div>
				{
					dataArr.map((dataPair)=>(
						<div className='model-content-row'>
							<h4 className='model-content-label'>{`${dataPair[0]}:`}</h4>
							<h4 className='model-content-data'>{dataPair[1] ? dataPair[1] : "None"}</h4>
						</div>
					))
				}
			</React.Fragment>
		)
	}


	renderModelContent() {

		var modelDataArray = [['Model Name', this.props.modelData.label],
						  ['Model Key', this.props.modelData.model_key],
						  ['Date Created', this.props.modelData.datetime_added],
						  ['Status', this.props.modelData.status]]

		var solvedDataArray = [['Date Solved', this.props.modelData.datetime_executed],
						  ['Solver Status', this.props.modelData.solver_status],
						  ['Solve Time', this.props.modelData.solve_time],
						  ['Solver Details', this.props.modelData.msg],
						  ['Results Expiration', this.props.modelData.expiration]]

		var modelSolved = this.props.modelData.status !== 'queued';


		return (
			<div className='model-content'>

				<div className='model-content-section'>

					<div className='model-content-table'>
						{this.createModelTable('Model Info', modelDataArray)}
						{modelSolved ? this.createModelTable('Solve Info', solvedDataArray) : null}
					</div>
				
				</div>

				{this.renderButtonRow()}

				<div className='model-help-section'>
					<p className='model-help-text'>
						<strong>Edit a Copy</strong> fetches the input data used for this model and opens it in the Project Editor.
					</p>
					<p className='model-help-text'>
						When submitted, you will see results for both the new and old project models in your projects list.
					</p>
					<br/>
					<p className='model-help-text'>
						<strong>Delete Model</strong> sends this model to your Deleted Models list. 
					</p>
					<p className='model-help-text'>
						Deleted models can be restored from the Deleted Models list by clicking Restore.
					</p>
					<p className='model-help-text'>
						Deleted models are permanently removed after 10 days in the Deleted Models List.
					</p>
					<br/>
					<p className='model-help-text'>
						User may need to use the <strong>Model List Refresh</strong> button to see changes to model statuses.
					</p>
				</div>
				
			</div>
		)

	}

	render(){
		return(
			<div className='model-card'>
				{this.renderModelBar()}
				{this.state.expanded ? this.renderModelContent() : null}
			</div>
		)
	}
};

export default ModelCard;

				