import React from 'react';

import Modal from '../Modal/Modal'

import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

class ResetSuccess extends React.Component{ 

  render(){ 
    return( 
      <Modal
        icon={<SuccessIcon />}
        label={<>Password Reset Successful</>}
        rotating={false}
        modalText={'Your account password has succesfully been changed. Please log in with your new credentials to access your DERCAM account.'}
      >

      </Modal>
    ) 
  } 
} 


export default ResetSuccess;