 const vectors = {

	 closeSvg: (
	 	<svg  x="0px" y="0px" viewBox="-95.98 336.02 72 72">
<g>
	<path d="M-59.98,336.02L-59.98,336.02c-19.92,0-36,16.08-36,36l0,0c0,19.92,16.08,36,36,36l0,0c19.92,0,36-16.08,36-36l0,0
		C-23.98,352.1-40.06,336.02-59.98,336.02z M-41.66,384.179c1.68,1.681,1.68,4.4,0,6.081c-0.88,0.88-1.92,1.279-3.04,1.279
		c-1.12,0-2.24-0.399-3.04-1.279l-12-12.001l-12.08,12.001c-0.88,0.88-1.92,1.279-3.04,1.279s-2.24-0.399-3.04-1.279
		c-1.68-1.681-1.68-4.4,0-6.081l12.08-12.08l-12.08-12c-1.68-1.68-1.68-4.399,0-6.079c1.68-1.681,4.399-1.681,6.08,0l12.08,12.079
		l12.08-12.079c1.68-1.681,4.4-1.681,6.08,0c1.68,1.68,1.68,4.399,0,6.079l-12.08,12.08L-41.66,384.179z"/>
</g>
</svg>
	 ),

	 warningSvg: (<svg className='submit-warning' 
	 	viewBox="0 0 47.99562 41.00998" x="0px" y="0px">
	 	<path d="M47.66779,37.40988,27.1178,1.8a3.604,3.604,0,0,0-6.24,0L.32783,
	 	37.40988A2.39789,2.39789,0,0,0,2.40778,41.01H45.58784A2.39789,2.39789,0,0,
	 	0,47.66779,37.40988Zm-23.67-3.3999a2,2,0,1,1,2-2A2.00591,2.00591,0,0,1,
	 	23.99781,34.01Zm2-9a2,2,0,0,1-4,0v-11a2,2,0,1,1,4,0Z"></path></svg>),

	 backSvg: (<svg x="0px" y="0px" width="36px" height="36px" viewBox="0 0 36 36">
		<path d="M20.91,30.49l-10.957-9.9c-1.229-1.111-1.229-2.912,0-4.022L20.91,6.668c1.23-1.11,
		2.227-0.667,2.227,0.989V29.5 C23.137,31.158,22.141,31.6,20.91,30.49z"/>
		</svg>),

	 forwardSvg: (<svg x="0px" y="0px" width="36px" height="36px" viewBox="0 0 36 36">
		<path d="M11.258,30.49l10.957-9.9c1.229-1.111,1.229-2.912,0-4.022L11.258,
		6.668c-1.23-1.11-2.227-0.667-2.227,0.989V29.5
		C9.031,31.158,10.027,31.6,11.258,30.49z"/>
		</svg>)

 }



export default vectors;
