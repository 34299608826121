import React from 'react';

import './Admin.css';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';



class DashStatus extends React.Component {
	constructor(props) {
    	super(props);

	    this.state = {
	      latestModels: [],
	      queuedModels: [],
	      serverStats: null
	    }

	    this.fetchQueueStatus = this.fetchQueueStatus.bind(this);
	    

	  }

	async componentDidMount() {
		// fetch list of pending users on mount

		await this.fetchQueueStatus()
	}

	async fetchQueueStatus() {
		// fetch list of pending users on mount

		let urlStatus = `${process.env.REACT_APP_URL_BASE}/admin/status/queue`;

		try{
			let response = await fetch(urlStatus, {credentials: 'include'});

			// if status GET request successful
			if(response.status===200) {
				let result = await response.json();

				this.setState(result)
		    
			  } else {
			    console.log('fetch failed')
			  }
		}
		catch (e) {
		  console.log(e)
		}
	}	

	renderLatestModelList() {
		
		// render a table row for each model in latestModels
		const modelDataList = this.state.latestModels.map((model, index) => {
			if(!model){
				return null;
			}

			let rt = model.solve_time ? parseInt(model.solve_time) : -1;
			let label = model.label;

			if (label.length > 40) {
				label = label.substring(0, 40)
				label += '...'
			}

			

			return (

				<tr className='admin-data-table-row'>
					<td>{model.id}</td> 
				    <td>{model.type}</td> 
				    <td>{model.datetime_added}</td> 
				    <td className={`admin-model-status-${model.status}`}>{model.status}</td> 
				    <td>{label}</td> 
				    <td>{model.user_id}</td>
				    <td>{rt}</td> 
				</tr>
			)
		})

		return (
			<table className='admin-data-table'>
				<tr className='admin-data-table-row'>
					<th><div >Model No.</div></th> 
				    <th><div >Type</div></th> 
				    <th><div >Uploaded</div></th> 
				    <th><div >Status</div></th> 
				    <th><div >Label</div></th> 
				    <th><div >User Id</div></th>
				    <th><div >Runtime</div></th> 
				</tr>
				{modelDataList}
			</table>

		)
	}  


	renderSummary() {

		const stats = this.state.serverStats

		const formatVal = (val) => {
			// func changes null val to 0
			return val ? val : 0;
		}

		if(!stats) {
			return (
				<h2 className='admin-subheader'> Server Stats Loading... </h2>
			)
		}


		const table1 = (
			<table className='admin-small-table'>
				<tr className='admin-small-table-row'>
					<th>All</th> 
				    <td>{formatVal(stats.modelsAll)}</td> 
				</tr>
				<tr className='admin-small-table-row'>
					<th>Queued</th> 
				    <td>{formatVal(stats.queuedAll)}</td> 
				</tr>
				<tr className='admin-small-table-row'>
					<th>Failed</th> 
				    <td>{formatVal(stats.failedAll)}</td> 
				</tr>
			</table>
		)
		
		const table2 = (
			<table className='admin-small-table'>
				<tr className='admin-small-table-row'>
					<th></th> 
				    <th>All</th>
				    <th>Failed</th>
				</tr>
				<tr className='admin-small-table-row'>
					<th>24 Hours</th> 
				    <td>{formatVal(stats.models1)}</td> 
				    <td>{formatVal(stats.failed1)}</td> 
				</tr>
				<tr className='admin-small-table-row'>
					<th>1 Week</th> 
				    <td>{formatVal(stats.models7)}</td> 
				    <td>{formatVal(stats.failed7)}</td> 
				</tr>
				<tr className='admin-small-table-row'>
					<th>30 Days</th> 
				    <td>{formatVal(stats.models30)}</td> 
				    <td>{formatVal(stats.failed30)}</td> 
				</tr>
			</table>
		)

		return (
			<>
				{table1}
				<br/>
				{table2}
			</>

		)
	}

	


	render() {
		return(
		    <div className='page-scroll-container'>
			    <div className='admin-page-container'>
			    	{this.props.adminLinkBar}
				    <div className='admin-header-row-div'>

				      <h2 className='admin-header'> Server Queue Status </h2>
				      <div className='admin-refresh-div dark-btn' onClick={this.fetchQueueStatus}>
			        		<RefreshIcon/>
			        	</div>
			        </div>
			        <h2 className='admin-subheader'> Solver Summary </h2>
			        {this.renderSummary()}
			        <br/>
			        
			        <h2 className='admin-subheader'> Latest Models </h2>
			      	{this.renderLatestModelList()}
		      </div>
		    </div>
		)
	}
}



export default DashStatus;