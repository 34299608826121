import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



class ChartMonthly extends PureComponent {

  generateRandomColor(){
      let maxVal = 0xFFFFFF; // 16777215
      let randomNumber = Math.random() * maxVal; 
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);   
      return `#${randColor.toUpperCase()}`
  }

  getColorFromScen(scen) {
      // console.log(this.props.scenarios)
      // get the color-set for plotting
      var nScen = (this.props.scenarios.length)/2;
      // select entry in colorMap input corresponding to number of outage scens
      var colorMap = this.props.colors[nScen]
      var outageKey = null;

      // match the scenario name to a key in this.props.scenario
      for(let ss=0;ss<this.props.scenarios.length; ss++) {

        let scenData = this.props.scenarios[ss];

        // check if the input scen name comtain the scenario outageLabel
        if (scen.indexOf(scenData.outageLabel) !== -1 ) {
          outageKey = scenData.oo
        }
      }

      // selected color
      var selectedColor = colorMap[outageKey];

      // console.log(`${scen} ${selectedColor}`)
      return selectedColor;



    // if(this.props.colors) {
    //   return this.props.color[scen.oo]
    // } else {
    //   return generateRandomColor()
    // }

  }

  generateLines() {

    // get list of scenarios
    // if(!this.props.data || !this.props.scenarios || !this.props.colors){
    //   return null
    // }

    // get list of scenarios from props.data
    var scenarios = Object.keys(this.props.data[0])

    // remove data entry 'name' if it exists
    const index = scenarios.indexOf('name');
    if (index > -1) {
        scenarios.splice(index, 1);
    }

    var lineList = [];

    for(let ss=0; ss<scenarios.length; ss++){

      // define line style by new vs existing
      if(scenarios[ss].indexOf('New') !== -1){
        var stroke = '1';
      } else {
        var stroke = '5 5';
      }

      let newLine = (
        <Line 
          type="monotone" 
          dataKey={scenarios[ss]} 
          stroke={this.getColorFromScen(scenarios[ss])} 
          strokeDasharray={stroke} 
          strokeWidth={3}
        />
      )
    
      lineList.push(newLine)
    }

    return lineList

  }

  render() {

    try {

      if(!this.props.data || !this.props.scenarios || !this.props.colors){
        return null;
      }

      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={this.props.data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />

          {this.generateLines()}
        </LineChart>
      </ResponsiveContainer>
      );
    } catch {
      console.log('monthly chart failed to render')
      return null;
    }
  }
}

export default ChartMonthly
