import React from 'react';

import './Inputs.css';


class InputCheckbox extends React.Component{ 
	

  render(){ 

  	// create label if passed in props
  	var labelHtml = null
  	if(this.props.fieldLabel) {
  		labelHtml = (
  			<div className='np-label'>	
					<label htmlFor={this.props.fieldName} className='np-input-label'>
						{this.props.fieldLabel}: 
					</label>
				</div>
  		)  
  	}

    return( 
    	
    	// props needed: fieldName, fieldLabel, formvalue, unitLabel, onChange
		
		<div className='np-data np-checkbox'>
				{labelHtml}
      	<div className='npcheckbox-box'>	
	          <input  
	            name={this.props.fieldName}
	            type="checkbox"
	            checked = {this.props.formValue} 
	            onChange={this.props.onChange} 
	            className=''
	            data-type={this.props.type}
	          /> 
	       </div>
	  </div>  
		
    ) 
  } 
} 


export default InputCheckbox;