import React from 'react';

import './ModelAssetList.css'

import Helper from '../../helper';


class ModelAsset extends React.Component {

	displayAssetData(dataItem) {
		// checks if data item is bool and replaces with legible char
		// otherwise, returns dataItem unchanged
		if (typeof dataItem == "boolean") {
		    // variable is a boolean
		    if (dataItem) {
		    	return ('●')
		    } else {
		    	return ('○')
		    }
		} else if (dataItem===0){
			return dataItem
		} else if (!dataItem || dataItem=='' || dataItem==null){
			// if dataItem is null or '' add space to render better
			console.log('replacing empty item')
			return '[missing]'
		} else if (typeof dataItem === 'string') {
			// can only apply replace method if type of dataItem is string
			if (!dataItem.replace(/\s/g, '').length) {
				// if dataItem is null or '' add space to render better
				// console.log('replacing empty item')
				return '[missing]'
			} else {
				return dataItem
			}
		} else {
			return dataItem
		}
	}

	render(){

		// extract data from assetData using visKey props
		// e.g. the keys of the data that will be visible in the
		// asset display
		var assetRow = this.props.visKeys.map((visKey) => (
				<td>
					<div className='asset-data-text'>
						{this.displayAssetData(this.props.assetData[visKey])}
					</div>
				</td>
		))

		// create array of asset buttons (edit and delete)
		var buttonCells = [
			<td>
				<div 
					className='asset-btn dark-btn'
					onClick={()=>{this.props.deleteAsset(this.props.assetListName, this.props.assetIndex, true)}}
				>
					Edit
				</div>
			</td>,
			<td>
				<div 
					className='asset-btn dark-btn'
					onClick={()=>{this.props.deleteAsset(this.props.assetListName, this.props.assetIndex)}}
				>
					Delete
				</div>
			</td>,
		]

		// add button cells to asset row
		assetRow = assetRow.concat(buttonCells)

		return(
			<tr className='asset-data-row'>
				{assetRow}
			</tr>
		)
	}
};


class ModelAssetList extends React.Component {

	renderAssetList(){
		// renders an array of ModelAsset Components for a give Asset List
		var assetList = this.props.assetList

		return assetList.map((assetData, assetIndex) => (
			<ModelAsset
				assetData={assetData}
				visKeys={this.props.visKeys}
				keyLabels={this.props.visKeys}
				assetListName={this.props.assetListName}
				deleteAsset={this.props.deleteAsset}
				editAsset={this.props.editAsset}
				assetIndex={assetIndex}
			/>
	    ))

	}

	renderLabelRow(propName) {
		// extract list of labels to show in asset table header or footer using provided propName
		// add two blanks for 'remove' and 'edit' buttons
		var labelList = this.props[propName]

		if(!labelList) {
			// if no list found, return nothing
			return null
		}

		labelList = labelList.concat(['',''])


		return (
			<tr className='asset-label-row'>
				{labelList.map((labelVal) => (
					<th>
						<div className='asset-label-text'>
							{labelVal}
						</div>
					</th>
				))}
			</tr>
		)

	}

	render() {
		// Renders either a list of Model Assets by calling the renderAssetList method
		// if list is empty or lacks assetList prop, renders nothing for the list
		var assetList = this.props.assetList
		if(!assetList){
			assetList = []
		}
		if(assetList.length>0) {

			return (
				<div className='asset-list-section'>
					<table className='asset-list-table'>
						{this.renderLabelRow('keyLabels')}
						{this.renderAssetList()}
						{this.renderLabelRow('units')}
					</table>
				</div>
			)

		} else {
			return (
				<div className='asset-list-section'>

					<h4 className='empty-asset-list-text'> No saved assets. <br/>Use menu below to create a new assest </h4>

				</div>
			)
		}
	}

};

export default ModelAssetList;

				