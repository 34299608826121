import React from 'react';

import Modal from '../Modal/Modal'

import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

class ResetSent extends React.Component{ 

  

  render(){ 
    return( 
      <Modal
        icon={<SuccessIcon />}
        label={<>Reset Request Submitted</>}
        rotating={false}
        modalText={'Please check the email address you submitted for instructions on reseting your account password. Your reset link will be valid for 1 hour after submitting this request.'}
      >

      </Modal>
    ) 
  } 
} 


export default ResetSent;