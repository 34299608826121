import Helper from './helper';

const _ = require('lodash');



const ProjectUtils = {


	// methods of packing and unpacking project objects when interacting with server
	packageProject(projectData) {
		// function converts flat project obj into nested project obj for use by opt server
		const projectDataNested = {};

		// loop through keys in projectData obj
		const keys = Object.keys(projectData);

		keys.forEach((key, index) => {

			// extract value for corresponding key
			var value = projectData[key];

			// break key into parts using '_' as delimiter
		    var keyPath = key.split('_');

		    // dynamically define nested obj location with value
		    Helper.assignNestedValue(projectDataNested, keyPath, value)

		});

		return projectDataNested
		
	},
	
	
	unpackProject(nestedProject, flatProject) {
		// method to convert nested project instance (i.e. with real data)
		// to flat project structure for use with web UI project builder

		// nestedProject - object with nested structure and real data to be copied
		// flatProject - object with flat structure and default starting data

		// data will be copied from nested to flat if present, otherwise default values will persist

		// get list of keys in default flat project
		const flatKeys = Object.keys(flatProject);
		// init converted project data
		const convertedProject = {}

		// loop through flat project keys
		flatKeys.forEach((flatKey, index) => {

			// divide flat key into sub keys
			let subKeyList = flatKey.split('_')

			// initialize value of target to whole nested project, will isolate value using key list
			let target = _.cloneDeep(nestedProject)

			// loop through subkeys to extract value from nested
			for(let kk=0; kk<subKeyList.length; kk++){
				let subKey = subKeyList[kk];
				target = target[subKey]
			}

			// assigned flat val to extracted nested value
			convertedProject[flatKey] = target

		});

		return convertedProject
		
	},


	getConnectorTypes(projectData) {
		// method gets list of network connection types
		// and returns object of lists, e.g.

		const typeListGeneric = {
			'cable': [
				'C1',
				'C2',
				'C3',
				'C4'
			],
			'transformer': [
				'Tx1',
				'Tx2',
				'Tx3',
				'Tx4'
			],
			'HT pipe': [
				'HT1',
				'HT2',
				'HT3',
				'HT4'
			],
			'LT pipe': [
				'LT1',
				'LT2',
				'LT3',
				'LT4'	]
		}

		// init empty object to host extracted data
		const typeList = {}

		// list of types to extract, and table keys where data is located
		const typeMap = [
			{
				name: 'cable',
				key: 'cableparams'
			},
			{
				name: 'transformer',
				key: 'transformerparams'
			},
			{
				name: 'HT pipe',
				key: 'htpipeparams'
			},
			{
				name: 'LT pipe',
				key: 'ltpipeparams'
			}
		]

		// loop through each type and perform extraction of options
		for(let tt=0; tt<typeMap.length; tt++){

			let typeName = typeMap[tt].name;
			let typeKey = typeMap[tt].key;

			// extract data for current table using key
			let tableData = projectData[typeKey]

			// get the colIndex for "Description" field
			// should always be first col, but may vary?
			let tableCols = tableData.colLabels
			let colIndex = tableCols.indexOf("Description");

			// if index not found, skip
			if(colIndex < 0) {
				console.log(`failed to find descriptions for type: ${typeName}`)
				continue
			}

			// get all instances of that colIndex in data field
			var descList = []

			for(let ii=0;ii<tableData.data.length; ii++) {
				let value = tableData.data[ii][colIndex];
				descList.push(value)
			}

			// add list of descriptions to typeList Obj
			typeList[typeName] = descList
		}

		return typeList


	},

	createNodeTable(nodeData, lineData, lineType, propName, incrementVal=0, nMax = 20){
		// method creates an n x n 2-d array with the value of
		// propName taken from lineData

		// initialize nMax x nMax empty 2D array
		const dataTable = Helper.createMatrix(nMax, nMax, 0);

		if(!nodeData || !lineData){
			// return empty matrix if no data provided
			return dataTable
		}

		// loop through line Data
		for (let [lineKey, lineItem] of Object.entries(lineData)) {

			// if linetype does not match, skip iteration
			let lineTypeN = lineItem.type;

			if (lineTypeN.toUpperCase() !== lineType.toUpperCase()) {
				continue
			} 

			// get property value
			// add increment (to convert from 0-indexing to 1-indexing)
			let propVal = parseInt(lineItem[propName]) + parseInt(incrementVal);

			// get list of connected nodes
			let nodeKey1 = lineItem.nodes[0];
			let nodeKey2 = lineItem.nodes[1];


			// lookup nodeIndex of nodes
			// subtract 1 to switch from 1-indexed to 0-indexed
			let nodeIndex1 = nodeData[nodeKey1].nodeIndex - 1;
			let nodeIndex2 = nodeData[nodeKey2].nodeIndex - 1;

			// at n1-n2 and n2-n1 locations, update value
			dataTable[nodeIndex1][nodeIndex2] = propVal;
			dataTable[nodeIndex2][nodeIndex1] = propVal;

		}

		console.log(`formatting node table: ${lineType} - ${propName}`)
		console.log(dataTable)

		// return processed table data
		return dataTable
	},

	checkRdtProject(projectData) {
		// method checks RDT project data for key items to display before submission

		// 1 - building id (bldg_bldgId >=0 )
		// 2 - location id (loc_stationId >=0 )
		// 3 - new ders enabled (newDers_new Dg, Es, or PV true)
		// 4 - outages enabled (res_outageDays sum > 0)
		// 5 - critical load scenarios enabled (res_critLoad # of non-zero values > 0)
	
		var checkList = []

		// 1 - building id (bldg_bldgId >=0 )
		if(projectData.bldg_bldgId > 0) {
			checkList.push({
				status: 'valid',
				msg: `Building type index ${projectData.bldg_bldgId} valid.`
			})
		} else {
			checkList.push({
				status: 'error',
				msg: `Building type selection not found. Model will use default loads.`
			})		
		}

		// 2 - location id (loc_stationId >=0 )
		if(projectData.loc_stationId > 0) {
			checkList.push({
				status: 'valid',
				msg: `Solar location index ${projectData.loc_stationId} valid.`
			})
		} else {
			checkList.push({
				status: 'error',
				msg: `Solar location selection not found. Model will use default solar data.`
			})		
		}

		// 3 - new ders enabled (newDers_new Dg, Es, or PV true)
		var newDers = (projectData.newDers_newPv || projectData.newDers_newEs || projectData.newDers_newDg);
		if(newDers) {
			checkList.push({
				status: 'valid',
				msg: `New DER options enabled.`
			})
		} else {
			checkList.push({
				status: 'warning',
				msg: 'No new DER options enabled. Results will not include DER investments'
			})		
		}

		// 4 - outages enabled (res_outageDays sum > 0)
		var outageDays = projectData.res_outageDays.reduce((a, b) => a + b, 0);
		if(outageDays > 0) {
			checkList.push({
				status: 'valid',
				msg: `${outageDays} outage days included in model.`
			})
		} else {
			checkList.push({
				status: 'warning',
				msg: 'No outage days included in model. Results will not include resilience results.'
			})		
		}

		// 5 - critical load scenarios enabled (res_critLoad # of non-zero values > 0)
		var numZeros = projectData.res_critLoads.filter(x => x==0).length;
		var numCritScens = projectData.res_critLoads.length - numZeros
		if(numCritScens > 0) {
			checkList.push({
				status: 'valid',
				msg: `${numCritScens} critical load scenarios included in model.`
			})
		} else {
			checkList.push({
				status: 'warning',
				msg: 'No critical load scenarios included in model. Results will not include resilience results.'
			})		
		}

		return checkList;


	}

} 

export default ProjectUtils;