import React from 'react';

import './ResultsStatusTable.css';

import { ReactComponent as Circle } from '../../icons/circle.svg';

class ResultsStatusTable extends React.Component {


		extractData() {
			// reads data in props.scenarios to create a list for:
			// scenarioList, newStatusList, exStatus list

			if(!this.props.scenarios) {
				return null
			}

			const data = {}
			data.scenarioList = []
			data.newStatusList = []
			data.exStatusList =[]

			// get unique scenarios
			for(let ss=0; ss<this.props.scenarios.length; ss++) {

				let scen = this.props.scenarios[ss];

				// add outage label if not already present
				if(!data.scenarioList.includes(scen.outageLabel)){
					data.scenarioList.push(scen.outageLabel)
				}

				// add existing scenario status if dd is 'ex'
				if(scen.dd==='ex'){
					data.exStatusList.push(scen.status)
				}

				// add existing scenario status if dd is 'ex'
				if(scen.dd==='new'){
					data.newStatusList.push(scen.status)
				}

			}

			// data.exStatusList = ['solved', 'solved', 'solved', 'infeasible'];
			// data.newStatusList = ['solved', 'solved', 'failed', 'failed'];
			// data.scenarioList = ['Blue Sky', '25% Critical Load', '50% Critical Load', '100% Critical Load']

			return data


		}

		makeTableHeader(headerLabelList) {

		// dynamically generate status table header based on list of scenario names
		const tableHeader = (
			<tr className='res-status-header-row'>
					<th></th>
					{headerLabelList.map((label) => (
						<th>{label}</th>
		    ))}
			</tr>
		);

		return tableHeader

	}


	render() {


		const data = this.extractData()

		if(!data){
			return null
		}

		// dynamically generate status table header based on list of scenario names
		const statusHeader = (
			<tr className='res-status-header-row'>
					<th></th>
					{data.scenarioList.map((scenLabel) => (
						<th>{scenLabel}</th>
		    ))}
			</tr>
		);


		// define function to generate status of scenarios using list of status values
		const generateStatusRow = (statusList, label) => {
			let statusRow = (
				<tr className='res-status-data-row'>
					<td className='res-status-data-label'>{label}:</td>
					{statusList.map((status) => (
						<td className='res-status-data'>
							<div className='res-status-container'>
								< Circle className={`res-status-${status}`}/><p>{status}</p>
							</div>
						</td>
					))}
				</tr>
			);

			return statusRow
		} 

		return (
			<table className='res-status-table'>
				{statusHeader}
				{generateStatusRow(data.exStatusList, 'Existing Assets')}
				{generateStatusRow(data.newStatusList, 'New Investments')}
			</table>

		)

	}

}


export default ResultsStatusTable;