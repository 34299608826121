import React from 'react';

import Helper from '../../helper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

import ResultsTable from '../ResultsTable/ResultsTable';
// import ChartAnnual from '../ChartAnnual/ChartAnnual'
// import ToggleSection from '../ToggleSection/ToggleSection'

import ResultsSelect from './ResultsSelect'

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';

// import { convertAnnDataForPlot, convertMonDataForPlot, processMonTable, processTsPlotData,
// 				 getMetricOptions, getMetricFromKey, transposeData,
// 				 filterFirst, filterMetricCat, processInvPlot, unpackInvData, unpackAnnData, unpackMonData
// 			 } from './resultsProcessing'


class SectionAnn extends React.Component {

	constructor(props){
		super(props)

		this.renderSection = this.renderSection.bind(this);
	}

	renderSingleTable(tableData, title) {
		// renders data for a given category

		let unit = tableData[0].unit;

		const tableDataFormatted = {' ':{}};

		for(let mm=0; mm<tableData.length; mm++) {
			let curMetric = tableData[mm]

			// add metric to table if not omitted when 0

			if (parseInt(curMetric.omit) && parseFloat(curMetric.val)===0){
				// if omit option = 1 and val = 0, skip metric
				continue
			} else {
				// otherwise, add metric to table data
				tableDataFormatted[' '][curMetric.label] = curMetric.val
			}
			
		}


		return (
			<ResultsTable
				data={tableDataFormatted}
				title={title}
				unit={unit}
			/>
		)

	}

	renderSection() {

		if(!this.props.dataAnnual){
		// if data isn't load yet, return nothing
			return null
		}

		// init list of rendered metric tables by category
		const tableList = []

		// loop through categories in metaAnnual props
		for(let cc=0; cc<this.props.metaAnnual.length; cc++) {

			// extract meta data for current category
			let curCat = this.props.metaAnnual[cc];
			let curCatTitle = curCat.label;
			let curCatKey = curCat.cat;

			// filter data to current category
			let curCatData = Helper.filterObjList(this.props.dataAnnual, 'cat', curCatKey)

			// render new table
			let newTable = this.renderSingleTable(curCatData, curCatTitle)

			tableList.push(newTable)
		}

		

		// split data by category
		// let finData = filterMetricCat(this.props.dataAnnual, this.props.metricsAnn, 'fin')
		// let energyData = filterMetricCat(this.props.dataAnnual, this.props.metricsAnn, 'energy')
		// let powerData = filterMetricCat(this.props.dataAnnual, this.props.metricsAnn, 'power')


		return(
			<div className='results-section'>
				<h2 className='results-heading'>4. Annual Metrics</h2>

					
					{tableList}
					
					
					<div className='results-note-table'>
						<p>
							Note: data tables can be highlighted, copied, and pasted
							into Excel or other spreadsheet applications.
						</p>
					</div>
	


			</div>
		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Annual Metrics'
			/>
		)
	}

}

export default SectionAnn;