import React from 'react';

import Modal from '../Modal/Modal';

import NodeSandBox from '../NodeSandBox/NodeSandBox';

import { ReactComponent as SuccessIcon } from '../../icons/success.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';

class ProjectPageNetwork extends React.Component {



	render() {

		const networkEnabledOld = (
			<div className='page-scroll-container-new-project project-main-container'>

				<div className='npdc-container'>
					<Modal
		  				icon={<ErrorIcon />}
						label={<>Network Builder Menu <br/>Under Construction</>}
						rotating={false}
						
		  			>
		  				<div className='modal-text'>
							<p>
								The interactive network-builder menu is currently under construction. 
								Users can create their microgrid electrical and heat networks directly using
								the table inputs listed under the Network section.
								<br/><br/>
								Additional guidance on manual network configuration will be published at this
								locations shortly. We appreciate your patience.
							</p>
						</div>



		  			</Modal>
	  			</div>
	  		</div>
		)

		const networkEnabled = (
			<div className='page-scroll-container-new-project project-main-container'>

				
					
					<NodeSandBox
	                  saveProject = {this.props.saveProject}
	                  networkModel = {this.props.networkModel}
	                  projData = {this.props.projData}
	                  writeNetworkModel = {this.props.writeNetworkModel}
	                />

	  			
	  		</div>
		)

		const networkDisabled = (
			<div className='page-scroll-container-new-project project-main-container'>

				<div className='npdc-container'>
					<Modal
		  				icon={<SuccessIcon />}
						label={<>Multi-Node Network <br/> Configuration Not Required</>}
						rotating={false}
						
		  			>
		  				<div className='modal-text'>
							<p>
								Multi-Node network options have not been selected for this model. No additional
								connfiguration required.
								<br/><br/>
								If you wish to model an internal multi-node network, please update your selections
								in the project configuration page.
							</p>
						</div>



		  			</Modal>
	  			</div>
	  		</div>
		)

		if(this.props.multiNode){
			return networkEnabled
		} else {
			return networkDisabled
		}

		
	}
}



export default ProjectPageNetwork;