
const climateZoneMap = '/img/climateZoneMap.png';

const HelpText = {

	project: (
		<div className='help-content'>
			<h2 className='help-title'> General Project Information </h2>
			<p className='help-text'>
				Your project's name is the identifying that will allow to to track 
				its status and view results. Using a unique and meaningful name for your project is recommended.
				<br/><br/>
				Project decription is an optional input where you can provide additional detail
				on what values, inputs, or parameters you are including in your model.
				<br/><br/>
				All project data is visible only to your user account.
			</p>
		</div>
	),

	loc: (
		<div className='help-content'>
			<h2 className='help-title'> Selecting Solar Location </h2>
			<p className='help-text'>
				Your location selection determines the solar profile used with your project.
				Typical solar data is available for select U.S. weather-station locations. 
				Please select a weather station closest to your project location to ensure
				accurate modeling of solar PV generation.
				<br/><br/>
				If solar PV is not included in your project, solar location will not impact
				your model results.
			</p>
		</div>
	),

	bldg: (
		<div className='help-content'>
			<h2 className='help-title'> Selecting Building Type </h2>
			<p className='help-text'>
				Building loads are calculated using reference building models developed by 
				the U.S. Department of Energy to represent typical U.S. building stock. To 
				define the energy loads in your project, please select a building type, vintage,
				and climate zone that best characterize your building.
				<br/><br/>
				You can customize this building load by scaling electricity and natural gas use up or down
				by modifying the building size or annual consumption values. If you wish
				to keep the default load profiles, simply leave the default values unmodified.
				<br/><br/>
				By default, the model will use building size as the scaling factor. If you select 
				'Scale by Annual Energy', the model will instead use the energy values you provide. 
				Only one of these options (building size or annual energy) can be used to scale loads. 
				So please keep this in mind when modifying default values.
			</p>
			<br/><br/>
			<h2 className='help-title'> Selecting Climate Zone </h2>
			<div className='help-img'>
          		<img src={climateZoneMap} alt="climate zone map" />
	        </div>
		</div>
	),

	tariff: (
		<div className='help-content'>
			<h2 className='help-title'> Electricity Tariff Structure </h2>
			<p className='help-text'>
				This menu allows you to select which tariff elements compose your project's 
				overall rate structure. Any tariff element left unselected will not apply to your
				project. For the elements selected, the next page will present you with tables to
				define the actual rates that apply.
				<br/><br/>
			</p>
			<h4 className='help-sub-title'> Tariff Selection Options </h4>
			<table>
				<tr>
					<td className='help-var'>TOU Energy Rates</td>
					<td className='help-def'> 
						time-of-use energy rates that apply a set of prices based on season and time of day
					</td>
				</tr>
				<tr>
					<td className='help-var'>Tiered Energy Rates</td>
					<td className='help-def'> 
						tariff scheme that applies different tiers of rates to differing levels of electricity
						consumption
					</td>
				</tr>
				<tr>
					<td className='help-var'>Hourly Energy Rates</td>
					<td className='help-def'> 
						rate structure approximating a real-time price. Allows user to define an hourly rate for each season and daytype.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Monthly Demand Charges</td>
					<td className='help-def'> 
						rates applied to monthly peak power import for each time-of-use period, as well as coincident and non-coincident demand.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Daily Demand Charges</td>
					<td className='help-def'> 
						rates applied to daily peak power import for each time-of-use period, as well as coincident and non-coincident demand.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Monthly Fees</td>
					<td className='help-def'> 
						monthly service fees for electricity
					</td>
				</tr>
				<tr>
					<td className='help-var'>Standby Fees</td>
					<td className='help-def'> 
						fees applied to installed DER power capacity
					</td>
				</tr>
			</table>
		</div>
	),

	rates: (
		<div className='help-content'>
			<h2 className='help-title'> Tariff Rate Inputs </h2>
			<p className='help-text'>
				The tables displayed on this page depend on the options selected on the previous tariff structure page.
				<br/><br/>
				Descriptions of each table, datatype, and units are provided below.
				<br/><br/>
				If you do not see a table you are expected, check the previous page to ensure the corresponding
				tariff element has been enabled.
			</p>
			<h4 className='help-sub-title'> Tariff Tables Definitions </h4>
			<table>
				<tr>
					<th className='help-col-header'>table</th>
					<th className='help-col-header'>type</th>
					<th className='help-col-header'>unit</th>
					<th className='help-col-header'>description</th>
				</tr>
				<tr>
					<td className='help-var'>Tariff Seasons</td>
					<td className='help-type'>binary</td>
					<td className='help-unit'>None</td>
					<td className='help-def'>table to assign each month to a tariff season</td>
				</tr>
				<tr>
					<td className='help-var'>TOU Hours</td>
					<td className='help-type'>integer</td>
					<td className='help-unit'>None</td>
					<td className='help-def'>
						table to assign the hours of each daytype to TOU rate periods
						<br/><br/>
						1: on-peak<br/>
						2: mid-peak<br/>
						3: off-peak<br/>
					</td>
				</tr>
				<tr>
					<td className='help-var'>TOU Energy Rates</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/kWh</td>
					<td className='help-def'>table to assign rate to each TOU period</td>
				</tr>
				<tr>
					<td className='help-var'>Hourly Energy Rates</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/kWh</td>
					<td className='help-def'>table to assign an hourly rate to the hours of each daytype</td>
				</tr>
				<tr>
					<td className='help-var'>Tiered Rates - Energy Limit</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>kWh</td>
					<td className='help-def'>table to define the maximum energy allowed in each tier</td>
				</tr>
				<tr>
					<td className='help-var'>Tiered Rates - Energy Rate</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/kWh</td>
					<td className='help-def'>table to define the tariff rate applied each tier</td>
				</tr>
				<tr>
					<td className='help-var'>Monthly Demand Charge</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/kW</td>
					<td className='help-def'>table to define the monthly demand charge rate for each demand period</td>
				</tr>
				<tr>
					<td className='help-var'>Daily Demand Charge</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/kW</td>
					<td className='help-def'>table to define the daily demand charge rate for each demand period</td>
				</tr>
				<tr>
					<td className='help-var'>Monthly Service Fee</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/month</td>
					<td className='help-def'>monthly flat fee applied to electricity costs each month</td>
				</tr>
				<tr>
					<td className='help-var'>Standby Fee</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/kW</td>
					<td className='help-def'>monthly fee applied to install DER power capacity</td>
				</tr>
			</table>
		</div>
	),

	export: (
		<div className='help-content'>
			<h2 className='help-title'> Electricity Export </h2>
			<p className='help-text'>
				Options to enable and limit the sales of on-site generation.
			</p>
			<table>
				<tr>
					<td className='help-var'>Enable PV Export</td>
					<td className='help-def'>allows sales of PV generation</td>
				</tr>
				<tr>
					<td className='help-var'>Enable Other Export</td>
					<td className='help-def'>allows sales of electricity generated from gensets or from battery storage</td>
				</tr>
				<tr>
					<td className='help-var'>Annual Net-Export</td>
					<td className='help-def'>allows project site to export a higher volume of electricity than it imports over the course of a year. (Net-exporter)</td>
				</tr>
				<tr>
					<td className='help-var'>Net-Energy Metering Price</td>
					<td className='help-def'>Applied retail price to any exports, with any adjustments defined below also applied. If disabled, exports are compensated at the flat rate price defined below.</td>
				</tr>
			</table>

			<h4 className='help-sub-title'> Export Options </h4>
			<table>
				<tr>
					<th className='help-col-header'>table</th>
					<th className='help-col-header'>type</th>
					<th className='help-col-header'>unit</th>
					<th className='help-col-header'>description</th>
				</tr>
				<tr>
					<td className='help-var'>NEM Price Fraction</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>None</td>
					<td className='help-def'>fraction of retail price applied to export (e.g. 0.8 = 80% of NEM price)</td>
				</tr>
				<tr>
					<td className='help-var'>NEM Price Fraction</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/kWh</td>
					<td className='help-def'>reduction in NEM price applied to export (i.e. $/kWh below full NEM rate)</td>
				</tr>
				<tr>
					<td className='help-var'>Flat Rate</td>
					<td className='help-type'>positive</td>
					<td className='help-unit'>$/kWh</td>
					<td className='help-def'>flat rate applied to export if NEM is not enabled.</td>
				</tr>
			</table>
		</div>
	),

	fuels: (
		<div className='help-content'>
			<h2 className='help-title'> Fuel Prices </h2>
			<p className='help-text'>
				The tool currently only supports flat rates for fuels. 
				Users can define the rate and any monthly service fees for the four fuel types available.
			</p>
		</div>
	),

	exDers: (
		<div className='help-content'>
			<h2 className='help-title'> Existing DER Options </h2>
			<p className='help-text'>
				This menu allows users to define existing DER assets that can be used to support site objectives,
				but as existing assets, their capital costs are not included in the overall energy cost optimization.
				<br/><br/>
				Select the DER type at the top of the menu to display input options for that specific DER.
				<br/><br/>
				For generator assets, you can define multiple existing units. To add a unit, complete the
				'Add Existing Generator' form, and press the 'Save Asset' button to add it to the list of
				existing genset assets. You can modify or remove saved assests as needed.
			</p>

			<h4 className='help-sub-title'> PV Options </h4>
			<table>
				<tr>
					<td className='help-var'>PV Capacity</td>
					<td className='help-def'>total capacity of existing PV system</td>
				</tr>
				<tr>
					<td className='help-var'>Angle</td>
					<td className='help-def'>
						angle configuration of PV. 
						This angle will be applied to current and any additional PV installed.
						'Lat' is the latitude of the project location, based on the solar location selected.
						This is typically the optimal PV angle.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Orientation</td>
					<td className='help-def'>orientation of existing and new PV arrays</td>
				</tr>
			</table>

			<h4 className='help-sub-title'> Battery Options </h4>
			<table>
				<tr>
					<td className='help-var'>Battery Energy Capacity</td>
					<td className='help-def'>total capacity of existing battery system</td>
				</tr>
				<tr>
					<td className='help-var'>Max Charge/Discharge Rate</td>
					<td className='help-def'>
						maximum rate for battery charging and discharging per kWh energy capacity.
						Applied to existing and new battery assets.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Charging Efficiency</td>
					<td className='help-def'>
						efficiency for charging and discharging the battery system.
						Applied to existing and new battery assets.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Min./Max. State-of-Charge</td>
					<td className='help-def'>
						limits on state-of-charge allowed for battery.
						Applied to existing and new battery assets.
					</td>
				</tr>
			</table>

			<h4 className='help-sub-title'> Genset Options </h4>
			<table>
				<tr>
					<td className='help-var'>Generator Name</td>
					<td className='help-def'>Unique name to identify your generator asset</td>
				</tr>
				<tr>
					<td className='help-var'>Generator Capacity</td>
					<td className='help-def'>Power capacity of unit</td>
				</tr>
				<tr>
					<td className='help-var'>Number of Units</td>
					<td className='help-def'>Number of that genset unit installed</td>
				</tr>
				<tr>
					<td className='help-var'>Fuel type</td>
					<td className='help-def'>Fuel type consumed by genset unit</td>
				</tr>
				<tr>
					<td className='help-var'>Efficiency</td>
					<td className='help-def'>Operational efficiency for power generation</td>
				</tr>
				<tr>
					<td className='help-var'>Back-up Only</td>
					<td className='help-def'>Option to allow use for back-up power only</td>
				</tr>
				<tr>
					<td className='help-var'>CHP Enabled</td>
					<td className='help-def'>Option to enable combined heat and power/waste heat recovery</td>
				</tr>
				<tr>
					<td className='help-var'>Heat Recovery Fraction</td>
					<td className='help-def'>Operational efficiency for heat recovery</td>
				</tr>
				<tr>
					<td className='help-var'>O&M Cost</td>
					<td className='help-def'>Operation & Maintenance costs per unit of energy generated</td>
				</tr>
			</table>

			<h2 className='help-title'>Continuous vs Discrete DER Technologies</h2>
			<p className='help-text'>
			PV and batteries are modeled in DER-CAM as continuous technologies. That means the model is free to select whatever capacity of these DER types that maximize the total value of the system. 
			<br/><br/>
			Generators are modeled as discrete technologies, meaning they can be adopted in specific increments (i.e. 1 or 2 units of a specific generator type). Users must therefore define individual generator units to be considered for deployment. Several examples of generic generators are provided below. These may be used as a starting point for users interested in generator DERs, but without specific data of their own.
			<br/><br/>
			Note: each of the examples below have CHP enabled. Users may simply disable the CHP enabled field if wishing to use these examples with CHP capabilities.
			</p>

			<div className='help-horz-scroll-div'>
				<table>
					<tr>
						<th className='help-table-th'>Name</th>
						<th className='help-table-th'>Capital Cost</th>
						<th className='help-table-th'>Capacity</th>
						<th className='help-table-th'>Lifetime</th>
						<th className='help-table-th'>O&M Cost</th>
						<th className='help-table-th'>Fuel</th>
						<th className='help-table-th'>Efficiency</th>
						<th className='help-table-th'>Backup Only</th>
						<th className='help-table-th'>CHP</th>
						<th className='help-table-th'>Heat Recovery</th>
					</tr>

					<tr>
						<td className='help-table-td'>Microturbine 65</td>
						<td className='help-table-td'>6440</td>
						<td className='help-table-td'>65</td>
						<td className='help-table-td'>15</td>
						<td className='help-table-td'>0.00725</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.238</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>1.567</td>
					</tr>

					<tr>
						<td className='help-table-td'>Internal Combustion Engine 75</td>
						<td className='help-table-td'>5761</td>
						<td className='help-table-td'>75</td>
						<td className='help-table-td'>15</td>
						<td className='help-table-td'>0.01275</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.260</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>2.006</td>
					</tr>

					<tr>
						<td className='help-table-td'>Microturbine 200</td>
						<td className='help-table-td'>6300</td>
						<td className='help-table-td'>200</td>
						<td className='help-table-td'>15</td>
						<td className='help-table-td'>0.0085</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.267</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>1.101</td>
					</tr>

					<tr>
						<td className='help-table-td'>Internal Combustion Engine 250</td>
						<td className='help-table-td'>5228</td>
						<td className='help-table-td'>250</td>
						<td className='help-table-td'>15</td>
						<td className='help-table-td'>0.0125</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.270</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>1.83</td>
					</tr>

					<tr>
						<td className='help-table-td'>MC Fuel Cell 300</td>
						<td className='help-table-td'>20600</td>
						<td className='help-table-td'>300</td>
						<td className='help-table-td'>20</td>
						<td className='help-table-td'>0.023</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.427</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>0.469</td>
					</tr>

				</table>
			</div>

		</div>

	),

	newDers: (
		<div className='help-content'>
			<h2 className='help-title'> New DER Options </h2>
			<p className='help-text'>
				This menu allows users to define new DER assets to be considered by the optimization. Adding assets 
				here does not guarantee they will be selected and installed.
				<br/><br/>
				Select the DER type at the top of the menu to display input options for that specific DER.
				<br/><br/>
				For generator assets, you can define multiple new unit options. To add a unit, complete the
				'Add New Generator' form, and press the 'Save Asset' button to add it to the list of
				new genset assets. You can modify or remove saved assets as needed.
			</p>

			<h4 className='help-sub-title'> PV Options </h4>
			<table>
				<tr>
					<td className='help-var'>Fixed Capital Cost</td>
					<td className='help-def'>fixed cost for installing any new capacity</td>
				</tr>
				<tr>
					<td className='help-var'>Variable Capital Cost</td>
					<td className='help-def'>variable cost for each new unity of capacity installed</td>
				</tr>
				<tr>
					<td className='help-var'>Lifetime</td>
					<td className='help-def'>The lifetime of the asset</td>
				</tr>
				<tr>
					<td className='help-var'>O&M Cost</td>
					<td className='help-def'>Annual operation and maintenance costs per unit installed</td>
				</tr>
				<tr>
					<td className='help-var'>Angle</td>
					<td className='help-def'>
						angle configuration of PV. 
						This angle will be applied to current and any additional PV installed.
						'Lat' is the latitude of the project location, based on the solar location selected.
						This is typically the optimal PV angle.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Orientation</td>
					<td className='help-def'>orientation of existing and new PV arrays</td>
				</tr>
			</table>

			<h4 className='help-sub-title'> Battery Options </h4>
			<table>
				<tr>
					<td className='help-var'>Fixed Capital Cost</td>
					<td className='help-def'>fixed cost for installing any new capacity</td>
				</tr>
				<tr>
					<td className='help-var'>Variable Capital Cost</td>
					<td className='help-def'>variable cost for each new unity of capacity installed</td>
				</tr>
				<tr>
					<td className='help-var'>Lifetime</td>
					<td className='help-def'>The lifetime of the asset</td>
				</tr>
				<tr>
					<td className='help-var'>O&M Cost</td>
					<td className='help-def'>Annual operation and maintenance costs per unit installed</td>
				</tr>
				<tr>
					<td className='help-var'>Max Charge/Discharge Rate</td>
					<td className='help-def'>
						maximum rate for battery charging and discharging per kWh energy capacity.
						Applied to existing and new battery assets.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Charging Efficiency</td>
					<td className='help-def'>
						efficiency for charging and discharging the battery system.
						Applied to existing and new battery assets.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Min./Max. State-of-Charge</td>
					<td className='help-def'>
						limits on state-of-charge allowed for battery.
						Applied to existing and new battery assets.
					</td>
				</tr>
			</table>

			<h4 className='help-sub-title'> Genset Options </h4>
			<table>
				<tr>
					<td className='help-var'>Max Identical units</td>
					<td className='help-def'>The maximum number of any single unit that can be installed.</td>
				</tr>
				<tr>
					<td className='help-var'>Generator Name</td>
					<td className='help-def'>Unique name to identify your generator asset</td>
				</tr>
				<tr>
					<td className='help-var'>Unit Capital Cost</td>
					<td className='help-def'>Capital cost per kW capacity of unit</td>
				</tr>
				<tr>
					<td className='help-var'>Lifetime</td>
					<td className='help-def'>The lifetime of the asset</td>
				</tr>
				<tr>
					<td className='help-var'>Generator Capacity</td>
					<td className='help-def'>Power capacity of unit</td>
				</tr>
				<tr>
					<td className='help-var'>Number of Units</td>
					<td className='help-def'>Number of that genset unit installed</td>
				</tr>
				<tr>
					<td className='help-var'>Fuel type</td>
					<td className='help-def'>Fuel type consumed by genset unit</td>
				</tr>
				<tr>
					<td className='help-var'>Efficiency</td>
					<td className='help-def'>Operational efficiency for power generation</td>
				</tr>
				<tr>
					<td className='help-var'>Back-up Only</td>
					<td className='help-def'>Option to allow use for back-up power only</td>
				</tr>
				<tr>
					<td className='help-var'>CHP Enagled</td>
					<td className='help-def'>Option to enable combined heat and power/waste heat recovery</td>
				</tr>
				<tr>
					<td className='help-var'>Heat Recovery Fraction</td>
					<td className='help-def'>Operational efficiency for heat recovery</td>
				</tr>
				<tr>
					<td className='help-var'>O&M Cost</td>
					<td className='help-def'>Operation & Maintenance costs per unit of energy generated</td>
				</tr>
			</table>


			<h2 className='help-title'>Continuous vs Discrete DER Technologies</h2>
			<p className='help-text'>
			PV and batteries are modeled in DER-CAM as continuous technologies. That means the model is free to select whatever capacity of these DER types that maximize the total value of the system. 
			<br/><br/>
			Generators are modeled as discrete technologies, meaning they can be adopted in specific increments (i.e. 1 or 2 units of a specific generator type). Users must therefore define individual generator units to be considered for deployment. Several examples of generic generators are provided below. These may be used as a starting point for users interested in generator DERs, but without specific data of their own.
			<br/><br/>
			Note: each of the examples below have CHP enabled. Users may simply disable the CHP enabled field if wishing to use these examples with CHP capabilities.
			</p>

			<div className='help-horz-scroll-div'>
				<table>
					<tr>
						<th className='help-table-th'>Name</th>
						<th className='help-table-th'>Capital Cost</th>
						<th className='help-table-th'>Capacity</th>
						<th className='help-table-th'>Lifetime</th>
						<th className='help-table-th'>O&M Cost</th>
						<th className='help-table-th'>Fuel</th>
						<th className='help-table-th'>Efficiency</th>
						<th className='help-table-th'>Backup Only</th>
						<th className='help-table-th'>CHP</th>
						<th className='help-table-th'>Heat Recovery</th>
					</tr>

					<tr>
						<td className='help-table-td'>Microturbine 65</td>
						<td className='help-table-td'>6440</td>
						<td className='help-table-td'>65</td>
						<td className='help-table-td'>15</td>
						<td className='help-table-td'>0.00725</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.238</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>1.567</td>
					</tr>

					<tr>
						<td className='help-table-td'>Internal Combustion Engine 75</td>
						<td className='help-table-td'>5761</td>
						<td className='help-table-td'>75</td>
						<td className='help-table-td'>15</td>
						<td className='help-table-td'>0.01275</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.260</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>2.006</td>
					</tr>

					<tr>
						<td className='help-table-td'>Microturbine 200</td>
						<td className='help-table-td'>6300</td>
						<td className='help-table-td'>200</td>
						<td className='help-table-td'>15</td>
						<td className='help-table-td'>0.0085</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.267</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>1.101</td>
					</tr>

					<tr>
						<td className='help-table-td'>Internal Combustion Engine 250</td>
						<td className='help-table-td'>5228</td>
						<td className='help-table-td'>250</td>
						<td className='help-table-td'>15</td>
						<td className='help-table-td'>0.0125</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.270</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>1.83</td>
					</tr>

					<tr>
						<td className='help-table-td'>MC Fuel Cell 300</td>
						<td className='help-table-td'>20600</td>
						<td className='help-table-td'>300</td>
						<td className='help-table-td'>20</td>
						<td className='help-table-td'>0.023</td>
						<td className='help-table-td'>N.G.</td>
						<td className='help-table-td'>0.427</td>
						<td className='help-table-td'>No</td>
						<td className='help-table-td'>Yes</td>
						<td className='help-table-td'>0.469</td>
					</tr>

				</table>
			</div>


		</div>

	),

	eff: (
		<div className='help-content'>
			<h2 className='help-title'> Energy Efficiency Options </h2>
			<p className='help-text'>
				Energy efficiency investments can be adopted to reduce overall consumption. These can
				be combined with DER investments to support model objectives of reducing energy costs
				and providing resilient operation.
				<br/><br/>
				As with generator assets, users must define discrete efficiency investment options and
				add them to a list of investments to consider. The model is only able to select one efficiency
				investment, so users should define their investment options accordingly.
				<br/><br/>
				if a user wants to consider investment scenarios that can combine separate investments, they
				should create additional scenarios capturing that combination. E.g. option A, option B, and option A+B

			</p>
			<h4 className='help-sub-title'> Inputs for New Efficiency Options </h4>
			<table>
				<tr>
					<td className='help-var'>Name</td>
					<td className='help-def'>Unique name to identify your efficiency option</td>
				</tr>
				<tr>
					<td className='help-var'> Capital Cost</td>
					<td className='help-def'>Total cost to deploy the efficiency investment</td>
				</tr>
				<tr>
					<td className='help-var'>Lifetime</td>
					<td className='help-def'>The lifetime of the asset or improvement</td>
				</tr>
				<tr>
					<td className='help-var'>Time Variable</td>
					<td className='help-def'>allows user to define an efficiency improvement that varies in time</td>
				</tr>
				<tr>
					<td className='help-var'>Fractional Impact</td>
					<td className='help-def'>
						If enabled, the user-define impact values will apply as a fraction of each overall load (e.g. 0.2 = 20% reduction).
						If disabled, the impact values will be in units of kW
					</td>
				</tr>
				<tr>
					<td className='help-var'>Impact Values </td>
					<td className='help-def'>
						Impact of the specific investments on reducing each end-use load.
						A single investment can impact multiple end-uses.
					</td>
				</tr>
			</table>

			<br/>

			<h2 className='help-title'>How to Define an Energy Efficiency Option</h2>
			<p className='help-text'>
				The potential cost and impact of energy efficiency (EE) options will vary significantly by building size, vintage, and type, as well as the building technology affected by that EE improvement. You may need to complete some analysis external to the RDT tool in order to create accurate EE investment options.
			</p>
			<h4 className='help-sub-title'>Quantifying Impact</h4>
			<p className='help-text'>
				The first step to consider which end-uses are affected by the EE investment. Users can create either uniform or time-variable impacts that reduce any or all of the six end-uses considered by DER-CAM. By default, the impacts are defined as fraction (e.g. a value of 0.2 would mean a 20% reduction to the target end-use). By deselected “Fraction Impact”, users can instead define reductions in units of kW directly.
			</p>
			<h4 className='help-sub-title'>Quantifying Cost</h4>
			<p className='help-text'>
				The cost of investment will the factor that users will have to consider when defining their EE options. DER-CAM will consider the savings potential of the investment versus this cost to determine if the investment is cost-optimal and should be adopted. The cost of any given investment will significantly between applications. 
			</p>
			<h4 className='help-sub-title'>Combining Options</h4>
			<p className='help-text'>
			The model is only able to adopt a single efficiency investment option. Users can create an option that combines the impact and cost of multiple separate options to consider efficiency upgrades that target different end-uses simultaneously.
			</p>
			<h4 className='help-sub-title'>Example Options</h4>
			<p className='help-text'>
				Below are some examples of efficiency options users can start with. Note, you may need to scale these cost and impact values relative to your particular building size and characteristics.
			</p>

			<table>
				<tr>
					<th className='help-table-th'>Measure</th>
					<th className='help-table-th'>Description</th>
					<th className='help-table-th'>Cost Metric</th>
					<th className='help-table-th'>Impact</th>
				</tr>

				<tr>
					<td className='help-table-td'>Improved HVAC controls</td>
					<td className='help-table-td'>Reduction of HVAC loads by 20%</td>
					<td className='help-table-td'>$60 per occupant</td>
					<td className='help-table-td'>20% cooling and heating savings</td>
				</tr>

				<tr>
					<td className='help-table-td'>NVC AC retrofit</td>
					<td className='help-table-td'>Retrofit of chiller to non-vapor compression. Improvement of average COP from 5.2 to 6.9</td>
					<td className='help-table-td'>$200 per kBTU/hr chiller capacity</td>
					<td className='help-table-td'>20% cooling savings</td>
				</tr>

				<tr>
					<td className='help-table-td'>SSL lighting retrofit</td>
					<td className='help-table-td'>Solid state lighting retrofit. Improvement of lighting efficiency from 82 lumen/W to 226 lumen/W</td>
					<td className='help-table-td'>$5.50 per 1000 lum</td>
					<td className='help-table-td'>20% savings of non-HVAC electric loads</td>
				</tr>
			</table>
		</div>
	),

	res: (
		<div className='help-content'>
			

			<h2 className='help-title'> Resilience and Outage Options </h2>
			<p className='help-text'>
				This menu allows you to define your outage scenarios and resilience requirements. 
				The tool allows you to define multiple critical load scenarios to explore how performance
				varies under differing resilience requirements. The model will also run a 'Blue Sky' scenario,
				where no outages occur. Users can define load shedding capabilities and costs.
			</p>
			<h4 className='help-sub-title'> Defining Outages & Critical Loads </h4>
			<table>
				<tr>
					<td className='help-var'>Grid Outage Events</td>
					<td className='help-def'>The number of days each month when a 24-hour grid outage occurs</td>
				</tr>
				<tr>
					<td className='help-var'> Critical Load Level Scenarios</td>
					<td className='help-def'>
						Users can define up to four scenarios of critical load that must be supported during outage.
						Value indicates the fraction of ordinary load that must be supported.
						If fewer than four scenarios are needed, leave additional scenario values at 0.
					</td>
				</tr>
				<tr>
					<td className='help-var'>Allow Critical Load Shed</td>
					<td className='help-def'>
						Option to allow the model the shed critical load. If selected, the model will incur
						the VoLL critical per kWh of unserved critical load. If not selected, model may become infeasible
						if DER capacity options are not sufficient to support the critical load.
					</td>
				</tr>
				<tr>
					<td className='help-var'>VoLL Non-Critical</td>
					<td className='help-def'>Cost for unserved non-critical load</td>
				</tr>
				<tr>
					<td className='help-var'>VoLL Critical</td>
					<td className='help-def'>Cost for unserved critical load. Only used if critical load shedding is enabled.</td>
				</tr>
			</table>

			<br/>
			<h2 className='help-title'> How to Define Your Resilience Scenarios </h2>

			<h4 className='help-sub-title'>When do my outages occur?</h4>

			<p className='help-text'>
				Select which months your outage scenarios occur. 
				If they are related to season events, e.g. hurricanes other natural disasters, 
				you might target specific months. If outage events might occur randomly 
				throughout the year, select outages in each season. You should only need a few 
				outages events to produce a reasonably accurate design estimate.
			</p>
			<h4 className='help-sub-title'>How long do they last?</h4>

			<p className='help-text'>
				Each outage event lasts a full 24 hours. However, if you would like to model 
				multi-day outages, you can include multiple outage days within a single month.
			</p>
			<h4 className='help-sub-title'>What loads are needed during a grid outage?</h4>

			<p className='help-text'>
				Your critical load scenarios are the loads that must be served during the outage event. 
				These are defined as a fraction on typical loads. So a value of 0.5 would represent a 
				critical load of half of typical loads. Critical loads preserve the same load shape as 
				typical loads. 
			</p>
			<h4 className='help-sub-title'>What are the “costs” of unserved loads?</h4>
			<p className='help-text'>
				Value of lost load helps quantify the value of resilience. Non-critical costs are 
				incurred for the load that is shed during emergencies. You can also allow the model 
				to shed critical load if it cannot find a feasible design solution. In this case, you 
				might use a very high value for critical VoLL. This represents to cost of failing to 
				provide resilient service.
			</p>

		</div>
	),

} 

export default HelpText;