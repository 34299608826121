import React from 'react';

import './NavbarProject.css';


import { ReactComponent as PrevIcon } from '../../icons/previous.svg';
import { ReactComponent as NextIcon } from '../../icons/next.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as HelpIcon } from '../../icons/help.svg';

class NavbarProject extends React.Component {

	selectButtonStatus(disableStatus) {
		// function returns a classname to disable button if disableStatus boolean is true
		// otherwise returns nothing
		return disableStatus ? "disabled-icon-button" : null;
	}

	render() {
		return (
			<div className="np-nav-container">
				<div className="np-nav-container-top">
					<div className='np-nav-title'>
			        	<h1 className='np-nav-heading'>Create Project:</h1>
			        </div>

			        <div className='np-nav-proj-name'>
			        	<input 
							name={this.props.fieldName}
							className='np-nav-nameinput'
							value={this.props.projectName} 
							onChange={this.props.updateName}
						/> 
			        </div>
			        
			        <div className='np-nav-menu-button-container'>

						<li className="np-nav-item">
						    <div className={`icon-button ${this.selectButtonStatus(this.props.firstPage)}`} onClick={this.props.prevPage}>
					        {<PrevIcon />}
					      </div>
					    </li>
					    <li className="np-nav-item">
						    <div className={`icon-button ${this.selectButtonStatus(this.props.lastPage)}`} onClick={this.props.nextPage}>
					        {<NextIcon />}
					      </div>
					    </li>
					    <li className="np-nav-item">
					      <div className="icon-button" onClick={this.props.exitProject}>
					        {<CloseIcon />}
					      </div>
					    </li>
		        	</div>
		        </div>

		        <div className="np-nav-container-bottom">
		        	<div></div>
					<div className='np-nav-section-pagename'>
			        	<h4 className='np-nav-pagename'>{this.props.pageName}</h4>
			        </div>
			        <div></div>
		        </div>
		        
		    </div>
		)
	}
}



export default NavbarProject;