import React from 'react';


class InputTable2d extends React.Component{ 

	renderTableHeader(colLabels, nSpaces=1){

		if(!colLabels){return null}

		var colHeads = colLabels.map((colLabel)=>(
			<th>
				<h4 className='input-1d-header-label'>{colLabel}</h4>
			</th>
		))

		// create nSpaces number of space elements
		const spaceHeads = []
		for(let ss=0; ss<nSpaces; ss++){
			spaceHeads.push(<th className='input-header-space'></th>)
		}

		// for node-indexed colum heads, return only nNodes elements
		if (colLabels[0] == 'node1') {
			colHeads = colHeads.slice(0, this.props.nNodes);
		}

		const headerRow = (
			<tr>
				{spaceHeads}
				{colHeads}
			</tr>
		)

		return headerRow
	}

	getTypeRule(paramData, index) {
		// extracts type from paramData.type
		// works for list of types or single val

		// set default type to null
		var type = null

		// check for 'type' field in paramData
		if(paramData.type){
			// if type field is array, extract value
			if(Array.isArray(paramData.type)){
				// If type attr is array, try to extract the value using index
				try {
					type = paramData.type[index]
				} catch {
					// if index exceeds array len, assume none (default) type
					type = null
				}
				//
			} else {
				// otherwise, use type directly
				type = paramData.type
			}
		}

		return type


	}

	checkNodeNum(curLabel) {
		// parses a row label to extract node num
		// returns true if curNode should be displayed
		// returns false if curNode exceeds nNodes, and should be hidden

		// check if label in form nodeX
		if(curLabel.startsWith('node')){
			// if so, extract value from current node:
			let curNode = curLabel.slice(4)
			curNode = parseInt(curNode)

			// check if node # exceeds max nodes
			if (curNode > this.props.nNodes){
				return false
			}
		}

		// default to true if conditions above not met
		return true
	}

	render1d() {

		if(!this.props.paramData) {
			return null
		}

		var paramData = this.props.paramData

		var tableRows = []

		for(let ii=0; ii<paramData.labels.length; ii++){

			let newRow = (
				<tr className='input-1d-row'>
					<td className='input-1d-label-div'>
						<h4 className='input-1d-label'>{paramData.labels[ii]}</h4>
					</td>
					<td className='input-1d-val-div'>
						<input 
							className='input-1d-val' 
							value={paramData.data[ii]}
							onChange={this.props.onChange} 
				            onBlur={this.props.onExit}

				            data-row-num={ii}
				            data-name={paramData.key}
				            data-dims='1'
				            data-type={this.getTypeRule(paramData, ii)}
						>
						</input>
					</td>
					
				</tr>
			)


			tableRows.push(newRow)
		}

		const tableObj = (
			<div className='input-flex-container'>
				<table className='input-1d-table'>
					{tableRows}
				</table>
			</div>
		)

		return tableObj
	}

	render2d() {

		if(!this.props.paramData) {
			return null
		}

		var paramData = this.props.paramData

		var tableRows = []

		// generate col headers
		const colHeadRow = this.renderTableHeader(paramData.colLabels, 1)

		tableRows.push(colHeadRow)

		for(let ii=0; ii<paramData.rowLabels.length; ii++){

			// check if label in form nodeX
			let curLabel = paramData.rowLabels[ii]
			// skip row if node row not valid
			if(!this.checkNodeNum(curLabel)) {
				continue
			}

			// create row data val for given row number
			var rowData = paramData.data[ii]
			var rowInputs = []
			for(let vv=0; vv<rowData.length; vv++){

				

				var newInput = (
					<td className='input-1d-val-div'>
						<input 
							className='input-1d-val' 
							value={paramData.data[ii][vv]}
				            name={`'item-${ii}-${vv}' `} 

				            onChange={this.props.onChange} 
				            onBlur={this.props.onExit}

				            data-row-num={ii}
				            data-col-num={vv}
				            data-name={paramData.key}
				            data-dims='2'
				            data-type={this.getTypeRule(paramData, vv)}
				            
				            required
				        />
						
					</td>

				)

				rowInputs.push(newInput)
			}

			// for node-indexed colum heads, return only nNodes elements
			if (paramData.colLabels[0] == 'node1') {
				rowInputs = rowInputs.slice(0, this.props.nNodes);
			}

			let newRow = (
				<tr className='input-1d-row'>
					<td className='input-1d-label-div'>
						<h4 className='input-1d-label'>{paramData.rowLabels[ii]}</h4>
					</td>


					{rowInputs}
					
				</tr>
			)


			tableRows.push(newRow)
		}

		const tableObj = (
			<div className='input-flex-container'>
				<table className='input-1d-table'>
					{tableRows}
				</table>
			</div>
		)

		return tableObj
	}

	render3d() {

		var tableRows = []

		var paramData = this.props.paramData

		// generate col headers
		const colHeadRow = this.renderTableHeader(paramData.colLabels, 2)

		tableRows.push(colHeadRow)

		

		for(let ii=0; ii<paramData.rowLabels1.length; ii++){

			// check if rowLabels1 are valid node #
			let curLabel1 = paramData.rowLabels1[ii]
			// skip row if node row not valid
			if(!this.checkNodeNum(curLabel1)) {
				continue
			}

			for(let jj=0; jj<paramData.rowLabels2.length; jj++){

				// check if rowLabels2 are valid node #
				let curLabel2 = paramData.rowLabels2[jj]
				// skip row if node row not valid
				if(!this.checkNodeNum(curLabel2)) {
					continue
				}

				// create row data val for given row number
				var rowData = paramData.data[ii][jj]
				var rowInputs = []
				// console.log(`looking for ii=${ii} jj=${jj}`)
				// console.log(rowData)
				for(let vv=0; vv<rowData.length; vv++){
					var newInput = (
						<td className='input-1d-val-div'>
							<input 
								className='input-1d-val' 
								value={paramData.data[ii][jj][vv]}
								data-dims='3'

					            name={`'item-${ii}-${jj}-${vv}' `}

					            onChange={this.props.onChange} 
					            onBlur={this.props.onExit}

					            data-row-num={ii}
					            data-row2-num={jj}
					            data-col-num={vv}
					            data-name={paramData.key}
					            data-type={this.getTypeRule(paramData, vv)}
				            
							>
							</input>
						</td>

					)

					rowInputs.push(newInput)
				}

				let newRow = (
					<tr className='input-1d-row'>
						<td className='input-1d-label-div'>
							<h4 className='input-1d-label'>{paramData.rowLabels1[ii]}</h4>
						</td>
						<td className='input-1d-label-div'>
							<h4 className='input-1d-label'>{paramData.rowLabels2[jj]}</h4>
						</td>


						{rowInputs}
						
					</tr>
				)


				tableRows.push(newRow)
			}
		}

		const tableObj = (
			<div className='input-flex-container'>
				<table className='input-1d-table'>
					{tableRows}
				</table>
			</div>
		)

		return tableObj
	}


	render4d() {

		var tableRows = []

		var paramData = this.props.paramData

		// generate col headers
		const colHeadRow = this.renderTableHeader(paramData.colLabels, 3)

		tableRows.push(colHeadRow)

		

		for(let ii=0; ii<paramData.rowLabels1.length; ii++){

			// check if rowLabels1 are valid node #
			let curLabel1 = paramData.rowLabels1[ii]
			// skip row if node row not valid
			if(!this.checkNodeNum(curLabel1)) {
				continue
			}

			for(let jj=0; jj<paramData.rowLabels2.length; jj++){

				// check if rowLabels2 are valid node #
				let curLabel2 = paramData.rowLabels2[jj]
				// skip row if node row not valid
				if(!this.checkNodeNum(curLabel2)) {
					continue
				}

				for(let kk=0; kk<paramData.rowLabels3.length; kk++){

					// check if rowLabels3 are valid node #
					let curLabel3 = paramData.rowLabels3[kk]
					// skip row if node row not valid
					if(!this.checkNodeNum(curLabel3)) {
						continue
					}

					// create row data val for given row number
					var rowData = paramData.data[ii][jj][kk]
					var rowInputs = []
					
					for(let vv=0; vv<rowData.length; vv++){
						var newInput = (
							<td className='input-1d-val-div'>
								<input 
									className='input-1d-val' 
									value={paramData.data[ii][jj][kk][vv]}
									data-dims='4'
									onChange={this.props.onChange}

									name={`'item-${ii}-${jj}-${kk}-${vv}' `}

						            onChange={this.props.onChange} 
						            onBlur={this.props.onExit}

						            data-row-num={ii}
						            data-row2-num={jj}
						            data-row3-num={kk}
						            data-col-num={vv}
						            data-name={paramData.key}
						            data-type={this.getTypeRule(paramData, vv)}
								>
								</input>
							</td>

						)

						rowInputs.push(newInput)
					}

					let newRow = (
						<tr className='input-1d-row'>
							<td className='input-1d-label-div'>
								<h4 className='input-1d-label'>{paramData.rowLabels1[ii]}</h4>
							</td>
							<td className='input-1d-label-div'>
								<h4 className='input-1d-label'>{paramData.rowLabels2[jj]}</h4>
							</td>
							<td className='input-1d-label-div'>
								<h4 className='input-1d-label'>{paramData.rowLabels3[kk]}</h4>
							</td>


							{rowInputs}
							
						</tr>
					)


					tableRows.push(newRow)
				}
			}
		}

		const tableObj = (
			<div className='input-flex-container'>
				<table className='input-1d-table'>
					{tableRows}
				</table>
			</div>
		)

		return tableObj
	}

	render() {

		if(!this.props.paramData) {
			return null
		}

		if(this.props.paramData.dims===1) {
			return this.render1d()
		}

		if(this.props.paramData.dims===2) {
			return this.render2d()
		}

		if(this.props.paramData.dims===3) {
			return this.render3d()
		}

		if(this.props.paramData.dims===4) {
			return this.render4d()
		}
	}
	
} 


export default InputTable2d;