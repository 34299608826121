import React from 'react';
import { Link } from 'react-router-dom';

import './Home.css';

var logo = process.env.PUBLIC_URL + 'icons/dercam-logo.png';


class Home extends React.Component {

	renderButtonSet() {
		// renders sign up/login buttons if user is not auth
		// renders user home/log out buttons if user is auth

		if (!this.props.auth) {
			return (
				 <div className="home-menu">
		        	<div className='home-menu-button'>
		        		<Link to="/acct/register" className='react-link'><h2>Sign Up</h2></Link>
		        	</div>
		        	<div className='home-menu-button'>
		        		<Link to="/login" className='react-link'><h2>Log In</h2></Link>
		        	</div>
		        </div>
			)
		} else {
			return (
				 <div className="home-menu">
		        	
		        	<div className='home-menu-button'>
		        		<Link to="/u" className='react-link'><h2>User Home</h2></Link>
		        	</div>
		        	<div className='home-menu-button' onClick={this.props.userLogout}>
		        		<h2>Log Out</h2>
		        	</div>
		        </div>
			)
		}
	}

	render() {
		return (
			<div className="home-container">
		        <header className="home-header">
		        	<div className='home-header-logo'>
		          		<img src={logo} className="home-header-logo-img" alt="logo" />
			        </div>
			        <div className='home-header-text'>
		          		<h1>DER-CAM<span className="home-sub-head"> IO</span></h1>
			        </div>
		        </header>
		       {this.renderButtonSet()}
		        <div className="home-footer">
		        	<p> &#169; 2021 Lawrence Berkeley National Lab </p>
		        </div>
		    </div>
		)
	}
}



export default Home;