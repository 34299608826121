import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import './HomeDercam.css';

import Navbar from '../Navbar/Navbar'
import ModelList from '../ModelList/ModelList'

const dlLink = 'https://drive.google.com/file/d/14SDPtN-xZrxnyeERIUiWuwjRsEMM-rj_/view?usp=sharing';
const webLink = 'https://microgrids2.lbl.gov/';

class HomeDercam extends React.Component {



  render(){ 

  	const url = this.props.match.url
  	// get upload url by going one level up from url
  	const ulUrl = url.slice(0, url.lastIndexOf('/'));

    return( 
    	<div className="page-scroll-container">

    		<section className='sec-text text-section'>
    			<h2 className='sec-title-h1'>DER-CAM Web UI</h2>
    			<p > <span className='text-emph'>UPDATE:</span> a beta release of the browser-based web interface for DER-CAM is currently available.
    			Use the buttons below to access the New Project Menu or Existing Projects. Please note when using the beta version 
    			UI, you may experience bugs, errors, or temporary disruptions to operations. Functionality currently missing form
    			the beta version is outlined below. If you experience bugs or issues when using the tool beyond this list and would
    			like to share your feedback, please contact us at <span className='text-emph'>dercam@lbl.gov</span> with the subject "DER-CAM UI Bug Report".
    			</p>

    			<p><span className='text-emph'>Incomplete UI Features</span></p>
    			<ul>
    				<li>Results graphics and Data visualization</li>
    				<li>Iteractive Network Construction Menu</li>
    				<li>Type checking for input table cells</li>
    			</ul>

    			<div className='dark-btn dl-btn'>
    				<Link to={`${url}/new`} className='react-link'><h2>New DER-CAM Project</h2></Link>
		    	</div>
		    	<br/>
		    	<div className='dark-btn dl-btn'>
		    		<Link to={`${url}/projects`} className='react-link'><h2>Existing DER-CAM Projects</h2></Link>
		    	</div>
		    	<br/>
		    	<div className='dark-btn dl-btn'>
		    		<Link to={`${ulUrl}/upload`} className='react-link'><h2>Upload Project from File</h2></Link>
		    	</div>

    		</section>

    		<section className='sec-text text-section'>
    			<h2 className='sec-title-h1'>DER-CAM Desktop Application</h2>
    			<p >The current interface to DER-CAM is available to download as a desktop application,
    			and will continue to operate until the web-version is complete. You may download an installer using the link
    			below. The DER-CAM team has limited capacity for user support but will do our best to assist with technical
    			questions or issues related to this version.</p>
    			<p >Installation requirements:</p>
    			<ul >
    				<li> Windows OS </li>
    				<li>Microsoft Excel</li>
    				<li>Microsoft Access</li>
    			</ul>

    			<div className='dark-btn dl-btn' onClick={()=>{window.open(dlLink, '_blank')}}>
		    		<p><a href={dlLink} target="_blank">Download DER-CAM Desktop</a></p>
		    	</div>
    		</section>

    		<section className='sec-text text-section'>
    			<h2 className='sec-title-h1'>DER-CAM Desktop Browser Environment</h2>
    			<p >A test version of DER-CAM Desktop is hosted on our servers, and available to users who do not
    			meet the installation requirements. This version have many of the same features as the desktop 
    			application, but imposes some limits to importing/exporting data.  The web UI workspace is shared among
    			 other DER-CAM users. Project files hosted on the web UI are public and can be accessed and modified by 
    			 other users of the web UI. Please be aware of this if you plan to store any sensitive information on 
    			 the server. Please also be respectful of other users files.
				</p>

				<p >Please note, this DER-CAM test application will cease operation once the new web application is released.
				Users will have limited access to model files and data after the app is retired.</p>

				<p >Your login credentials:</p>
				<table className='ui-table'>
				  <tr>
				    <td className='ui-field'>username:</td>
				    <td className='ui-value'>microgrids2\user250</td>
				  </tr>
				  <tr>
				    <td className='ui-field'>password:</td>
				    <td className='ui-value'>5&3FQxY38LkF</td>
				  </tr>
				</table>

    			<div className='dark-btn dl-btn' onClick={()=>{window.open(webLink, '_blank')}}>
		    		<p><a href={webLink} target="_blank">Access DER-CAM Web Desktop</a></p>
		    	</div>
		    	<br/>
		    	<br/>
		    	<br/>
    		</section>
			
		
		</div>
    ) 
  }
} 


export default withRouter(HomeDercam);