import React from 'react';
import { Link, withRouter} from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import './Navbar.css';

import { ReactComponent as BurgerIcon } from '../../icons/burger.svg';
import { ReactComponent as SignoutIcon } from '../../icons/signout.svg';
import { ReactComponent as HomeIcon } from '../../icons/home.svg';

import { ReactComponent as CaretIcon } from '../../icons/caret.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevron.svg';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';


// var logo = process.env.PUBLIC_URL + 'icons/dercam-logo.png';
var logo = '/icons/dercam-logo.png';

class NavItem extends React.Component {
	constructor(props){
		super(props)

		this.state = {
			open: false
		}

		this.toggleShow = this.toggleShow.bind(this);
		this.selectOnClick = this.selectOnClick.bind(this);
	}

	toggleShow() {
		this.setState({open:!this.state.open})
	}

	selectOnClick() {
		// function selects onclick action
		// if onClick passed in props, use that
		// otherwise toggleshow (for dropdown functionality)
		if(this.props.onClick){
			// run onClick prop function
			this.props.onClick();
			this.toggleShow();
		} else if(this.props.dropdown) {
			// otherwise, run toggleShow
			this.toggleShow();
		} else {
			return;
		}
	}

	render() {
		return (
		    <li className="nav-item">
		    <Link to={this.props.to} className='react-link'>
		      <div className="icon-button" onClick={this.selectOnClick} onBlur={this.toggleShow}>
		        {this.props.icon}
		      </div>
		    </Link>

		      {this.state.open && this.props.children}
		    </li>
		);
	}
}


class DropdownItem extends React.Component {
    render () {
    	return (<div href="#" className="menu-item" onClick={this.props.onClick}>
            <span className="icon-button">{this.props.leftIcon}</span>
            {this.props.children}
            <span className="icon-right">{this.props.rightIcon}</span>
          </div>
        );
     }
  }


class DropdownSection extends React.Component {
    render () {
    	return (<div href="#" className="menu-item" onClick={this.props.onClick}>
            <span className="icon-button">{this.props.leftIcon}</span>
            {this.props.children}
            <span className="icon-right">{this.props.rightIcon}</span>
          </div>
        );
     }
  }


class DropdownMenu extends React.Component {
	constructor(props){
		super(props)

		this.state = {
			activeMenu: 'default',
			menuHeight: null
		}

		this.dropdownRef = React.createRef(null);

		this.calcHeight = this.calcHeight.bind(this);
		this.useEffect = this.useEffect.bind(this);
		this.setMenuState = this.setMenuState.bind(this);
	}

	useEffect() {
		this.setState({menuHeight: this.dropdownRef.current?.firstChild.offsetHeight});
	}

  	calcHeight(el) {
	    const height = el.offsetHeight;
	    this.setState({menuHeight: height});
	 }

	 setMenuState(activeMenu) {
	 	this.setState({activeMenu: activeMenu})
	 }

  
  	render() {
  		return (
		    <div className="dropdown" style={{ height: this.state.menuHeight }} ref={this.dropdownRef}>

		      <CSSTransition
		        in={this.state.activeMenu === 'default'}
		        timeout={500}
		        classNames="menu-primary"
		        unmountOnExit
		        onEnter={this.calcHeight}>
		        <div className="menu">
		          <DropdownItem
		            onClick={()=>{this.props.history.push('/u')}}>
		            My Account
		          </DropdownItem>
		          <DropdownItem
		            onClick={()=>{this.props.history.push('/u/dercam')}}>
		            DER-CAM UI
		          </DropdownItem>
		          <DropdownItem
		            onClick={()=>{this.props.history.push('/u/rdt')}}>
		            Resilient Design Tool
		          </DropdownItem>
		          <DropdownItem
		            onClick={()=>{this.props.history.push('/u/api')}}>
		            DER-CAM API
		          </DropdownItem>
		          <DropdownItem
		            leftIcon={<SignoutIcon />}
		            onClick={this.props.userLogout}>
		            Logout
		          </DropdownItem>


		        </div>
		      </CSSTransition>

		      <CSSTransition
		        in={this.state.activeMenu === 'main'}
		        timeout={500}
		        classNames="menu-primary"
		        unmountOnExit
		        onEnter={this.calcHeight}>
		        <div className="menu">
		          <DropdownItem>My Profile</DropdownItem>
		          <DropdownItem
		            leftIcon={<HomeIcon />}
		            rightIcon={<ChevronIcon />}
		            goToMenu="settings"
		            onClick={()=>{this.setMenuState('settings')}}>
		            Settings
		          </DropdownItem>
		          <DropdownItem
		            leftIcon="🦧"
		            rightIcon={<ChevronIcon />}
		            goToMenu="animals"
		            onClick={()=>{this.setMenuState('animals')}}>
		            Animals
		          </DropdownItem>

		        </div>
		      </CSSTransition>

		      <CSSTransition
		        in={this.state.activeMenu === 'settings'}
		        timeout={500}
		        classNames="menu-secondary"
		        unmountOnExit
		        onEnter={this.calcHeight}>
		        <div className="menu">
		          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />} 
		            onClick={()=>{this.setMenuState('main')}}>
		            <h2>My Tutorial</h2>
		          </DropdownItem>
		          <DropdownItem leftIcon={<HomeIcon />}>HTML</DropdownItem>
		          <DropdownItem leftIcon={<HomeIcon />}>CSS</DropdownItem>
		          <DropdownItem leftIcon={<HomeIcon />}>JavaScript</DropdownItem>
		          <DropdownItem leftIcon={<HomeIcon />}>Awesome!</DropdownItem>
		        </div>
		      </CSSTransition>

		      <CSSTransition
		        in={this.state.activeMenu === 'animals'}
		        timeout={500}
		        classNames="menu-secondary"
		        unmountOnExit
		        onEnter={this.calcHeight}>
		        <div className="menu">
		          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}
		            onClick={()=>{this.setMenuState('main')}}>
		            <h2>Animals</h2>
		          </DropdownItem>
		          <DropdownItem leftIcon="🦘">Kangaroo</DropdownItem>
		          <DropdownItem leftIcon="🐸">Frog</DropdownItem>
		          <DropdownItem leftIcon="🦋">Horse?</DropdownItem>
		          <DropdownItem leftIcon="🦔">Hedgehog</DropdownItem>
		        </div>
		      </CSSTransition>
		    </div>
		)
  	}
}


class Navbar extends React.Component {

	renderNavButtonsIn() {
		return (
			<div className='nav-menu-button-container'>
				<NavItem icon={<ArrowIcon />} dropdown={false} onClick={()=>{this.props.history.go(-1)}} to={'/u'} />
				<NavItem icon={<HomeIcon />} dropdown={false} onClick={null} to={'/u'} />
				<NavItem icon={<SignoutIcon />} dropdown={false} onClick={this.props.userLogout} />

				<NavItem icon={<BurgerIcon />} dropdown={true} onClick={null}>
					<DropdownMenu userLogout={this.props.userLogout} history={this.props.history}>
					</DropdownMenu>
				</NavItem>
        	</div>
		)

	}

	renderNavButtonsOut() {
		return (
			<div className='nav-menu-button-container'>
				<NavItem icon={<ArrowIcon />} dropdown={false} onClick={()=>{this.props.history.go(-1)}} to={'/u'} />
        	</div>
		)

	}

	selectNavButtons() {
		if(this.props.auth) {
			return(this.renderNavButtonsIn())
		} else {
			return(this.renderNavButtonsOut())
		}
	}

	render() {
		return (
			<div className="nav-container">
				<div className='nav-brand'>
		        	<div className='nav-logo'>
		          		<img src={logo} alt="logo" />
			        </div>
			        <div className='nav-header-text'>
		          		<Link to="/u" className='react-link'>
		          			<h1>DER-CAM<span className="nav-sub-head">IO</span></h1>
		          		</Link>
			        </div>
		        </div>
		        
		        {this.selectNavButtons()}
		        
		    </div>
		)
	}
}



export default withRouter(Navbar);