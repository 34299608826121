import React from 'react';

import Helper from '../../helper';

import './Results.css';


class ResultsSelect extends React.Component {

	makeSelections() {
		if(this.props.optionList) {
			return(
				this.props.optionList.map((option) => (
					<option value={option.value}>{option.label}</option>
				))
			)
		} else {
			return <option value='na'>Select Category</option>
		}
	}

	render() {

		// check if minWidths passed in props, if so, create style strings for label and select
		var labelStyle = {};
		var selectStyle = {};
		if(this.props.minWidths){
			//style="color: red; font-size: 20px;"
			try{
				labelStyle={'width':this.props.minWidths[0]};
				selectStyle={'width':this.props.minWidths[1]};
			} catch{
				// do nothing
			}
		}

		return (
			<div className='res-select-container'>
				<div className='res-select-label-container' style={labelStyle}>
					<label 
						for={this.props.name} 
						className='res-select-label'
					>
						{this.props.label}:
					</label>
				</div>
				<select 
					className='res-select'
					name={this.props.name}  
					id={this.props.name} 
					onChange={this.props.onChange}
					data-category={this.props.category}
					data-results={this.props.results}
					data-callback={this.props.callback}
					style={labelStyle}					
				>
				  {this.makeSelections()}
				</select>
			</div>
		)
	}
}

export default ResultsSelect;