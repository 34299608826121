import React from 'react';

import Helper from '../../helper';

import './Results.css';

import ResultsSection from './ResultsSection.js'

import ResultsTable from '../ResultsTable/ResultsTable';

import { ReactComponent as CaretIcon } from '../../icons/caretSolid.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';

const _ = require('lodash');



class SectionInvest extends React.Component {
	constructor(props){
		super(props)

		this.renderSection = this.renderSection.bind(this);
	}

	formatContInvs(contInv){
		// method formats cont-tech investments for writing to table
		if(!contInv){
			return null
		}

		// check if data is empty list
		if(contInv.length==0){
			return null
		}

		var contInvFormatted = {}

		contInv = _.cloneDeep(contInv)

		for(let tt=0; tt<contInv.length; tt++) {

			// extract tech data from array
			let curTech = contInv[tt];

			// get tech type name
			let techType = curTech.type;

			// drop 'type' field from tech obj
			delete curTech.type;

			// check if remaining fields are 0
			var hasNonZero = false
			for (var key in curTech) {
				// convert vals to float
				curTech[key] = parseFloat(curTech[key])
				// check if value is non-zero. if so, flip flag
				hasNonZero = curTech[key] != 0.0 ? true : hasNonZero;
			}

			// add value to formatted obj if value contains non-zero
			if (hasNonZero) {
				contInvFormatted[techType] = curTech
			}

		}

		// transpose obj for plotting
		contInvFormatted = Helper.transposeObj(contInvFormatted)

		return contInvFormatted;
	}

	renderSection() {

		// format cont tech investments
		const contInvFormatted = this.formatContInvs(this.props.dataInvestments.investmentCont)
		const dgInvFormatted = this.formatContInvs(this.props.dataInvestments.investmentDg)
		const dgLfInvFormatted = this.formatContInvs(this.props.dataInvestments.investmentLfDg)

		return(
			<div className='results-section'>
				<h2 className='results-heading'>3. Investments</h2>

				
					<div className='results-subsection'>

						<ResultsTable
							data={contInvFormatted}
							title='Continuous DER Techs'
							unit=''
						/>

						<ResultsTable
							data={dgInvFormatted}
							title='Discrete Genset Techs'
							unit=''
						/>

						<ResultsTable
							data={dgLfInvFormatted}
							title='Load-Following Genset Techs'
							unit=''
						/>
						
						<div className='results-note-table'>
							<p>
								Note: Investments for inverters, central HVAC capacities, energy efficiency and other
								minor components are not yet reported through the results interface. Please refer to the
								full .csv results files for details of these investments, if enabled in your model.
							</p>
						</div>
					</div>
			</div>

		)
		
	}

	render() {
		return(
			<ResultsSection
				dataLoaded={this.props.dataLoaded}
				renderFunc={this.renderSection}
				sectionName='Investment Results'
			/>
		)
	}

}

export default SectionInvest;