import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

class Chart extends PureComponent {

  render() {

    // console.log(this.props.data)

    // return nothing if data in props in missing or empty
    if(!this.props.data || Object.entries(this.props.data).length === 0) {
      return null
    }

    // initialize data as an empty array
    var data = []
    var barList = []

    // process data into correct form for plotting
    if (this.props.data) {
        // if data is in props, extract keys which rep labels of each group
        const groupLabels = Object.keys(this.props.data)
        
        // extract the names of the data points within each group
        const itemLabelsRaw =  Object.keys(this.props.data[groupLabels[0]])
        // convert raw data item labels to processed labels
        var itemLabels = itemLabelsRaw.map((e) => ( 
           this.props.procRowLabels(e)
        ));
        // console.log('raw item labels:')
        // console.log(itemLabelsRaw)
        
        // console.log('processed item labels:')
        // console.log(itemLabels)

        // loop through props data objects, process, and add to list
        for (let i = 0; i < groupLabels.length; i++) {
          let groupLabel = groupLabels[i];
          
          // extract data from group. keys will need to be renamed
          let groupDataRaw = this.props.data[groupLabel]
          let groupData = {}

          // update data key labels with processed names
          for (let jj = 0; jj < itemLabelsRaw.length; jj++) {
                let itemLabelRaw = itemLabelsRaw[jj]
                let itemLabel = itemLabels[jj];

                groupData[itemLabel] = groupDataRaw[itemLabelRaw]
                
            }

            // add name to groupdata, used for plotting
            groupData['name'] = this.props.procColLabels(groupLabel)

            data.push(groupData)
        }

        // console.log(itemLabels)

        // build barchart items list
        for (let jj = 0; jj < itemLabels.length; jj++) {
          let itemLabel = itemLabels[jj];
          let itemLabelRaw = itemLabelsRaw[jj]
          
          let newBar =  <Bar dataKey={itemLabel} fill={this.props.colorMap[itemLabelRaw]}/>

          barList.push(newBar)

        }
    }

    



    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {barList}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default Chart;
