import React from 'react';
import { BrowserRouter, Link, Route, Switch, withRouter} from 'react-router-dom';

import './NodeSandBox.css';

import Helper from '../../helper';
import ProjectUtils from '../../ProjectUtils'

import Modal from '../Modal/Modal';

import { ReactComponent as LoadIcon } from '../../icons/loading.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

import { ReactComponent as NodeIcon000 } from '../../icons/nodes/node_0_0_0.svg';
import { ReactComponent as NodeIcon001 } from '../../icons/nodes/node_0_0_1.svg';
import { ReactComponent as NodeIcon010 } from '../../icons/nodes/node_0_1_0.svg';
import { ReactComponent as NodeIcon011 } from '../../icons/nodes/node_0_1_1.svg';
import { ReactComponent as NodeIcon100 } from '../../icons/nodes/node_1_0_0.svg';
import { ReactComponent as NodeIcon101 } from '../../icons/nodes/node_1_0_1.svg';
import { ReactComponent as NodeIcon110 } from '../../icons/nodes/node_1_1_0.svg';
import { ReactComponent as NodeIcon111 } from '../../icons/nodes/node_1_1_1.svg';


const _ = require('lodash');

const nodeIcons = {
	'000' : <NodeIcon000/>,
	'001' : <NodeIcon001/>,
	'010' : <NodeIcon010/>,
	'011' : <NodeIcon011/>,
	'100' : <NodeIcon100/>,
	'101' : <NodeIcon101/>,
	'110' : <NodeIcon110/>,
	'111' : <NodeIcon111/>
}

const typeListGeneric = {
	'cable': [
		'C1',
		'C2',
		'C3',
		'C4'
	],
	'transformer': [
		'Tx1',
		'Tx2',
		'Tx3',
		'Tx4'
	],
	'HT pipe': [
		'HT1',
		'HT2',
		'HT3',
		'HT4'
	],
	'LT pipe': [
		'LT1',
		'LT2',
		'LT3',
		'LT4'	]
}


// Node Object Component
class NodeObject extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			initX: 0,
			initY: 0,
			curX: 0,
			curY: 0,
			diffX: 0,
			diffY: 0
		}

		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		this.handleNodeClick = this.handleNodeClick.bind(this);
	}
	
	handleDrag(e) {
		// method handles drag event by updating node X & Y pos

		// do nothing is cursor X Y shows 0 (not sure why this happens, but causes node to jump)
		if(e.clientX == 0 && e.clientY == 0){
			return
		}

		// extract node key from event
		var nodeKey = e.target.dataset.nodeKey;

		// update x/y positions
		var diffX = e.clientX - this.state.initX;
		var diffY = e.clientY - this.state.initY;

		// get previous init pos for cursor
		var initX = this.state.initX
		var initY = this.state.initY

		// calculate new positions
		var newX = parseInt(e.target.dataset.posX) + parseInt(diffX);
		var newY = parseInt(e.target.dataset.posY) + parseInt(diffY);

		// update init cursor position to current position
		this.setState({
			initX:parseInt(e.clientX), 
			initY:parseInt(e.clientY),
			newX:newX,
			newY:newY
		});

		// update node location 
		this.props.updateNodeLocation(nodeKey, newX, newY)

		// console.log(`cursor at: ${e.clientX} ${e.clientY}`)
		// console.log(`update loc to: ${newX} ${newY}`)
	}

	async handleMouseDown(e) {
		// method on initial mouse down. sets starting point for user mouse position

		await this.setState({initX:parseInt(e.clientX), initY:parseInt(e.clientY)});

		// console.log("INIT STATE X: " + this.state.initX + " | Y: " + this.state.initY)

		// console.log('Mouse Down Now')
	}

	async handleMouseUp(e) {
		// method on mouse release. Not sure if this is doing anything useful


		// console.log('Mouse Up Now')

		// extract node key from event
		var nodeKey = e.target.dataset.nodeKey;

		// var diffX = this.state.curX - this.state.initX;
		// var diffY = this.state.curY - this.state.initY;

		// var newX = parseInt(e.target.dataset.posX) + parseInt(this.state.curX) - parseInt(this.state.initX);
		// var newY = parseInt(e.target.dataset.posY) + parseInt(this.state.curY) - parseInt(this.state.initY);;

		// console.log("CHANGE STATE X: " + this.state.curX + " | Y: " + this.state.curY)

		// console.log("Final STATE X: " + this.state.newX + " | Y: " + this.state.newY)
		this.props.updateNodeLocation(nodeKey, this.state.newX, this.state.newY)


	    // await this.props.updateNodeLocation(nodeKey, this.state.newX, this.state.newY)
	}

	handleNodeClick(e) {
		// method handles click on node to activate/deactivate

		// extract node key from event
		var nodeKey = e.target.dataset.nodeKey;

		console.log(`handling click on node ${nodeKey}`)

		this.props.activateNode(nodeKey)
	}

	selectNodeIcon(nodeData) {
		// selects node icon file name based on properties
		// by looking up icon in nodeIcon object
		// using key generated from pcc, load, and slack properties

		// func to convert bool to 1 or 0
		const bool2int = (val) => (val ? 1 : 0)

		// get bool for pcc, load, and slack to reference correct icon
		const isPcc = bool2int(nodeData.pcc);
		const isLoad = bool2int(nodeData.load);
		const isSlack = bool2int(nodeData.slack);

		const iconKey = `${isPcc}${isLoad}${isSlack}`

		return nodeIcons[iconKey];

	}

	render() {

		const nodeData = this.props.nodeData

		// logic for selecting node icon
		var nodeIcon = this.selectNodeIcon(nodeData)

		// extract x/y positions from nodeData
		const posX = `${nodeData.posX}px`;
		const posY = `${nodeData.posY}px`;

		if(this.props.activeNode == this.props.nodeData.key) {

			return (
				<div 
					className='node-obj node-obj-active' 
					style={{top: posY, left: posX}}
				>
					<h4>{nodeData.name}</h4>
					{nodeIcon}
					<div 
						className='node-overlay'
						draggable
						onDragStart={this.handleMouseDown}
						onDragEnd={this.handleMouseUp}
						onDrag={this.handleDrag}
						onClick={this.handleNodeClick}
						data-node-key={nodeData.key}
						data-pos-x={nodeData.posX}
						data-pos-Y={nodeData.posY}
					>

					</div>
				</div>

			)
		} else {
			return (
				<div 
					className='node-obj node-obj-inactive' 
					style={{top: posY, left: posX}}
				>
					<h4
						onClick={this.handleNodeClick}
						data-node-key={nodeData.key}
					>{nodeData.name}</h4>
					{nodeIcon}
					<div
						className='node-overlay'
						draggable
						onDragStart={this.handleMouseDown}
						onDragEnd={this.handleMouseUp}
						onDrag={this.handleDrag}
						onClick={this.handleNodeClick}
						data-node-key={nodeData.key}
						data-pos-x={nodeData.posX}
						data-pos-Y={nodeData.posY}
					>

					</div>
				</div>

			)
		}
	}

}


// Node Sand Box Component
class NodeSandBox extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			maxNodes: 20,
			msg: '',
			nodeList: {},
			lineList: {},
			typeList: typeListGeneric,
			activeNode: null,
			activeLine: null,
			delNodeList: [],
			lineTypes: ['cable', 'transformer', 'HT pipe', 'LT pipe'],
			conflictLineTypes: ['cable', 'transformer'],
			selectedNode: null,
			selectedType: null,
			selectedLen: null,
			selectedModelIndex: null,
			lineOffsets: {'cable': 0, 
						'transformer': 0, 
						'HT pipe': 6, 
						'LT pipe': -6
			},
			lineLabels: {'cable': null, 
						'transformer': 'Tx', 
						'HT pipe': 'HT', 
						'LT pipe': 'LT'
			},

			// state vars related to submission (write network to tables)
			displayHelp: false,
			writeConfirm: false,
			writePending: false,
			writeSuccess: false,
			writeFailure: false,
			writeDelay: 1000, 
		}

		this.renderNodes = this.renderNodes.bind(this);
		this.renderLines = this.renderLines.bind(this);
		this.updateNodeLocation = this.updateNodeLocation.bind(this);
		this.activateNode = this.activateNode.bind(this);
		this.addNewNode = this.addNewNode.bind(this);
		this.addLine = this.addLine.bind(this);
		this.deleteCurNode = this.deleteCurNode.bind(this);
		this.deleteLine = this.deleteLine.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.updateMsg = this.updateMsg.bind(this);
		this.updateNodeProp = this.updateNodeProp.bind(this);
		this.submitWriteNetwork = this.submitWriteNetwork.bind(this);
		this.writeNetwork = this.writeNetwork.bind(this);
		this.returnToEditor = this.returnToEditor.bind(this);
		this.toggleHelp = this.toggleHelp.bind(this);
	}

	componentDidMount() {
		// on mount, check if project passed in props.
		// if not, populate a generic project

		// FIX: should align # nodes to prop value

		var msg = ''

		// try to extract network data from props
		try {
			// if network model in props, load to vars
			console.log('loading network model from props')

			var {nodeList, lineList} = this.props.networkModel
			msg = 'Loaded existing network model'
				
		} catch {
			// if no network model passed in props, create generic model
			console.log('creating generic network model')

			msg = 'Created new generic network model '
			var {nodeList, lineList} = this.createStarterNetwork()

		}

		// extract typeList if  projData passed in props, otherwise use generic typeList
		try {
			
			var typeList = ProjectUtils.getConnectorTypes(this.props.projData)
				
		} catch {
			
			var typeList = typeListGeneric;

		}
		if(this.props.projData){

		}

		// var typeList = this.props.typeList ? this.props.typeList : typeListGeneric;	
		
		// update state with network model vars and message
		this.setState({
			msg: msg,
			nodeList: nodeList,
			lineList: lineList,
			typeList: typeList
		})

	}

	componentWillUnmount() {
		// save current network model data on unmount
		if(this.props.saveProject) {

			var networkModel = {
				nodeList:this.state.nodeList,
				lineList:this.state.lineList
			}

			this.props.saveProject('networkModel', networkModel)
			console.log('saving current network model data')
		} else {
			console.log ('no saveProject method passed in props')
		}
		
	}

	createStarterNetwork() {
		// method will generate a generic starter network
		// called when existing network is not passed through props


		// To-Do: should eventually have a # of nodes passed to align with user selection
		const nodeList = {
			nodeABC: {
				key:'nodeABC',
				name: 'node 01',
				nodeIndex: 1,
				posX: 30,
				posY: 30,
				connections: [],
				pcc: false,
				load: false,
				slack:false
			},
			nodeXYZ: {
				key:'nodeXYZ',
				name: 'node 02',
				nodeIndex: 2,
				posX: 30,
				posY: 130,
				connections: [],
				pcc: false,
				load: false,
				slack:false
			},
			nodeJKL: {
				key:'nodeJKL',
				name: 'node 03',
				nodeIndex: 3,
				posX: 200,
				posY: 200,
				connections: [],
				pcc: false,
				load: false,
				slack:false
			},
		}

		const lineList = {
			'nodeABC-nodeXYZ-cable': {
				key: 'nodeABC-nodeXYZ-cable',
				nodes:['nodeABC', 'nodeXYZ'],
				type: 'cable',
				modelIndex: 2,
				length: 99
			},
			'nodeXYZ-nodeJKL-cable': {
				key: 'nodeXYZ-nodeJKL-cable',
				nodes:['nodeXYZ', 'nodeJKL'],
				type: 'cable',
				modelIndex: 1,
				length: 101
			}
		}

		return {nodeList:nodeList, lineList:lineList}
	}

	renderNodes() {
		// method renders a NodeObject component for each node present in state.nodeList

		// get nodeList as array
		var nodeList = Object.values(this.state.nodeList)

		// loop through existing nodes
		return (
			nodeList.map(nodeData => {

				return(
					<NodeObject
						nodeData={nodeData}
						updateNodeLocation={this.updateNodeLocation}
						activeNode={this.state.activeNode}
						activateNode={this.activateNode}
					/>
				)
			})
		)
	}

	renderLines() {
		// method renders svg for each line between nodes

		const nodeOffsetY = 64;
		const nodeOffsetX = 44;

		// line width of node-box outline
		const nodeOutline = 2;

		// length of straight segment coming from node
		const segLen = 10;
		// threshold for switching between H and V lines
		const switchVal = nodeOffsetY + 2 * segLen

		const lineList = this.state.lineList

		const lineListSvg = [

		]

		// loop through lines in list
		// for(let ll=0; ll<lineList.length; ll++) {
		for (const lineKey in lineList) {

			// get data for current line
			let curLine = lineList[lineKey];

			// get lineType for styling - no caps or whitespace
			let lineType = curLine.type;
			let lineTypeCss = Helper.removeWhiteSpace(lineType).toLowerCase();

			// get offset for current line
			let lineOffset = this.state.lineOffsets[lineType];
			let lineLabel = this.state.lineLabels[lineType];

			// get node keys for line
			let nodeKey1 = curLine.nodes[0];
			let nodeKey2 = curLine.nodes[1];


			try {

				
				// get node data for each node
				let node1 = this.state.nodeList[nodeKey1];
				let node2 = this.state.nodeList[nodeKey2];

				// assign node1 to node with smaller posY (i.e. higher node)
				if (node2.posY < node1.posY){
					// if node 2 higher, switch nodes
					let nodeCopy = node2;
					node2 = node1;
					node1 = nodeCopy;
				}

				// get node locations
				let x1 = node1.posX;
				let x2 = node2.posX;

				let y1 = node1.posY;
				let y2 = node2.posY;

				// get location deltas
				let deltaX = x2 - x1;
				let deltaY = y2 - y1 ;

				// determine angle/quadrant of node-relative position
				//if 

				let deltaXAbs = Math.abs(deltaX);
				let deltaYAbs = Math.abs(deltaY)

				// 

				// if Delta Y larger than switchVal, use vertical line to connect nodes
				if ((deltaY > switchVal) || (deltaXAbs < 2*nodeOffsetX)) {

					// if deltaY small, set segLen to 0
					let segLenEff = (deltaY < switchVal) ? 0 : segLen;

					// define bounds for svg
					// find top, left of svg with lower of two nodes positions
					var svgTop = Math.min(y1, y2) + nodeOffsetY;
					var svgLeft = Math.min(x1, x2) + nodeOffsetX;

					var svgTopStr = `${svgTop}px`;
					var svgLeftStr = `${svgLeft}px`;


					// define points for right-angled lines
					if(deltaX>=0 && deltaY>=0) {
						// generate points of line
						let pointA = `${nodeOutline + lineOffset},${nodeOutline}`;
						let pointB = `${nodeOutline + lineOffset},${segLenEff}`;
						let pointC =  `${deltaXAbs + nodeOutline + lineOffset},${deltaYAbs - segLenEff - nodeOffsetY}`;
						let pointD =  `${deltaXAbs + nodeOutline + lineOffset},${deltaYAbs - nodeOffsetY}`;

						var pointsStr = `${pointA} ${pointB} ${pointC} ${pointD} `
					} else {
					// define points for left-angeled lines
						// generate points of line
						let pointA = `${deltaXAbs + nodeOutline + lineOffset},${nodeOutline}`;
						let pointB = `${deltaXAbs + nodeOutline + lineOffset},${segLenEff}`;
						let pointC =  `${nodeOutline + lineOffset},${deltaYAbs - segLenEff - nodeOffsetY}`;
						let pointD =  `${nodeOutline + lineOffset},${deltaYAbs - nodeOffsetY}`;

						var pointsStr = `${pointA} ${pointB} ${pointC} ${pointD} `
					}

				} else {
				// if Delta Y too small, generate horizontal connectin lines

					// if deltaX small, set segLen to 0
					let segLenEff = (deltaX < (2*(segLen + nodeOffsetX))) ? 0 : segLen;

					// define bounds for svg
					// find top, left of svg with lower of two nodes positions
					var svgTop = Math.min(y1, y2) + nodeOffsetY/2;
					var svgLeft = Math.min(x1, x2) + (nodeOffsetX + nodeOutline)*2;

					var svgTopStr = `${svgTop}px`;
					var svgLeftStr = `${svgLeft}px`;


					// define points for right-angled lines
					if(deltaX>=0 && deltaY>=0) {
						// generate points of line
						let pointA = `${2*nodeOutline},${lineOffset}`;
						let pointB = `${2*nodeOutline + segLenEff},${lineOffset}`;
						let pointC =  `${deltaXAbs - segLenEff - (nodeOffsetX + nodeOutline)*2},${deltaYAbs + lineOffset}`;
						let pointD =  `${deltaXAbs - (nodeOffsetX + nodeOutline)*2},${deltaYAbs  + lineOffset}`;

						var pointsStr = `${pointA} ${pointB} ${pointC} ${pointD} `
					} else {
					// define points for left-angeled lines
						// generate points of line
						let pointA = `${2*nodeOutline},${deltaYAbs + lineOffset}`;
						let pointB = `${2*nodeOutline + segLenEff},${deltaYAbs + lineOffset}`;
						// let pointC =  `${deltaXAbs - segLen - (nodeOffsetX + nodeOutline)*2},${deltaYAbs - nodeOffsetY/2 - 2*nodeOutline}`;
						// let pointD =  `${deltaXAbs - (nodeOffsetX + nodeOutline)*2},${deltaYAbs - nodeOffsetY/2 - 2*nodeOutline}`;
						let pointC =  `${deltaXAbs - segLenEff - (nodeOffsetX + nodeOutline)*2},${0 + lineOffset}`;
						let pointD =  `${deltaXAbs - (nodeOffsetX + nodeOutline)*2},${0 + lineOffset}`;
						
						var pointsStr = `${pointA} ${pointB} ${pointC} ${pointD} `
					}
				}

				let newSvg = (
					<svg className={`node-line node-line-${lineTypeCss}`} style={{'top':svgTopStr, 'left':svgLeftStr}} width={`${deltaXAbs+4}px`} height={`${deltaYAbs+4}px`} >
						<polyline fill="none" stroke-width="4" stroke-linejoin="round" stroke-miterlimit="10" 
						points={pointsStr}/>
					</svg>
				)

				lineListSvg.push(newSvg)

			} catch (error) {
  				console.error(error)
				console.log(`cannot render line ${nodeKey1}-${nodeKey2}`)
			}
		}

		return lineListSvg
	}

	renderMenus() {
		// method renders the node and line editing menus beneath sandbox plot

		const curNode = this.state.activeNode;

		// get list of nodes
		var nodeList = _.cloneDeep(this.state.nodeList);

		// remove current node (as it can't connect to itself)
		delete nodeList[curNode];

		// convert nodeList obj to list
		const nodeListNew = []
		for(const key in nodeList){
			// get data from obj
			let nodeData = nodeList[key]
			// add key field
			nodeData.key = key
			// add to list
			nodeListNew.push(nodeData)
		}
		// replace nodeList with nodeListNew
		nodeList = nodeListNew

		// generate select list for creating new nodes
		const nodeSelect = (

				<select
		            value={this.state.selectedNode}
		            onChange={this.handleSelect} 
		            className='line-select node-input'
		            data-field='selectedNode'
		            required
				>
					<option value="" selected disabled hidden>Select Node</option>
					{nodeList.map((node) => (
						<option value={node.key}>{node.name}</option>
				    ))}
					}

				</select>
		)

		// generate select list for line types
		const lineSelect = (

				<select
					value={this.state.selectedType}
		            onChange={this.handleSelect} 
		            className='line-select node-input'
		            data-field='selectedType'
		            required
				>
					<option value="" selected disabled hidden>Select Type</option>
					{this.state.lineTypes.map((line) => (
						<option value={line}>{line}</option>
				    ))}

				</select>
		)

		// generate select list of types for current line type
		const typeSelect = (

				<select
					value={null}
		            onChange={this.handleSelect} 
		            className='line-select node-input'
		            data-field='selectedType'
		            required
				>
					<option value="" selected disabled hidden>Select Type</option>
					{this.state.typeList['cable'].map((type) => (
						<option value={type}>{type}</option>
				    ))}

				</select>
		)

		// generate select list of types for current line type
		const lenSelect = (

				<input
					value={null}
		            onChange={this.handleSelect} 
		            className='line-select node-input'
		            data-field='selectedLen'
				>
				</input>
		)

		// get list of lines connected to current node

		// top-level menu buttons
		const secMainMenu = (
			<div className='node-menu-bar'>

				<h2 className="node-sand-box-subheader">Network Options:</h2>

				<div className='node-menu-btn text-button' onClick={this.addNewNode}>
					<h2> Add New Node </h2>
				</div>

				<div className='node-menu-btn text-button' onClick={this.submitWriteNetwork}>
					<h2> Write Network to Model </h2>
				</div>
			</div>
		)

		// get lineList as an array
		const lineListArray = Object.values(this.state.lineList);

		// filter lineList for lines that include curNode in key field
		var lineListLinked = lineListArray.filter(line => (line.key.indexOf(curNode)>=0));

		// existing connection row
		var lineTable = (
			
			lineListLinked.map((line) => {

				// get key of current line
				let lineKey = line.key;
				let lineType = line.type;

				// get key of node connected to curNode for current line
				let conNodes = line.nodes;
				let curNodeIndex = conNodes.indexOf(curNode); // should be 1 or 0
				let conNodeIndex = curNodeIndex == 1 ? 0 : 1; // should be inverse of above
				let conNodeKey = conNodes[conNodeIndex];

				// get name/label of other node for display
				let conNodeName = this.state.nodeList[conNodeKey].name

				return(
					<tr>
						<td> 
					        <div 
					        	className='node-menu-btn node-menu-btn-small text-button'
					        	onClick={()=>{this.deleteLine(lineKey)}}
					        >
								<h2> - </h2>
							</div>
						</td>
						<td className='node-line-table-data'> {conNodeName} </td>
						<td className='node-line-table-data'> {lineType} </td>
						<td className='node-line-table-data'> {this.state.typeList[lineType][line.modelIndex]} </td>
						<td className='node-line-table-data'> {line.length} </td>
					</tr>
				)
			})
							
		)

		// section for editing node
		var secNodeData = null;

		// extract node if activeNode is selected
		if(this.state.activeNode) {

			let nodeData = this.state.nodeList[curNode]

			secNodeData = (
				<div className='node-menu-box'>
					
					

					<div className='node-menu-section'>

						<h2 className="node-sand-box-subheader">Node Details: </h2>
						
						<table className='node-menu-table'>
							<tr>
								<th> Node: </th>
								<td> {nodeData.name} </td>
							</tr>
							<tr>
								<th> PCC: </th>
								<td> 
							          <input  
							            type="checkbox"
							            checked = {nodeData.pcc} 
							            onChange={this.updateNodeProp} 
							            className=''
							            data-field-key='pcc'
							            data-node-key={curNode}
							          /> 
								</td>
							</tr>
							<tr>
								<th> Enable Load: </th>
								<td> 
							          <input  
							            type="checkbox"
							            checked = {nodeData.load} 
							            onChange={this.updateNodeProp} 
							            className=''
							            data-field-key='load'
							            data-node-key={curNode}
							          /> 
								</td>
							</tr>
							<tr>
								<th> Slack Bus: </th>
								<td> 
							          <input  
							            type="checkbox"
							            checked = {nodeData.slack} 
							            onChange={this.updateNodeProp} 
							            className=''
							            data-field-key='slack'
							            data-node-key={curNode}
							          /> 
								</td>
							</tr>
						</table>

						<div className='node-menu-btn text-button' onClick={this.deleteCurNode}>
							<h2> Delete Current Node </h2>
						</div>


					</div>

					<div className='node-menu-section'>

						<h2 className="node-sand-box-subheader">Connections:</h2>
						
						<table className='node-menu-table'>
							<tr>
								<th>  </th>
								<th> Node </th>
								<th> Type </th>
								<th> Model </th>
								<th> Length </th>
							</tr>

							{lineTable}
							
							<tr>
								<td> 
							        <div className='node-menu-btn node-menu-btn-small text-button' onClick={this.addLine}>
										<h2> + </h2>
									</div>
								</td>
								<td> {nodeSelect} </td>
								<td> {lineSelect} </td>
								<td> {this.generateTypeSelect(this.state.selectedType, this.state.typeList)}</td>
								<td> {lenSelect} </td>	
							</tr>
						</table>

					</div>


				</div>
			)

		}

		//{secMainMenu}

		return (
			<div className='node-menu-container'>

				<div className='node-menu-msg'>
					<p> {this.state.msg} </p>
				</div>
			
				

				{secNodeData}

			</div>
		)

	}

	async activateNode(nodeKey) {
		// method actives node on click of node obj

		// set selections back to default
		await this.setState({selectedNode: null, selectedType: null})

		// deactive current node, if clicked
		if(this.state.activeNode == nodeKey) {

			// get index number of selected node
			var nodeIndex = this.state.nodeList[nodeKey].nodeIndex
			var msg = `Deselected node: node ${nodeIndex}`

			this.setState({activeNode:null, msg:msg})
		} else {
			// first set active node to null to re-render menu
			await this.setState({activeNode:null})
			// else, active clicked node

			// get index number of selected node
			var nodeIndex = this.state.nodeList[nodeKey].nodeIndex
			var msg = `Selected node: node ${nodeIndex}`

			this.setState({activeNode:nodeKey, msg:msg})
		}


	}

	async updateNodeLocation(nodeKey, newX, newY) {
		// method changes X Y pos of node to new location

		// console.log(`updating ${nodeKey} to X:${newX}, Y:${newY}`)

		// extract current node list
		const curNodeList = this.state.nodeList;

		// update node at nodeKey
		const curNode = curNodeList[nodeKey];

		// update X and Y
		// curNode.posX = curNode.posX + diffX;
		// curNode.posY = curNode.posY + diffY;

		newX = parseInt(newX);
		newY = parseInt(newY);

		// make sure new pos is within bounds
		newX = newX > 0 ? newX : 0;
		newY = newY > 0 ? newY : 0;

		curNode.posX = parseInt(newX);
		curNode.posY = parseInt(newY);

		curNodeList[nodeKey] = curNode

		await this.setState({nodeList: curNodeList})

	}

	addNewNode() {
		// method generates a new node

		// get current node List
		const curNodeList = this.state.nodeList;

		// get current list of nodes stored in state
		var nNodes = Object.keys(curNodeList).length

		// if current no of nodes at max, do nothing
		if (nNodes == this.state.maxNodes) {
			return
		}

		// increment node list by 1
		nNodes += 1

		// generate random unique key for new node
		let uniqueKey = false
		var nodeKey = null

		// use while loop and only proceed when unique key is generated
		while(!uniqueKey) {

			// generate random key
			nodeKey = Helper.genCapString(4);
			nodeKey = `node${nodeKey}`;

			// check that key doesn't already exist
			var existingKeys
			if(Object.keys(curNodeList).indexOf(nodeKey)==-1){
				uniqueKey = true;
			}
		}

		const nodeNum = String(nNodes).padStart(2, '0'); // '09'

		const nodeLabel = `node ${nodeNum}`

		//  generate random starting spot
		const initX = parseInt(Math.random() * 300) + 100;
		const initY = parseInt(Math.random() * 300) + 100;

		const newNode = {
			key: nodeKey,
			name: nodeLabel,
			nodeIndex: nNodes,
			posX: initX,
			posY: initY,
			connections: [],
			pcc: false,
			load: false
		}

		// add new node to node list
		curNodeList[nodeKey] = newNode;

		// create msg for new node
		let msg = `Created new node: ${nodeLabel}`

		// update state
		this.setState({nodeList: curNodeList, msg:msg})


	}

	addLine() {
		// method adds line between two nodes of specified type

		// get node1, node2, lineType from state
		const node1 = this.state.activeNode;
		const node2 = this.state.selectedNode;
		const lineType = this.state.selectedType;
		var lineLen= this.state.selectedLen;
		const modelIndex = this.state.selectedModelIndex;

		// validate lineLen as numerical or NA for tx
		if(lineType=='transformer') {
			console.log('line type is tx, len to 0')
			lineLen = 'NA';
		} else {
			lineLen = parseInt(lineLen)

			// set to 1 if parseInt returns NaN
			lineLen = lineLen ? lineLen : 1;

			if (lineLen < 0) {lineLen = 1}
		}




		// if selections not made, show warning msg
		if(!this.state.selectedNode || !this.state.selectedType || !this.state.selectedModelIndex) {
			let msg = `Please select Node and Type to add new Connection`;
			this.setState({msg:msg})

			return
		}



		// get current line list
		const lineList = this.state.lineList

		// generate field for new line obj
		var nodeList = [node1, node2]
		// sort nodeList so newLine order is always alphabetical
		nodeList = nodeList.sort()
		// generate new node-node str
		const lineStr = `${nodeList[0]}-${nodeList[1]}-${lineType}`

		// get node labels for node1 and node 2
		let nodeLabel1 = this.state.nodeList[nodeList[0]].name;
		let nodeLabel2 = this.state.nodeList[nodeList[1]].name;

		// if lineStr already exists, show warning smg
		if(Object.keys(lineList).indexOf(lineStr)>=0) {
			let msg = `Line of type: ${lineType} between ${nodeLabel1} - ${nodeLabel2} already exists.`
			this.setState({msg:msg})

			return
		}

		// console.log('debugging line conflict code')
		// console.log(lineType)


		// check that transformer and cable lines can't both be implemented
		if (this.state.conflictLineTypes.indexOf(lineType)>=0){
			// check if any version of conflicting lines already exists
			console.log('triggered conflict check')

			console.log(Object.keys(lineList))

			for(let n1=0; n1<2; n1++){
				for(let n2=0; n2<2; n2++){
					for(let cc=0; cc<this.state.conflictLineTypes.length; cc++){


						let nodeCheck1 = nodeList[n1];
						let nodeCheck2 = nodeList[n2];
						let lineCheck = this.state.conflictLineTypes[cc];

						let keyCheck = `${nodeCheck1}-${nodeCheck2}-${lineCheck}`;

						console.log(`checking conflict for ${keyCheck}`)

						// if conflicting line exists, pring warning
						if(Object.keys(lineList).indexOf(keyCheck)>=0) {
							let msg = `A conflicting line-type between ${nodeLabel1} - ${nodeLabel2} already exists.`
							this.setState({msg:msg})

							return
						}

					}
				}
			}
		}

		const newLine = {
			key:lineStr,
			nodes:nodeList,
			type: lineType,
			length: lineLen,
			modelIndex: modelIndex
		}

		

		// create msg for new line
		let msg = `Added line type: ${lineType} between ${nodeLabel1} - ${nodeLabel2}`

		// add new line to lineList, then update to state
		lineList[lineStr] = (newLine);
		this.setState({
			lineList:lineList, 
			msg:msg,
			selectedNode: null,
			selectedType: null,
			selectedLen: null
		})


	}

	deleteCurNode() {
		// method deletes current node

		// if no node active, do nothing
		if (!this.state.activeNode) {
			return
		}

		// get current node List
		var curNodeList = this.state.nodeList;
		const delNodeList = this.state.delNodeList;

		// get node data to be removed
		const delNode = curNodeList[this.state.activeNode]

		// delete node from list
		delete curNodeList[this.state.activeNode]

		// add removed node to delNodeList for later retrieval
		delNodeList.push(delNode)

		// reorder nodeList
		curNodeList = this.reorderNodes(curNodeList)

		// create msg for new line
		let msg = `Deleted node: ${delNode.name}. Re-ordering remaining nodes.`

		// update state
		this.setState({nodeList: curNodeList, delNodeList:delNodeList, activeNode:null, msg:msg})


	}

	deleteLine(lineKey) {
		// method deletes line with given lineKey

		// if no node active, do nothing
		if (!lineKey) {
			return
		}

		// get current line List
		var lineList = this.state.lineList;

		// get line to delete
		var delLine = this.state.lineList[lineKey]

		// delete node from list
		delete lineList[lineKey]

		// get node labels for node1 and node 2
		let lineType = delLine.type;
		let nodeLabel1 = this.state.nodeList[delLine.nodes[0]].name;
		let nodeLabel2 = this.state.nodeList[delLine.nodes[1]].name;

		// create msg for new line
		let msg = `Deleted line type: ${lineType} between ${nodeLabel1} - ${nodeLabel2}`

		// update state
		this.setState({lineList: lineList, msg:msg})
	}

	reorderNodes(nodeList) {
		// method reorders node number labels and keys when node is removed

		// init reordered list
		const newNodeList = {}

		// loop through keys
		const nodeKeys = Object.keys(nodeList)
		for(let nn=0; nn<nodeKeys.length; nn++) {

			// get current node data
			let nodeData = nodeList[nodeKeys[nn]]

			// generate new node number
			let nodeNum = nn+1

			// generate new key and label
			const nodeNumStr = String(nodeNum).padStart(2, '0'); // '09'
			const nodeLabel = `node ${nodeNumStr}`

			// update node 'name' in cur node object
			nodeData.name = nodeLabel;
			nodeData.nodeIndex = nodeNum;

			// add to newNodeList
			newNodeList[nodeKeys[nn]] = nodeData


		}

		// return new reordered node list
		return newNodeList

	}

	generateTypeSelect(lineType, typeList) {
		// method generates a Select input for the instance of line 
		// based on selected type

		// return inactive select if no type given
		if(!lineType) {
			var options = (
				<option value="" selected disabled hidden>Select Model</option>
			)
		}
		else {
			var dynOptions = typeList[lineType].map((type, ii) => (
					<option value={ii}>{type}</option>
			    ))

			var options = (
				<>
				<option value="" selected disabled hidden>Select Model</option>
				{dynOptions}
				</>
			)
		}

		return (

				<select
					value={this.state.selectedModelIndex}
		            onChange={this.handleSelect} 
		            className='line-select node-input'
		            data-field='selectedModelIndex'
		            required
				>
					{options}

				</select>
		)

	} 

	handleSelect(e) {
		// method updates selection state when changes made to select inputs

		// get field frome event
		var selectField = e.target.dataset.field

		// get value
		var selectVal = e.target.value;

		console.log(`field:${selectField}, value:${selectVal}`)

		// update state
		this.setState({[selectField]:selectVal})



	}

	updateNodeProp(e) {
		// method updates the property of a node when changed in node menu

		// get node list from state
		const nodeList = this.state.nodeList;

		// get nodekey, field, and checked value from event
		const nodeKey = e.target.dataset.nodeKey;
		const fieldKey = e.target.dataset.fieldKey;

		const fieldVal = e.target.checked;

		// update value of node
		nodeList[nodeKey][fieldKey] = fieldVal;

		// update state
		this.setState({nodeList:nodeList})
	}

	updateMsg(msg) {
		// method updates the msg field of state
		// to display errors, warning, and updates

		if(!msg){
			msg =''
		}

		this.state({msg:msg})
	}

	submitWriteNetwork() {
		// method opens confirm menu to start write proces
		this.setState({writeConfirm: true, writeSuccess: false, writePending:false})
	}

	async writeNetwork() {
		// method to write network data to input tables

		// set submitPending to true
		await this.setState({writeConfirm: false, writePending: true})

		// wait for small period of time
		await Helper.sleep(this.state.writeDelay);

		// call props method to write data, with success flag returned
		const writeStatus = this.props.writeNetworkModel(this.state.nodeList, this.state.lineList);

	    // if write successful, switch to status to sucee
	    if(writeStatus) {
	      console.log('network written successful')

	      await this.setState({writeConfirm: false, writeSuccess: true, writePending:false})

	    } else {
	      console.log('network export failed')

	      await this.setState({writeConfirm: false, writeFailure: true, writePending:false})
	    }

	}

	returnToEditor() {
		// method returns to network editor from modal display
		this.setState({writeConfirm: false, writeFailure: false, writePending:false, writeSuccess:false})

	}

	renderHelpPane() {
		return(
			<div className='node-help-menu'>
			
			<div className='node-help-bar'>

						<h1 className="node-sand-box-header">DER-CAM Network Builder</h1>

						<div className='node-menu-btn-row'>

							<div className='node-menu-btn text-button node-menu-top-btn' onClick={this.toggleHelp}>
								<h2> Hide Help </h2>
							</div>
						</div>
					</div>

			<h4>How to Use</h4>

			<p>
				The <strong>Network Builder</strong> tool allows you to construct the topology of a project electrical and thermal network. 
				You can use this tool to add and modify nodes and lines that comprise your system. Once complete, 
				use the <strong>Export Network</strong> button to write the network to the specific tables in DER-CAM. 
				<br/><br/>
				Please note, changes made in the <strong>Network Builder</strong>  will only be reflected in your DER-CAM project once 
				you have exported the network to your project. Additional changes to the network can be made at any time, 
				but must be exported in order to be included in your final project data.
				<br/><br/>
				You can examine the data exported by the <strong>Network Builder</strong> by exploring the corresponding tables in
				the <strong>Tables</strong> page. However, any changes made directly in those tables will  not be reflected in the
				network data displayed in the <strong>Network Builder</strong>. We encourage you to only make changes to network data
				using the <strong>Network Builder</strong> tool.
				<br/><br/>
				Whenever making changes to your model, a message should be displayed in the <strong>Message</strong> pane, indicating the change 
				or any possible errors that may have occurred.
			</p>

			<h4>Modifying Nodes</h4>

			<p>
				The <strong>Network Builder</strong> opens with a generic network. You may add up to 20 nodes using the <strong>Add Node</strong> button located
				at the top of the page. You may drag nodes as needed within the network model menu. Nodes are automatically numbered
				as they are created and removed. Please use these node indexes when defining other data in your model (e.g. load profiles)
				<br/><br/>
				To edit the properties of a node, click the node to open the <strong>Node Details</strong> menu below the network model menu. Here you
				can define node properties including enabling load, setting as reference/slack bus, or adding a point of common coupling (PCC). 
				You may also delete the current node from the network.
			</p>

			<h4>Modifying Lines</h4>

			<p>
				When a node is selected, you will also see the <strong>Connections</strong> menu, which displays all existing connections from the selected
				node to other nodes in the network. You may use this menu to add new connections or remove existing connections.
				<br/><br/>
				Duplicate connection types may not be added between the same two nodes. E.g. two nodes may not share two connections both of
				type 'cable'. Additionally nodes may not share both a 'cable' and 'transformer' connection. They may share different thermal connections
				in addition to a single electrical connection.
				<br/><br/>
				The connection Model option correspond to properties outlined in Network tables in the <strong>Tables</strong> page. You may refer to these tables
				when selecting the connection type/model to use in your network. Any changes you make to those connection properties will be reflected
				in the network generated by the <strong>Network Builder</strong>, once exported to your project
			</p>

			<h4>Exporting Your Network</h4>

			<p>
				In order to be included in the your DER-CAM project data submitted to the optimization server, you must export the
				network data to your project using <strong>Export Network</strong>. Once exported, the current network data will be written
				to <strong>Tables</strong> in your DER-CAM project.
				You may update your network at any time prior to submission, but all changes must be exported in order to be included.
			</p>

			<div className='node-menu-btn text-button' onClick={this.toggleHelp}>
				<h2> Hide Help </h2>
			</div>

			<br/>


			</div>
		)
	}

	toggleHelp() {
		// method toggles displayHelp state variable to show/hide help info

		this.setState({displayHelp:!this.state.displayHelp})
	}

	renderSandbox() {


		return (
			<div className="node-page-container">
				<div className='node-sand-box-container'>

					

					<div className='node-menu-bar'>

						<h2 className="node-sand-box-header">Network Topology</h2>

						<div className='node-menu-btn-row'>
							<div className='node-menu-btn text-button node-menu-top-btn' onClick={this.addNewNode}>
								<h2> Add Node </h2>
							</div>

							<div className='node-menu-btn text-button node-menu-top-btn' onClick={this.submitWriteNetwork}>
								<h2> Export Network </h2>
							</div>

							<div className='node-menu-btn text-button node-menu-top-btn' onClick={this.toggleHelp}>
								<h2> Help </h2>
							</div>
						</div>
					</div>

					<div className="node-sand-box">

						{this.renderNodes()}

						{this.renderLines()}

					</div>

				</div>

				{this.renderMenus()}


			</div>
		)
	}

	render(){ 

		// if help pane is active
		if(this.state.displayHelp){

			return(
				this.renderHelpPane()
			)

		}

		// if submission pending
  		if(this.state.writeConfirm){

  			var msg = [
	  			'Your current network configuration will be written to your DER-CAM model.',
	  			<br/>,<br/>,
				'The data in the DER-CAM input tables will be updated to reflect the inputs ' +
				'as described here. If you modify those tables directly, those changes will ' +
				'not be reflected in this Network construction menu.',
				<br/>,<br/>,
				'If you wish to update or modify your model network, you can re-run the ' +
				'Write Network to Model function multiple times.'
			]


  			return(
  				<Modal
					label={<>Confirm Network Export</>}
					rotating={false}
					modalText={msg}
	  			>
	  				<div className='modal-icon-btn dark-btn' onClick={this.writeNetwork}>
						<h2 className='modal-btn-text'> Confirm </h2>
					</div>
					<br/>
					<div className='modal-icon-btn dark-btn' onClick={this.returnToEditor}>
						<h2 className='modal-btn-text'> Return </h2>
					</div>

	  			</Modal>
	  		)
  		}

  		// if submission pending
  		if(this.state.writePending){
  			return(
  				<Modal
	  				icon={<LoadIcon />}
					label={<>Exporting Network Data to Model</>}
					rotating={true}
	  			>

	  			</Modal>
	  		)
  		}


  		// if submission failed
  		if(this.state.writeFailure){
  			return(
	  			<Modal
	  				icon={<ErrorIcon />}
					label={<>Network Export Failed</>}
					rotating={false}
					modalText={'An error occurred when processing your network data. Please return the network editor and retry after a few minutes.'}
	  			>
	  				<div className='modal-icon-btn dark-btn' onClick={this.returnToEditor}>
						<h2 className='modal-btn-text'> Return </h2>
					</div>

	  			</Modal>
	  		)
  		}


  		// if submission succeeded
  		if(this.state.writeSuccess){
  			return(
	  			<Modal
	  				icon={<SuccessIcon />}
					label={<>Network Export Successful</>}
					rotating={false}
					modalText={'Your network data has been exported to input tables. You may confirm the changes to your project by examining tables in the Network section.'}
	  			>
	  				<div className='modal-btn dark-btn' onClick={this.returnToEditor}>
						<h2 className='modal-btn-text'> Return </h2>
					</div>

	  			</Modal>
	  		)
  		}


  		// else, display project builder
	  	if(!this.state.writePending) {
		    return (
		    	this.renderSandbox()
		    )

	    }

	}
  
} 


export default withRouter(NodeSandBox);