import React from 'react';

import './Inputs.css';


class InputSelect extends React.Component{ 	

  render(){ 

  	// create label if passed in props
  	var labelHtml = null
  	if(this.props.fieldLabel) {
  		labelHtml = (
  			<div className='np-label'>	
					<label htmlFor={this.props.fieldName} className='np-input-label'>
						{this.props.fieldLabel}: 
					</label>
				</div>
  		)  
  	}		

  	// console.log(`the init value for ${this.props.fieldName} is "${this.props.formValue}"`)

  	// create empty option if no value passed to formValue
  	var emptyOption = null
  	if(this.props.formValue=='' || this.props.formValue==null || !this.props.formValue){
  		
  		emptyOption = <option selected hidden value=' ' className='disabled-select-option'>{'  '}</option>
  		
  	}


    return( 
    	
    	// props needed: itemList, fieldName, formValue, onChange
		
		<div className='np-data np-field'> 
			{labelHtml}
			<select
				name={this.props.fieldName}
	            value={this.props.formValue}
	            onChange={this.props.onChange} 
	            className='np-input'
	            required
			>
				{emptyOption}
				{this.props.itemList.map((item) => (
					<option value={item}>{item}</option>
			    ))}
				}

			</select>
		</div>
		
    ) 
  } 
} 


export default InputSelect;