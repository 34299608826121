import React from 'react';

import './Modal.css'


class Modal extends React.Component {
	// stateless component to render a model 
	// e.g. for loading or error
	

	render(){

		// set icon classname based on 'rotating' prop
		var iconClass = 'modal-icon';
		if(this.props.rotating){
			iconClass = 'modal-icon modal-icon-rotating';
		}

		// format text if passed in props
		var modalText = null;
		if(this.props.modalText){
			modalText = (
				<div className='modal-text'>
					<p>{this.props.modalText}</p>
				</div>
			)
		}

		return (
			<div className="page-container">
				<div className='page-scroll-container'>
					<div className='modal-container'>
						<div className='modal-div'>
							<div className={iconClass}>
								{this.props.icon}
							</div>
							<h2 className='modal-label'> 
								{this.props.label}
							</h2>
							{modalText}
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		)
	}
};

export default Modal;

				