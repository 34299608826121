import React from 'react';

import './UserTerms.css';
import Navbar from '../Navbar/Navbar'
import terms from '../../resources/terms.js';
import vectors from '../../resources/vectors.js'

const closeSvg = vectors.closeSvg

class Terms extends React.Component {

	renderTerms() {
		return (
			<div className="terms-popup">
				<div className="terms-heading">
					<h2> DER-CAM API Terms of Service</h2>
					<div className='hide-terms' onClick={this.props.toggleTerms}>{closeSvg}</div>
				</div>
				<div className="terms-popup-inner">
					{terms}
				</div>
		    </div>
		)
	}

	render() {
		return (
			<div>{this.props.showTerms ? this.renderTerms() : null}</div>
		)
	}

}



export default Terms;

// <div className="terms-popup">
// 					<div className="terms-heading">
// 						<h2> DER-CAM API Terms of Service</h2>
// 						<div className='hide-terms' onClick={this.props.toggleTerms}>{closeSvg}</div>
// 					</div>
// 					<div className="terms-popup-inner">
// 						{terms}
// 					</div>
// 			    </div>

