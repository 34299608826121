import React from 'react';

import InputField from '../Inputs/InputField';
import InputSelect from '../Inputs/InputSelect';

import Modal from '../Modal/Modal';
import HelpBox2 from '../HelpBox/HelpBox2'

import { ReactComponent as LoadIcon } from '../../icons/loading.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

import Helper from '../../helper';

const _ = require('lodash');


class ProjectPageSolar extends React.Component {

	constructor(props) {
		super(props)

		this.state = {

			refData: null,

			dlPending: false,
			dlSuccess: false,
			dlFail: false,

			formatPending: false,
			formatSuccess: false,
			formatFail: false,

			dataErrorMsg: '',

			delay: 2000

		}

		this.returnToFormatter = this.returnToFormatter.bind(this);
		this.downloadData = this.downloadData.bind(this);


	}

	returnToFormatter() {
		// drops all state flags, so that component renders the menu

		this.setState(
			{
				dlPending: false,
				dlSuccess: false,
				dlFail: false,
				formatPending: false,
				formatSuccess: false,
				formatError: false,
				dataErrorMsg: ''
			}
		)
	}


	async downloadData() {

		await this.setState({dlPending:true})

		// download data from server
		await Helper.sleep(this.state.delay)

		// check that bldg id has been selected
		if(!Number.isInteger(this.props.loc_stationId) || this.props.loc_stationId<0){
			var dataErrorMsg = "Station ID missing or invalid."
			this.setState({dlPending:false, dlFail: true, dataErrorMsg: dataErrorMsg})
			return
		}

		if(!Number.isInteger(this.props.loc_stationId) || !this.props.loc_stationId<0){
			var dataErrorMsg = "Station ID missing or invalid."
			this.setState({dlPending:false, dlFail: true, dataErrorMsg: dataErrorMsg})
			return
		}


		// url to fetch data for building reference library
		let urlPvData = `${process.env.REACT_APP_URL_BASE}/data/pv/dl?locId=${this.props.loc_stationId}`;
		console.log(urlPvData)
		var refData = [];

		let response = await fetch(urlPvData, {credentials: 'include'});

	    // if status GET request successful
	    if(response.status===200) {
	      	refData = await response.json();
	    } else {
	    	var dataErrorMsg = "Server failed to return requested PV data"
			this.setState({dlPending:false, dlFail: true, dataErrorMsg: dataErrorMsg})
			return
	    }

		
		// create copy of load template
		var newPVData = _.cloneDeep(this.props.pvTemplate)
		const refPVData = _.cloneDeep(refData.data)

		// get month keys for refPVData
		const mKeys = Object.keys(refPVData); // month key list

		// loop through ref data keys
		for(let mm=0; mm<mKeys.length; mm++) {
			
			// get current keys
			let mKey = mKeys[mm];

			// get hourly data from ref data
			let hourlyData = refPVData[mKey];

			// round data to 4 decimal places
			hourlyData = hourlyData.map((val) => {
				return +(val).toFixed(4);
			})


			// get indices of current month key from template data
			let mInd = newPVData.rowLabels.indexOf(Helper.capitalizeFirst(mKey)); // have to capitalize month due to format mismatch


			// check that indices all successfully found
			if(mInd < 0) {
				console.log(`cannot find index for ${mKey}`)
				continue
			} else {
				// console.log(`copying data for ${eKey} ${dKey} ${mKey}`)
				// console.log(hourlyData)
			}

			// if all indices found, apply data to template
			newPVData.data[mInd] = hourlyData
				
		}


		// determine destination table location
		const destTableName = 'normalizedpvoutput'


		// write new data to table
		var writeStatus = this.props.replaceTable(newPVData, destTableName)
		// var writeStatus = {
		// 	success: true,
		// 	msg: "NO DATA WRITTEN. DISABLED FOR TESTING"
		// }


		// if success, return to formatter
		if(writeStatus.success){
			await this.setState({dlPending:false, dlSuccess: true, refData: refData, newPVData:newPVData})
		} else {
			var dataErrorMsg = writeStatus.msg
			await this.setState({dlPending:false, dlFail: true, refData: refData, newPVData:newPVData, dataErrorMsg:dataErrorMsg})
		}

		

	}



	renderRefMenu() {
		// method renders the menu section for importing reference data

		return(				
			<div className='npdc-section'>

				<h2 className='npdc-subheader'> Reference Solar PV Data </h2>

				<HelpBox2
					label='More Info'
				>
					<p className='npdc-text'>
						Use this menu to process to import reference Normalized PV generation
						profiles for existing U.S. weather station locations. Select PV configuration,
						and press download when ready. New data will then be written to current
						project input table. Note: this importer currently on works for normalized PV
						output. It does not population general solar irradiation (e.g. for solar thermal assets).
					</p>
				</HelpBox2>


				<table className='npdc-grid-table'>
					<tr>
						<td className='npdc-input-label'>
							State
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.stateList}
								fieldName='loc_stateUs'
								fieldLabel={null}
								formValue={this.props.loc_stateUs}
								onChange={this.props.handleChangePvStation}
								inTable={true}
							/>
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							Station Location
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.stationList}
								fieldName='loc_station'
								fieldLabel=''
								formValue={this.props.loc_station}
								onChange={this.props.handleChangePvStation}
							/>
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							PV Angle
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.pvAngleList}
								fieldName='pvAngle'
								fieldLabel={null}
								formValue={this.props.pvAngle}
								onChange={this.props.handleChange}
								inTable={true}
								data-type='str'
							/>
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td className='npdc-input-label'>
							PV Orientation
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={this.props.pvOrientList}
								fieldName='pvOrient'
								fieldLabel={null}
								formValue={this.props.pvOrient}
								onChange={this.props.handleChange}
								inTable={true}
								data-type='str'
							/>
						</td>
						<td>
							
						</td>
					</tr>

				</table>

				<br/>

				<div className='text-button npdc-button' onClick={this.downloadData}>
					<h2>Download & Apply</h2>
				</div>

			</div>
		)
		
	}


	renderManualMenu() {
		// method renders the menu section for processing user data manually

		return(
			<div className='npdc-section'>

				<h2 className='npdc-subheader'> Custom Solar Formatter [Disabled]</h2>

				<HelpBox2
					label='More Info'
				>
					<p className='npdc-text'>
						[Under Construction]<br/><br/>
						Use this menu to process custom solar profile data. Please paste 
						comma-separated hourly (8760) solar data into the input field. 
						Select the solar metric to be defined and press the Process button
						when ready.
					</p>
				</HelpBox2>

				<table className='npdc-grid-table'>

					<tr>
						<td className='npdc-input-label'>
							Annual Profile
						</td>
						<td>
							<InputField
								fieldName='bldg_size'
								fieldLabel=''
								formValue={123}
								onChange={this.props.handleChange}
								onExit={this.handleExitNumeric}
							/>
						</td>
						<td className='npdc-unit-label'>
							kW
						</td>
					</tr>

					<tr>
						<td><br/><br/></td>
					</tr>
					<tr>
						<td className='npdc-input-label'>
							Apply to Solar Table
						</td>
						<td>
							<InputSelect
								// props needed: itemList, fieldName, formValue, onChange
								itemList={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]}
								fieldName='bldg_type'
								fieldLabel={null}
								formValue={null}
								onChange={this.props.handleChangeBldg}
								inTable={true}
							/>
						</td>
						<td>
							
						</td>
					</tr>

				</table>

				<br/>

				<div className='text-button npdc-button  text-button-disabled' onClick={this.props.downloadData}>
					<h2>Format & Apply</h2>
				</div>

			</div>
		)
		
	}


	render() {

		if(this.state.dlPending){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<LoadIcon />}
							label={<>Downloading & Applying Reference Data</>}
							rotating={true}
			  			>

			  			</Modal>
		  			</div>
		  		</div>
			)
		}

		if(this.state.dlSuccess){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<SuccessIcon />}
							label={<>New Reference Data <br/>Successfully Written to Model</>}
							rotating={false}
			  			>
			  				<div className='modal-btn dark-btn' onClick={this.returnToFormatter}>
								<h2 className='modal-btn-text'> Return to PV Menu </h2>
							</div>

		  				</Modal>
	  				</div>
		  		</div>
			)
		}

		if(this.state.dlFail){
			return(
				<div className='page-scroll-container-new-project project-main-container'>

					<div className='npdc-container'>
						<Modal
			  				icon={<ErrorIcon />}
							label={<>Data Download Failed</>}
							rotating={false}
							modalText={
								'An error occurred when downloading or formatting the requested PV data.'+
								' Please return to the Solar menu and retry after a few minutes.'
							}
			  			>
			  				<div className='modal-text'>
								<br/><p>{`Error Msg: ${this.state.dataErrorMsg}`}</p>
							</div>

			  				<div className='modal-btn dark-btn' onClick={this.returnToFormatter}>
								<h2 className='modal-btn-text'> Return to PV Menu </h2>
							</div>

			  			</Modal>
		  			</div>
		  		</div>
			)
		}

		return(
			<div className='page-scroll-container-new-project project-main-container'>

				<div className='npdc-container'>
					<h1 className='npdc-header'> Solar Data Menu </h1>
	    			
	    			<div className='npdc-panel'>
	    				{this.renderRefMenu()}
	    				{this.renderManualMenu()}
					</div>	
				
				</div>

	    	</div>
		)

	}
}



export default ProjectPageSolar;