import React from 'react';
// import { BrowserRouter, Link, Route, Switch, Redirect } from 'react-router-dom';

import './ChangeLog.css';

import Navbar from '../Navbar/Navbar'


import Helper from '../../helper';

const exampleUpdates = [
	{
		date: '2023-05-07',
		title: 'example update 001',
		body: 'this is the top level example change. the code was changed in order to improve such and such a thing.'
	},
	{
		date: '2023-05-01',
		title: 'example update 002',
		body: 'tLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Volutpat blandit aliquam etiam erat velit scelerisque in dictum. Tincidunt vitae semper quis lectus nulla at volutpat. Egestas tellus rutrum tellus pellentesque eu. Tempus quam pellentesque nec nam aliquam sem et. Faucibus purus in massa tempor nec feugiat nisl pretium fusce. Eu ultrices vitae auctor eu augue ut. In hendrerit gravida rutrum quisque non tellus orci ac. Elit scelerisque mauris pellentesque pulvinar pellentesque..'
	},
	{
		date: '2023-04-22',
		title: 'example update 003',
		body: 'Mauris sit amet massa vitae. Arcu cursus euismod quis viverra nibh cras. Velit laoreet id donec ultrices tincidunt arcu non. Risus in hendrerit gravida rutrum. Vulputate ut pharetra sit amet. Imperdiet proin fermentum leo vel orci porta non pulvinar neque. In est ante in nibh mauris cursus mattis molestie a. Faucibus pulvinar elementum integer enim neque. Aenean et tortor at risus viverra adipiscing at. Aliquet lectus proin nibh nisl condimentum id venenatis.'
	},
	{
		date: '2023-04-01',
		title: 'example update 4',
		body: 'Adipiscing elit pellentesque habitant morbi. Vehicula ipsum a arcu cursus vitae congue mauris rhoncus. Consectetur libero id faucibus nisl tincidunt eget nullam non. Porta lorem mollis aliquam ut porttitor leo a. Amet justo donec enim diam vulputate ut. Et tortor at risus viverra adipiscing at in. Quam adipiscing vitae proin sagittis nisl rhoncus. Et sollicitudin ac orci phasellus egestas tellus rutrum tellus pellentesque. Sem integer vitae justo eget magna fermentum iaculis. Netus et malesuada fames ac turpis egestas maecenas pharetra. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Ac turpis egestas maecenas pharetra convallis posuere.'
	},
	{
		date: '2022-12-30',
		title: 'example update 5',
		body: 'Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus. Nisi quis eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Etiam non quam lacus suspendisse faucibus interdum posuere. Non enim praesent elementum facilisis leo vel fringilla est ullamcorper. Sit amet venenatis urna cursus eget. Odio facilisis mauris sit amet massa vitae.'
	},
	{
		date: '2022-12-25',
		title: 'example update 6',
		body: 'Leo in vitae turpis massa sed elementum tempus. Nibh nisl condimentum id venenatis a condimentum vitae sapien pellentesque. Diam maecenas sed enim ut sem viverra aliquet eget sit. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et.'
	},
	{
		date: '2022-11-30',
		title: 'example update 7',
		body: 'Enim nulla aliquet porttitor lacus luctus. Scelerisque viverra mauris in aliquam sem fringilla. A iaculis at erat pellentesque adipiscing. Auctor elit sed vulputate mi sit amet mauris. Quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus. Scelerisque viverra mauris in aliquam sem fringilla ut morbi. Nunc sed velit dignissim sodales ut. Neque viverra justo nec ultrices dui. Sit amet justo donec enim diam vulputate ut pharetra. Metus vulputate eu scelerisque felis. In mollis nunc sed id semper risus. Libero volutpat sed cras ornare arcu dui vivamus arcu. Vitae ultricies leo integer malesuada. Enim nec dui nunc mattis enim ut tellus elementum. Nibh ipsum consequat nisl vel pretium lectus. Eleifend donec pretium vulputate sapien. Id donec ultrices tincidunt arcu. Id nibh tortor id aliquet lectus proin. Mauris nunc congue nisi vitae suscipit tellus. Habitant morbi tristique senectus et netus et.'
	},
	{
		date: '2023-11-07',
		title: 'example update 8',
		body: 'Dui ut ornare lectus sit amet est placerat in egestas. Pharetra convallis posuere morbi leo urna molestie. Sit amet mattis vulputate enim nulla aliquet porttitor lacus. Lacus sed viverra tellus in hac habitasse platea dictumst. Donec adipiscing tristique risus nec feugiat. Quam viverra orci sagittis eu. Turpis tincidunt id aliquet risus feugiat. Lacus sed turpis tincidunt id aliquet. Imperdiet nulla malesuada pellentesque elit eget gravida cum. Nulla facilisi nullam vehicula ipsum. Est ante in nibh mauris cursus mattis molestie a. Nisi lacus sed viverra tellus in hac. Nunc sed id semper risus in. Id semper risus in hendrerit gravida. Mattis ullamcorper velit sed ullamcorper morbi.'
	}
]



class ChangeLog extends React.Component{
	constructor(props) {
		super(props)

		this.state = {
			updateList: []
		}

	}

	async componentDidMount() {
	    	// fetch change log from server

			const updateList = exampleUpdates;

			// function to check if API server is running
		    // and retrieve lastest task timestamps
		    // console.log('checking server status')
		    let urlFetch = `${process.env.REACT_APP_URL_BASE}/admin/updates`;

		    let response = await fetch(urlFetch, {credentials: 'include'});
		    console.log('fetching updates list')
		    // if status GET request successful
		    if(response.status===200) {
		      let result = await response.json();
		      console.log('fetch successful')
		      // get projects categorized by type
		      let updateList = result.updateList;

		      this.setState({updateList:updateList})

		    } else {
		    	console.log(response)
			    this.setState({updateList:[]})

		  }
	  }

	
  

  	render(){ 

  		





	    return( 
	    	
				<div className='sub-nav-container page-scroll-container'>

					<div className='update-list-container'>
					
						<h2 className='update-page-header'> DER-CAM Updates & Changes </h2>

						{this.state.updateList.map((updateItem) => {
							return(
								<div className='update-container'>
									<div className='update-header'>

										<div className='update-date'>
											{updateItem.date}
										</div>


										<div className='update-title'>
											{updateItem.title}
										</div>

									</div>

									<div className='update-body'>

										{updateItem.body}

									</div>

								</div>
							)
						})}

				    </div>
				</div>
	    ) 
  	} 

} 


export default ChangeLog;