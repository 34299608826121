import React from 'react';
import { withRouter } from 'react-router-dom';

import './Upload.css';

import Modal from '../Modal/Modal';

import { ReactComponent as LoadIcon } from '../../icons/loading.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

import Helper from '../../helper';

class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modelType: null, 

      ulPending: false,
      ulSuccess: false,
      ulFailed: false,

      ulData: null,
      ulValid: null,

      msg: null

    }

    this.processUpload = this.processUpload.bind(this);
    this.redirectToModel = this.redirectToModel.bind(this);
    this.returnToForm = this.returnToForm.bind(this);
    this.handleChange = this.handleChange.bind(this);


  }

  handleChange(event){ 

    var ulJson = {}
    var ulValid = null
    var msg = ''
    var modelType = null

    // parse input to json
    try{
      ulJson = JSON.parse(event.target.value)
      ulValid = true

      modelType = ulJson.modelType

      // check that model json contains necessary keys
      if(!ulJson.modelType){
        msg = 'Model missing required field "modelType"'
        ulValid = false
      } else if(!ulJson.modelData){
        msg = 'Model missing required field "modelData"'
        ulValid = false
      } else {
        msg = 'Model content validated'
      }


    } catch {
      ulJson = event.target.value
      ulValid = false
      msg = 'Data provided in not in valid JSON format'
    }

    // remove message if no content
    if(event.target.value.length == 0) {
      msg = ''
      ulValid = null
    }

    //update state to reflect change
    this.setState({ulData:ulJson, ulValid:ulValid, msg:msg, modelType:modelType})
  }


  async processUpload() {

    // set state to pending
    await this.setState({ulPending: true})

    // wait a few seconds
    await Helper.sleep(2000)

    // run processing code
    var procStatus = null

    try {

      var projType = this.state.modelType + 'Proj'
      var projData = this.state.ulData.modelData

      // add 'upload flag to model'
      projData.src = 'upload'

      this.props.saveProject(projType, projData)

      procStatus = true

    } catch(err) {

      console.log(err)

      procStatus = false

    }

    // if success set state to success
    if(procStatus) {
      await this.setState({ulPending: false, ulSuccess: true})
    } else {
      // if failed set state to failed
      await this.setState({ulPending: false, ulSuccess: false, ulFailed:true})
    }




  }


  redirectToModel() {

    // generate link to uploaded model
    var newUrl = `${this.props.baseUrl}/${this.state.modelType}/new`

    // execute redirect
    console.log('going to projects list')
    this.props.history.push(newUrl);

  }


  returnToForm() {


    // clear stored model data?


    // hide modal window by setting all flags to false
    this.setState({ulPending: false, ulSuccess: false, ulFailed:false})

  }
  

  renderUpload() {

    var ulButton = null;
    var msgIcon = null;

    // render button depending on value of state.ulValid
    if(this.state.ulValid) {
      ulButton = (
        <div className='text-button upload-button' onClick={this.processUpload}>
          <h2>Upload Model</h2>
        </div>
      )
      msgIcon = <SuccessIcon className='upload-icon-success'/>
    } else if(this.state.ulValid === false) {
      ulButton = (
        <div className='text-button upload-button upload-button-disabled text-button-disabled' >
          <h2>Invalid Model</h2>
        </div>
      )

        msgIcon = <ErrorIcon className='upload-icon-fail'/>
    } else if(this.state.ulValid === null) {
      ulButton = (
        <div className='text-button upload-button upload-button-disabled text-button-disabled '>
          <h2>Enter Model</h2>
        </div>
      )
    }

    return (
      <div className='page-scroll-container upload-page'> 
        <div className='upload-menu'> 
        
          <h2 className='upload-header'>Upload External Model to Account</h2>

          <textarea 
            className='upload-input'
            placeholder='Copy/paste model JSON file contents directly here.'
            onChange={this.handleChange}
          />

          <div className='upload-msg'>
            {msgIcon}
            <p>{this.state.msg}</p>
          </div>

          {ulButton}

        </div>
      </div>
            
    )
  }


  render(){ 

      // if submission pending
      if(this.state.ulPending){
        return(
          <Modal
            icon={<LoadIcon />}
          label={<>Procesing Uploaded Model</>}
          rotating={true}
          >

          </Modal>
        )
      }


      // if submission failed
      if(this.state.ulFailed){
        return(
          <Modal
            icon={<ErrorIcon />}
          label={<>Model Processing Failed</>}
          rotating={false}
          modalText={'An error occurred when processing your model data. Please return the upload page and confirm your model data is correct.'}
          >
            <div className='modal-icon-btn dark-btn' onClick={this.returnToForm}>
            <h2 className='modal-btn-text'> Return </h2>
          </div>

          </Modal>
        )
      }


      // if submission succeeded
      if(this.state.ulSuccess){
        return(
          <Modal
            icon={<SuccessIcon />}
          label={<>Model Upload Successful</>}
          rotating={false}
          modalText={'Your model has been successfully loaded. Please use the button below to open your new project in the model editor.'}
          >
            <div className='modal-btn dark-btn' onClick={this.redirectToModel}>
            <h2 className='modal-btn-text'> Open Model </h2>
          </div>

          </Modal>
        )
      }


      // else, display project builder
      if(!this.state.ulPending) {
        return(this.renderUpload())
      }

    }


}

export default withRouter(Upload);
