import React from 'react';

import './NavbarDercam.css';


import { ReactComponent as PrevIcon } from '../../icons/previous.svg';
import { ReactComponent as NextIcon } from '../../icons/next.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as HelpIcon } from '../../icons/help.svg';

import { ReactComponent as ChevronIcon } from '../../icons/chevronSolid.svg';
import { ReactComponent as CircleIcon } from '../../icons/circleOutline.svg';

class NavbarDercam extends React.Component {

	selectButtonStatus(disableStatus) {
		// function returns a classname to disable button if disableStatus boolean is true
		// otherwise returns nothing
		return disableStatus ? "disabled-icon-button" : null;
	}

	buttonClassName(activeState) {
		// returns class name for navbar btn obj based on active/inactive state
		// used for styling only

		return activeState ? 'npdc-subnav-btn-active' : 'npdc-subnav-btn';
	}

	buttonIconSvg(activeState) {
		// returns circleOutline for inactive, chevron for active

		return activeState ? <ChevronIcon/> : <CircleIcon/>;
	}

	pageActive(pageKey) {
		// checks if pageKey matches current currentPage passed in props

		return this.props.currentPage === pageKey;
	}

	generateButtonList() {
		// generates a list of nav buttons for each project page

		const pageDataList = [
			{
				pageKey: 'introPage',
				isActive: this.pageActive('introPage'),
				label: 'Guide'
			},
			{
				pageKey: 'configurePage',
				isActive: this.pageActive('configurePage'),
				label: 'Configure'
			},
			{
				pageKey: 'loadsPage',
				isActive: this.pageActive('loadsPage'),
				label: 'Loads'
			},
			{
				pageKey: 'solarPage',
				isActive: this.pageActive('solarPage'),
				label: 'Solar'
			},
			{
				pageKey: 'networkPage',
				isActive: this.pageActive('networkPage'),
				label: 'Network'
			},
			{
				pageKey: 'tablesPage',
				isActive: this.pageActive('tablesPage'),
				label: 'Tables'
			},
			{
				pageKey: 'submitPage',
				isActive: this.pageActive('submitPage'),
				label: 'Submit'
			}
		]

		const buttonList = pageDataList.map((pageData) => (
			<div 
				className={this.buttonClassName(pageData.isActive)}
				onClick={()=>{this.props.activatePage(pageData.pageKey)}}
			>
				{this.buttonIconSvg(pageData.isActive)}
				<h4>{pageData.label}</h4>
			</div>
		))

		return buttonList


	}

	render() {

		// gerenate button list
		const buttonList = this.generateButtonList()

		// determine which page is on
		var pageNum = this.props.pageList.indexOf(this.props.currentPage)
		var isFirstPage = (pageNum == 0)
		var isLastPage = (pageNum == (this.props.pageList.length-1))

		var prevPage = this.props.currentPage;
		var nextPage = this.props.currentPage;

		if(!isFirstPage){
			// if not on first page, set prev page
			prevPage = this.props.pageList[pageNum-1]
		}
		if(!isLastPage){
			// if not on first page, set prev page
			nextPage = this.props.pageList[pageNum+1]
		}

		return (
			<div className="npdc-nav-container">
				<div className="npdc-nav-container-top">
					<div className='npdc-nav-title'>
			        	<h1 className='npdc-nav-heading'>Create Project:</h1>
			        </div>

			        <div className='npdc-nav-proj-name'>
			        	<input 
							name='projName'
							data-type='text50'
							className='npdc-nav-nameinput'
							value={this.props.projectName} 
							onChange={this.props.onNameChange}
							onBlur={this.props.onNameExit}
							placeholder='Enter Project Name'
						/> 
			        </div>
			        
			        <div className='npdc-nav-menu-button-container'>

						<li className="npdc-nav-item">
						    <div 
						    	className={`icon-button ${this.selectButtonStatus(isFirstPage)}`} 
						    	onClick={()=>{this.props.activatePage(prevPage)}}
						    >
					        {<PrevIcon />}
					      </div>
					    </li>
					    <li className="npdc-nav-item">
						    <div 
						    	className={`icon-button ${this.selectButtonStatus(isLastPage)}`} 
						    	onClick={()=>{this.props.activatePage(nextPage)}}
						    >
					        {<NextIcon />}
					      </div>
					    </li>
					    <li className="npdc-nav-item">
					      <div className="icon-button disabled-icon-button" onClick={this.props.exitProject}>
					        {<CloseIcon />}
					      </div>
					    </li>
		        	</div>
		        </div>

		        <div className="npdc-nav-container-bottom">
		        	
					{buttonList}
			       
		        </div>
		        
		    </div>
		)
	}
}



export default NavbarDercam;