import React from 'react';
import { withRouter } from 'react-router-dom';

import { cloneDeep } from 'lodash';

import './AccountForms.css';

import Terms from './UserTerms'

import Helper from '../../helper';

import countryList from '../../resources/countries.js';


import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as HelpIcon } from '../../icons/help.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';


const industryList = [
	'Engineering', 
	'Academic/Research', 
	'Banking/Finance', 
	'US DOE National Lab',
	'US Gov - Federal', 
	'US Gov - State/Local', 
	'Gov - Non-US', 
	'Other'
]

// const blankForm = {
//   formData: {
//     first: '',
//     last:'',
//     email:'',
//     password:'',
//     confirmPassword:'',
//     country: null,
//     industry: null,
//     resendEmail: '',
//     accept: false,
//     text: ''
//   },

//   formDataValid: {
//     first: false,
//     last:false,
//     email:false,
//     password:false,
//     confirmPassword:false,
//     country: false,
//     industry: false,
//     resendEmail: false,
//     accept: false,
//     text: false
//   },

//   formValid:false,
//   formBlank:true,
// }


class NewRegister extends React.Component{ 
	constructor(props) {
		super(props)

		this.state = {
			showTerms: false,
			showPw: false,
			showPwRules: false,
			showUseText: false,
			// form: cloneDeep(blankForm),

			// initilize state of form
			formData: {
		    first: '',
		    last:'',
		    email:'',
		    password:'',
		    country: null,
		    industry: null,
		    accept: false,
		    text: ''
		  },

		  formDataValid: {
		    first: false,
		    last:false,
		    email:false,
		    password:false,
		    country: false,
		    industry: false,
		    accept: false,
		    text: false
		  },

		  formValid:false,
		  formBlank:true,
		  showInvalid: false,
			errors: {},
		}

  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.checkValidForm = this.checkValidForm.bind(this);
	this.toggleTerms = this.toggleTerms.bind(this);
	this.togglePwVis = this.togglePwVis.bind(this);
	this.togglePwRulesVis = this.togglePwRulesVis.bind(this);
	this.toggleUseTextVis = this.toggleUseTextVis.bind(this);
	this.redirectReset = this.redirectReset.bind(this);

	}


  // Form submitting logic, prevent default page refresh  
  async handleSubmit(event) { 

    const {first, last, email, country, industry, password, text} = this.state.formData;
    event.preventDefault()

    // if form is not valid, do nothing
  	if(!this.state.formValid) {
  		// if attempting to submit before form is valid, show invalid fields
  		this.setState({showInvalid: true})
  		console.log('cannot submit invalid form')
  		return;
  	}

    let data =  {
        'firstname': first,
        'lastname': last,
        'email': email,
        'country': country,
        'industry': industry,
        'password': password,
        'text': text
    }

    let urlAddUser = `${process.env.REACT_APP_URL_BASE}/auth/register`;

    let response = await fetch(urlAddUser, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    });

    if(response.status===200) {
      // new account request successfully received by server
      // redirect to req (successful request) page
      console.log('new acct req successful')
	    this.props.history.push({
		    pathname: '/acct/newreq',
		  })
    } else if(response.status===409) {
      // console.log(response.status)
      // console.log(response.statusText)
      this.setState({errors:{duplicateError:true}})
    }else {
      console.log(response.status)
      console.log(response.statusText)
      this.setState({errors:{requestFailed:true}})
    }
    
  } 


  async checkValidForm(){
  	// this method checks the current content of the reg form
  	// and updates state after checking validity of each form field

  	// extract current form object
  	var curForm = this.state.formData
  	var curValid = this.state.formDataValid

  	// check data for each form
    curValid.email = Helper.emailIsValid(curForm.email);
    curValid.first = Helper.nameIsValid(curForm.first);
    curValid.last = Helper.nameIsValid(curForm.last);
    curValid.password = Helper.pwIsValidComplex(curForm.password);
    curValid.country= (curForm.country !== null);
    curValid.industry = (curForm.industry !== null);
    curValid.accept = (curForm.accept);
    curValid.text = Helper.textIsValid(curForm.text);

    // check if all fiels in curValid are true
    var formValid = Object.values(curValid).every(item => item === true)

    this.setState(
    {
    	formValid: formValid,
    	formDataValid: curValid
    }
    )


  }

  
  handleChange(event){ 

    // initialize new form data as previous form data
    var curFormData = this.state.formData;

    // update form data with changed value
    if(event.target.name==='accept'){
      curFormData[event.target.name] = event.target.checked;
    } else {
      curFormData[event.target.name] = event.target.value;
    }

    // update state, then check validity
    this.setState({formData: curFormData}, this.checkValidForm)
    
  }


	toggleTerms() {
		this.setState({showTerms:!this.state.showTerms})
	}

	togglePwVis() {
		this.setState({showPw:!this.state.showPw})
	}

	togglePwRulesVis() {
		this.setState({showPwRules:!this.state.showPwRules})
	}

	toggleUseTextVis() {
		this.setState({showUseText:!this.state.showUseText})
	}


	redirectReset() {
		this.props.history.push({
	    pathname: '/acct/reset',
	  })
	}
	  

	styleSubmitButton(entryValid) {
		if(entryValid) {
			return 'form-button valid-form-button reg-new-button';
		} else {
			return 'form-button invalid-form-button reg-new-button';
		}
	}


	generateSelectList(itemList, fieldName, formValue, formValid){
		// generates list of options for select fields in signup form
		return(
			<div className='form-field'> 
				<div className='form-spacer'></div>
				<label htmlFor={fieldName.toLowerCase()} className='form-label'>{fieldName}</label>
				<select
					className={`form-input ${this.fieldStatusClass(formValid)}`}
					name={fieldName.toLowerCase()}
					placeholder={`'${fieldName}'`}
		            value={formValue} 
		            onChange={this.handleChange} 
		            required
				>
					<option disabled selected value className='disabled-select-option'>  </option>
					{itemList.map((item) => (
						<option value={item}>{item}</option>
				    ))}
			</select>
			<div className='form-spacer'></div>
		</div>
		)
	}


	generateFooter(formType) {

		if(this.state.errors.requestFailed) {
			return (
				<div className='form-footer'>
					<ErrorIcon/>
			      	<p>Request failed. Please try again later.</p>
			    </div>
			)

		}
		
		if(!this.state.errors.duplicateError) {
			return (
				<div className='form-footer'>
			      	<p>Forgot Password? 
			      		<span className='form-footer-link' onClick={this.redirectReset}>Request Password Reset.</span>
			      	</p>
			    </div>
			)

		} else {
			return (
				<div className='form-footer'>
					<ErrorIcon/>
			      	<p>An account with this email already exists.
			      		<span className='form-footer-link' onClick={this.redirectReset}>Request Password Reset.</span>
			      	</p>
			    </div>
			)
		}

	}


	fieldStatusClass(validBool) {
		// method checks field status.
		// returns field-invalid if validBool is false
		// if this.state.showInvalid is also true

		if(this.state.showInvalid) {
			if(!validBool){
				return 'field-invalid'
			} else {
				return ''
			}
		} else {
			return ''
		}
	}


	

	renderNewForm() {


		return (
			<section className = 'form-section'>
				<h1>Create DER-CAM account</h1>
					
			      	<form  className='signup-form'  onSubmit={this.handleSubmit}> 
			      	<div className='form-field'> 
			      		<div className='form-spacer'></div>
			          <label htmlFor='first' className='form-label'>First Name</label> 
			          <input 
			            name='first' 
			            className={`form-input ${this.fieldStatusClass(this.state.formDataValid.first)}`}
			            value={this.state.formData.first} 
			            onChange={this.handleChange} 
			            required
			          />
			          <div className='form-spacer'></div>
			        </div> 

			        <div className='form-field'> 
			        	<div className='form-spacer'></div>
			          <label htmlFor='last' className='form-label'>Last Name</label> 
			          <input 
			            name='last' 
			            className={`form-input ${this.fieldStatusClass(this.state.formDataValid.last)}`}
			            value={this.state.formData.last} 
			            onChange={this.handleChange} 
			            required
			          />
			          <div className='form-spacer'></div> 
			        </div> 

			        <div className='form-field'>
			        	<div className='form-spacer'></div> 
			          <label htmlFor='email' className='form-label'>Email Address</label> 
			          <input  
			            name='email'
			            className={`form-input ${this.fieldStatusClass(this.state.formDataValid.email)}`}
			            value = {this.state.formData.email} 
			            onChange={this.handleChange} 
			            required
			          />
			          <div className='form-spacer'></div> 
			        </div> 

			        <div className='form-field'>
			        	<div className='form-spacer'></div> 
			          <label htmlFor='last' className='form-label'>Password</label> 
			          <input 
			            name='password' 
			            className={`form-input ${this.fieldStatusClass(this.state.formDataValid.password)}`}
			            type={this.state.showPw ? 'text' : 'password'}
			            value={this.state.formData.password} 
			            onChange={this.handleChange} 
			            required
			          />
			          <div className='form-icons'>
			          	<EyeIcon onClick={this.togglePwVis}/>
			          	<HelpIcon onClick={this.togglePwRulesVis}/>
			          </div> 
			        </div>

			        <div className={`form-text-toggle-div ${this.state.showPwRules ? ' ' : 'display-none'}`}>
			        	
			        	<div className='form-text-div'>
				        	<p>
				 	        	Password must be 8+ characters, and include uppercase, lowercase, numeric, and special characters
				 	        	or 14+ characters of any type.
				 	        </p>
				 	      </div>
			 	        
			        </div>


			        {this.generateSelectList(countryList, 'Country', this.state.formData.country, this.state.formDataValid.country)}

			        {this.generateSelectList(industryList, 'Industry', this.state.formData.industry, this.state.formDataValid.country)}

			        <div className='form-field-tos'> 
			        	<div className='form-spacer'></div>
			          <label htmlFor='accept' className='form-checkbox-label'>
			          	Agree to <span className='tos-link' onClick={this.toggleTerms}>Terms of Service</span>
			          </label> 
			          <input  
			            name='accept'
			            type="checkbox"
			            checked = {this.state.formData.accept} 
			            onChange={this.handleChange} 
			            className={`form-checkbox ${this.fieldStatusClass(this.state.formDataValid.accept)}`}
			            required
			          /> 
			          <div className='form-spacer'></div>
			        </div> 
			        <br/>
			        <div className='form-field'> 
			        	<div className='form-spacer'></div>
			          <label htmlFor='text' className='form-question'>How do you plan to use DER-CAM?</label> 

			          <div className='form-icons'>
			          	<HelpIcon onClick={this.toggleUseTextVis}/>
			          </div> 
			        </div> 

			        <div className={`form-text-toggle-div ${this.state.showUseText ? ' ' : 'display-none'}`}>
			        	
			        	<div className='form-text-div'>
				        	<p>
				 	        	This information helps us understand how our tools are being used by the public,
				 	        	develop new features, and improve performance.
				 	        	<br/><br/>
				 	        	We also use this information to review and approve new accounts, and ensure our tools are
				 	        	being used properly.
				 	        	<br/><br/>
				 	        	Maximum length 500 characters.
				 	        </p>
				 	      </div>
			 	        
			        </div>

			        <div className='form-field'> 
			        	<div className='form-spacer'></div>
			          <textarea 
			            name='text' 
			            className={`form-input-text ${this.fieldStatusClass(this.state.formDataValid.text)}`}
			            value={this.state.formData.text} 
			            onChange={this.handleChange} 
			            required
			          />
			          <div className='form-spacer'></div> 
			        </div> 

			        <div className='form-field'> 
			        	<div className='form-spacer'></div>
			          <button className={this.styleSubmitButton(this.state.formValid)}><h2>Submit</h2></button> 
			        	<div className='form-spacer'></div>
			        </div> 

			        <div className={`form-text-toggle-div ${this.state.showInvalid ? ' ' : 'display-none'}`}>
			        	<div className='form-text-div'>
			        		<div className='form-text-div-icon'>
			        			<ErrorIcon/>
			        		</div>
				        	<h4>
				 	        	There is a problem with your application.
				 	        </h4>
				 	        <p>
				 	        	Please correct or update any highlighted fields to complete your application.
				 	        	<br/><br/>
				 	        	Application requirements include:
				 	        </p>
				 	        <ul>
				 	        	<li>
				 	        		All fields are required
				 	        	</li>
				 	        	<li>
				 	        		Name fields may not contain numbers or special characters
				 	        	</li>
				 	        	<li>
				 	        		Email address must be valid format
				 	        	</li>
				 	        	<li>
				 	        		Password must be 8+ characters
				 	        	</li>
				 	        	<li>
				 	        		Passwords under 14 characters must include uppercase, lowercase, numeric, and special characters
				 	        	</li>
				 	        </ul>
			 	        </div>
			        </div>

			    </form> 
		      	{this.generateFooter('newAccount')}
		    </section>
		)
	}
  

  render(){ 
    return( 

			<>
				{this.renderNewForm()}
				<Terms showTerms={this.state.showTerms} toggleTerms={this.toggleTerms}/>   
			</>

    ) 
  } 
} 


export default withRouter(NewRegister);